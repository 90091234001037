// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #232ed1;
  --secondary-color: #8390fa;
  --text-color-start: #393e44;
  --text-color-start-primary: #2c2c34;
  --text-color-app: #0d1821;
  --divider-color: #adb5bd;
  --background-main: rgba(13, 24, 33, 0.05);
  --background-lvl-0: #fff;
  --background-lvl-1: #fff;
  --disabled-color: #42444e;
  --drop-color: #f1f3fd54;
  --tertiary-color: rgba(72, 105, 225, 0.05);
  --chart-1: rgb(9, 38, 56);
  --chart-2: rgb(4, 65, 102);
  --chart-3: rgb(0, 114, 184);
  --chart-4: rgb(10, 169, 222);
  --chart-5: rgb(217, 70, 140);
  --chart-6: rgb(210, 210, 235);
  --chart-7: rgb(232, 92, 34);
  --chart-8: rgb(119, 66, 73);
}
`, "",{"version":3,"sources":["webpack://./src/styles/universalStyles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,2BAA2B;EAC3B,mCAAmC;EACnC,yBAAyB;EACzB,wBAAwB;EACxB,yCAAyC;EACzC,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;EACzB,uBAAuB;EACvB,0CAA0C;EAC1C,yBAAyB;EACzB,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,4BAA4B;EAC5B,6BAA6B;EAC7B,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":[":root {\n  --primary-color: #232ed1;\n  --secondary-color: #8390fa;\n  --text-color-start: #393e44;\n  --text-color-start-primary: #2c2c34;\n  --text-color-app: #0d1821;\n  --divider-color: #adb5bd;\n  --background-main: rgba(13, 24, 33, 0.05);\n  --background-lvl-0: #fff;\n  --background-lvl-1: #fff;\n  --disabled-color: #42444e;\n  --drop-color: #f1f3fd54;\n  --tertiary-color: rgba(72, 105, 225, 0.05);\n  --chart-1: rgb(9, 38, 56);\n  --chart-2: rgb(4, 65, 102);\n  --chart-3: rgb(0, 114, 184);\n  --chart-4: rgb(10, 169, 222);\n  --chart-5: rgb(217, 70, 140);\n  --chart-6: rgb(210, 210, 235);\n  --chart-7: rgb(232, 92, 34);\n  --chart-8: rgb(119, 66, 73);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
