import Dexie from "dexie";

class fileColumnSearchbar {
  constructor(dbName, objectStoreName) {
    // Erstelle eine neue Dexie-Datenbank mit dem übergebenen Namen
    this.db = new Dexie(dbName);
    this.objectStoreName = objectStoreName;
    this.dbName = dbName;
  }

  async searchDatabase(indexColumn, searchTerm) {
    await this.db.open();
    const results = await this.db
      .table(this.objectStoreName)
      .where(indexColumn)
      .startsWithIgnoreCase(searchTerm)
      .toArray();

    const uniqueValuesSet = new Set();
    const uniqueResults = [];

    for (const item of results) {
      const value = item[indexColumn];
      if (!uniqueValuesSet.has(value)) {
        uniqueValuesSet.add(value);
        uniqueResults.push(value);

        // Stoppe, wenn 10 eindeutige Einträge gefunden wurden
        if (uniqueResults.length >= 10) {
          break;
        }
      }
    }

    return uniqueResults;
  }

  async firstUniqueValues(indexColumn) {
    await this.db.open();

    const allResults = await this.db
      .table(this.objectStoreName)
      .limit(150)
      .toArray();

    // Erstelle ein Set, um nur einzigartige Werte des indexColumn zu behalten
    const uniqueValuesSet = new Set();
    const uniqueResults = [];

    for (const item of allResults) {
      const value = item[indexColumn];
      if (!uniqueValuesSet.has(value)) {
        uniqueValuesSet.add(value);
        uniqueResults.push(value);

        // Stoppe, wenn 10 eindeutige Einträge gefunden wurden
        if (uniqueResults.length >= 10) {
          break;
        }
      }
    }

    return uniqueResults;
  }

  async closeDatabase() {
    try {
      this.db.close();
      console.log("Datenbank geschlossen.");
    } catch (error) {
      console.error("Fehler beim Schließen der Datenbank:", error);
    }
  }
}

export default fileColumnSearchbar;
