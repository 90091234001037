import React, { Component } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default class Troubleshooting extends Component {
  clearAllLocalStorage = () => {
    localStorage.clear();
    this.deleteAllDatabases();
  };

  deleteAllDatabases() {
    // Holen aller Datenbanknamen
    indexedDB
      .databases()
      .then((databases) => {
        databases.forEach((dbInfo) => {
          // Jede Datenbank einzeln löschen
          indexedDB.deleteDatabase(dbInfo.name);
        });
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Datenbanken:", error);
      });
  }
  render() {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <div
          style={{
            height: "7.5vh",
            backgroundColor: "var(--primary-color)",
            color: "white",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "15px",
            fontSize: "45px",
          }}
        >
          {" "}
          SV
        </div>
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Delete all databases (filter profiles and filter criterions will
              be kept):
            </p>
            <Button
              variant="contained"
              color="error"
              onClick={this.deleteAllDatabases}
            >
              Delete Databases
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Delete all locally stored data (including filter profiles and
              filter criterions):
            </p>
            <Button
              variant="contained"
              color="error"
              onClick={this.clearAllLocalStorage}
            >
              Delete all data
            </Button>
          </div>
        </div>
        <div
          style={{
            height: "5vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {" "}
          <Link to="/">
            <Button variant="contained">back to website</Button>
          </Link>
        </div>
      </div>
    );
  }
}
