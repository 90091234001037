// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navItem {
  position: relative;
  color: white;
  cursor: pointer;
}

.navItem:hover {
  color: var(--secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/AceternityComponents/floating-navbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".navItem {\n  position: relative;\n  color: white;\n  cursor: pointer;\n}\n\n.navItem:hover {\n  color: var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
