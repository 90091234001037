import React, { Component } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";

import PropTypes from "prop-types";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

import "./OverviewSavedCriterions.css";

export default class OverviewSavedCriterions extends Component {
  // Component that displayes all saved filter criterions of the overview component -> allows to manage hidden and show criterions
  constructor(props) {
    super(props);
    this.state = { tabValue: 0, hiddenCriterions: {} };
  }

  handleChange = (event, newValue) => {
    //handles the chnage of tabs
    this.setState({ tabValue: newValue });
  };

  mapObject(savedCriterions) {
    // maps the prop savedCriterions Object
    let filenames = [];
    for (const key in savedCriterions) {
      filenames.push(key);
    }

    return filenames;
  }

  findHiddenCriterions(savedCriterions, displayedCriterions) {
    // returns array of all criterions that are not displayed
    let hiddenCriterions = {};
    var displayedCriterionsCopy = structuredClone(displayedCriterions);

    for (const key in savedCriterions) {
      hiddenCriterions[key] = [];
      for (let i = 0; i < savedCriterions[key].length; i++) {
        if (displayedCriterionsCopy[key] !== undefined) {
          const findObj = displayedCriterionsCopy[key].find(
            (o) => o.id === savedCriterions[key][i].id
          );

          if (findObj === undefined) {
            hiddenCriterions[key].push(savedCriterions[key][i]);
          }
        } else {
          hiddenCriterions[key].push(savedCriterions[key][i]);
        }
      }
    }

    return hiddenCriterions;
  }

  /**----------------------------------------------react functions--------------------------------------------------------------------------------------------------------------------------------------- */

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.displayedCriterions !== this.props.displayedCriterions) {
      //set hidden and displayed criterions
      this.setState({
        hiddenCriterions: this.findHiddenCriterions(
          this.props.savedCriterions,
          this.props.displayedCriterions
        ),
      });
    }
  }

  componentDidMount() {
    var tabValueOpenedCrit = this.props.loadedFileList.findIndex(
      (o) => o.name === this.props.openFileFilter
    );

    //set hidden and displayed criterions
    this.setState({
      tabValue: tabValueOpenedCrit,
      hiddenCriterions: this.findHiddenCriterions(
        this.props.savedCriterions,
        this.props.displayedCriterions
      ),
    });
  }

  render() {
    return (
      <div>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: 224,
            width: "50vw",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.tabValue}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              fontFamily: "Spline Sans",
            }}
          >
            {this.props.loadedFileList.map((file, index) => (
              <Tab label={file.name} {...a11yProps(index)} />
            ))}
          </Tabs>
          {this.props.loadedFileList.map((file, index) => (
            <TabPanel value={this.state.tabValue} index={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40vw",
                  fontFamily: "Spline Sans",
                }}
              >
                <div>
                  <div
                    style={{
                      height: "20px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="heading-text">SHOWN CRITERIONS</span>
                    <VisibilityIcon
                      style={{ color: "#4169e1", marginRight: "18px" }}
                    />
                  </div>
                  <div className="display-box">
                    <List>
                      {this.props.displayedCriterions[file.name] !==
                        undefined &&
                        this.props.displayedCriterions[file.name].map(
                          (crit, index) => (
                            <ListItem
                              key={index}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    this.props.deleteFilterCriterion(
                                      file.name,
                                      crit.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={() =>
                                  this.props.hideCriterion(file.name, crit.id)
                                }
                                dense="true"
                              >
                                <ListItemText
                                  id={crit.id}
                                  primary={crit.name}
                                  style={{ fontFamily: "Spline Sans" }}
                                />
                              </ListItemButton>
                            </ListItem>
                          )
                        )}
                    </List>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <ExpandLessIcon style={{ fontSize: 50 }} />
                  <ExpandMoreIcon style={{ fontSize: 50 }} />
                </div>
                <div>
                  <div
                    style={{
                      height: "20px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="heading-text">HIDDEN CRITERIONS</span>
                    <VisibilityOffIcon
                      style={{ color: "#4169e1", marginRight: "18px" }}
                    />
                  </div>
                  <div className="display-box">
                    <List>
                      {this.state.hiddenCriterions[file.name] !== undefined &&
                        this.state.hiddenCriterions[file.name].map(
                          (crit, index) => (
                            <ListItem
                              key={index}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    this.props.deleteFilterCriterion(
                                      file.name,
                                      crit.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={() =>
                                  this.props.addFilterCriterionFromLocal(
                                    file.name,
                                    crit
                                  )
                                }
                                dense="true"
                              >
                                <ListItemText
                                  id={crit.id}
                                  primary={crit.name}
                                />
                              </ListItemButton>
                            </ListItem>
                          )
                        )}
                    </List>
                  </div>
                </div>
              </div>
            </TabPanel>
          ))}
        </Box>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------ tabs functions from MUI------------------------------------------------------------------------------------------------------------- */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
