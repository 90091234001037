import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import { Tooltip, Divider } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import "./PatientDataview.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ flexGrow: 1, overflow: "hidden" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height: "100%", overflowY: "auto" }}>{children}</Box>
      )}
    </div>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const btnStyle = { height: "30px", width: "30px" };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4169e1",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 400,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Spline Sans",
    fontWeight: 300,
    lineHeight: "1rem",
    padding: "8px",
  },
  "&.encounterCell:hover": {
    color: "#aabcf2",
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class PatientDataview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      rowsPerPage: 25,
      page: 0,
      order: "asc",
      orderBy: "",
      tableToBig: false,
      checkedIfTableFits: true,
    };

    this.checkOverflow = this.checkOverflow.bind(this);
    this.containerRef = React.createRef();
  }

  /*------------------------------------------state handle functions-------------------------------------------------------------------------------------------------------------------- */

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue, page: 0, orderBy: "" }, () =>
      this.props.scrollToTop()
    );
  };

  onClick = (index, indexObject, encounterId) => {
    if (index === indexObject.indexOfEncounterId) {
      this.props.openEncounter(encounterId);
    } else if (index === indexObject.indexOfEncounter) {
      this.props.openEncounter(encounterId);
    }
  };

  checkOverflow = () => {
    const container = this.containerRef.current;

    if (container) {
      const isOverflowing = container.scrollWidth > container.offsetWidth;
      this.setState(
        { tableToBig: isOverflowing, checkedIfTableFits: false },
        () => {
          this.setState({ checkedIfTableFits: true });
        }
      );
    }
  };

  /*---------------------------------------- page functions ---------------------------------------------------------------------------------------------------------------------- */

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  /*---------------------------------------- sort functions ---------------------------------------------------------------------------------------------------------------------- */

  handleRequestSort = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  sortAlphabetically(arr) {
    return arr.sort((a, b) => a.typ.localeCompare(b.typ));
  }

  /*---------------------------------------- display functions---------------------------------------------------------------------------------------------------------------------- */

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  noData(data) {
    if (data.data.length === 0) {
      return <h4>No {data.typ} available.</h4>;
    }
  }

  mapDataView(data) {
    var displayArray = [];

    for (const key in data) {
      displayArray.push(data[key]);
    }

    return displayArray;
  }

  mapTableBody(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT" && key !== "_id" && key !== "primaryKey") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  checkForEncounter(index, indexOfEncounter, view) {
    if (index === indexOfEncounter && view === "dataView") {
      return "encounterTd";
    } else {
      return "";
    }
  }

  mapTableHead(data, tableToBig) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT" && key !== "_id" && key !== "primaryKey") {
        var splicedKey = { key: key };
        if (key.length > 10 && tableToBig) {
          splicedKey["displayName"] = key.slice(0, 3);
          splicedKey["tooltip"] = true;
        } else {
          splicedKey["displayName"] = key;
          splicedKey["tooltip"] = false;
        }
        displayArray.push(splicedKey);
      }
    }
    return displayArray;
  }

  checkForAge(data) {
    if (data !== null) {
      if (data.years !== undefined) {
        return data.years;
      } else {
        return data;
      }
    } else {
      return data;
    }
  }

  returnPatientTable(data, tableToBig) {
    let patientDataHead = this.mapTableHead(data.data[0], tableToBig);
    let indexOfEncounter = undefined;
    let indexOfCode = undefined;
    let indexOfEncounterId = undefined;

    for (let i = 0; i < patientDataHead.length; i++) {
      if (patientDataHead[i].key === "CODE") {
        indexOfCode = i;
      }

      if (patientDataHead[i].key === "ENCOUNTER") {
        indexOfEncounter = i;
      }

      if (data.typ === "encounters" && patientDataHead[i].key === "Id") {
        indexOfEncounterId = i;
      }
    }

    const indexObject = {
      indexOfEncounter: indexOfEncounter,
      indexOfCode: indexOfCode,
      indexOfEncounterId: indexOfEncounterId,
    };

    if (data.typ !== "patient") {
      return (
        <Paper
          style={{
            overflow: "hidden",
            position: "relative",
            width: "100%",
            height: "100%",
            marginRight: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableContainer
            ref={this.containerRef}
            sx={{ fontSize: "14px", flexGrow: 1, overflowY: "auto" }}
          >
            <Table aria-label="patDataviewTable" stickyHeader>
              <TableHead>
                <TableRow>
                  {patientDataHead.map((data, index) => (
                    <StyledTableCell
                      key={data.key}
                      sortDirection={
                        this.state.orderBy === data.key
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === data.key}
                        direction={
                          this.state.orderBy === data.key
                            ? this.state.order
                            : "asc"
                        }
                        onClick={this.handleRequestSort(data.key)}
                        sx={{ fontFamily: "Spline Sans" }}
                      >
                        {data.tooltip ? (
                          <Tooltip title={data.key} arrow>
                            <span>{data.displayName}</span>
                          </Tooltip>
                        ) : (
                          data.displayName
                        )}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.stableSort(
                  data.data,
                  this.getComparator(this.state.order, this.state.orderBy)
                )
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((x, i) => (
                    <StyledTableRow key={"rowTbody" + i}>
                      {this.mapTableBody(x).map((y, index) => (
                        <StyledTableCell
                          onClick={() => {
                            this.onClick(index, indexObject, y);
                          }}
                          className={
                            indexOfEncounter === index ||
                            indexOfCode === index ||
                            indexOfEncounterId === index
                              ? "encounterCell"
                              : ""
                          }
                        >
                          {indexOfEncounter === index ||
                          indexOfCode === index ||
                          indexOfEncounterId === index ? (
                            <Tooltip
                              title={
                                indexOfEncounter === index ||
                                indexOfEncounterId === index
                                  ? "See all entries of encounter " + y
                                  : "Open " + y + " in Chart"
                              }
                              enterDelay={200}
                            >
                              <span>{y}</span>
                            </Tooltip>
                          ) : y instanceof Date ? (
                            this.formatDate(y)
                          ) : (
                            y
                          )}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />

          <div style={{ height: "35px" }}>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                30,
                50,
                100,
                200,
                500,
                { label: "All", value: -1 },
              ]}
              component="div"
              count={data.data.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              selectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  "margin-top": "0",
                  "margin-bottom": "0",
                },
                ".MuiTablePagination-selectLabel": {
                  "margin-top": "0",
                  "margin-bottom": "0",
                },
                ".MuiTablePagination-toolbar": {
                  minHeight: "35px",
                  height: "35px",
                },
              }}
            />
          </div>
        </Paper>
      );
    } else {
      patientDataHead = this.mapTableHead(data.data, tableToBig);
      return (
        <TableContainer
          ref={this.containerRef}
          style={{
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
            height: "200vh",
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              padding: "2px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              {patientDataHead.map((head, index) => (
                <TableRow key={"rowTHead" + index}>
                  <StyledTableCell key={head.key + index + "tabHead"}>
                    {head.key}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {this.mapTableBody(data.data).map((data, index) => (
                <StyledTableRow
                  key={data + index + "tabBody"}
                  style={{ height: "57px", flexGrow: "1" }}
                >
                  <StyledTableCell>
                    {data instanceof Date
                      ? this.formatDate(data)
                      : this.checkForAge(data)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }

  /*------------------React functions-------------------------------------------------------------------------------------------------------------------------------------------- */

  componentDidMount() {
    this.props.scrollToTopFast();
    this.props.whichLocationWhenMounted();
    this.checkOverflow(); //checks if table is too big for screen

    //set patient view as open tab
    var indexOf = 0;
    for (const key in this.props.displayedData) {
      if (key === "patient") {
        break;
      }
      indexOf++;
    }

    this.setState({ tabValue: indexOf });
    //when dataview is opend from historyview open encounter tab and scroll to encounter
    if (this.props.clickedDataViewEncounter !== "") {
      var findIndexOfEncounter = 0;
      for (const key in this.props.displayedData) {
        if (key === "encounters") {
          break;
        } else {
          findIndexOfEncounter++;
        }
      }

      const encounterIndex = this.props.displayedData.encounters.data.findIndex(
        (o) => o.Id === this.props.clickedDataViewEncounter
      );

      /**  -------------------------------------------------------------------------------------------------------------------------- to do
      var pageOfEncounter;

      for (let i = 1; i < 1000; i++) {
        if (encounterIndex <= i * this.state.rowsPerPage) {
          pageOfEncounter = i;
          break;
        }
      }
       */

      this.setState({ tabValue: findIndexOfEncounter }, () =>
        this.props.setDataViewEncounterNull()
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tabValue !== this.state.tabValue) {
      this.checkOverflow();
    }
  }

  render() {
    if (this.state.checkedIfTableFits) {
      return (
        <Box className="overflow-y-hidden h-full w-full flex flex-col">
          <Box
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              backgroundColor: "white",
            }}
          >
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
            >
              {this.sortAlphabetically(
                this.mapDataView(this.props.displayedData)
              ).map((data, index) => (
                <Tab
                  label={data.typ.toUpperCase()}
                  {...a11yProps(index)}
                  style={{
                    fontSize: "14px",
                    fontFamily: "Spline Sans",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    lineHeight: "1rem",
                  }}
                  disabled={data.data.length === 0}
                  key={"tabDataView" + data + index}
                />
              ))}
            </Tabs>
          </Box>

          {this.sortAlphabetically(
            this.mapDataView(this.props.displayedData)
          ).map((data, index) => (
            <CustomTabPanel
              value={this.state.tabValue}
              index={index}
              key={"tabPanelDataView" + data.typ + index}
            >
              {this.noData(data)}
              {this.returnPatientTable(data, this.state.tableToBig)}
            </CustomTabPanel>
          ))}
        </Box>
      );
    }
  }
}
