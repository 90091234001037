import React, { Component } from "react";
import { Divider, useTheme, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import {
  BentoGrid,
  BentoGridItem,
} from "../AceternityComponents/bento-grid.tsx";
import { IconDatabase } from "@tabler/icons-react";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MasksIcon from "@mui/icons-material/Masks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PersonIcon from "@mui/icons-material/Person";

import PopAnaDonutChartDashboard from "./PopAnaDonutChartDashboard";
import PopAnaAgeDashboard from "./PopAnaAgeDashboard";

const Skeleton = () => (
  <div
    style={{ backgroundColor: "var(--tertiary-color)" }}
    className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl"
  ></div>
);

const items = [
  {
    id: "dataset",
    title: "Dataset details",
    description: "look  at the raw data ",
    link: "/syn-viewer/datasets/datasets-overview",
    header: <Skeleton />,
    icon: (
      <IconDatabase
        style={{ color: "var(--primary-color)", height: 30, width: 30 }}
      />
    ),
    className: "md:col-span-8 row-span-2",
  },
  {
    id: "patRecord",
    title: "Patient records",
    description:
      "explore the detailed course of the disease in individual patients",
    link: "/syn-viewer/patient-records/overview",
    header: <Skeleton />,
    icon: (
      <FormatListBulletedOutlinedIcon
        style={{ color: "var(--primary-color)", height: 30, width: 30 }}
      />
    ),
    className: "md:col-span-2 row-span-7",
  },
  {
    id: "popAna",
    title: "Population analysis",
    description:
      "Analyze your data set for survival, age distribution and much more. ",
    link: "/syn-viewer/population-analysis/pop-ana-dashboard",
    header: <Skeleton />,
    icon: (
      <LeaderboardIcon
        style={{ color: "var(--primary-color)", height: 30, width: 30 }}
      />
    ),
    className: "md:col-span-6 row-span-7",
  },
];

export default class OverviewDatset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
      tableHead: [
        {
          name: "Name",
          width: {
            marginTop: "8px",
            marginBottom: "8px",
            width: "30%",
          },
        },
        {
          name: "Age",
          width: { marginTop: "8px", marginBottom: "8px", width: "10%" },
        },
        {
          name: "Diagnoses",
          width: { marginTop: "8px", marginBottom: "8px", width: "60%" },
        },
      ],
    };
  }

  handleRequestSort = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      displayArray.push(key);
    }
    return displayArray;
  }

  displaySize(size) {
    const disSize = Math.round(size * 10) / 10 + " MB";

    if (disSize !== "NaN MB") {
      return disSize;
    } else {
      return "20 MB";
    }
  }

  returnHeader(id) {
    if (id === "dataset") {
      return (
        <div
          style={{
            backgroundColor: "transparent",
            border: "1px solid var(--secondary-color)",
          }}
          className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl p-4 group-hover/bento:translate-x-2 transition duration-200"
        >
          <div style={{ marginTop: "4vh" }}>
            <p
              style={{
                fontSize: "1.8rem",
                fontWeight: "400",
                lineHeight: 1,
                color: "var(--primary-color)",
              }}
            >
              {this.props.openDB}
            </p>
            <p style={{ fontSize: "0.9rem", fontWeight: "300", lineHeight: 1 }}>
              Database name
            </p>
          </div>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{
              backgroundColor: "var(--primary-color)",
              borderColor: "var(--primary-color)",
              marginLeft: "1vw",
              marginRight: "1vw",
            }}
          />
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="3rem"
              height="3rem"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="var(--primary-color)"
                stroke="none"
              >
                <path
                  d="M1694 4291 c-47 -22 -80 -59 -94 -110 -8 -25 -10 -508 -8 -1519 l3 -1482 -56 0 c-50 0 -58 3 -65 23 -5 12 -10 541 -11 1175 l-3 1153 -513 -3 c-461 -3 -515 -5 -543 -20 -42 -23 -81 -71 -94 -116 -8 -26 -10 -368 -8 -1125 l3 -1087 -152 0 -153 0 0 -185 0 -185 2560 0 2560 0 0 185 0 185 -140 0 -140 0 0 1102 c0 1098 0 1102 -21 1145 -15 30 -35 52 -67 71 l-47 27 -522 3 -523 3 -3 -1153 c-1 -634 -6 -1163 -11 -1176 -7 -19 -15 -22 -65 -22 l-56 0 3 1482 c2 1011 0 1494 -8 1519 -14 51 -47 88 -94 110 -39 18 -84 19 -866 19 -782 0 -827 -1 -866 -19z m1064 -94 c198 -69 341 -213 406 -407 23 -69 28 -104 29 -185 1 -121 -13 -184 -65 -294 -33 -68 -56 -101 -122 -166 -128 -127 -267 -185 -446 -185 -179 0 -319 58 -445 185 -147 147 -211 326 -186 524 34 265 234 487 493 546 95 22 245 14 336 -18z m-1918 -1257 l0 -131 -127 3 -128 3 -3 128 -3 127 131 0 130 0 0 -130z m408 3 l-3 -128 -127 -3 -128 -3 0 131 0 130 130 0 131 0 -3 -127z m2952 -3 l0 -130 -125 0 -125 0 0 130 0 130 125 0 125 0 0 -130z m408 3 l-3 -128 -127 -3 -128 -3 0 131 0 130 130 0 131 0 -3 -127z m-3768 -443 l0 -131 -127 3 -128 3 -3 128 -3 127 131 0 130 0 0 -130z m408 3 l-3 -128 -127 -3 -128 -3 0 131 0 130 130 0 131 0 -3 -127z m2952 -3 l0 -130 -125 0 -125 0 0 130 0 130 125 0 125 0 0 -130z m408 3 l-3 -128 -127 -3 -128 -3 0 131 0 130 130 0 131 0 -3 -127z m-3768 -453 l0 -130 -130 0 -130 0 0 130 0 130 130 0 130 0 0 -130z m410 0 l0 -130 -130 0 -130 0 0 130 0 130 130 0 130 0 0 -130z m2950 0 l0 -130 -125 0 -125 0 0 130 0 130 125 0 125 0 0 -130z m410 0 l0 -130 -130 0 -130 0 0 130 0 130 130 0 130 0 0 -130z m-1650 -385 l0 -475 -380 0 -380 0 0 475 0 475 380 0 380 0 0 -475z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M2407 3994 c-4 -4 -7 -61 -7 -126 l0 -118 -120 0 -120 0 0 -160 0 -160 120 0 120 0 0 -120 0 -120 160 0 160 0 0 120 0 120 120 0 120 0 0 160 0 160 -120 0 -119 0 -3 123 -3 122 -151 3 c-82 1 -153 -1 -157 -4z"
                  fill="var(--primary-color)"
                />
              </g>
            </svg>
            <div style={{ marginLeft: "0.4vw", textAlign: "left" }}>
              <p style={{ fontSize: "28px", fontWeight: "400", lineHeight: 1 }}>
                {this.props.dashboardInfo.loaded ? (
                  this.props.dashboardInfo.data.encounters !== undefined ? (
                    this.props.dashboardInfo.data.encounters.toLocaleString(
                      "en-US"
                    )
                  ) : (
                    0
                  )
                ) : (
                  <Skeleton height={100} />
                )}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300", lineHeight: 1 }}>
                Encounters
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "1.5vw",
            }}
          >
            <VisibilityIcon
              style={{
                width: "2.5rem",
                height: "2.5rem",
                color: "var(--primary-color)",
              }}
            />
            <div style={{ marginLeft: "0.2vw", textAlign: "left" }}>
              <p style={{ fontSize: "28px", fontWeight: "400", lineHeight: 1 }}>
                {this.props.dashboardInfo.loaded ? (
                  this.props.dashboardInfo.data.observations !== undefined ? (
                    this.props.dashboardInfo.data.observations.toLocaleString(
                      "en-US"
                    )
                  ) : (
                    0
                  )
                ) : (
                  <Skeleton />
                )}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300", lineHeight: 1 }}>
                Observations
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "1.5vw",
            }}
          >
            <MasksIcon
              style={{
                width: "3rem",
                height: "3rem",
                color: "var(--primary-color)",
              }}
            />
            <div style={{ marginLeft: "0.2vw", textAlign: "left" }}>
              <p style={{ fontSize: "28px", fontWeight: "400", lineHeight: 1 }}>
                {this.props.dashboardInfo.loaded ? (
                  this.props.dashboardInfo.data.conditions !== undefined ? (
                    this.props.dashboardInfo.data.conditions.toLocaleString(
                      "en-US"
                    )
                  ) : (
                    0
                  )
                ) : (
                  <Skeleton />
                )}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300", lineHeight: 1 }}>
                Conditions
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "1.5vw",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="2.6rem"
              height="2.6rem"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="var(--primary-color)"
                stroke="none"
              >
                <path
                  d="M3025 5015 l-25 -24 0 -246 0 -245 -72 0 c-87 0 -147 -25 -178 -74 -20 -33 -20 -47 -20 -672 0 -620 1 -640 20 -672 11 -18 34 -41 52 -52 32 -19 53 -20 919 -20 l886 0 33 23 c18 12 43 35 54 50 21 28 21 35 21 665 l0 637 -24 39 c-33 53 -86 76 -178 76 l-73 0 0 244 c0 231 -1 245 -21 270 -26 33 -79 36 -109 6 -19 -19 -20 -33 -20 -270 l0 -250 -565 0 -565 0 0 229 c0 235 -6 283 -40 301 -32 17 -70 11 -95 -15z m1432 -777 l23 -21 0 -464 0 -464 -25 -24 -24 -25 -711 0 -711 0 -24 25 -25 24 0 462 c0 461 0 463 22 486 l21 23 716 0 715 0 23 -22z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M3110 3970 l0 -140 99 0 98 0 63 96 c69 103 90 124 130 124 46 0 57 -17 167 -279 8 -18 16 -31 19 -28 3 2 23 51 44 108 22 57 48 110 57 117 27 20 77 15 100 -10 12 -13 42 -56 67 -95 26 -40 51 -73 55 -73 5 0 14 7 21 15 9 11 44 15 154 17 l141 3 3 143 3 142 -611 0 -610 0 0 -140z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M3436 3747 c-20 -30 -48 -59 -62 -65 -14 -7 -76 -12 -145 -12 l-120 0 3 -137 3 -138 603 -3 602 -2 0 140 0 140 -103 0 -103 0 -44 -45 c-34 -34 -52 -45 -75 -45 -35 0 -54 17 -108 99 l-36 54 -11 -29 c-47 -127 -84 -221 -93 -233 -19 -24 -58 -33 -88 -21 -30 13 -35 22 -120 223 -29 70 -56 127 -60 127 -3 0 -22 -24 -43 -53z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M839 3406 c-155 -43 -305 -197 -410 -420 -117 -251 -179 -563 -179 -906 0 -488 127 -916 348 -1169 38 -44 39 -44 73 -31 21 8 101 15 211 18 l177 4 -28 42 c-131 195 -226 474 -273 806 -17 122 -17 534 0 655 58 417 189 742 382 948 l62 67 -158 -1 c-95 0 -178 -6 -205 -13z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M1496 3409 c-106 -25 -249 -139 -330 -262 -118 -181 -213 -455 -250 -727 -24 -170 -24 -523 0 -667 l7 -43 153 0 c169 0 160 -4 144 65 -5 22 -15 102 -21 177 -22 280 26 568 131 778 82 164 193 240 302 209 67 -20 156 -114 206 -219 22 -47 48 -110 57 -141 l18 -55 50 13 c61 15 155 17 216 2 24 -5 45 -8 46 -7 2 2 -11 62 -30 133 -67 269 -165 464 -300 600 -130 130 -259 177 -399 144z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M2019 2390 c-68 -12 -141 -53 -192 -110 -147 -164 -91 -424 112 -518 82 -39 193 -38 273 1 74 36 151 120 174 189 80 239 -120 478 -367 438z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M3067 2305 c-54 -8 -192 -37 -307 -65 l-209 -52 6 -86 c8 -129 -25 -240 -99 -334 l-30 -38 1103 0 1102 0 -6 56 c-8 73 -55 172 -111 232 -73 77 -137 106 -331 146 -94 19 -244 53 -335 75 -91 22 -205 48 -255 57 -124 22 -403 27 -528 9z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M1687 1566 c-49 -18 -64 -32 -89 -79 -30 -59 -23 -161 15 -210 55 -73 -66 -68 1673 -65 l1561 3 37 29 c52 39 69 84 64 168 -4 76 -23 110 -79 143 -34 20 -54 20 -1589 22 -1283 2 -1562 0 -1593 -11z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M950 1552 c0 -5 13 -55 30 -113 56 -200 127 -351 227 -481 l43 -56 228 -4 228 -3 64 -32 65 -32 60 62 c33 34 75 85 93 112 l33 50 -168 5 -168 5 -79 38 c-130 62 -220 171 -296 360 l-37 92 -162 3 c-88 1 -161 -1 -161 -6z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M3165 734 c-121 -179 -222 -330 -223 -335 -2 -6 138 -8 370 -7 l373 3 213 315 c117 173 218 323 223 333 9 16 -11 17 -363 17 l-373 -1 -220 -325z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M700 721 c-19 -10 -45 -33 -57 -51 -21 -30 -23 -45 -23 -156 l0 -124 585 0 585 0 0 118 c0 137 -13 173 -76 209 -39 23 -41 23 -509 23 -439 0 -473 -1 -505 -19z"
                  fill="var(--primary-color)"
                />
                <path
                  d="M191 204 c-28 -35 -26 -69 4 -99 l24 -25 2270 0 c1248 0 2277 3 2286 6 24 10 46 60 39 89 -15 59 157 55 -2325 55 l-2278 0 -20 -26z"
                  fill="var(--primary-color)"
                />
              </g>
            </svg>
            <div style={{ marginLeft: "0.5vw", textAlign: "left" }}>
              <p style={{ fontSize: "28px", fontWeight: "400", lineHeight: 1 }}>
                {this.props.dashboardInfo.loaded ? (
                  this.props.dashboardInfo.data.procedures !== undefined ? (
                    this.props.dashboardInfo.data.procedures.toLocaleString(
                      "en-US"
                    )
                  ) : (
                    0
                  )
                ) : (
                  <Skeleton />
                )}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300", lineHeight: 1 }}>
                Procedures
              </p>
            </div>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              style={{
                backgroundColor: "var(--primary-color)",
                borderColor: "var(--primary-color)",
                marginLeft: "1vw",
                marginRight: "1vw",
              }}
            />
            <div style={{ textAlign: "left", width: "32vw" }}>
              <p style={{ color: "var(--primary-color)" }}>
                Included .csv-files
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300" }}>
                {this.props.dashboardInfo.loaded ? (
                  this.mapDataView(this.props.dashboardInfo.data).join(", ")
                ) : (
                  <Skeleton />
                )}
              </p>
            </div>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              style={{
                backgroundColor: "var(--primary-color)",
                borderColor: "var(--primary-color)",
                marginLeft: "1vw",
                marginRight: "1vw",
              }}
            />
            <div style={{ textAlign: "left" }}>
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: "400",
                  lineHeight: 1,
                  color: "var(--primary-color)",
                }}
              >
                {this.displaySize(this.state.size)}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "300", lineHeight: 1 }}>
                Size
              </p>
            </div>
          </div>
        </div>
      );
    } else if (id === "patRecord") {
      const displayLength = 50;
      return (
        <div
          style={{
            backgroundColor: "transparent",
            border: "1px solid var(--secondary-color)",
          }}
          className="w-full h-full min-h-[6rem] rounded-xl "
        >
          <TableContainer
            sx={{
              width: "100%",
              height: "100%",
            }}
            className="rounded-tl rounded-tr"
          >
            <Table stickyHeader aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  {this.state.tableHead.map((data, i) => (
                    <StyledTableCell
                      key={"dashboard-table-head" + data.name + i}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p style={data.width}>{data.name}</p>{" "}
                        {data.name === "Diagnoses" && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PersonIcon
                              sx={{ height: "1.2rem", width: "1.2rem" }}
                            />{" "}
                            {this.props.patientList.length}
                          </div>
                        )}
                      </div>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.patientList
                  .slice(0, displayLength)
                  .map((patient, i) => (
                    <StyledTableRow
                      key={"overview-pat-table" + patient.LAST + i}
                    >
                      <StyledTableCell name="Name">
                        {patient.Name.replace(/\d/g, "")} ({patient.Gender})
                      </StyledTableCell>
                      <StyledTableCell name="Age">
                        {patient.Age.years}
                      </StyledTableCell>
                      {patient.ImportantDiagnoses !== undefined ? (
                        <StyledTableCell
                          name="Important Diagnoses"
                          className="td-diagnosis"
                        >
                          {patient.ImportantDiagnoses}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          name="Important Diagnoses"
                          className="td-diagnosis"
                        >
                          -
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                {this.props.patientList.length > displayLength && (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={3}
                      style={{ textAlign: "center" }}
                    >
                      + {this.props.patientList.length - displayLength} more
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "transparent",
            border: "1px solid var(--secondary-color)",
            color: "#6573b3",
          }}
          className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl"
        >
          <PopAnaDonutChartDashboard patientList={this.props.patientList} />
          <div className="flex-grow">
            <PopAnaAgeDashboard patientList={this.props.patientList} />
          </div>
        </div>
      );
    }
  }

  calSize = (allData) => {
    var size = 0;
    for (let i = 0; i < allData.length; i++) {
      if (allData[i].name === this.props.openDB) {
        size = allData[i].size;
      }
    }

    this.setState({
      size: size,
    });
  };

  componentDidMount() {
    this.props.getAllEntries("CSVNames", "names", "csvFiles");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.csvFiles !== this.props.csvFiles) {
      this.calSize(this.props.csvFiles.data);
    }
  }

  render() {
    return (
      <div className="body-view-main p-4">
        <BentoGrid className="w-full">
          {items.map((item, i) => (
            <Link to={item.link} className={item.className}>
              <BentoGridItem
                key={i}
                title={item.title}
                description={item.description}
                header={this.returnHeader(item.id)}
                icon={item.icon}
                id={item.id}
                dbName={this.props.openDB}
              />
            </Link>
          ))}
        </BentoGrid>
      </div>
    );
  }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4169e1",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontFamily: "Spline Sans",
    fontWeight: 350,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
/**
 * 
 * <div
        style={{
          height: "100%",
          width: "100%",
          padding: "20px",
        }}
      >
        <Link to="/synthea-viewer/datasets/datasets-overview">
          <Paper
            sx={{
              height: "10vh",
              width: "100%",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <DirectionAwareHover text="to details of datasets">
              <div
                style={{
                  padding: "20px",
                  paddingLeft: 140,
                  paddingRight: 140,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ fontSize: "1.5rem", color: "var(--primary-color)" }}
                >
                  Dashboard: {this.props.openDB}
                </p>
                <Divider
                  flexItem
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "var(--primary-color)",
                    marginLeft: "0.5vw",
                    marginRight: "0.5vw",
                  }}
                />
              </div>
            </DirectionAwareHover>
          </Paper>
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "3vh",
            marginBottom: "3vh",
          }}
        >
          <Link to="/synthea-viewer/patient-records/overview">
            <Paper
              sx={{
                height: "70vh",
                width: "37vw",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              <DirectionAwareHover text="to Patient Records">
                <div
                  style={{
                    padding: "20px",
                    paddingLeft: 70,
                    paddingRight: 70,
                    paddingTop: 60,
                    height: "100%",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{ fontSize: "1rem", color: "var(--primary-color)" }}
                  >
                    Patient Records
                  </p>
                </div>
              </DirectionAwareHover>
            </Paper>
          </Link>
          <Link to="/synthea-viewer/population-analysis">
            <Paper
              sx={{
                height: "70vh",
                width: "60vw",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              <DirectionAwareHover text="to Population analysis">
                <div
                  style={{
                    padding: "20px",
                    paddingLeft: 90,
                    paddingRight: 70,
                    paddingTop: 60,
                    height: "100%",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{ fontSize: "1rem", color: "var(--primary-color)" }}
                  >
                    Population analysis
                  </p>
                </div>
              </DirectionAwareHover>
            </Paper>
          </Link>
        </div>
      </div>
 */
