// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts-options-div-dashboard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
  overflow-y: auto;
}

.box-shadow {
  height: 100%;
  position: relative;
}

.line-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(100, 100, 100, 0.082);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Dashboard/Charts.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;EAC3C,4CAA4C;EAC5C,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".charts-options-div-dashboard {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-left: 8px;\n  overflow-y: auto;\n}\n\n.box-shadow {\n  height: 100%;\n  position: relative;\n}\n\n.line-shadow {\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);\n  border: 1px solid rgba(100, 100, 100, 0.082);\n  border-radius: 5px;\n  margin: 5px;\n  padding: 5px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
