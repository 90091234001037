import React, { Component } from "react";

import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";

import Filter from "./Filter";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import { CircularProgress, Divider, Skeleton } from "@mui/material";
import Overlay from "../Overlay/Overlay";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelineIcon from "@mui/icons-material/Timeline";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import dummyPic from "../data/face-generator/white/31-50/m/white-31-50-m-1.jpg";

import "./PatientView.css";
import "./Scrollbar.css";

import { Outlet, Link } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default class PatientView extends Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.thumbRef = React.createRef();
    this.elementRef = React.createRef();

    this.state = {
      isOverlayOpen: false,
      includeOArray: [],
      includeOArrayNew: false,
      includeAllDataOfEncounter: false,
      excludeArray: [],
      excludeArrayNew: false,
      changeIncludeExclude: true,
      autocompleteArray: [],
      sliderValue: [1920, 2023],
      inputValue: [1920, 2023],
      minMaxDate: { minDatum: 1920, maxDatum: 2023 },
      sortBy: "oldFirst",
      filterProfileLoaded: false,
      watchForFilterChanges: false,
      changedFromFilterProfile2: false,
      activeFilterProfile: "",
      scrollPosition: 0,
      isCollapsedI: false,
      isCollapsedNav: false,
      faceSource: dummyPic,
      faceLoaded: false,
      showPicOverlay: false,
      showBigPic: false,
    };

    this.changeToChangedProfile = this.changeToChangedProfile.bind(this);
    this.changeFromFilterProfile = this.changeFromFilterProfile.bind(this);
    this.updateDisplayedData = this.updateDisplayedData.bind(this);
  }

  /*--------------------------------------------------------------------------- face generator ------------------------------------------------------------------------------------- */
  generateFaceSource(gender, age, ethnicity) {
    const images = require.context("../data/face-generator/allFaces", true);
    const randomNumber = Math.floor(Math.random() * 5) + 1;
    var ethnicityString = ethnicity;

    if (ethnicity === "nonhispanic") {
      ethnicityString = "white";
    }

    var ageString = "";
    if (age < 18) {
      ageString = "0-30";
    } else if (50 > age > 18) {
      ageString = "31-50";
    } else {
      ageString = "50+";
    }

    var genderString;

    try {
      genderString = gender.toLowerCase();
    } catch {
      genderString = "f";
    }

    var faceSource =
      ethnicityString +
      "-" +
      ageString +
      "-" +
      genderString +
      "-" +
      randomNumber;

    var face;
    try {
      face = images(`./${faceSource}.jpg`);
    } catch {
      if (gender === "M") {
        face = images(`./dummy-male.jpg`);
      } else {
        face = images(`./dummy-female.png`);
      }
    }

    this.setState({ faceSource: face }, () => {
      this.setState({ faceLoaded: true });
    });
  }

  /*--------------------------------------------------------------------------- fullscreen functions------------------------------------------------------------------------------------- */

  toggleFullscreen = () => {
    this.setState((prevState) => ({
      isFullscreen: !prevState.isFullscreen,
    }));
  };

  /*-------------------------------------------- handel Collapse ------------------------------------------------------------------------------------------------------------ */

  handleToggleCollapseI = () => {
    this.setState((prevState) => ({
      isCollapsedI: !prevState.isCollapsedI,
    }));
  };

  handleToggleCollapseNav = () => {
    this.setState((prevState) => ({
      isCollapsedNav: !prevState.isCollapsedNav,
    }));
  };

  /*--------------------------------------------Navigation------------------------------------------------------------------------------------------------------------ */

  handleIntersect = (entries) => {
    entries.forEach((entry) => {
      this.props.setPatientInfoVisible(entry.isIntersecting);
    });
  };

  /*--------------------------------------------Input-, onBlur-Handler, etc. ------------------------------------------------------------------------------------------------------------ */

  updateDisplayedData = (displayedData) => {
    this.props.getFilteredPatData(displayedData);
  };

  handleSliderChangeCommitted = (e, newValue) => {
    this.setState({ sliderValue: newValue, inputValue: newValue });
  };

  handleSliderChange = (e) => {
    this.setState({ sliderValue: e.target.value });
  };

  getValuetext(value) {
    return `${value}°C`;
  }

  handleInputChange1 = (e) => {
    let newvalue = [];

    newvalue.push(e.target.value);
    newvalue.push(this.state.inputValue[1]);

    this.setState({ inputValue: newvalue });
  };

  handleInputChange2 = (e) => {
    let newvalue = [];

    newvalue.push(this.state.inputValue[0]);
    newvalue.push(e.target.value);

    this.setState({ inputValue: newvalue });
  };

  onBlur1 = () => {
    let newvalue = [];
    if (this.state.inputValue[0] < 1900) {
      newvalue.push(1900);
      newvalue.push(this.state.inputValue[1]);
    } else if (this.state.inputValue[0] > this.state.inputValue[1]) {
      newvalue.push(this.state.inputValue[1]);
      newvalue.push(this.state.inputValue[1]);
    } else {
      newvalue = this.state.inputValue;
    }

    this.setState({ sliderValue: newvalue, inputValue: newvalue });
  };

  onBlur2 = () => {
    let newvalue = [];
    if (this.state.inputValue[0] > 2100) {
      newvalue.push(2100);
      newvalue.push(this.state.inputValue[1]);
    } else if (this.state.inputValue[1] < this.state.inputValue[0]) {
      newvalue.push(this.state.inputValue[0]);
      newvalue.push(this.state.inputValue[0]);
    } else {
      newvalue = this.state.inputValue;
    }

    this.setState({ sliderValue: newvalue, inputValue: newvalue });
  };

  findeMinMaxDatum(datenArray) {
    // Konvertiere die Strings zu Date-Objekten
    const dateObjects = datenArray.map((dateString) => dateString.START);

    // Finde das älteste und jüngste Datum
    const maxDatum = new Date(Math.min(...dateObjects));
    const minDatum = new Date(Math.max(...dateObjects));

    return {
      minDatum: minDatum.getFullYear(),
      maxDatum: maxDatum.getFullYear(),
    };
  }

  changeToChangedProfile(filterData) {
    //changes filter settings to the filter profile that was chosen in the the filter saver
    var slider = filterData.data.sliderValue;
    if (slider === "") {
      slider = [this.state.minMaxDate.maxDatum, this.state.minMaxDate.minDatum];
    }
    this.setState(
      { filterProfileLoaded: false, watchForFilterChanges: false },
      () =>
        this.setState(
          {
            includeOArray: filterData.data.includeOArray,
            includeAllDataOfEncounter:
              filterData.data.includeAllDataOfEncounter,
            excludeArray: filterData.data.excludeArray,
            sliderValue: slider,
            inputValue: slider,
            sortBy: filterData.data.sortBy,
            activeFilterProfile: filterData.name,
          },
          () =>
            this.setState({
              filterProfileLoaded: true,
              watchForFilterChanges: true,
            })
        )
    );
  }

  changeFromFilterProfile() {
    this.setState({ changedFromFilterProfile2: false });
  }

  /*---------------------------------------------------------- display options---------------------------------------------------------------------------------------------------- */

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return "-";
    }
  }

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  mapTableHead(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(key);
      }
    }
    return displayArray;
  }

  mapHistory(data) {
    var displayArray = [];
    for (const key in data) {
      if (data[key].length !== 0) {
        var obj = {};
        obj["typ"] = key;
        obj["data"] = data[key];
        displayArray.push(obj);
      }
    }

    return displayArray;
  }

  componentDidMount() {
    this.setState({
      isCollapsedNav: this.props.collapsesSave.nav,
      isCollapsedI: this.props.collapsesSave.info,
    });

    /*checks if patient info is visible -> if not in the head the name and position of patient is shown */
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Ändere diesen Wert entsprechend deinen Anforderungen
    };

    this.observer = new IntersectionObserver(this.handleIntersect, options);

    if (this.elementRef.current) {
      this.observer.observe(this.elementRef.current);
    }
  }

  returnGenderIcon(gender) {
    if (gender === "M") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon icon-tabler icons-tabler-outline icon-tabler-gender-male mr-2"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 14m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />
          <path d="M19 5l-5.4 5.4" />
          <path d="M19 5h-5" />
          <path d="M19 5v5" />
        </svg>
      );
    } else if (gender === "F") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon icon-tabler icons-tabler-outline icon-tabler-venus mr-2"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 9m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />
          <path d="M12 14l0 7" />
          <path d="M9 18l6 0" />
        </svg>
      );
    }
  }

  allValuesTrue(obj) {
    // Überprüfe jedes Schlüssel-Wert-Paar im Objekt
    for (const key in obj) {
      // Wenn irgendein Wert nicht true ist, gib false zurück
      if (obj[key] !== true) {
        return false;
      }
    }
    // Wenn alle Werte true sind, gib true zurück
    return true;
  }

  closeBigPic = () => {
    this.setState({ showBigPic: false });
  };

  componentWillUnmount() {
    if (this.elementRef.current) {
      this.observer.unobserve(this.elementRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isCollapsedNav !== this.state.isCollapsedNav) {
      var collapseSates = {
        nav: this.state.isCollapsedNav,
      };

      this.props.changePatViewCollapses(collapseSates);
    }
    //update slider and input value to min max Date
    if (prevState.minMaxDate !== this.state.minMaxDate) {
      let newValue = [];
      newValue.push(
        this.state.minMaxDate.maxDatum,
        this.state.minMaxDate.minDatum
      );
      this.setState({ sliderValue: newValue, inputValue: newValue });
    }

    //load new face when patient is changed
    if (
      prevProps.openPatientData.patient.data.Id !==
      this.props.openPatientData.patient.data.Id
    ) {
      this.generateFaceSource(
        this.props.openPatientData.patient.data.GENDER,
        this.props.openPatientData.patient.data.AGE,
        this.props.openPatientData.patient.data.RACE
      );
    }

    //if new open patint data is handed down -> rerender
    if (prevProps.allPatDatLoaded !== this.props.allPatDatLoaded) {
      if (this.allValuesTrue(this.props.allPatDatLoaded)) {
        //prepare the data for the autocompelte in the Filter component
        let autocompleteArray = [];
        let codeArray = [];

        for (const key in this.props.openPatientData) {
          if (key !== "patient") {
            for (
              let i = 0;
              i < this.props.openPatientData[key].data.length;
              i++
            ) {
              if (
                !codeArray.includes(
                  this.props.openPatientData[key].data[i].CODE
                )
              ) {
                var label =
                  this.props.openPatientData[key].data[i].DESCRIPTION +
                  " - " +
                  this.props.openPatientData[key].data[i].CODE;
                var option = {
                  label: label,
                  description:
                    this.props.openPatientData[key].data[i].DESCRIPTION,
                  code: this.props.openPatientData[key].data[i].CODE,
                  file: key,
                  typ: "codeDesc",
                };

                autocompleteArray.push(option);
                codeArray.push(this.props.openPatientData[key].data[i].CODE);
              }
            }
          }
        }

        var emptyOption = { label: "", description: "", code: "", file: "" };
        autocompleteArray.push(emptyOption);
        this.setState({
          autocompleteArray: autocompleteArray,
        });
      }
    }

    //when all encounters are loaded slider minMax are set
    if (
      prevProps.openPatientData.encounters !==
        this.props.openPatientData.encounters &&
      this.props.openPatientData.encounters.data.length !== 0
    ) {
      let minMaxDate = this.findeMinMaxDatum(
        this.props.openPatientData["encounters"].data
      );
      this.setState({ minMaxDate: minMaxDate });
    }

    if (
      prevProps.displayedDataProcessed !== this.props.displayedDataProcessed &&
      this.props.displayedDataProcessed
    ) {
      this.generateFaceSource(
        this.props.openPatientData.patient.data.GENDER,
        this.props.openPatientData.patient.data.AGE.years,
        this.props.openPatientData.patient.data.RACE
      );
    }
  }

  render() {
    const { isCollapsedNav } = this.state;
    const { isFullscreen } = this.state;

    return (
      <div className="patient-main-div">
        <Overlay
          isOpen={this.state.showBigPic}
          onClose={() => this.closeBigPic()}
          title={""}
        >
          <img
            src={this.state.faceSource}
            style={{
              width: "40vw",
              height: "40vw",
              padding: "15px",
            }}
          />
        </Overlay>
        <Paper
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
            borderRadius: "10px",
            marginBottom: "2px",
            backgroundColor: "var(--background-lvl-1)",
          }}
          className="mr-4"
        >
          <Collapse orientation="horizontal" in={!isCollapsedNav}>
            <div className="nav-patient-div">
              <div className="patientView-general-div" ref={this.elementRef}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    marginLeft: "1vw",
                  }}
                >
                  <IconButton
                    sx={{
                      color: "var(--primary-color)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={this.props.prevPatient}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "var(--primary-color)",
                      lineHeight: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {this.props.openPatientData.patient.data.LAST !==
                    undefined ? (
                      this.props.openPatientData.patient.data.LAST.replace(
                        /\d/g,
                        ""
                      )
                    ) : (
                      <Skeleton width={35} />
                    )}
                    ,{" "}
                    {this.props.openPatientData.patient.data.FIRST !==
                    undefined ? (
                      this.props.openPatientData.patient.data.FIRST.replace(
                        /\d/g,
                        ""
                      )
                    ) : (
                      <Skeleton width={35} />
                    )}{" "}
                    {this.props.openPatientData.patient.data.GENDER !==
                    undefined ? (
                      this.returnGenderIcon(
                        this.props.openPatientData.patient.data.GENDER
                      )
                    ) : (
                      <Skeleton width={15} />
                    )}
                    <p>
                      ({this.props.openPatientIndex} / {this.props.numbPatients}
                      )
                    </p>
                  </div>
                  <IconButton
                    sx={{
                      color: "var(--primary-color)",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={this.props.nextPatient}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {this.state.faceLoaded ? (
                    <div
                      className="relative cursor-pointer"
                      onMouseEnter={() =>
                        this.setState({ showPicOverlay: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ showPicOverlay: false })
                      }
                      onClick={() => this.setState({ showBigPic: true })}
                    >
                      <img
                        src={this.state.faceSource}
                        style={{
                          borderRadius: "50%",
                          width: "4vw",
                          height: "4vw",
                        }}
                      />
                      {this.state.showPicOverlay && (
                        <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex justify-center items-center rounded-full text-white text-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon icon-tabler icons-tabler-outline icon-tabler-zoom-in"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                            <path d="M7 10l6 0" />
                            <path d="M10 7l0 6" />
                            <path d="M21 21l-6 -6" />
                          </svg>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Skeleton
                      width={"4vw"}
                      height={"4vw"}
                      variant="circular" // oder "text", "circle", je nach gewünschtem Skelettstil
                    />
                  )}
                  <div
                    style={{
                      marginBottom: "1.5vh",
                      marginLeft: "10px",
                      marginTop: "1vh",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="var(--primary-color)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-cake"
                        style={{ marginRight: "0.2vw" }}
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 20h18v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -3 3v8z" />
                        <path d="M3 14.803c.312 .135 .654 .204 1 .197a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1c.35 .007 .692 -.062 1 -.197" />
                        <path d="M12 4l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z" />
                      </svg>
                      {this.props.openPatientData.patient.data.BIRTHDATE !==
                      undefined ? (
                        this.formatDate(
                          this.props.openPatientData.patient.data.BIRTHDATE
                        )
                      ) : (
                        <Skeleton width={35} />
                      )}{" "}
                      (
                      {this.props.openPatientData.patient.data.AGE !==
                      undefined ? (
                        <span>
                          {this.props.openPatientData.patient.data.AGE.years}y{" "}
                        </span>
                      ) : (
                        <Skeleton width={5} />
                      )}
                      )
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="var(--primary-color)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-grave-2"
                        style={{ marginRight: "0.2vw" }}
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 16.17v-9.17a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3v9.171" />
                        <path d="M12 7v5" />
                        <path d="M10 9h4" />
                        <path d="M5 21v-2a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v2h-14z" />
                      </svg>
                      {this.props.openPatientData.patient.data.DEATHDATE !==
                      undefined ? (
                        this.formatDate(
                          this.props.openPatientData.patient.data.DEATHDATE
                        )
                      ) : (
                        <Skeleton width={35} />
                      )}
                    </div>
                  </div>
                </div>
                <Divider
                  style={{
                    marginBottom: "1.5vh",
                    marginLeft: "1vw",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    paddingLeft: "1.5vw",
                    paddingRight: "1vw",
                  }}
                >
                  <div
                    style={{
                      width: "5vw",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: 1,
                      }}
                    >
                      {this.props.displayedDataProcessed ? (
                        this.props.displayedData.encounters.data.length.toLocaleString(
                          "en-US"
                        )
                      ) : (
                        <Skeleton width={40} />
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: 1,
                      }}
                    >
                      Encounters
                    </p>
                  </div>

                  <div
                    style={{
                      textAlign: "left",
                      marginLeft: "1vw",
                      width: "5vw",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: 1,
                      }}
                    >
                      {this.props.displayedDataProcessed ? (
                        this.props.displayedData.observations !== undefined ? (
                          this.props.displayedData.observations.data.length.toLocaleString(
                            "en-US"
                          )
                        ) : (
                          0
                        )
                      ) : (
                        <Skeleton width={40} />
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: 1,
                      }}
                    >
                      Observations
                    </p>
                  </div>

                  <div
                    style={{
                      textAlign: "left",
                      width: "5vw",
                      marginTop: "1vh",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: 1,
                      }}
                    >
                      {this.props.displayedDataProcessed ? (
                        this.props.displayedData.conditions.data.length.toLocaleString(
                          "en-US"
                        )
                      ) : (
                        <Skeleton width={40} />
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: 1,
                      }}
                    >
                      Conditions
                    </p>
                  </div>

                  <div
                    style={{
                      marginLeft: "1vw",
                      marginTop: "1vh",
                      textAlign: "left",
                      width: "5vw",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: 1,
                      }}
                    >
                      {this.props.displayedDataProcessed ? (
                        this.props.displayedData.procedures !== undefined ? (
                          this.props.displayedData.procedures.data.length.toLocaleString(
                            "en-US"
                          )
                        ) : (
                          0
                        )
                      ) : (
                        <Skeleton width={40} />
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: 1,
                      }}
                    >
                      Procedures
                    </p>
                  </div>
                </div>
              </div>
              <Divider
                style={{
                  marginTop: "1.5vh",
                  marginLeft: "1vw",
                }}
              />
              <Filter
                autocompleteArray={this.state.autocompleteArray}
                setDisplayDataProcessed={this.props.setDisplayDataProcessed}
                openPatientData={this.props.openPatientData}
                updateDisplayedData={this.updateDisplayedData}
                sliderValue={this.state.inputValue}
                setPatViewFilter={this.props.setPatViewFilter}
                patViewFilter={this.props.patViewFilter}
                allPatDatLoaded={this.props.allPatDatLoaded}
                minMaxDate={this.state.minMaxDate}
                newSnackbar={this.props.newSnackbar}
                showDeleteSnackbar={this.props.showDeleteSnackbar}
                allReasonCodes={this.props.allReasonCodes}
              />
              <div className="slider">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={this.state.sliderValue}
                  onChangeCommitted={this.handleSliderChangeCommitted}
                  onChange={this.handleSliderChange}
                  valueLabelDisplay="auto"
                  min={this.state.minMaxDate.maxDatum}
                  max={this.state.minMaxDate.minDatum}
                  getAriaValueText={this.getValuetext}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Input
                    value={this.state.inputValue[0]}
                    onChange={this.handleInputChange1}
                    onBlur={this.onBlur1}
                    size="medium"
                    inputProps={{
                      step: 1,
                      min: this.state.minMaxDate.maxDatum,
                      max: this.state.minMaxDate.minDatum,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                    style={{ width: "55px" }}
                  />
                  <div style={{ fontSize: "15px", fontWeight: "300" }}>
                    Year Span
                  </div>
                  <Input
                    value={this.state.inputValue[1]}
                    onChange={this.handleInputChange2}
                    onBlur={this.onBlur2}
                    size="medium"
                    inputProps={{
                      step: 1,
                      min: this.state.minMaxDate.maxDatum,
                      max: this.state.minMaxDate.minDatum,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                    style={{ width: "55px" }}
                  />
                </div>
              </div>
            </div>
          </Collapse>
          <div>
            <IconButton
              onClick={this.handleToggleCollapseNav}
              style={{ position: "relative" }}
            >
              {!isCollapsedNav ? (
                <ArrowBackIosNewIcon style={{ fontSize: "small" }} />
              ) : (
                <ArrowForwardIosIcon style={{ fontSize: "small" }} />
              )}
            </IconButton>
          </div>
        </Paper>

        <Paper
          className="patient-view-content-div"
          style={{
            backgroundColor: "var(--background-lvl-1)",
            marginBottom: "2px",
          }}
        >
          <div
            className={isFullscreen ? "fullscreen" : ""}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1vh",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "15vw",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Collapse orientation="horizontal" in={isCollapsedNav}>
                  <div
                    style={{
                      width: "15vw",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "var(--primary-color)",
                        width: "25px",
                        height: "25px",
                      }}
                      onClick={this.props.prevPatient}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "var(--primary-color)",
                        lineHeight: 1,
                        textAlign: "center",
                        display: "flex",
                      }}
                    >
                      {this.props.openPatientData.patient.data.LAST !==
                      undefined ? (
                        this.props.openPatientData.patient.data.LAST.replace(
                          /\d/g,
                          ""
                        )
                      ) : (
                        <Skeleton />
                      )}
                      ,{" "}
                      {this.props.openPatientData.patient.data.FIRST !==
                      undefined ? (
                        this.props.openPatientData.patient.data.FIRST.replace(
                          /\d/g,
                          ""
                        )
                      ) : (
                        <Skeleton />
                      )}{" "}
                      (
                      {this.props.openPatientData.patient.data.GENDER !==
                      undefined ? (
                        this.props.openPatientData.patient.data.GENDER
                      ) : (
                        <Skeleton />
                      )}
                      )
                    </p>
                    <IconButton
                      sx={{
                        color: "var(--primary-color)",
                        width: "25px",
                        height: "25px",
                      }}
                      onClick={this.props.nextPatient}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </div>
                </Collapse>
              </div>
              <div style={{ display: "flex" }}>
                <Link
                  className="link-btn-pat-view"
                  to="/syn-viewer/patient-records/patient-view/patient-dashboard"
                >
                  <div
                    className={
                      this.props.location ===
                      "/syn-viewer/patient-records/patient-view/patient-dashboard"
                        ? "nav-btn-pat-view-active"
                        : "nav-btn-pat-view"
                    }
                  >
                    <DashboardCustomizeIcon className="icon-nav-pat-view" />
                    Dashboard
                  </div>
                </Link>
                <Link
                  className="link-btn-pat-view"
                  to="/syn-viewer/patient-records/patient-view/patient-dataview"
                >
                  <div
                    className={
                      this.props.location ===
                      "/syn-viewer/patient-records/patient-view/patient-dataview"
                        ? "nav-btn-pat-view-active"
                        : "nav-btn-pat-view"
                    }
                  >
                    <FolderIcon className="icon-nav-pat-view" />
                    Data
                  </div>
                </Link>
                <Link
                  className="link-btn-pat-view"
                  to="/syn-viewer/patient-records/patient-view/patient-history"
                >
                  <div
                    className={
                      this.props.location ===
                      "/syn-viewer/patient-records/patient-view/patient-history"
                        ? "nav-btn-pat-view-active"
                        : "nav-btn-pat-view"
                    }
                  >
                    <AccessTimeIcon className="icon-nav-pat-view" />
                    History
                  </div>
                </Link>
                <Link
                  className="link-btn-pat-view"
                  to="/syn-viewer/patient-records/patient-view/patient-timeline"
                >
                  <div
                    className={
                      this.props.location ===
                      "/syn-viewer/patient-records/patient-view/patient-timeline"
                        ? "nav-btn-pat-view-active"
                        : "nav-btn-pat-view"
                    }
                  >
                    <TimelineIcon className="icon-nav-pat-view" />
                    Timeline
                  </div>
                </Link>
                <Link
                  className="link-btn-pat-view"
                  to="/syn-viewer/patient-records/patient-view/patient-charts"
                >
                  <div
                    className={
                      this.props.location ===
                      "/syn-viewer/patient-records/patient-view/patient-charts"
                        ? "nav-btn-pat-view-active"
                        : "nav-btn-pat-view"
                    }
                  >
                    <SsidChartIcon className="icon-nav-pat-view" />
                    Charts
                  </div>
                </Link>
              </div>

              <div
                style={{
                  width: "15vw",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {!isFullscreen ? (
                  <IconButton
                    onClick={this.toggleFullscreen}
                    style={{
                      height: "25px",
                      width: "25px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={this.toggleFullscreen}>
                    <FullscreenExitIcon
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "var(--secondary-color)",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </div>
            <div className="patientView-body">
              <Outlet />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
