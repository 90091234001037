import React, { Component } from "react";

import {
  Chart as ChartJs,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

import "chartjs-adapter-date-fns";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LineCharts from "../Charts/LineCharts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  IconButton,
  Switch,
  Chip,
  Collapse,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import "./Charts.css";
import { differenceInBusinessDays } from "date-fns";

ChartJs.register(
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartTyp: "line",
      dataTyp: ["encounters"],
      label: [],
      datasets: [],
      observationsNum: 0,
      openObservation: [],
      dataProcessed: false,
      tabValue: 0,
      isCollapsed: [false, false],
      noLine: 2,
    };

    this.handleChangeBar = this.handleChangeBar.bind(this);
    this.handleChangeLine = this.handleChangeLine.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleChangeSelect = (event) => {
    if (this.state.tabValue === 0) {
      let newData = [...this.state.openObservation];
      var difference = newData.length - event.target.value;
      if (difference < 0) {
        for (let index = 0; index < difference * -1; index++) {
          newData.push([this.state.observationsNum[0]]);
        }
      } else if (difference > 0) {
        newData = [...this.state.openObservation].slice(0, difference * -1);
      }

      this.setState({ noLine: event.target.value, openObservation: newData });
    } else {
      let newData = [...this.state.dataTyp];
      var difference = newData.length - event.target.value;
      if (difference < 0) {
        for (let index = 0; index < difference * -1; index++) {
          newData.push("encounters");
        }
      } else if (difference > 0) {
        newData = [...this.state.dataTyp].slice(0, difference * -1);
      }

      this.setState({
        noBar: event.target.value,
        dataTyp: newData,
      });
    }
  };

  handleToggleCollapse = (indexToRemove) => {
    let copyState = [...this.state.isCollapsed];
    let trueFalse = copyState[indexToRemove];
    copyState[indexToRemove] = !trueFalse;
    this.setState({ isCollapsed: copyState });
  };

  filterNumObservations(observations) {
    var numObservations = [];
    if (observations !== undefined) {
      for (let i = 0; i < observations.data.length; i++) {
        if (observations.data[i].TYPE === "numeric") {
          numObservations.push(observations.data[i]);
        }
      }

      return numObservations.sort(function (a, b) {
        if (a.DESCRIPTION > b.DESCRIPTION) {
          return +1;
        }
        if (a.DESCRIPTION < b.DESCRIPTION) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      return numObservations;
    }
  }

  getAllKindsOfObservations(observations) {
    let observationCoded = [];
    let observationArray = [];

    for (let i = 0; i < observations.length; i++) {
      if (!observationCoded.includes(observations[i].CODE)) {
        observationCoded.push(observations[i].CODE);
        observationArray.push(observations[i]);
      }
    }

    return observationArray.sort(function (a, b) {
      if (a.DESCRIPTION > b.DESCRIPTION) {
        return +1;
      }
      if (a.DESCRIPTION < b.DESCRIPTION) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  handleChangeBar = (e) => {
    this.setState((prevState) => ({
      dataTyp: prevState.dataTyp.map((element, i) =>
        i === e.target.name ? e.target.value : element
      ),
    }));
  };

  handleChangeLine = (e) => {
    var openObservation = this.state.observationsNum.find(
      (o) => o.DESCRIPTION === e.target.value
    );

    var newOpenObservationArray = [
      ...this.state.openObservation[e.target.name],
    ];

    newOpenObservationArray.push(openObservation);
    this.setState(
      (prevState) => ({
        openObservation: prevState.openObservation.map((element, i) =>
          i === e.target.name ? newOpenObservationArray : element
        ),
      }),
      () => this.handleToggleCollapse(e.target.name)
    );
  };

  removeObservation = (openObservation, index, indexChart) => {
    var newOpenObservations = [...openObservation[indexChart]];
    newOpenObservations.splice(index, 1);
    this.setState((prevState) => ({
      openObservation: prevState.openObservation.map((element, i) =>
        i === indexChart ? newOpenObservations : element
      ),
    }));
  };

  prepareDataTypSelect(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "patient") {
        displayArray.push(key);
      }
    }

    return displayArray;
  }

  kuerzeString(string) {
    // Überprüfe, ob der String länger als 10 Zeichen ist
    if (string.length > 14) {
      // Kürze den String auf die ersten 10 Zeichen und füge "..." hinzu
      return string.slice(0, 14) + "...";
    } else {
      // Wenn der String bereits 10 Zeichen oder weniger hat, gib ihn unverändert zurück
      return string;
    }
  }

  displayOptions(view, openObservation) {
    const { isCollapsed } = this.state;
    const lineColors = [
      "rgb(10,169,222)",
      "rgb(249,227,12)",
      "rgb(217,70,140)",
      "rgb(246,220,228)",
      "rgb(0,114,184)",
      "rgb(210,210,235)",
      "rgb(232,92,34)",
      "rgb(119,66,73)",
    ];

    if (this.props.displayedDataProcessed) {
      return (
        <div style={{ display: "flex" }}>
          {openObservation.map((chartNo, i) => (
            <div
              style={{
                display: "flex",
                width: "50%",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "15px",
                  marginLeft: "10px",
                }}
              >
                Chart {i + 1}:
              </p>
              <div className="charts-options-div-dashboard">
                {chartNo.map((options, index) => (
                  <Chip
                    label={this.kuerzeString(options.DESCRIPTION)}
                    onDelete={() =>
                      this.removeObservation(
                        this.state.openObservation,
                        index,
                        i
                      )
                    }
                    style={{
                      marginRight: 5,
                      backgroundColor: lineColors[index],
                    }}
                  />
                ))}
                {!isCollapsed[i] && chartNo.length < 2 && (
                  <IconButton
                    aria-label="add"
                    onClick={() => this.handleToggleCollapse(i)}
                  >
                    <AddCircleIcon />
                  </IconButton>
                )}

                <Collapse orientation="horizontal" in={isCollapsed[i]}>
                  <Select
                    id="select"
                    onChange={this.handleChangeLine}
                    value=""
                    name={i}
                    sx={{
                      marginLeft: "10px",
                      height: "35px",
                      minWidth: "120px",
                    }}
                  >
                    <MenuItem value={""}>
                      <em>None</em>
                    </MenuItem>
                    {this.getAllKindsOfObservations(
                      this.state.observationsNum
                    ).map((item) => (
                      <MenuItem value={item.DESCRIPTION}>
                        {item.DESCRIPTION}
                      </MenuItem>
                    ))}
                  </Select>
                </Collapse>

                {isCollapsed[i] ? (
                  <IconButton
                    aria-label="add"
                    onClick={() => this.handleToggleCollapse(i)}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width: "50%", alignItems: "center" }}>
            <p
              style={{
                fontSize: "15px",
                marginLeft: "10px",
              }}
            >
              Chart 1:
            </p>
            <div className="charts-options-div-dashboard">
              <IconButton aria-label="add" onClick={this.handleToggleCollapse}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", width: "50%", alignItems: "center" }}>
            <p
              style={{
                fontSize: "15px",
                marginLeft: "10px",
              }}
            >
              Chart 2:
            </p>
            <div className="charts-options-div-dashboard">
              <IconButton aria-label="add" onClick={this.handleToggleCollapse}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
        </div>
      );
    }
  }

  displayEmptySelect(openObservation, index) {
    if (openObservation[index].DESCRIPTION === undefined) {
      return (
        <option value="none" selected disabled hidden>
          Select an Observation
        </option>
      );
    }
  }

  loadFirstObservations(observationsNum) {
    var openObservation = [];

    var index1 = observationsNum.findIndex(
      (o) => o.DESCRIPTION === "Body Height"
    );

    var index2 = observationsNum.findIndex(
      (o) => o.DESCRIPTION === "Body Weight"
    );

    if (index1 !== -1 && index2 !== -1) {
      openObservation = [[observationsNum[index1]], [observationsNum[index2]]];
    } else if (observationsNum.length > 2) {
      openObservation = [[observationsNum[0]], [observationsNum[1]]];
    } else {
      openObservation = [[observationsNum[0]], [observationsNum[0]]];
    }

    this.setState({
      observationsNum: observationsNum,
      openObservation: openObservation,
    });
  }

  loadObservations = (data) => {
    const observationsNum = this.filterNumObservations(data);
    if (observationsNum.length !== 0) {
      this.loadFirstObservations(observationsNum);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.displayedDataProcessed !== this.props.displayedDataProcessed &&
      this.props.displayedDataProcessed
    ) {
      this.loadObservations(this.props.data.observations);
    }
  }

  componentDidMount() {
    if (this.props.displayedDataProcessed) {
      this.loadObservations(this.props.data.observations);
    }
  }

  render() {
    return (
      <div className="box-shadow">
        <Backdrop
          sx={{
            color: "#4169e1",
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.623)",
          }}
          open={!this.props.displayedDataProcessed}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{ display: "flex" }}>
          {this.state.openObservation.map((openObservation) => (
            <div style={{ width: "49%" }}>
              <div>
                <LineCharts
                  data={this.props.data}
                  openObservation={openObservation}
                  observationsNum={this.state.observationsNum}
                  showLegend={false}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="barOptions">
          {this.displayOptions(this.state.tabValue, this.state.openObservation)}
        </div>
      </div>
    );
  }
}
