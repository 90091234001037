import React, { Component } from "react";

import PopAnaDashboardMostPopular from "./PopAnaDashboardMostPopular";
import PopAnaDashboardAgeDis from "./PopAnaDashboardAgeDis";
import PopAnaDashboardDonutCharts from "./PopAnaDashboardDonutCharts";
import PopAnaDashboardSurvivale from "./PopAnaDashboardSurvivale";
import PopAnaDashboardGenralInfo from "./PopAnaDashboardGenralInfo";

export default class PopAnaDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "20%", height: "100%" }}>
            <PopAnaDashboardSurvivale patientList={this.props.patientList} />
          </div>
          <div style={{ width: "40%", height: "100%" }}>
            <PopAnaDashboardMostPopular
              popAnaDashboardData={this.props.popAnaDashboardData}
            />
          </div>
          <div style={{ width: "40%", height: "100%" }}>
            <PopAnaDashboardDonutCharts patientList={this.props.patientList} />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "45%",
            display: "flex",
          }}
        >
          <div style={{ width: "30%", height: "100%" }}>
            <PopAnaDashboardGenralInfo
              patientList={this.props.patientList}
              openDB={this.props.openDB}
              allReasonCodes={this.props.allReasonCodes}
              location={this.props.location}
            />
          </div>
          <div style={{ width: "40%", height: "100%" }}>
            <PopAnaDashboardAgeDis patientList={this.props.patientList} />
          </div>
        </div>
      </div>
    );
  }
}
