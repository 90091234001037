// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-box {
  padding: 6px;
  width: 30vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  align-items: flex-start;
  min-height: 10vh;
}

.heading-text{
  color: #4169e1;
  font-weight: 400;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/PatientRecords/OverviewSavedCriterions.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,oCAAoC;EACpC,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".display-box {\n  padding: 6px;\n  width: 30vw;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 16px;\n  align-items: flex-start;\n  min-height: 10vh;\n}\n\n.heading-text{\n  color: #4169e1;\n  font-weight: 400;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
