import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/universalStyles.css";
import "./styles/fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";

import DeleteSnackbar from "./utils/Snackbars/DeleteSnackbar";
import ReconMongoSnackbar from "./utils/Snackbars/ReconMongoSnackbar";

const SnackbarInitializer = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reconnect, setReconnect] = useState(true);

  const handleReconnect = (id) => {
    closeSnackbar(id);
    setReconnect((prevState) => !prevState); // Ändert den Wert auf das Gegenteil
  };

  const showSnackbar = (variant, text) => {
    enqueueSnackbar(text, {
      variant: variant,
    });
  };

  const showDeleteSnackbar = (message) => {
    enqueueSnackbar("", {
      content: (key) => (
        <DeleteSnackbar
          id={key}
          message={message}
          closeSnackbar={closeSnackbar}
        />
      ),
    });
  };

  const showMongoReSnackbar = (message) => {
    enqueueSnackbar("", {
      content: (key) => (
        <ReconMongoSnackbar
          id={key}
          message={message}
          closeSnackbar={closeSnackbar}
          handleReconnect={handleReconnect}
        />
      ),
      autoHideDuration: null,
    });
  };

  return (
    <App
      showSnackbar={showSnackbar}
      showDeleteSnackbar={showDeleteSnackbar}
      showMongoReSnackbar={showMongoReSnackbar}
      reconnect={reconnect}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <SnackbarInitializer />
    </SnackbarProvider>
  </BrowserRouter>
);

reportWebVitals();
