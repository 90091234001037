import React, { Component } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Head/Header";

import "./SyntheaView.css";

export default class SyntheaView extends Component {
  render() {
    return (
      <div className="synthea-view-main">
        <Header
          savingLocation={this.props.savingLocation}
          openNewDb={this.props.openNewDb}
          allDbs={this.props.allDbs}
          location={this.props.location}
          openDB={this.props.openDB}
          nextPat={this.props.nextPatient}
          prevPat={this.props.prevPatient}
          collapsesSave={this.props.collapsesSave}
          openPatient={this.props.openPatient}
          navigate={this.props.navigate}
          openSettings={this.props.openSettings}
          sHeaderDisplay={this.props.sHeaderDisplay}
          simulationSpan={this.props.simulationSpan}
        />
        <div className="syn-view-content-box">
          <div className="background-main-view">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}
