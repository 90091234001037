import React, { Component } from "react";
import iccasLogo from "../data/iccas_logo.jpg";
import nfdi4 from "../data/NFDI4DS_Logo.png";
import { Divider } from "@mui/material";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Collapse,
  TextField,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";

import { Link } from "react-router-dom";

import "./Header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsedInput: false,
      open: false,
      showPatViewNav: false,
      allDbs: [],
    };
  }

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        isCollapsedInput: !prevState.isCollapsedInput,
      }),
      () => {
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  findObjectByName(array, name) {
    return array.find((obj) => obj.name === name);
  }

  handleChange = (event) => {
    const name = event.target.value;
    const location = this.findObjectByName(this.state.allDbs, name).location;
    this.props.openNewDb(name, location);
    this.setState({ open: false });
  };

  onBlur = () => {
    this.setState({ open: false, isCollapsedInput: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, isCollapsedInput: true });
    document.getElementById("select-view").blur();
  };

  whichPage(navItem) {
    if (navItem === this.props.location.pathname) {
      return "header-navigation-item-active";
    } else {
      return "header-navigation-item";
    }
  }

  formatDateToISO(date) {
    const year = date.getFullYear(); // Jahr
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Monat (Januar = 0, daher +1) mit führender Null
    const day = String(date.getDate()).padStart(2, "0"); // Tag mit führender Null

    return `${year}/${month}/${day}`;
  }

  prepareDbForSelect(dbs) {
    let allDbs = [];
    for (const key in dbs) {
      for (let i = 0; i < dbs[key].length; i++) {
        let db = {};
        if (key === "index") {
          db["name"] = dbs[key][i].name;
        } else if (key === "mongo") {
          db["name"] = dbs[key][i].Name;
        }

        db["location"] = key;
        allDbs.push(db);
      }
    }

    return allDbs;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      const parts = this.props.location.pathname.split("/");
      var newView = "";
      var showPatViewNav = false;

      if (parts.includes("overview-dataset")) {
        newView = "Dataset dashboard";
      } else if (parts.includes("patient-records")) {
        newView = "Patient records";
      } else if (parts.includes("population-analysis")) {
        newView = "Population analysis";
      }

      if (parts.includes("patient-view")) {
        showPatViewNav = true;
      }

      this.setState({ showPatViewNav: showPatViewNav });
    }

    if (prevProps.allDbs !== this.props.allDbs) {
      this.setState({ allDbs: this.prepareDbForSelect(this.props.allDbs) });
    }
  }

  componentDidMount() {
    const parts = this.props.location.pathname.split("/");
    var newView = "";
    var showPatViewNav = false;

    if (parts.includes("overview-dataset")) {
      newView = "Dataset dashboard";
    } else if (parts.includes("patient-records")) {
      newView = "Patient records";
    } else if (parts.includes("population-analysis")) {
      newView = "Population analysis";
    }

    if (parts.includes("patient-view")) {
      showPatViewNav = true;
    }

    this.setState({
      showPatViewNav: showPatViewNav,
      allDbs: this.prepareDbForSelect(this.props.allDbs),
    });
  }

  render() {
    return (
      <div className="header-main-box">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "330px",
            width: "20vw",
            marginLeft: "2vw",
          }}
        >
          <Link to="/" style={{ cursor: "pointer" }}>
            <div className="synspector-logo">Synspector</div>
          </Link>
          <div
            style={{
              fontSize: "13px",
              lineHeight: "14px",
              color: "var(--disabled-color)",
              marginLeft: "5vw",
            }}
          >
            <div className="flex gap-4 items-center">
              <p className="whitespace-nowrap">Open dataset:</p>
              <FormControl variant="standard" fullWidth>
                <Select
                  id="select-view"
                  value={this.props.openDB}
                  open={this.state.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                  onClose={this.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                  onOpen={this.handleOpen}
                  onChange={this.handleChange}
                  onBlur={this.onBlur}
                  disableUnderline
                  bluronselect="true"
                  sx={{
                    height: "20px", // Ändere die Mindesthöhe nach Bedarf
                    fontSize: "13px",
                    fontWeight: "400",
                    width: "fit-content",
                    "& .MuiSelect-icon": {
                      color: "var(--disabled-color)", // Farbe des Icons
                      width: "21px",
                    },
                    "& .MuiSelect-select": {
                      borderBottom: "none", // Entfernt die untere Linie
                      color: "var(--disabled-color)", // Farbe des Texts
                      padding: 0,
                    },
                  }}
                >
                  <MenuItem
                    value=""
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    None
                  </MenuItem>
                  {this.state.allDbs.map((db) => (
                    <MenuItem
                      value={db.name}
                      sx={{
                        fontSize: "13px",
                      }}
                      location={db.location}
                    >
                      {db.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <p
              className="text-left"
              style={{ fontSize: "11px", color: "var(--divider-color)" }}
            >
              {this.props.simulationSpan.loaded &&
                this.formatDateToISO(this.props.simulationSpan.start)}{" "}
              -{" "}
              {this.props.simulationSpan.loaded &&
                this.formatDateToISO(this.props.simulationSpan.end)}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            flexShrink: 1,
            minWidth: "580px",
            maxWidth: "750px",
          }}
        >
          <Link
            to="/syn-viewer/overview-dataset"
            className={this.whichPage("/syn-viewer/overview-dataset")}
          >
            <p>DASHBOARD</p>
          </Link>
          <Link
            to="/syn-viewer/patient-records/overview"
            className={this.whichPage("/syn-viewer/patient-records/overview")}
          >
            <p>PATIENT RECORDS</p>
          </Link>
          <Link
            to="/syn-viewer/population-analysis/pop-ana-dashboard"
            className={this.whichPage(
              "/syn-viewer/population-analysis/pop-ana-dashboard"
            )}
          >
            <p>POPULATION ANALYSIS</p>
          </Link>
          <Link
            to="/syn-viewer/module-diagram"
            className={this.whichPage("/syn-viewer/module-diagram")}
          >
            <p>MODULE VIEWER</p>
          </Link>
          <Link
            to="/syn-viewer/datasets/datasets-overview"
            className={this.whichPage("/syn-viewer/datasets/datasets-overview")}
          >
            <p>DATASETS</p>
          </Link>
          <div className="icon-head" onClick={this.props.openSettings}>
            <SettingsIcon sx={{ width: "19px", height: "19px" }} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "2vw",
          }}
        >
          <img src={nfdi4} alt="" className="logo-nfdi-head" />
          <img src={iccasLogo} alt="" className="logo-iccas-head"></img>
        </div>
      </div>
    );
  }
}
