import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import axios from "axios";

import React, { Component } from "react";

// more ideas var minAge = 200;
/**
 *     var maxAge = 0;
    var incomeSum = 0;
    var averageIncome = 0;
    var healthESum = 0;
    var averageHealthE = 0;
    var healthCSum = 0;
    var averageHealthC = 0;
    var patientNoHealthCare = 0;
 */

export default class PopAnaDashboardGenralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simSpan: "",
      ageStatistics: {
        medianAge: 0,
        averageAge: 0,
        averageMaleAge: 0,
        averageFemaleAge: 0,
        processed: false,
      },
      pop: { f: 0, m: 0, all: 0, ethinics: [], processed: false },
      averageEncounters: { mean: 0, deviation: 0, processed: false },
      mostPopBirth: "",
    };
  }

  getNoOFEncountersPerPat(dbName, storeName) {
    return new Promise((resolve, reject) => {
      // Öffne die IndexedDB-Datenbank
      const request = indexedDB.open(dbName);

      request.onerror = (event) => {
        reject(`Database error: ${event.target.errorCode}`);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(storeName, "readonly");
        const objectStore = transaction.objectStore(storeName);
        const entriesPatIdArr = [];
        var entriesCountObj = {};

        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            if (!entriesPatIdArr.includes(cursor.value.PATIENT)) {
              entriesPatIdArr.push(cursor.value.PATIENT);
              entriesCountObj[cursor.value.PATIENT] = 0;
            } else {
              entriesCountObj[cursor.value.PATIENT] += 1;
            }
            cursor.continue();
          } else {
            resolve(entriesCountObj);
          }
        };

        transaction.onerror = (event) => {
          reject(`Transaction error: ${event.target.errorCode}`);
        };
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };
    });
  }

  getNoOFEncountersPerPatMongoDB = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getAverageEncounter",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        this.setState({
          averageEncounters: {
            mean: response.data.data[0].averageEncountersPerPatient,
            deviation: response.data.data[0].deviationEncountersPerPatient,
            processed: true,
          },
        });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  sortByBirthday(arr) {
    return arr.sort((a, b) => {
      let dateA = new Date(a.Birthdate);
      let dateB = new Date(b.Birthdate);
      return dateA - dateB;
    });
  }

  formatDate(date) {
    var dateO = new Date(date);
    let year = dateO.getFullYear();
    let month = (dateO.getMonth() + 1).toString().padStart(2, "0"); // Monate sind 0-basiert, daher +1
    let day = dateO.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  getSimSpan(patientList) {
    console.log(patientList);
    const start = this.sortByBirthday(patientList)[0].Birthdate;
    const end = patientList[0].Age.estimatedEndOfSimulation;
    return this.formatDate(start) + " / " + this.formatDate(end);
  }

  calculateAgeStatistics(patients) {
    const ages = patients.map((patient) => patient.Age.years);
    const maleAges = patients
      .filter((patient) => patient.Gender === "M")
      .map((patient) => patient.Age.years);
    const femaleAges = patients
      .filter((patient) => patient.Gender === "F")
      .map((patient) => patient.Age.years);

    const medianAge = this.calculateMedian(ages);
    const averageAge = this.calculateAverage(ages);
    const averageMaleAge = this.calculateAverage(maleAges);
    const averageFemaleAge = this.calculateAverage(femaleAges);

    return {
      medianAge: medianAge,
      averageAge: averageAge,
      averageMaleAge: averageMaleAge,
      averageFemaleAge: averageFemaleAge,
      processed: true,
    };
  }

  calculateMedian(arr) {
    const sortedArr = arr.slice().sort((a, b) => a - b);
    const midIndex = Math.floor(sortedArr.length / 2);

    if (sortedArr.length % 2 === 0) {
      return (sortedArr[midIndex - 1] + sortedArr[midIndex]) / 2;
    } else {
      return sortedArr[midIndex];
    }
  }

  calculateAverage(arr) {
    const sum = arr.reduce((acc, age) => acc + age, 0);
    return sum / arr.length;
  }

  calculateFM(patientList) {
    var f = 0;
    var m = 0;
    var ethinics = [];

    for (let i = 0; i < patientList.length; i++) {
      if (patientList[i].Gender === "M") {
        m++;
      } else {
        f++;
      }

      if (!ethinics.includes(patientList[i].JSONFile.RACE)) {
        ethinics.push(patientList[i].JSONFile.RACE);
      }
    }

    return { f: f, m: m, all: f + m, ethinics: ethinics, processed: true };
  }

  getMostPopBirth(patientList) {
    var birthplaceName = [];
    var birthplaceFrequencies = {};

    for (let i = 0; i < patientList.length; i++) {
      let birthplace = patientList[i].JSONFile.BIRTHPLACE;
      if (!birthplaceName.includes(birthplace)) {
        birthplaceName.push(birthplace);
        birthplaceFrequencies[birthplace] = 0;
      } else {
        birthplaceFrequencies[birthplace] += 1;
      }
    }

    var mostCommonName = "";
    var mostCommonNo = 0;

    for (const key in birthplaceFrequencies) {
      if (birthplaceFrequencies[key] > mostCommonNo) {
        mostCommonName = key;
        mostCommonNo = birthplaceFrequencies[key];
      }
    }

    return mostCommonName;
  }

  processPatientList(patientList) {
    const SimSpan = this.getSimSpan(patientList);
    const ageStatistics = this.calculateAgeStatistics(patientList);
    const pop = this.calculateFM(patientList);
    const mostPopBirth = this.getMostPopBirth(patientList);
    this.setState({
      simSpan: SimSpan,
      ageStatistics: ageStatistics,
      pop: pop,
      mostPopBirth: mostPopBirth,
    });
  }

  calculateStandardDeviation(numbers) {
    const n = numbers.length;
    if (n === 0) return 0;

    const mean = numbers.reduce((acc, num) => acc + num, 0) / n;
    const variance =
      numbers.reduce((acc, num) => acc + Math.pow(num - mean, 2), 0) / n;
    return Math.sqrt(variance);
  }

  calculateAverageEncounters(encounters) {
    var allFrequencies = [];

    for (const key in encounters) {
      allFrequencies.push(encounters[key]);
    }

    var averageEncounters = {
      mean: this.calculateAverage(allFrequencies),
      deviation: this.calculateStandardDeviation(allFrequencies),
      processed: true,
    };

    return averageEncounters;
  }

  componentDidMount() {
    if (this.props.patientList.length !== 0) {
      this.processPatientList(this.props.patientList);
    }

    if (this.props.location === "index") {
      this.getNoOFEncountersPerPat(this.props.openDB, "encounters")
        .then((entries) =>
          this.setState({
            averageEncounters: this.calculateAverageEncounters(entries),
          })
        )
        .catch((error) => console.error(error));
    } else if (this.props.location === "mongo") {
      this.getNoOFEncountersPerPatMongoDB(this.props.openDB);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.patientList !== this.props.patientList &&
      this.props.patientList.length > 0
    ) {
      this.processPatientList(this.props.patientList);
    }
  }

  render() {
    const styleLisItem = {
      marginTop: "0",
      marginBottom: "0",
      fonSize: "15px",
    };
    const alignRight = {
      textAlign: "right",
      marginTop: "0",
      marginBottom: "0",
      fonSize: "15px",
    };
    return (
      <div>
        {" "}
        <p
          style={{
            textAlign: "left",
            lineHeight: "2rem",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "14px",
            marginLeft: "15px",
          }}
        >
          Basic dataset information
        </p>
        <Divider />
        <List dense>
          <ListItem>
            <ListItemText style={styleLisItem}>
              Estimated simulation span (start/stop)
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.state.simSpan !== "" ? (
                this.state.simSpan
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              No. of reason codes
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.props.allReasonCodes.loaded ? (
                this.props.allReasonCodes.data.length
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              Most frequent reason code
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.props.allReasonCodes.loaded ? (
                this.props.allReasonCodes.data.length > 0 ? (
                  this.props.allReasonCodes.data[0].description
                ) : (
                  <span>no reason codes</span>
                )
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              Population Size (Female/Male)
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.state.pop.processed ? (
                <>
                  {this.state.pop.all} ({this.state.pop.f} / {this.state.pop.m})
                </>
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>Median age (y)</ListItemText>
            <ListItemText style={alignRight}>
              {this.state.ageStatistics.processed ? (
                this.state.ageStatistics.medianAge.toFixed(1)
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>Average age (y)</ListItemText>
            <ListItemText style={alignRight}>
              {this.state.ageStatistics.processed ? (
                this.state.ageStatistics.averageAge.toFixed(1)
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>Average age F (y)</ListItemText>
            <ListItemText style={alignRight}>
              {this.state.ageStatistics.processed ? (
                this.state.ageStatistics.averageFemaleAge.toFixed(1)
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>Average age M (y)</ListItemText>
            <ListItemText style={alignRight}>
              {this.state.averageEncounters.processed ? (
                this.state.ageStatistics.averageMaleAge.toFixed(1)
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              Average encounters with the health system (SD)
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.state.averageEncounters.processed ? (
                <>
                  {this.state.averageEncounters.mean.toFixed(1) + " ("} &plusmn;
                  {this.state.averageEncounters.deviation.toFixed(1) + ")"}
                </>
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              Most common place of birth
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.state.mostPopBirth !== "" ? (
                this.state.mostPopBirth
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
          <ListItem style={styleLisItem}>
            <ListItemText style={styleLisItem}>
              Represented Ethnicities
            </ListItemText>
            <ListItemText style={alignRight}>
              {this.state.pop.processed ? (
                this.state.pop.ethinics.join(", ")
              ) : (
                <CircularProgress color="inherit" size={15} />
              )}
            </ListItemText>
          </ListItem>
        </List>
      </div>
    );
  }
}
