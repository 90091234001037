/*
kaplanMeierAnalysis(data, 'time', 'event')
*/

export default class KaplanMeier {
  kaplanMeierAnalysis(data, timeColumn, eventColumn) {
    // Sortiere die Daten nach Zeit
    data.sort((a, b) => a[timeColumn] - b[timeColumn]);

    // Initialisiere Variablen
    let survival = 1;
    let survivalCurve = [];
    let nAtRisk = data.length;
    let events = 1;
    let censoredPoints = [];

    // Berechne die Überlebenswahrscheinlichkeiten
    data.forEach((row, index) => {
      const currentTime = row[timeColumn];
      const nextTime =
        index < data.length - 1 ? data[index + 1][timeColumn] : undefined;
      const isEvent = row[eventColumn] === 1;
      const atRisk = data.length - index;

      if (isEvent && currentTime !== nextTime) {
        survival *= (atRisk - 1) / atRisk;
        survivalCurve.push({
          time: currentTime,
          survival: survival,
          nAtRisk: nAtRisk,
          events: events,
        });

        if (events !== 1) {
          events = 1;
        }
      } else if (isEvent && currentTime === nextTime) {
        survival *= (atRisk - 1) / atRisk;
        events++;
      }

      if (!isEvent) {
        var cordinaten = { x: currentTime, y: survival };
        censoredPoints.push(cordinaten);
      }

      nAtRisk--;
    });

    // Funktion zur Berechnung der Überlebensrate bei einem bestimmten Zeitpunkt
    const getSurvivalRate = (time) => {
      const entryIndex = survivalCurve.findIndex((entry) => entry.time > time);
      var entry =
        entryIndex > 0
          ? survivalCurve[entryIndex - 1]
          : survivalCurve[entryIndex];
      return entry ? entry.survival : 0;
    };

    // Berechne die Überlebensraten für 1 und 5 Jahre
    const oneYearSurvival = getSurvivalRate(1 * 365);
    const fiveYearSurvival = getSurvivalRate(5 * 365);
    const tenYearSurvival = getSurvivalRate(10 * 365);
    const yearSurv = {
      oneYearSurvival: (oneYearSurvival * 100).toFixed(1),
      fiveYearSurvival: (fiveYearSurvival * 100).toFixed(1),
      tenYearSurvival: (tenYearSurvival * 100).toFixed(1),
    };
    return {
      yearSurv: yearSurv,
      n: data.length,
      censoredPoints: censoredPoints,
      survivalCurve: survivalCurve,
    };
  }
}
