import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Divider, Skeleton } from "@mui/material";

import Stack from "@mui/material/Stack";

import "../PopulationAnalysis/Dashboard/AgeDisStyles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default class PopAnaAgeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { dataProcessed: false, ageDis: {} };
  }

  componentDidMount() {
    if (this.props.patientList.length !== 0) {
      this.setState(
        { ageDis: this.calculateAgeDistribution(this.props.patientList) },
        () => {
          this.setState({ dataProcessed: true });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.patientList !== this.props.patientList) {
      this.setState({ dataProcessed: false }, () =>
        this.setState(
          { ageDis: this.calculateAgeDistribution(this.props.patientList) },
          () => {
            this.setState({ dataProcessed: true });
          }
        )
      );
    }
  }

  calculateAgeDistribution(patients) {
    const ageGroups = [
      "0-9",
      "10-19",
      "20-29",
      "30-39",
      "40-49",
      "50-59",
      "60-69",
      "70-79",
      "80+",
    ];

    const distribution = {
      male: Array(ageGroups.length).fill(0),
      female: Array(ageGroups.length).fill(0),
    };

    patients.forEach((patient) => {
      const { Gender, Age } = patient;

      if (Gender !== undefined) {
        let ageGroupIndex;
        if (Age.years < 10) ageGroupIndex = 0;
        else if (Age.years < 20) ageGroupIndex = 1;
        else if (Age.years < 30) ageGroupIndex = 2;
        else if (Age.years < 40) ageGroupIndex = 3;
        else if (Age.years < 50) ageGroupIndex = 4;
        else if (Age.years < 60) ageGroupIndex = 5;
        else if (Age.years < 70) ageGroupIndex = 6;
        else if (Age.years < 80) ageGroupIndex = 7;
        else ageGroupIndex = 8;

        if (Gender.toLowerCase() === "m") {
          distribution.male[ageGroupIndex]++;
        } else if (Gender.toLowerCase() === "f") {
          distribution.female[ageGroupIndex]++;
        }
      }
    });

    return distribution;
  }

  // Funktion zum Abrufen von CSS-Variablenwerten
  getCSSVariableValue = (variableName) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variableName)
      .trim();
  };

  render() {
    const data = {
      labels: [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80+",
      ],
      datasets: [
        {
          label: "Female",
          backgroundColor: this.getCSSVariableValue("--chart-1"),
          borderColor: this.getCSSVariableValue("--chart-1"),
          borderWidth: 1,
          data: this.state.ageDis.female, // Beispielwerte für weiblich
        },
        {
          label: "Male",
          backgroundColor: this.getCSSVariableValue("--chart-2"),
          borderColor: this.getCSSVariableValue("--chart-2"),
          borderWidth: 1,
          data: this.state.ageDis.male, // Beispielwerte für männlich
        },
      ],
    };

    const styleHeading = {
      textAlign: "left",
      lineHeight: "1rem",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "14px",
      color: "var(--primary-color)",
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Verstecke die eingebaute Legende
        },
        title: {
          display: false, // Verstecke den eingebauten Titel
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Age in years", // X-Achsentitel
          },
        },
        y: {
          title: {
            display: true,
            text: "n", // Y-Achsentitel
          },
        },
      },
    };

    if (this.state.dataProcessed) {
      return (
        <div className="h-full w-full pt-5 pb-5 pr-5 flex flex-col">
          <div className="flex justify-between">
            <p style={styleHeading}>Age distribution by gender</p>

            <div className="flex gap-3" style={{ fontSize: "14px" }}>
              <div className="flex gap-1 items-center leading-4">
                <div
                  style={{
                    backgroundColor: "var(--chart-1)",
                    width: "10px",
                    height: "10px",
                  }}
                ></div>
                Female
              </div>
              <div className="flex gap-1 items-center leading-4">
                <div
                  style={{
                    backgroundColor: "var(--chart-2)",
                    width: "10px",
                    height: "10px",
                  }}
                ></div>
                Male
              </div>
            </div>
          </div>
          <Divider
            sx={{
              marginTop: "5px",
              marginBottom: "10px",
            }}
          />
          <div className="flex-grow flex items-center pr-5 pt-5">
            <Bar data={data} options={options} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="rounded" height={300} />
          </Stack>
        </div>
      );
    }
  }
}
