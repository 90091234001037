import React, { Component } from "react";
import DonutChartChartJs from "../Charts/DonutChartChartJs/DountChartChartJs";
import { Divider, Skeleton } from "@mui/material";

export default class PopAnaDashboardDonutCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataGender: { data: [], labels: [], processed: false },
    };
  }

  getGenderAndDeath(patients) {
    var female = 0;
    var male = 0;
    var death = 0;
    var alive = 0;

    for (let i = 0; i < patients.length; i++) {
      if (patients[i].Gender === "M") {
        male++;
      } else {
        female++;
      }

      if (patients[i].Deathdate !== null) {
        death++;
      }
    }

    alive = patients.length - death;

    const dataGender = {
      gender: { data: [female, male], labels: ["female", "male"] },
      death: { data: [death, alive], labels: ["dead", "alive"] },
      processed: true,
    };

    return dataGender;
  }

  componentDidMount() {
    if (this.props.patientList.length !== 0) {
      this.setState({
        dataGender: this.getGenderAndDeath(this.props.patientList),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.patientList !== this.props.patientList) {
      this.setState({
        dataGender: this.getGenderAndDeath(this.props.patientList),
      });
    }
  }

  render() {
    const styleHeading = {
      textAlign: "left",
      lineHeight: "2rem",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "14px",
      marginLeft: "15px",
    };

    const styleChart = {
      width: "200px",
      marginLeft: "10px",
      marginTop: "10px",
    };

    const backgroundColors = [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "rgb(158, 180, 197)",
    ];

    const backgroundColorsDeath = ["rgb(60, 60, 70)", "rgb(117, 120, 141)"];

    return (
      <div>
        <div>
          <p style={styleHeading}>Gender distribution and alive-dead ratio</p>
        </div>
        <Divider />
        <div style={{ display: "flex", marginLeft: "2vh" }}>
          {this.state.dataGender.processed ? (
            <div style={{ display: "flex" }}>
              <div style={styleChart}>
                <DonutChartChartJs
                  data={this.state.dataGender.gender.data}
                  labels={this.state.dataGender.gender.labels}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                {this.state.dataGender.gender.labels
                  .slice(0, 4)
                  .map((label, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: 1,
                        fontSize: "13px",
                        fontWeight: "300",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "8px",
                          width: "8px",
                          backgroundColor: backgroundColors[index],
                          marginRight: "8px",
                        }}
                      ></div>
                      <div>{label}</div>
                    </div>
                  ))}
              </div>
              <div style={{ display: "flex" }}>
                <div style={styleChart}>
                  <DonutChartChartJs
                    data={this.state.dataGender.death.data}
                    labels={this.state.dataGender.death.labels}
                    colors={backgroundColorsDeath}
                  />
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  {this.state.dataGender.death.labels
                    .slice(0, 4)
                    .map((label, index) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          lineHeight: 1,
                          fontSize: "13px",
                          fontWeight: "300",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            backgroundColor: backgroundColorsDeath[index],
                            marginRight: "8px",
                          }}
                        ></div>
                        <div>{label}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <Skeleton variant="circular" width={200} height={200} />
          )}
        </div>
      </div>
    );
  }
}
