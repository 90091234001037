import React, { forwardRef } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Button } from "@mui/material";

// Benutzerdefinierte Snackbar-Komponente mit Ref-Forwarding
const ReconMongoSnackbar = forwardRef(
  ({ id, message, closeSnackbar, handleReconnect }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          backgroundColor: "#f22e2e",
          color: "white",
          padding: "12px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
          width: "560px",
          fontSize: "14px",
        }}
      >
        <PriorityHighIcon
          className="ml-2"
          sx={{ color: "white", marginLeft: "2px" }}
        />
        <span className="ml-2">{message}</span>
        <Button
          variant="outlined"
          onClick={() => handleReconnect(id)}
          sx={{
            color: "white",
            borderColor: "white",
            marginLeft: "10px",
            width: "160px",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "white", // Hover-Farbe
              borderColor: "white",
              color: "#f22e2e",
            },
          }}
        >
          Reconnect
        </Button>
        <IconButton
          style={{
            color: "white",
            width: "25px",
            height: "25px",
            marginLeft: "8px",
          }}
          onClick={() => closeSnackbar(id)}
        >
          <CloseIcon size="small" />
        </IconButton>
      </div>
    );
  }
);

export default ReconMongoSnackbar;
