import React, { Component } from "react";

import {
  FormControl,
  TextField,
  Box,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import fileColumnSearchbar from "./DexieDatabase/fileColumnSearchbar";
import axios from "axios";

export default class FileColumValueSearchNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startPointFile: "",
      startPointColum: "",
      startPointEvent: "",
      searchResultsEvent: [],
      showDropdown: false,
      allFiles: [],
      allColumnsOfFile: [],
      searchId: 0,
      loadingSearchResults: true,
      prevLoaded: false,
    };
  }

  handleChangeSelectStart = (event) => {
    this.setState({ startPointFile: event.target.value });
  };

  handleChangeSelectStartColum = (event) => {
    this.setState({ startPointColum: event.target.value });
  };

  handleChangeEventStartPoint = (event) => {
    if (event.target.id !== "value") {
      this.setState({ startPointEvent: event.target.value });
      if (event.target.value !== "") {
        this.performSearch(
          event.target.value,
          this.state.startPointFile,
          this.state.startPointColum,
          this.props.location
        );
      } else {
        this.displayFirstColumn(
          this.props.prevChoice.file,
          this.props.prevChoice.colum,
          this.props.location
        );
      }
    } else {
      const value = event.target.getAttribute("data-value");
      this.setState({ startPointEvent: value });
    }
  };

  performSearch = async (
    searchTerm,
    filename,
    selectValueColumn,
    savingLocation
  ) => {
    if (searchTerm.toString().trim() === "") {
      this.displayFirstColumn(filename, selectValueColumn, this.state.location);
      return;
    }

    const searchId = this.state.searchId + 1;

    this.setState({ searchId: searchId, loadingSearchResults: true }, () => {
      if (savingLocation === "index") {
        this.searchDexie(searchTerm, filename, selectValueColumn, searchId);
      } else if (savingLocation === "mongo") {
        this.searchMongo(searchTerm, filename, selectValueColumn, searchId);
      }
    });
  };

  searchDexie = (searchTerm, filename, selectValueColumn, searchId) => {
    const dbSearch = new fileColumnSearchbar(this.props.openDB, filename);
    dbSearch
      .searchDatabase(selectValueColumn, searchTerm)
      .then((data) => {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResultsEvent: data,
            loadingSearchResults: false,
          });
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  searchMongo = async (searchTerm, filename, selectValueColumn, searchId) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/searchByStartsWith",
        {
          params: {
            dbName: this.props.openDB,
            collectionName: filename,
            indexName: selectValueColumn,
            searchValue: searchTerm,
          },
        }
      );
      if (response.data.status === "success") {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResultsEvent: response.data.data,
            loadingSearchResults: false,
          });
        } else {
          this.setState({
            searchResultsEvent: [],
            loadingSearchResults: false,
          });
        }
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  displayFirstColumn = (filename, selectValueColumn, savingLocation) => {
    //returns the first 10 entrys of fiting column
    const searchId = this.state.searchId + 1;

    this.setState({ searchId: searchId, loadingSearchResults: true }, () => {
      if (savingLocation === "index") {
        this.displayFirstColumnDexie(filename, selectValueColumn, searchId);
      } else if (savingLocation === "mongo") {
        this.displayFirstColumnMongo(filename, selectValueColumn, searchId);
      }
    });
  };

  displayFirstColumnDexie = (filename, selectValueColumn, searchId) => {
    const dbSearch = new fileColumnSearchbar(this.props.openDB, filename);
    dbSearch
      .firstUniqueValues(selectValueColumn)
      .then((data) => {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResultsEvent: data,
            loadingSearchResults: false,
          });
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  displayFirstColumnMongo = async (filename, selectValueColumn, searchId) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFileColumnSearchbar",
        {
          params: {
            dbName: this.props.openDB,
            collectionName: filename,
            indexName: selectValueColumn,
          },
        }
      );
      if (response.data.status === "success") {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResultsEvent: response.data.data,
            loadingSearchResults: false,
          });
        }
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  componentDidMount() {
    var filesForPat = [];
    for (let i = 0; i < this.props.loadedFileList.length; i++) {
      if (
        this.props.loadedFileList[i].indices.includes("PATIENT") ||
        this.props.loadedFileList[i].indices.includes("PATIENTID")
      ) {
        filesForPat.push(this.props.loadedFileList[i]);
      }

      if (
        !this.props.disablePat &&
        this.props.loadedFileList[i].name === "patients"
      ) {
        filesForPat.push(this.props.loadedFileList[i]);
      }
    }

    this.setState({
      allFiles: filesForPat,
      startPointFile: filesForPat[0].name,
    });

    if (this.props.prevChoice.file !== "") {
      var allIndices = [];
      for (let i = 0; i < filesForPat.length; i++) {
        if (filesForPat[i].name === this.props.prevChoice.file) {
          const toRemove = [
            "START",
            "STOP",
            "BIRTHDATE",
            "DEATHDATE",
            "DATE",
            "_id_",
          ];
          allIndices = filesForPat[i].indices.filter(
            (item) => !toRemove.includes(item)
          );
          break;
        }
      }

      this.displayFirstColumn(
        this.props.prevChoice.file,
        this.props.prevChoice.colum,
        this.props.location
      );

      this.setState(
        {
          allColumnsOfFile: allIndices,
        },
        () =>
          this.setState(
            {
              startPointFile: this.props.prevChoice.file,
              startPointColum: this.props.prevChoice.colum,
              startPointEvent: this.props.prevChoice.value,
            },
            () => {
              this.setState({ prevLoaded: true });
            }
          )
      );
    } else {
      this.setState({ prevLoaded: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startPointFile !== this.state.startPointFile &&
      this.state.prevLoaded
    ) {
      for (let i = 0; i < this.state.allFiles.length; i++) {
        if (this.state.allFiles[i].name === this.state.startPointFile) {
          const toRemove = [
            "START",
            "STOP",
            "BIRTHDATE",
            "DEATHDATE",
            "DATE",
            "_id_",
          ];
          const allIndices = this.state.allFiles[i].indices.filter(
            (item) => !toRemove.includes(item)
          );
          this.setState({
            allColumnsOfFile: allIndices,
            startPointColum: allIndices[0],
            startPointEvent: "",
          });
          this.displayFirstColumn(
            this.state.startPointFile,
            allIndices[0],
            this.props.location
          );
          break;
        }
      }
    }

    if (
      prevState.startPointColum !== this.state.startPointColum &&
      this.state.prevLoaded
    ) {
      if (
        this.state.startPointFile !== "" &&
        this.state.startPointColum !== ""
      ) {
        this.setState({
          startPointEvent: "",
        });

        this.displayFirstColumn(
          this.state.startPointFile,
          this.state.startPointColum,
          this.props.location
        );
      }
    }

    if (
      prevState.startPointEvent !== this.state.startPointEvent &&
      this.state.prevLoaded
    ) {
      this.props.callBack({
        file: this.state.startPointFile,
        colum: this.state.startPointColum,
        value: this.state.startPointEvent,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          marginTop: "15px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>Choose file</p>{" "}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              id="demo-simple-select-standard"
              value={this.state.startPointFile}
              onChange={this.handleChangeSelectStart}
              label="File"
            >
              {this.state.allFiles.map((file) => (
                <MenuItem value={file.name} key={file.name}>
                  {file.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <p>Choose colum</p>{" "}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              id="demo-simple-select-standard"
              value={this.state.startPointColum}
              onChange={this.handleChangeSelectStartColum}
              label="File"
            >
              {this.state.allColumnsOfFile.map((file) => (
                <MenuItem value={file} key={file}>
                  {file}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            position="relative"
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "25px",
            }}
          >
            <p>Event</p>
            <TextField
              name="value"
              variant="standard"
              value={this.state.startPointEvent}
              onBlur={() => this.setState({ showDropdown: false })}
              onFocus={() => this.setState({ showDropdown: true })}
              onChange={this.handleChangeEventStartPoint}
              sx={{ m: 1, width: "15vw", marginLeft: "15px" }}
              size="small"
            />
            {this.state.showDropdown && (
              <Box
                className="dropdown-box"
                style={{ marginLeft: "48px", width: "15vw" }}
              >
                {this.state.searchResultsEvent.length === 0 &&
                  !this.state.loadingSearchResults && (
                    <MenuItem name="value" value="" key="">
                      no fitting value found
                    </MenuItem>
                  )}

                {!this.state.loadingSearchResults ? (
                  this.state.searchResultsEvent.map((result, index) => (
                    <MenuItem
                      key={result + index}
                      id="value"
                      value={result}
                      data-value={result}
                      onMouseDown={this.handleChangeEventStartPoint}
                    >
                      {result}
                    </MenuItem>
                  ))
                ) : (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={25} />
                  </div>
                )}
              </Box>
            )}
          </Box>
        </div>
      </div>
    );
  }
}
