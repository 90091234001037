import React, { Component } from "react";
import { Checkbox } from "@mui/material";
import { Link } from "react-router-dom";

import manulePic31Pic1 from "../data/pictures/manule-3.1-pic1.png";
import manulePic31Pic2 from "../data/pictures/manule-3.1-pic2.png";
import manulePic32Pic1 from "../data/pictures/manule-3.2-pic1.png";
import manulePic33Pic1 from "../data/pictures/manule-3.3-pic1.png";
import manulePic33Pic2 from "../data/pictures/manule-3.3-pic2.png";
import manulePic33Pic3 from "../data/pictures/manule-3.3-pic3.png";
import manulePic33Pic4 from "../data/pictures/manule-3.3-pic4.png";

export default class DialogPopUp extends Component {
  openNewTab = (link) => {
    window.open(link, "_blank");
  };

  render() {
    return (
      <div style={{ width: "75vw", height: "fit-content", maxHeight: "85vh" }}>
        <div
          style={{
            height: "fit-content",
            maxHeight: "81vh",
            overflowY: "auto",
          }}
        >
          {this.props.showDialogStatesKey === "dashboard" && (
            <div
              style={{
                fontWeight: 300,
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <p>Welcome at the SynViewer! </p>
              <p style={{ marginTop: "15px" }}>
                The SynViewer is a program for visualizing data generated by{" "}
                <span
                  onClick={() =>
                    this.openNewTab(
                      "https://github.com/synthetichealth/synthea/wiki"
                    )
                  }
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  Synthea
                </span>
                . It allows you to explore each patient's disease progression in
                different views and to better understand how your modules affect
                each patient's medical history. You can also see the probability
                of each patient being generated by your modules, or how often
                your module generates the exact patient in question.
              </p>
              <p style={{ marginTop: "15px" }}>
                For more information on how the website works please click{" "}
                <Link
                  to="/"
                  onClick={this.props.closeDialog}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  here
                </Link>{" "}
                and go to the "HOW IT WORKS" Chapter 3.2.
              </p>
              <p style={{ marginTop: "15px" }}>
                You are now at the dataset dashboard. Here you will find an
                overview of the key points of the open dataset. The following
                image shows all button functions:
              </p>
              <img
                alt=""
                src={manulePic32Pic1}
                style={{
                  width: "60vw",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "6vw",
                }}
              />
              <ol style={{ marginLeft: "20px", marginLeft: "6vw" }}>
                <li>
                  1. Navigation, lets you change between dashboard, patient
                  records and population analysis.
                </li>
                <li>2. Opens the Module Viewer</li>
                <li>3. opens dataset manager.</li>
                <li>4. opens settings.</li>
                <li>
                  5. dashboard view of dataset details. Click on the box to open
                  dataset manger.
                </li>
                <li>
                  6. dashboard view of patient records. Click on the box to open
                  patient records.
                </li>
                <li>
                  7. dashboard view of population analysis. Click on the box to
                  open population analysis.
                </li>
              </ol>
            </div>
          )}
          {this.props.showDialogStatesKey === "patientRecordOverview" && (
            <div
              style={{
                fontWeight: 300,
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <p>
                This are the Patient records. Here you can view the individual
                patient histories. On the left you will find the filter options,
                click on a patient in the list to open their record (Patient
                View).
              </p>
              <p style={{ marginTop: "15px" }}>
                For more information on how the Patient records works please
                click{" "}
                <Link
                  to="/"
                  onClick={this.props.closeDialog}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  here
                </Link>{" "}
                and go to the "HOW IT WORKS", Chapter 3.2.1. .
              </p>
              <p style={{ marginTop: "15px" }}>
                This is a brief overview of the available functions:{" "}
              </p>
              <img
                alt=""
                src={manulePic33Pic1}
                style={{
                  width: "60vw",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "6vw",
                }}
              />
              <ol style={{ marginLeft: "20px", marginLeft: "6vw" }}>
                <li>
                  1. Click here to perform a search with in the patient list.
                </li>
                <li>2. check box to turn filter options on and off</li>
                <li>
                  3. opens pop up to manage visitable and hidden filter options
                </li>
                <li>4. opens the raw file in pop-up</li>
                <li>5. opens pop-up to add new filter option</li>
              </ol>
            </div>
          )}
          {this.props.showDialogStatesKey === "patientRecordPatView" && (
            <div
              style={{
                fontWeight: 300,
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <p>
                This is the Patient View. Here you can view the individual
                patient histories.
              </p>
              <p style={{ marginTop: "15px" }}>
                For more information on how the Patient View works please click{" "}
                <Link
                  to="/"
                  onClick={this.props.closeDialog}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  here
                </Link>{" "}
                and go to the "HOW IT WORKS", Chapter 3.2.2. .
              </p>
              <p style={{ marginTop: "15px" }}>
                The following screen shows all the controls with their function:
              </p>
              <img
                alt=""
                src={manulePic33Pic4}
                style={{
                  width: "60vw",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "6vw",
                }}
              />
              <ol style={{ marginLeft: "20px", marginLeft: "6vw" }}>
                <li>1. See next patient in the list.</li>
                <li>2. See previous patient in the list</li>
                <li>3. Switch in between filter profiles.</li>
                <li>
                  4. Safe current selection of include and exclude codes as
                  filter profile.
                </li>
                <li>5. Delete currently active filter profile</li>
                <li>6. Add a code that should be included.</li>
                <li>7. Add a code that should be excluded.</li>
                <li>
                  8. Select a reason code for which encounters should be
                  included.
                </li>
                <li>9. Click to collapse the control box.</li>
                <li>
                  10. Choose if all events of an included encounter should also
                  be included.
                </li>
                <li>
                  11. Slider to select the time period (in years) to include.
                </li>
                <li>12. Navigation to switch in between views.</li>
              </ol>
            </div>
          )}
          {this.props.showDialogStatesKey === "moduleViewer" && (
            <div
              style={{
                fontWeight: 300,
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <p>
                This is the Module viewer. This part of the website is in the
                early stages of development. You can already upload and view
                your own Synthea modules. Later it will be possible to view the
                course of selected patients through the selected module.
              </p>
              <p style={{ marginTop: "15px" }}>
                For more information on how the module viewer works please click{" "}
                <Link
                  to="/"
                  onClick={this.props.closeDialog}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  here
                </Link>{" "}
                and go to the "HOW IT WORKS", Chapter 3.2.4. .
              </p>
            </div>
          )}
          {this.props.showDialogStatesKey === "datasetManager" && (
            <div
              style={{
                fontWeight: 300,
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <p>
                This is the dataset manager. You can manage and switch between
                your datasets here. To do this, click on the green ‘Load’
                button. If you click on the line of a dataset, the detailed view
                of the dataset opens and you can view the raw data.
              </p>
              <p style={{ marginTop: "15px" }}>
                {" "}
                To import new datasets, click on the plus button.
              </p>
              <p style={{ marginTop: "15px" }}>
                For more information on how the Dataset manager works please
                click{" "}
                <Link
                  to="/"
                  onClick={this.props.closeDialog}
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: 400,
                    borderBottom: "1px solid var(--primary-color)",
                  }}
                >
                  here
                </Link>{" "}
                and go to the "HOW IT WORKS", Chapter 3.2.5. .
              </p>
              <img
                alt=""
                src={manulePic31Pic2}
                style={{
                  width: "60vw",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "6vw",
                }}
              />
              <p
                style={{
                  marginBottom: "20px",
                  marginTop: "15px",
                  marginLeft: "6vw",
                }}
              >
                Dataset manager, 3 - Button that allows you to load already
                locally saved datasets, 4 - button to load new datasets
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            height: "4vh",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 300, marginRight: "15px" }}>
            Do not show this message again (Can be reset in settings)
          </p>
          <Checkbox
            edge="start"
            name="dont show again"
            onChange={this.props.dontShowAgain}
          />
        </div>
      </div>
    );
  }
}
