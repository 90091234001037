import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import {
  List,
  Divider,
  ListItemButton,
  Collapse,
  ListItemText,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./PatientHistory.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4169e1",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Spline Sans",
    fontWeight: 350,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class PatientHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "",
      selectedAccordionKey: {},
    };
  }

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  /*---------------------------------------- sort functions ---------------------------------------------------------------------------------------------------------------------- */

  handleRequestSort = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  mapTableHead(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(key);
      }
    }
    return displayArray;
  }

  mapHistory(data) {
    var displayArray = [];
    for (const key in data) {
      if (data[key].length !== 0) {
        var obj = {};
        obj["typ"] = key;
        obj["data"] = data[key];
        displayArray.push(obj);
      }
    }

    return displayArray;
  }

  returnPatientTable(data) {
    var patientDataHead = this.mapTableHead(data.data[0]);
    return (
      <TableContainer
        component={Paper}
        ref={this.containerRef}
        sx={{ marginLeft: "10px", marginRight: "10px", width: "99%" }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {patientDataHead.map((data) => (
                <StyledTableCell
                  key={data}
                  sortDirection={
                    this.state.orderBy === data ? this.state.order : false
                  }
                >
                  <TableSortLabel
                    active={this.state.orderBy === data}
                    direction={
                      this.state.orderBy === data ? this.state.order : "asc"
                    }
                    onClick={this.handleRequestSort(data)}
                    sx={{ fontFamily: "Spline Sans" }}
                  >
                    {data}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.stableSort(
              data.data,
              this.getComparator(this.state.order, this.state.orderBy)
            ).map((x) => (
              <StyledTableRow key={x.id}>
                {this.mapDataView(x).map((y, index) => (
                  <StyledTableCell
                    sx={{ fontFamily: "Spline Sans" }}
                    key={x + y + index}
                  >
                    {y instanceof Date ? this.formatDate(y) : y}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  handleAccordionSelect = (selectedKey) => {
    this.setState((prevState) => ({
      selectedAccordionKey: {
        ...prevState.selectedAccordionKey,
        [selectedKey]:
          prevState.selectedAccordionKey[selectedKey] === undefined
            ? true
            : !prevState.selectedAccordionKey[selectedKey],
      },
    }));

    /** currently not needed
    setTimeout(() => {
      const element = document.getElementById(
        `accordion-header-${selectedKey}`
      );
      if (element) {
        const headerRect = element.getBoundingClientRect();
        const isHeaderVisible =
          headerRect.top >= 0 && headerRect.bottom <= window.innerHeight;
        if (!isHeaderVisible) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, 260);
     */
  };

  componentDidMount() {
    this.props.whichLocationWhenMounted();
    if (this.props.clickedEncounter !== "") {
      const clickedEncounter = this.props.clickedEncounter;
      this.setState(
        (prevState) => ({
          selectedAccordionKey: {
            ...prevState.selectedAccordionKey,
            [clickedEncounter]: true,
          },
        }),
        () => {
          document.getElementById(clickedEncounter).scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.props.resetClickedEncounter();
        }
      );
    }
  }

  render() {
    return (
      <div style={{ padding: "10px" }}>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {this.props
            .matchEncounter(this.props.openPatientData)
            .map((data, index) => (
              <div id={data.encounter.Id} key={data.encounter.Id}>
                {index === 0 && <Divider />}
                <ListItemButton
                  onClick={() => this.handleAccordionSelect(data.encounter.Id)}
                  sx={{ height: "7vh" }}
                  id={`accordion-header-${data.encounter.Id}`}
                >
                  <ListItemText
                    primary={
                      this.formatDate(data.encounter.START) +
                      " " +
                      data.encounter.DESCRIPTION
                    }
                    sx={{ fontWeight: 400 }}
                  />
                  {this.state.selectedAccordionKey[data.encounter.Id] ===
                  true ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Divider />
                <Collapse
                  in={
                    this.state.selectedAccordionKey[data.encounter.Id] === true
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      fontWeight: "380",
                      margin: "15px",
                    }}
                  >
                    <p className="encounter-general-item">
                      <strong>Id:</strong> {data.encounter.Id}
                    </p>
                    <p className="encounter-general-item">
                      <strong>Class:</strong> {data.encounter.ENCOUNTERCLASS}
                    </p>
                    <p className="encounter-general-item">
                      <strong>Reason:</strong>{" "}
                      {data.encounter.REASONDESCRIPTION !== ""
                        ? data.encounter.REASONDESCRIPTION
                        : "-"}
                    </p>
                    <p className="encounter-general-item">
                      <strong>Start:</strong>{" "}
                      {this.formatDate(data.encounter.START)}
                    </p>
                    <p className="encounter-general-item">
                      <strong>Stop:</strong>{" "}
                      {this.formatDate(data.encounter.STOP)}
                    </p>
                    <button
                      className="btn-text"
                      onClick={() =>
                        this.props.onClickDataViewEncounter(data.encounter.Id)
                      }
                      style={{ height: "24px" }}
                    >
                      Encounter-Details <ArrowForwardIcon />
                    </button>
                  </div>
                  {this.state.selectedAccordionKey[data.encounter.Id] === true
                    ? this.mapHistory(data.events).map((events) => (
                        <div>
                          <h5
                            style={{
                              marginTop: "15px",
                              marginLeft: "15px",
                              marginBottom: "10px",
                              textAlign: "left",
                              fontWeight: "550",
                            }}
                          >
                            {events.typ.toUpperCase()}
                          </h5>
                          {this.returnPatientTable(events)}
                        </div>
                      ))
                    : ""}
                </Collapse>

                {this.state.selectedAccordionKey[data.encounter.Id] ===
                  true && <Divider style={{ marginTop: "15px" }} />}
              </div>
            ))}
        </List>
      </div>
    );
  }
}
