// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-dashboard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #9ba1bb2a;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".box-dashboard {\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  border: 1px solid #9ba1bb2a;\n  border-radius: 5px;\n  margin: 10px;\n  padding: 5px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
