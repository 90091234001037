import React, { forwardRef } from "react";

// Benutzerdefinierte Snackbar-Komponente mit Ref-Forwarding
const CustomSnackbar = forwardRef(({ id, message, closeSnackbar }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        backgroundColor: "#b4b8b5",
        color: "white",
        padding: "12px",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
        fontSize: "14px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="icon icon-tabler icons-tabler-filled icon-tabler-trash ml-2"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16z" />
        <path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" />
      </svg>
      <span className="ml-2">{message}</span>
    </div>
  );
});

export default CustomSnackbar;
