import { TextField, Button } from "@mui/material";
import axios from "axios";

import React, { Component } from "react";

export default class SetPasswort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      oldPassword: "",
      message: "",
    };
  }

  async setPassword(password, newPassword) {
    try {
      // Objekt mit den zu sendenden Daten
      const data = {
        password, // Altes Passwort (wenn vorhanden)
        newPassword, // Neues Passwort
      };

      // POST-Anfrage mit axios
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/set-password",
        data
      );

      // Erfolgreiche Antwort behandeln
      console.log("Antwort:", response.data);
      this.setState({ message: response.data });
    } catch (error) {
      // Fehler behandeln
      if (error.response) {
        // Der Server hat geantwortet, aber mit einem Fehlerstatuscode
        console.error("Fehler:", error.response.data);
        this.setState({ message: error.response.data });
      } else {
        // Ein anderer Fehler, z.B. Verbindungsfehler
        console.error("Fehler:", error.message);
        this.setState({ message: error.message });
      }
    }
  }

  handleChangeNew = (event) => {
    const value = event.target.value;
    this.setState({ newPassword: value });
  };

  handleChangeOld = (event) => {
    const value = event.target.value;
    this.setState({ oldPassword: value });
  };

  render() {
    return (
      <div className="text-left pl-9">
        <p className="pt-5 text-xl">Add new password:</p>
        <p className="pt-3 text-xl">
          If you already set other password you need to typ it in, to change to
          a new password. If this is the first password you set just leave the
          field empty.
        </p>
        <div className="flex font-light mt-8 gap-6">
          <div className="flex items-center gap-2">
            <p>Old password:</p>
            <TextField
              id="chunk-size"
              label=""
              variant="outlined"
              value={this.state.oldPassword}
              onChange={this.handleChangeOld}
              sx={{ width: "500px" }}
            />
          </div>
          <div className="flex items-center gap-2">
            <p>New password:</p>
            <TextField
              id="chunk-size"
              label=""
              variant="outlined"
              value={this.state.newPassword}
              onChange={this.handleChangeNew}
              sx={{ width: "500px" }}
            />
          </div>
          <Button
            color="success"
            variant="contained"
            onClick={() =>
              this.setPassword(this.state.oldPassword, this.state.newPassword)
            }
          >
            apply
          </Button>
          <p>{this.state.message}</p>
        </div>
      </div>
    );
  }
}
