// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
}

.chart-title {
  flex: 3 1;
  text-align: left;
}

.chart-legend {
  flex: 1 1;
  text-align: right;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/PopulationAnalysis/Dashboard/AgeDisStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".chart-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1vh;\n}\n\n.chart-title {\n  flex: 3;\n  text-align: left;\n}\n\n.chart-legend {\n  flex: 1;\n  text-align: right;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
