// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pat-csv-table {
  display: flex;
  flex-wrap: wrap;
  border-top: #4361ee 1px solid;
  border-left: #4361ee 1px solid;
  width: 80vw;
}

.pat-csv-table tr {
  width: 50%; /* Jede tr nimmt die Hälfte der Breite ein */
  box-sizing: border-box;
}

.pat-csv-table tr th {
  width: 15vw;
  background-color: #aabcf2a8;
  color: #4361ee;
  text-align: left;
  padding-left: 1vw;
  border-bottom: #4361ee 1px solid;
  border-right: #4361ee 1px solid;
}

.pat-csv-table tr td {
  width: 25vw;
  text-align: right;
  padding-right: 1vw;
  border-bottom: #4361ee 1px solid;
  border-right: #4361ee 1px solid;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/PatientDataview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,UAAU,EAAE,4CAA4C;EACxD,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,gCAAgC;EAChC,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,gCAAgC;EAChC,+BAA+B;AACjC","sourcesContent":[".pat-csv-table {\n  display: flex;\n  flex-wrap: wrap;\n  border-top: #4361ee 1px solid;\n  border-left: #4361ee 1px solid;\n  width: 80vw;\n}\n\n.pat-csv-table tr {\n  width: 50%; /* Jede tr nimmt die Hälfte der Breite ein */\n  box-sizing: border-box;\n}\n\n.pat-csv-table tr th {\n  width: 15vw;\n  background-color: #aabcf2a8;\n  color: #4361ee;\n  text-align: left;\n  padding-left: 1vw;\n  border-bottom: #4361ee 1px solid;\n  border-right: #4361ee 1px solid;\n}\n\n.pat-csv-table tr td {\n  width: 25vw;\n  text-align: right;\n  padding-right: 1vw;\n  border-bottom: #4361ee 1px solid;\n  border-right: #4361ee 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
