import React, { Component } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";

export default class GeneralSettings extends Component {
  clearAllLocalStorage = () => {
    localStorage.clear();
    this.deleteAllDatabases();
  };

  deleteDatabase = (dbName) => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        resolve(`Datenbank ${dbName} wurde erfolgreich gelöscht.`);
      };
    });
  };

  deleteAllDatabases = () => {
    // Holen aller Datenbanknamen
    // Holen aller Datenbanknamen
    indexedDB.databases().then((databases) => {
      databases.forEach((dbInfo) => {
        // Jede Datenbank einzeln löschen
        this.deleteDatabase(dbInfo.name)
          .then((message) => {
            this.props.showDeleteSnackbar(
              "Database '" + dbInfo.name + "' was deleted."
            );
          })
          .catch((error) => {
            console.error(error);
            // Fehlerbehandlung
          });
      });
    });
  };

  render() {
    const btnStyle = {
      borderColor: "var(--primary-color)",
      color: "var(--primary-color)",
    };

    const btnStyleActive = {
      color: "white",
      backgroundColor: "var(--primary-color)",
    };

    return (
      <div style={{ fontFamily: "Spline Sans, sans-serif" }}>
        <div style={{ width: "55vw" }}>
          <p style={{ color: "var(--primary-color)", fontSize: "25px" }}>
            Navigation
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "80%",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Header display:
            </p>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& > *": {
                  m: 1,
                },
              }}
            >
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button
                  sx={
                    this.props.sHeaderDisplay === "dense"
                      ? btnStyleActive
                      : btnStyle
                  }
                  onClick={() => this.props.changeSHeaderDisplay("dense")}
                >
                  Dense
                </Button>
                <Button
                  sx={
                    this.props.sHeaderDisplay === "standard"
                      ? btnStyleActive
                      : btnStyle
                  }
                  onClick={() => this.props.changeSHeaderDisplay("standard")}
                >
                  Standard
                </Button>
              </ButtonGroup>
            </Box>
          </div>
        </div>
        <div>
          <p
            style={{
              color: "var(--primary-color)",
              fontSize: "25px",
              marginTop: "20px",
            }}
          >
            Information Pop-Ups
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "80%",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Reset and show all messages again:
            </p>
            <Button variant="contained" onClick={this.props.resetDialog}>
              Reset
            </Button>
          </div>
        </div>
        <div>
          <p
            style={{
              color: "var(--primary-color)",
              fontSize: "25px",
              marginTop: "20px",
            }}
          >
            Locally stored data
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "80%",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Delete all databases (filter profiles and filter criterions will
              be kept):
            </p>
            <Button
              variant="contained"
              color="error"
              onClick={this.deleteAllDatabases}
            >
              Delete Databases
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "80%",
              marginTop: "10px",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: 300, marginRight: "2vw" }}
            >
              Delete all locally stored data (including filter profiles and
              filter criterions):
            </p>
            <Button
              variant="contained"
              color="error"
              onClick={this.clearAllLocalStorage}
            >
              Delete all data
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
