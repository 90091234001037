import React, { Component } from "react";

import {
  Paper,
  Divider,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  List,
} from "@mui/material";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import SsidChartIcon from "@mui/icons-material/SsidChart";

import "./PopulationAnalysis.css";
import { Link, Outlet } from "react-router-dom";

export default class PopulationAnalysis extends Component {
  render() {
    return (
      <div className="populationAnalysis-main-div">
        <Paper
          className="container1"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            height: "100%",
            backgroundColor: "var(--background-lvl-0)",
          }}
        >
          <div className="navigation-pop-ana">
            <List
              sx={{
                width: "100%",
                backgroundColor: "var(--background-lvl-0)",
                marginRight: "1vw",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Link to="/syn-viewer/population-analysis/pop-ana-dashboard">
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardCustomizeIcon />
                  </ListItemIcon>
                  <ListItemText>Dashboard</ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/syn-viewer/population-analysis/pop-ana-survival">
                <ListItemButton>
                  <ListItemIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-chart-dots"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 3v18h18" />
                      <path d="M9 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M14 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M10.16 10.62l2.34 2.88" />
                      <path d="M15.088 13.328l2.837 -4.586" />
                    </svg>
                  </ListItemIcon>
                  <ListItemText>Survival</ListItemText>
                </ListItemButton>
              </Link>
              <Link>
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardCustomizeIcon />
                  </ListItemIcon>
                  <ListItemText>Frequencies</ListItemText>
                </ListItemButton>
              </Link>
              <Link>
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardCustomizeIcon />
                  </ListItemIcon>
                  <ListItemText>Correlations</ListItemText>
                </ListItemButton>
              </Link>
            </List>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              marginLeft: "0.5vw",
              marginRight: "0.5vw",
            }}
          />
          <div className="content-pop-ana">
            <Outlet />
          </div>
        </Paper>
      </div>
    );
  }
}
