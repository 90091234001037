// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.populationAnalysis-main-div {
  width: 100%;
  height: 100%;
}

.col {
  height: 40vh;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.content-div {
  background-color: #f1f4fd;
  height: 100%;
  width: 100%;
  border: 1px solid #d5d8e057;
  border-radius: 7px;
  box-shadow: 0 4px 2px -2px rgba(168, 168, 168, 0.699);
  padding: 5px;
}

.container1 {
  position: relative;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Halbdurchsichtiger Hintergrund */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  z-index: 1000;
}

.overlay-text1 {
  color: white;
  font-size: 20px; /* Beispielschriftgröße */
}

.navigation-pop-ana {
  width: -moz-fit-content;
  width: fit-content;
}

.content-pop-ana {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/PopulationAnalysis/PopulationAnalysis.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;EAClB,qDAAqD;EACrD,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,mCAAmC;EACzE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe,EAAE,yBAAyB;AAC5C;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".populationAnalysis-main-div {\n  width: 100%;\n  height: 100%;\n}\n\n.col {\n  height: 40vh;\n  padding-left: 1vw;\n  padding-right: 1vw;\n  padding-top: 1vh;\n  padding-bottom: 1vh;\n}\n\n.content-div {\n  background-color: #f1f4fd;\n  height: 100%;\n  width: 100%;\n  border: 1px solid #d5d8e057;\n  border-radius: 7px;\n  box-shadow: 0 4px 2px -2px rgba(168, 168, 168, 0.699);\n  padding: 5px;\n}\n\n.container1 {\n  position: relative;\n}\n\n.overlay1 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4); /* Halbdurchsichtiger Hintergrund */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 20px;\n  color: white;\n  z-index: 1000;\n}\n\n.overlay-text1 {\n  color: white;\n  font-size: 20px; /* Beispielschriftgröße */\n}\n\n.navigation-pop-ana {\n  width: fit-content;\n}\n\n.content-pop-ana {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
