import {
  Divider,
  Collapse,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import React, { Component } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import KaplanMeier from "../../utils/PopAnalyser/KaplanMeier";

export default class PopAnaDashboardSurvivale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectItems: [],
      isCollapsedInput: true,
      valueSelect: "",
      open: false,
      no: 0,
      survival: {
        overAll: {},
        male: {},
        female: {},
        survivalAgeGroups: { "0-20": [], "21-50": [], "51-80": [], "80+": [] },
      },
      loaded: false,
    };
  }

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        isCollapsedInput: !prevState.isCollapsedInput,
      }),
      () => {
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  onBlur = () => {
    this.setState({ open: false, isCollapsedInput: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, isCollapsedInput: true });
    document.getElementById("diagnose-select").blur();
  };

  dateDifferenceInDays(date1, date2) {
    // Berechne den Zeitunterschied in Millisekunden
    const differenceInTime = date2.getTime() - date1.getTime();

    // Berechne den Zeitunterschied in Tagen
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  }

  prepareKM(patientList) {
    var overAllSurvival = [];
    var femaleMaleSurvival = {
      M: [],
      F: [],
    };
    var ageGroups = {
      "0-20": [],
      "21-50": [],
      "51-80": [],
      "80+": [],
    };

    var n = 0;

    //prepare Data
    for (let i = 0; i < patientList.length; i++) {
      let fitting = false;
      let conditionIndex = 0;

      for (
        let index = 0;
        index < patientList[i].JSONFile.CONDITIONS.length;
        index++
      ) {
        if (
          patientList[i].JSONFile.CONDITIONS[index].DESCRIPTION.replace(
            "(finding)",
            ""
          )
            .replace("(disorder)", "")
            .replace("(situation)", "") === this.state.valueSelect
        ) {
          fitting = true;
          n++;
          conditionIndex = index;
          break;
        }
      }

      if (fitting) {
        //perpare time and event
        let diagnoseDate = new Date(
          patientList[i].JSONFile.CONDITIONS[conditionIndex].START
        );
        let lastDay = new Date(patientList[i].Age.estimatedEndOfSimulation);
        let death = 0; //0 - living, 1 - death
        let ageGroupIndex = "";
        const { Age } = patientList[i];

        if (patientList[i].Deathdate !== null) {
          death = 1;
          lastDay = patientList[i].Deathdate;
        }

        let timeEvent = {
          time: this.dateDifferenceInDays(diagnoseDate, lastDay),
          event: death,
        };

        //for over all
        overAllSurvival.push(timeEvent);

        //for femaleMaleSurvival
        femaleMaleSurvival[patientList[i].Gender].push(timeEvent);

        //for age groups

        if (Age < 21) ageGroupIndex = "0-20";
        else if (Age.years < 51) ageGroupIndex = "21-50";
        else if (Age.years < 81) ageGroupIndex = "51-80";
        else ageGroupIndex = "80+";

        ageGroups[ageGroupIndex].push(timeEvent);
      }
    }

    this.setState({ n: n });

    const km = new KaplanMeier();

    //overall survival
    const survivalOverall = km.kaplanMeierAnalysis(
      overAllSurvival,
      "time",
      "event"
    );

    //male survival
    const survivalMale = km.kaplanMeierAnalysis(
      femaleMaleSurvival.M,
      "time",
      "event"
    );

    //female survival
    const survivalFemale = km.kaplanMeierAnalysis(
      femaleMaleSurvival.F,
      "time",
      "event"
    );

    var survivalAgeGroups = {};
    for (const key in ageGroups) {
      let survivalGroup = km.kaplanMeierAnalysis(
        ageGroups[key],
        "time",
        "event"
      );

      survivalGroup["n"] = ageGroups[key].length;
      survivalAgeGroups[key] = survivalGroup;
    }

    survivalOverall["n"] = overAllSurvival.length;
    survivalMale["n"] = femaleMaleSurvival.M.length;
    survivalFemale["n"] = femaleMaleSurvival.F.length;

    const survival = {
      overAll: survivalOverall,
      male: survivalMale,
      female: survivalFemale,
      survivalAgeGroups: survivalAgeGroups,
    };

    return survival;
  }

  handelSelect = (event) => {
    this.setState({ valueSelect: event.target.value, loaded: false });
  };

  sortStringsAlphabetically(strings) {
    return strings.sort((a, b) => a.localeCompare(b));
  }

  truncateString(str) {
    if (str !== undefined) {
      if (str.length > 30) {
        return str.substring(0, 27) + "...";
      }
    }
    return str;
  }

  getAllMenuItems(patientList) {
    var allDifferentDiagnosis = [];

    for (let i = 0; i < patientList.length; i++) {
      for (
        let index = 0;
        index < patientList[i].JSONFile.CONDITIONS.length;
        index++
      ) {
        if (
          !allDifferentDiagnosis.includes(
            patientList[i].JSONFile.CONDITIONS[index].DESCRIPTION.replace(
              "(finding)",
              ""
            )
              .replace("(disorder)", "")
              .replace("(situation)", "")
          )
        ) {
          allDifferentDiagnosis.push(
            patientList[i].JSONFile.CONDITIONS[index].DESCRIPTION.replace(
              "(finding)",
              ""
            )
              .replace("(disorder)", "")
              .replace("(situation)", "")
          );
        }
      }
    }

    return this.sortStringsAlphabetically(allDifferentDiagnosis);
  }

  componentDidMount() {
    if (this.props.patientList.Length !== 0) {
      var allMenuItems = this.getAllMenuItems(this.props.patientList);
      this.setState({ selectItems: allMenuItems }, () => {
        this.setState({ valueSelect: allMenuItems[0] });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.patientList !== this.props.patientList) {
      var allMenuItems = this.getAllMenuItems(this.props.patientList);
      this.setState({ selectItems: allMenuItems }, () => {
        this.setState({ valueSelect: allMenuItems[0] });
      });
    }

    if (prevState.valueSelect !== this.state.valueSelect) {
      this.setState({
        survival: this.prepareKM(this.props.patientList),
        loaded: true,
      });
    }

    if (
      prevState.isCollapsedInput !== this.state.isCollapsedInput &&
      !this.state.isCollapsedInput
    ) {
      document.getElementById("diagnose-select").focus();
    }
  }

  render() {
    const trBorder = {
      borderTop: "1px solid rgba(0, 0, 0, 0.15)",
    };

    const tdLeft = {
      textAlign: "left",
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <p
            style={{
              textAlign: "left",
              lineHeight: "2rem",
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "14px",
              marginLeft: "15px",
            }}
          >
            Survival
          </p>
          <div style={{ display: "flex" }}>
            <Collapse orientation="horizontal" in={this.state.isCollapsedInput}>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <p
                  style={{ whiteSpace: "nowrap", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  {this.state.valueSelect === ""
                    ? "No Diagnosis"
                    : this.truncateString(this.state.valueSelect)}
                </p>
                <IconButton
                  aria-label="openInput"
                  onClick={this.handleToggleCollapseInput}
                  style={{ width: "20px", height: "20px" }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </Collapse>
            <Collapse
              orientation="horizontal"
              in={!this.state.isCollapsedInput}
            >
              <FormControl variant="standard" fullWidth>
                <Select
                  id="diagnose-select"
                  value={this.state.valueSelect}
                  open={this.state.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                  onClose={this.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                  onOpen={this.handleOpen}
                  onChange={(event) => this.handelSelect(event)}
                  onBlur={this.onBlur}
                  autoFocus
                  sx={{
                    height: "30px", // Ändere die Mindesthöhe nach Bedarf
                    fontSize: "13px",
                    width: "6.8vw",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  <MenuItem
                    value={""}
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    <em>None</em>
                  </MenuItem>
                  {this.state.selectItems.map((item) => (
                    <MenuItem
                      value={item}
                      data-item={item}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
          </div>
        </div>
        <Divider />
        <div style={{ paddingLeft: "10px" }}>
          <table
            style={{
              lineHeight: "1.2rem",
              fontSize: "13px",
              marginRight: "10px",
              width: "97%",
              marginTop: "10px",
            }}
          >
            <tr
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "1.4rem",
              }}
            >
              <td colspan="2"></td>
              <td>1-year (%)</td>
              <td>5-year (%)</td>
              <td>N (={this.state.n})</td>
            </tr>

            {this.state.loaded ? (
              <tr style={trBorder}>
                <td colspan="2" style={tdLeft}>
                  overall
                </td>
                <td>{this.state.survival.overAll.yearSurv.oneYearSurvival}</td>
                <td>{this.state.survival.overAll.yearSurv.fiveYearSurvival}</td>
                <td>{this.state.survival.overAll.n}</td>
              </tr>
            ) : (
              <tr style={trBorder}>
                <td colspan="2" style={tdLeft}>
                  overall
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr style={trBorder}>
                <td style={tdLeft}>by gender</td>
                <td style={tdLeft}>female</td>
                <td>{this.state.survival.female.yearSurv.oneYearSurvival}</td>
                <td>{this.state.survival.female.yearSurv.fiveYearSurvival}</td>
                <td>{this.state.survival.female.n}</td>
              </tr>
            ) : (
              <tr style={trBorder}>
                <td style={tdLeft}>by gender</td>
                <td style={tdLeft}>female</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr>
                <td></td>
                <td style={tdLeft}>male</td>
                <td>{this.state.survival.male.yearSurv.oneYearSurvival}</td>
                <td>{this.state.survival.male.yearSurv.fiveYearSurvival}</td>
                <td>{this.state.survival.male.n}</td>
              </tr>
            ) : (
              <tr>
                <td></td>
                <td style={tdLeft}>male</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr style={trBorder}>
                <td
                  style={{ maxWidth: "65px", textAlign: "left" }}
                  rowSpan={"2"}
                >
                  by age-group (y)
                </td>
                <td style={tdLeft}>&lt;20</td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["0-20"].yearSurv
                      .oneYearSurvival
                  }
                </td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["0-20"].yearSurv
                      .fiveYearSurvival
                  }
                </td>
                <td>{this.state.survival.survivalAgeGroups["0-20"].n}</td>
              </tr>
            ) : (
              <tr style={trBorder}>
                <td style={tdLeft}>by age-group</td>
                <td style={tdLeft}>&lt;20</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr>
                <td style={tdLeft}>21-50</td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["21-50"].yearSurv
                      .oneYearSurvival
                  }
                </td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["21-50"].yearSurv
                      .fiveYearSurvival
                  }
                </td>
                <td>{this.state.survival.survivalAgeGroups["21-50"].n}</td>
              </tr>
            ) : (
              <tr>
                <td></td>
                <td style={tdLeft}>21-50</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr>
                <td></td>
                <td style={tdLeft}>51-80</td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["51-80"].yearSurv
                      .oneYearSurvival
                  }
                </td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["51-80"].yearSurv
                      .fiveYearSurvival
                  }
                </td>
                <td>{this.state.survival.survivalAgeGroups["51-80"].n}</td>
              </tr>
            ) : (
              <tr>
                <td></td>
                <td style={tdLeft}>51-80</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
            {this.state.loaded ? (
              <tr>
                <td></td>
                <td style={tdLeft}>&gt;80</td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["80+"].yearSurv
                      .oneYearSurvival
                  }
                </td>
                <td>
                  {
                    this.state.survival.survivalAgeGroups["80+"].yearSurv
                      .fiveYearSurvival
                  }
                </td>
                <td>{this.state.survival.survivalAgeGroups["80+"].n}</td>
              </tr>
            ) : (
              <tr>
                <td></td>
                <td style={tdLeft}>&gt;80</td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
                <td>
                  <Skeleton style={{ width: "100%" }} />
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
    );
  }
}
