import React, { Component } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import {
  List,
  Divider,
  ListItemButton,
  Collapse,
  ListItemText,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "./Timeline.css";

export default class PatientTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = { openAccordions: [] };
  }

  onClick = (encounterId) => {
    this.props.openEncounter(encounterId);
  };

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  mapHistory(data) {
    var displayArray = [];
    for (const key in data) {
      if (data[key].length !== 0) {
        var obj = {};
        obj["typ"] = key;
        obj["data"] = data[key];
        displayArray.push(obj);
      }
    }

    return displayArray;
  }

  mapHead(data) {
    let displayString = "";
    for (const key in data) {
      if (data[key].length !== 0) {
        var keyString = key + " (" + data[key].length + ")" + ", ";
        displayString = displayString + keyString;
      }
    }

    displayString = displayString.slice(0, -2);

    return displayString;
  }

  handleClickFilterList(id, showCard) {
    if (!showCard) {
      this.setState((prevState) => ({
        openAccordions: [...prevState.openAccordions, id],
      }));
    } else {
      this.setState((prevState) => ({
        openAccordions: prevState.openAccordions.filter((item) => item !== id),
      }));
    }
  }

  prepareCard(data) {
    var showCard = this.state.openAccordions.includes(data.encounter.Id);

    var card = (
      <div className="patient-card-body">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <div>
            <Divider />
            <ListItemButton
              onClick={() =>
                this.handleClickFilterList(data.encounter.Id, showCard)
              }
            >
              <ListItemText
                primary={" Events in Encounter " + this.mapHead(data.events)}
              />
              {showCard ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Divider />
            <Collapse
              in={showCard}
              timeout="auto"
              unmountOnExit
              sx={{ paddingLeft: "7px" }}
            >
              <table className="mt-4 mb-4 border-separate border-spacing-y-4">
                {this.mapHistory(data.events).map((events) => (
                  <tr>
                    <th className="align-top">{events.typ.toUpperCase()}</th>
                    <td className="align-top">
                      <ul className="list-disc ml-7 text-left">
                        {this.mapDataView(events.data).map((data) => (
                          <li>{data.DESCRIPTION}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </table>
            </Collapse>

            {showCard && <Divider />}
          </div>
        </List>
        <div className="flex justify-end w-full">
          <button
            className="detail-btn"
            onClick={() => this.onClick(data.encounter.Id)}
          >
            Details &#xbb;
          </button>
        </div>
      </div>
    );

    return card;
  }

  componentDidMount() {
    this.props.scrollToTopFast();
    this.props.whichLocationWhenMounted();
  }

  render() {
    return (
      <Timeline position="alternate">
        {this.props.matchEncounter(this.props.data).map((data) => (
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              {this.formatDate(data.encounter.START)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className="patient-card">
                <div className="patient-card-head">
                  <h4>{data.encounter.DESCRIPTION}</h4>
                  <h6>{data.encounter.CODE}</h6>
                </div>
                {this.prepareCard(data)}
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }
}
