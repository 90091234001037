// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-card {
  background-color: white;
  border: 1px solid rgba(100, 100, 100, 0.301);
  border-radius: 5px;
  font-family: Spline Sans;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.patient-card-body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.detail-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: 5px;
  width: 65px;
}

.detail-btn:hover {
  color: #fdc4de;
}

.icon-timeline {
  margin-right: 2px;
}

.icon-timeline:last-child {
  margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Dashboard/Timeline.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4CAA4C;EAC5C,kBAAkB;EAClB,wBAAwB;EACxB,aAAa;EACb,0CAA0C;AAC5C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".patient-card {\n  background-color: white;\n  border: 1px solid rgba(100, 100, 100, 0.301);\n  border-radius: 5px;\n  font-family: Spline Sans;\n  padding: 10px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.patient-card-body {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.detail-btn {\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n  margin-top: 5px;\n  width: 65px;\n}\n\n.detail-btn:hover {\n  color: #fdc4de;\n}\n\n.icon-timeline {\n  margin-right: 2px;\n}\n\n.icon-timeline:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
