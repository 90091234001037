import React, { Component } from "react";

import {
  Chart as ChartJs,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

ChartJs.register(
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale
);

export default class LineCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: [],
      datasets: [],
      yAxis: [],
      dataProcessed: false,
    };
  }

  getAllObservationsOfOneTyp(observationTyp, numObservations) {
    let allObservationsOfOneTyp = [];

    for (var i = 0; i < numObservations.length; i++) {
      if (numObservations[i].CODE !== undefined) {
        if (numObservations[i].CODE === observationTyp) {
          allObservationsOfOneTyp.push(numObservations[i]);
        }
      }
    }

    //sort by date
    allObservationsOfOneTyp.sort(function (a, b) {
      return new Date(a.DATE) - new Date(b.DATE);
    });

    return allObservationsOfOneTyp;
  }

  getAllKindsOfObservations(observations) {
    let observationCoded = [];
    let observationArray = [];

    for (let i = 0; i < observations.length; i++) {
      if (!observationCoded.includes(observations[i].CODE)) {
        observationCoded.push(observations[i].CODE);
        observationArray.push(observations[i]);
      }
    }

    return observationArray.sort(function (a, b) {
      if (a.DESCRIPTION > b.DESCRIPTION) {
        return +1;
      }
      if (a.DESCRIPTION < b.DESCRIPTION) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  prepareChart(data, dataTyp, chartTyp, openObservation) {
    var labelData = {
      datasets: [],
      observationName: "",
      observationUnit: "",
    };

    if (this.props.data.observations !== undefined) {
      if (this.props.data.observations.data.length !== 0) {
        let yAxis = [];
        const lineColors = [
          "rgb(10,169,222)",
          "rgb(249,227,12)",
          "rgb(217,70,140)",
          "rgb(246,220,228)",
          "rgb(0,114,184)",
          "rgb(210,210,235)",
          "rgb(232,92,34)",
          "rgb(119,66,73)",
        ];
        for (let index = 0; index < openObservation.length; index++) {
          if (
            yAxis.find((o) => o.UNITS === openObservation[index].UNITS) ===
            undefined
          ) {
            yAxis.push(openObservation[index].UNITS);
          }
          labelData.datasets.push({
            label: dataTyp,
            data: [],
            backgroundColor: lineColors[index],
            borderColor: lineColors[index],
            borderWidth: 1,
            yAxisID: openObservation[index].UNITS,
          });

          var observationsOfOneTyp = this.getAllObservationsOfOneTyp(
            openObservation[index].CODE,
            this.props.observationsNum
          );

          labelData.datasets[index]["label"] =
            openObservation[index].DESCRIPTION;
          for (let i = 0; i < observationsOfOneTyp.length; i++) {
            var itemData = {
              x: observationsOfOneTyp[i].DATE,
              y: observationsOfOneTyp[i].VALUE,
            };
            labelData.datasets[index].data.push(itemData);
          }
        }
        labelData["yAxis"] = yAxis;
      }
    }

    return labelData;
  }

  leftOrRight(i) {
    if (i % 2 === 0) {
      return "left";
    } else {
      return "right";
    }
  }

  emptyLineChart() {
    const data = {
      labels: [], // Keine Labels, da keine Daten vorhanden sind
      datasets: [
        {
          label: "Meine Daten",
          data: [], // Leeres Daten-Array
          borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(75,192,192,0.2)",
        },
      ],
    };
    const options = {
      plugins: { legend: this.props.showLegend },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "year",
          },
          title: {
            display: true,
            text: "Date",
          },
        },
      },
      options: {
        title: {
          display: this.props.title !== undefined ? true : false, // Zeigt den Titel an
          text: this.props.title, // Der Titeltext
          fontSize: 16, // Optional: Schriftgröße des Titels
          fontColor: "#333", // Optional: Farbe des Titels
        },
      },
    };
    return (
      <Line
        data={data}
        options={options}
        style={{ width: "100%", height: "100%" }}
      />
    );
  }

  componentDidMount() {
    var data = this.prepareChart(
      this.props.data,
      this.state.dataTyp,
      this.state.chartTyp,
      this.props.openObservation
    );

    this.setState(
      {
        datasets: data.datasets,
        yAxis: data.yAxis,
      },
      () => this.setState({ dataProcessed: true })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.openObservation !== this.props.openObservation) {
      this.setState({ dataProcessed: false });
      var data = this.prepareChart(
        this.props.data,
        this.state.dataTyp,
        this.state.chartTyp,
        this.props.openObservation
      );

      this.setState(
        {
          datasets: data.datasets,
          yAxis: data.yAxis !== undefined ? data.yAxis : [],
        },
        () => this.setState({ dataProcessed: true })
      );
    }

    if (prevProps.observationsNum !== this.props.observationsNum) {
      var data = this.prepareChart(
        this.props.data,
        this.state.dataTyp,
        this.state.chartTyp,
        this.props.openObservation
      );

      this.setState(
        {
          datasets: data.datasets,
          yAxis: data.yAxis !== undefined ? data.yAxis : [],
        },
        () => this.setState({ dataProcessed: true })
      );
    }
  }

  render() {
    if (
      this.state.dataProcessed &&
      this.props.data.observations !== undefined
    ) {
      const data = {
        datasets: this.state.datasets,
      };

      if (this.props.data.observations.data.length !== 0) {
        const options = {
          responsive: true, // Chart wird responsive
          maintainAspectRatio: false, // Deaktiviert das Standard-Seitenverhältnis
          plugins: {
            legend: this.props.showLegend,
            title: {
              display: this.props.title !== undefined ? true : false, // Zeigt den Titel an
              text: this.props.title, // Der Titeltext
              font: {
                size: 19, // Optional: Schriftgröße des Titels
              },
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "year",
              },
              title: {
                display: true,
                text: "Date",
              },
            },
          },
        };

        for (let i = 0; i < this.state.yAxis.length; i++) {
          options.scales[this.state.yAxis[i]] = {
            display: true,
            title: {
              display: true,
              text: this.state.yAxis[i],
            },
            position: this.leftOrRight(i),
          };
        }

        return (
          <Line
            data={data}
            options={options}
            style={{ width: "100%", height: "100%", maxWidth: "100%" }}
          />
        );
      } else {
        return this.emptyLineChart();
      }
    } else {
      return this.emptyLineChart();
    }
  }
}
