// db.js
import { count } from "d3";
import Dexie from "dexie";

class db {
  constructor(dbName, objectStoreName) {
    // Erstelle eine neue Dexie-Datenbank mit dem übergebenen Namen
    this.db = new Dexie(dbName);
    this.objectStoreName = objectStoreName;
    this.dbName = dbName;
  }

  // Private Methode zur Initialisierung der Datenbank
  async open() {
    try {
      await this.db.open();
    } catch (error) {
      // Fehler weitergeben
      throw new Error(`Fehler beim Öffnen der Datenbank: ${error.message}`);
    }
  }

  // Methode zum Abrufen aller Einträge aus der Tabelle
  async getAllEntries() {
    try {
      await this.db.open(); // Datenbank öffnen
      const allEntries = await this.db.table(this.objectStoreName).toArray();
      return allEntries;
    } catch (error) {
      console.error("Fehler beim Abrufen aller Einträge:", error);
      throw error;
    }
  }

  // Methode zum Schließen der Datenbank
  async closeDatabase() {
    try {
      this.db.close();
      console.log("Datenbank geschlossen.");
    } catch (error) {
      console.error("Fehler beim Schließen der Datenbank:", error);
    }
  }

  async listTablesAndIndexes() {
    try {
      // Hole alle Tabellen
      await this.db.open();

      const tables = this.db.tables;
      const tablesInfo = [];

      tables.forEach((table) => {
        const indices = table.schema.indexes.map((index) => index.name);
        tablesInfo.push({
          name: table.name,
          indices: indices,
        });
      });

      return tablesInfo;
    } catch (error) {
      console.error("Fehler beim Auflisten der Tabellen und Indizes:", error);
    }
  }

  async countEntriesInTables(tablesToCount) {
    try {
      await this.db.open();
      // Hole alle Tabellen
      const tables = this.db.tables;
      const counts = {};
      for (let table of tables) {
        if (tablesToCount.includes(table.name)) {
          const count = await this.db.table(table.name).count();
          counts[table.name] = count;
        } else {
          counts[table.name] = 0; // doesnt need to be counted but over this obj the dashboard figuersout which files are present -> all files need to be in this file
        }
      }

      return counts;
    } catch (error) {
      console.error("Fehler beim Zählen der Einträge in den Tabellen:", error);
      throw error;
    }
  }

  hasIndex(table, indexName) {
    const indexes = db.table(table).schema.indexes;
    return indexes.some((index) => index.name === indexName);
  }

  async getEntriesByIndex(tableName, indexName, value) {
    try {
      await this.db.open();
      const indexes = await this.db.table(tableName).schema.indexes;

      if (indexes.some((index) => index.name === indexName)) {
        const entries = await this.db
          .table(tableName)
          .where(indexName)
          .equals(value)
          .toArray();
        return entries;
      } else {
        console.log(
          "In object storage '" +
            tableName +
            "' no index with the name '" +
            indexName +
            "' found. getEntriesByIndex function stopped."
        );
        return [];
      }
    } catch (error) {
      console.error(
        `Fehler beim Abrufen der Einträge mit Index ${indexName} gleich ${value}:`,
        error
      );
      throw error;
    }
  }

  async addData(data) {
    try {
      await this.db.open();
      const ids = await this.db
        .table(this.objectStoreName)
        .bulkAdd(data[this.objectStoreName]);
      console.log(`Daten mit ID ${ids} hinzugefügt:`, data);
      return ids;
    } catch (error) {
      console.error("Fehler beim Hinzufügen von Daten:", error);
    }
  }

  async deleteEntryById(primaryKey) {
    try {
      await this.db.open();
      await this.db.table(this.objectStoreName).delete(primaryKey);
      console.log("dataset with primary key ", primaryKey, "was deleted.");
    } catch (error) {
      console.error("Fehler beim Löschen des Eintrags:", error);
    }
  }

  async deleteDatabase() {
    try {
      this.db.delete();
      console.log(this.dbName + " Database deleted.");
    } catch (error) {
      console.error("Fehler beim Löschen der Datenbank:", error);
    }
  }

  async singleArgPatOverview(filter) {
    try {
      await this.db.open(); // Öffnet die Datenbank
      const results = await this.db
        .table(this.objectStoreName)
        .where(filter.argumentsArray[0].data.column)
        [filter.argumentsArray[0].data.operator](
          filter.argumentsArray[0].data.value
        )
        .toArray();

      return results;
    } catch (error) {
      console.error("Fehler bei der Abfrage:", error);
    }
  }

  async allANDOverview(filter) {
    try {
      const createFunction = new Function("item", `return ${filter.string};`);
      await this.db.open(); // Öffnet die Datenbank
      const results = await this.db
        .table(this.objectStoreName)
        .where(filter.obj.argumentsArray[0].data.column)
        [filter.obj.argumentsArray[0].data.operator](
          filter.obj.argumentsArray[0].data.value
        )
        .filter((item) => createFunction(item))
        .toArray();

      return results;
    } catch (error) {
      console.error("Fehler bei der Abfrage:", error);
    }
  }

  async simpleOrOverview(filter) {
    try {
      await this.db.open(); // Öffnet die Datenbank
      let query = this.db.table(this.objectStoreName);

      filter.forEach((item, index) => {
        const { column, operator, value } = item.obj.data;

        // Initiale Abfrage oder .or() Abfrage hinzufügen
        if (index === 0) {
          query = query.where(column)[operator](value);
        } else {
          query = query.or(column)[operator](value);
        }

        // Filter-Funktion hinzufügen, wenn strg nicht leer ist
        if (item.string !== "") {
          const filterFunction = new Function("item", `return ${item.string};`);
          query = query.filter(filterFunction);
        }
      });

      // Abfrage ausführen und Ergebnisse zurückgeben
      const results = await query.toArray();
      return results;
    } catch (error) {
      console.error("Fehler beim Abrufen der Einträge:", error);
      throw error;
    }
  }

  async getEntryByArray(index, array) {
    try {
      await this.db.open();
      const results = await this.db
        .table(this.objectStoreName)
        .where(index)
        .anyOf(array)
        .toArray();

      return results;
    } catch (error) {
      console.error("Fehler beim Abrufen der Einträge:", error);
      throw error;
    }
  }

  async countReasonCodes(filesWithR) {
    try {
      await this.db.open();
      let resultsALL = [];

      for (const file of filesWithR) {
        const results = await this.db
          .table(file)
          .where("REASONCODE")
          .notEqual("null")
          .toArray();

        resultsALL = resultsALL.concat(results);
      }
      // Erstelle ein Objekt, um die Häufigkeit der einzelnen REASONCODES zu zählen
      const reasonCounts = {};
      resultsALL.forEach((item) => {
        const reasonCode = item.REASONCODE;
        const reasonDescription = item.REASONDESCRIPTION;

        if (!reasonCounts[reasonCode]) {
          reasonCounts[reasonCode] = {
            label: reasonDescription + " - " + reasonCode,
            description: reasonDescription,
            code: reasonCode,
            count: 0,
            typ: "reasoncode",
          };
        }
        reasonCounts[reasonCode].count++;
      });

      // Konvertiere das reasonCounts Objekt in ein Array
      const reasonArray = Object.values(reasonCounts);

      reasonArray.sort((a, b) => b.count - a.count);

      return reasonArray;
    } catch (error) {
      console.error("Error fetching entries:", error);
      return [];
    }
  }
}

export default db;

/**
 * 
 
 */
