import React, { Component } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Collapse,
  TextField,
} from "@mui/material";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./FilterSaver.css";

const selectRef = React.createRef();

export default class FilterSaver extends Component {
  // Component that saves filter profiles in the browsers local storage
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      valueSelect: "",
      openFilter: "",
      editing: false,
      isCollapsedI: true,
      isCollapsedInput: true,
      open: false, // Zustand für das Dropdown-Menü
    };

    this.selectRef = React.createRef();
  }

  handleToggleCollapseI = () => {
    this.setState((prevState) => ({
      isCollapsedI: !prevState.isCollapsedI,
    }));
  };

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        isCollapsedInput: !prevState.isCollapsedInput,
      }),
      () => {
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  onBlur = () => {
    this.setState({ open: false, isCollapsedInput: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, isCollapsedInput: true });
    document.getElementById("select-filter-saver").blur();
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  submit = () => {
    if (this.state.value !== "") {
      this.save(this.state.value, this.props.filterData);
      const newValue = this.state.value;
      this.setState({ value: "", valueSelect: newValue });
    } else {
      //show snackbar
      this.props.newSnackbar(
        "error",
        "Error: Please enter a name for your filter profile."
      );
    }
  };

  delete = () => {
    if (this.state.valueSelect !== "") {
      var nameFilterDelete = this.state.openFilter;
      const savedValues = JSON.parse(localStorage.getItem(this.props.typ));
      const index = savedValues.findIndex(
        (item) => item.name === this.state.openFilter
      );

      savedValues.splice(index, 1);
      const dataArrayString = JSON.stringify(savedValues);
      try {
        localStorage.setItem(this.props.typ, dataArrayString);
      } catch (error) {
        if (error.name === "QuotaExceededError") {
          console.error(
            "Fehler: Der verfügbare Speicherplatz des Local Storage ist überschritten."
          );
          //show snackbar
          this.props.newSnackbar(
            "error",
            "Error: The available storage space of the Local Storage has been exceeded."
          );
        } else {
          console.error(
            "Fehler beim Schreiben in den Local Storage:",
            error.message
          );
        }
      }

      this.setState({ openFilter: savedValues[0], valueSelect: "" });

      if (savedValues[0] !== undefined) {
        this.props.changeFilterFunction(savedValues[0]);
      } else {
        var filterObj = { name: "" };
        var standardFilterData = {
          includeOArray: [],
          includeAllDataOfEncounter: false,
          excludeArray: [],
          sliderValue: "",
          sortBy: "oldFirst",
        };

        filterObj["data"] = standardFilterData;
        this.props.changeFilterFunction(filterObj);
      }

      //show snackbar
      this.props.showDeleteSnackbar(
        "'" + nameFilterDelete + "' filter profile has been deleted."
      );
    }
  };

  save(name, data) {
    const savedValues = localStorage.getItem(this.props.typ);
    let dataArray = [];
    var nameAlreadyUsed = false;

    if (savedValues !== null) {
      dataArray = JSON.parse(savedValues);
      const doubleName = dataArray.find((o) => o.name === name);
      if (doubleName !== undefined) {
        nameAlreadyUsed = true;
      }
    }

    if (!nameAlreadyUsed) {
      var filterProfile = { name: name, data: data };
      dataArray.push(filterProfile);
      const dataArrayString = JSON.stringify(dataArray);

      try {
        localStorage.setItem(this.props.typ, dataArrayString);
      } catch (error) {
        if (error.name === "QuotaExceededError") {
          console.error(
            "Fehler: Der verfügbare Speicherplatz des Local Storage ist überschritten."
          );
          //show snackbar
          this.props.newSnackbar(
            "error",
            "Error: The available storage space of the Local Storage has been exceeded."
          );
        } else {
          console.error(
            "Fehler beim Schreiben in den Local Storage:",
            error.message
          );
        }
      }

      //show snackbar
      this.props.newSnackbar(
        "success",
        "'" + name + "' filter profile has been saved."
      );

      this.setState({ value: "" });
    } else {
      //show snackbar
      this.props.newSnackbar(
        "error",
        "Error: " +
          "'" +
          name +
          "' is already used for another profile. Please choose a other name for your filter profile."
      );
    }
  }

  handelSelect = (event, options) => {
    this.setState({ valueSelect: event.target.value });
    if (event.target.value !== "") {
      const selectedOption = options.find(
        (item) => item.name === event.target.value
      );
      this.props.changeFilterFunction(selectedOption);
      this.setState({ openFilter: selectedOption.name });
    } else {
      var filterObj = { name: "" };
      var standardFilterData = {
        includeOArray: [],
        includeAllDataOfEncounter: false,
        excludeArray: [],
        sliderValue: "",
        sortBy: "oldFirst",
      };

      filterObj["data"] = standardFilterData;
      this.props.changeFilterFunction(filterObj);
    }
  };

  changeOptionValue = (newValue) => {
    if (selectRef.current) {
      selectRef.current.value = newValue;
    }
  };

  mapFilterOptions() {
    const savedValues = JSON.parse(localStorage.getItem(this.props.typ));
    if (savedValues !== null) {
      return savedValues;
    } else {
      return [];
    }
  }

  display() {
    const savedValues = JSON.parse(localStorage.getItem(this.props.typ));
    if (savedValues.length !== 0) {
      return (
        <div>
          <h4>Filter Templates:</h4>
          <div className="filter-select-div">
            {savedValues.map((value) => (
              <div className="filter-select-option">{value.name}</div>
            ))}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  componentDidMount() {
    if (this.props.prevProfileName !== undefined) {
      this.setState({ valueSelect: this.props.prevProfileName });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.prevProfileName !== this.props.prevProfileName &&
      this.props.prevProfileName !== undefined
    ) {
      this.setState({ valueSelect: this.props.prevProfileName });
    }

    if (this.props.changedFromFilterProfile) {
      this.changeOptionValue("");
      this.setState({ valueSelect: "", value: "" });
      this.props.changeFromFilterProfile();
    }

    if (
      prevState.isCollapsedInput !== this.state.isCollapsedInput &&
      !this.state.isCollapsedInput
    ) {
      document.getElementById("select-filter-saver").focus();
    }

    if (
      prevState.isCollapsedInput !== this.state.isCollapsedI &&
      !this.state.isCollapsedI
    ) {
      document.getElementById("text-field-profile-name").focus();
    }
  }

  render() {
    var filterProfiles = this.mapFilterOptions();
    return (
      <div className="filter-saver-container">
        <Collapse orientation="horizontal" in={this.state.isCollapsedI}>
          <div className="profile-select">
            <Collapse orientation="horizontal" in={this.state.isCollapsedInput}>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                <p style={{ whiteSpace: "nowrap" }}>
                  {this.state.valueSelect === ""
                    ? "No profile"
                    : this.state.valueSelect}
                </p>
                <IconButton
                  aria-label="openInput"
                  onClick={this.handleToggleCollapseInput}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </Collapse>
            <Collapse
              orientation="horizontal"
              in={!this.state.isCollapsedInput}
            >
              <FormControl fullWidth>
                <Select
                  id="select-filter-saver"
                  value={this.state.valueSelect}
                  open={this.state.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                  onClose={this.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                  onOpen={this.handleOpen}
                  onChange={(event) =>
                    this.handelSelect(event, this.mapFilterOptions())
                  }
                  onBlur={this.onBlur}
                  autoFocus
                  sx={{
                    marginTop: "4px",
                    height: "35px", // Ändere die Mindesthöhe nach Bedarf
                    fontSize: "13px",
                    width: "6.8vw",
                  }}
                >
                  <MenuItem
                    value={""}
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    <em>None</em>
                  </MenuItem>
                  {filterProfiles.map((item) => (
                    <MenuItem
                      value={item.name}
                      data-item={item.data}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
          </div>
        </Collapse>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.state.isCollapsedI && (
            <IconButton
              onClick={() => this.delete()}
              aria-label="delete"
              size="small"
              sx={{ height: "25px", width: "25px" }}
            >
              <DeleteForeverIcon size="small" />
            </IconButton>
          )}
          {this.state.isCollapsedI ? (
            <IconButton
              aria-label="safe"
              onClick={this.handleToggleCollapseI}
              sx={{ height: "25px", width: "25px" }}
            >
              <SaveAltIcon size="small" />
            </IconButton>
          ) : (
            <></>
          )}
          <Collapse orientation="horizontal" in={!this.state.isCollapsedI}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl fullWidth>
                <TextField
                  id="text-field-profile-name"
                  variant="standard"
                  placeholder="Profile-Name"
                  onChange={this.handleChange}
                  onBlur={this.handleToggleCollapseI}
                  sx={{
                    maxWidth: "8vw",
                    marginRight: "5px",
                    ".MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                  size="small"
                />
              </FormControl>
              <IconButton
                aria-label="safe"
                onClick={this.submit}
                sx={{
                  height: "25px",
                  width: "25px",
                  color: "var(--primary-color)",
                }}
              >
                <SaveIcon />
              </IconButton>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
