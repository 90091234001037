import React from "react";

import Papa from "papaparse";
import axios from "axios";

import StartPage from "./StartPage";
import SyntheaView from "./SyntheaView";
import PopulationAnalysis from "./PopulationAnalysis/PopulationAnalysis";
import Overview from "./PatientRecords/Overview";
import PatientView from "./PatientRecords/PatientView";
import Datasets from "./Datasets/Datasets";
import DatasetDetails from "./Datasets/DatasetDetails";
import PatientHistory from "./PatientRecords/PatientHistory";
import PatientTimeline from "./PatientRecords/PatientTimeline";
import Charts from "./PatientRecords/Charts/Charts";
import PatientDataview from "./PatientRecords/PatientDataview";
import OverviewDatset from "./OverviewDataset/OverviewDatset";
import Dashboard from "./PatientRecords/Dashboard/Dashboard";
import Settings from "./Settings/Settings";
import PopAnaDashboard from "./PopulationAnalysis/Dashboard/PopAnaDashboard";
import SurvivalContainer from "./PopulationAnalysis/Survival/SurvivalContainer";
import NoDBselectedText from "./utils/NoDBselectedText";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@mui/material/SnackbarContent";

import { Button } from "@mui/material";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";

import Overlay from "./Overlay/Overlay";

import { Route, Routes, Outlet } from "react-router-dom";

import { withRouter } from "./utils/withRouter";

import db from "./utils/DexieDatabase/db";

import "./App.css";
import "./styles/tailwind.css"; // Importiere Tailwind CSS
import ModuleDataHandler from "./ModuleDiagram/ModuleDataHandler";
import DialogPopUp from "./DialogPopUp/DialogPopUp";
import Troubleshooting from "./Troubleshooting";

import Dexie from "dexie";
import SetPasswort from "./utils/SetPasswort";

const {
  checkWhereIsDate,
  addConditionsToPatientsDexie,
  addAgeToDataDexie,
} = require("./utils/dataPrep");

const theme = createTheme({
  typography: {
    fontFamily: "Geist Sans, sans-serif", // Setze die Schriftart für alle Komponenten
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
  },
  palette: {
    primary: {
      main: "#232ED1", // Hier kannst du die neue Primärfarbe festlegen
    },
    greyBtn: { main: "rgba(0, 0, 0, 0.54)" },
  },
});

const CustomSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: "#e0e0de", // Beispiel für Hintergrundfarbe
  color: "black", // Beispiel für Textfarbe
  fontSize: "14px", // Beispiel für Textgröße
  marginBottom: "-20px",
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingBottom: "3px",
  paddingTop: "3px",
  lineHeight: "1rem",
  "& .MuiSnackbarContent-message": {
    padding: 0, // Entfernt Padding des Message-Bereichs
    margin: 0, // Entfernt zusätzliches Margin, falls nötig
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 3.2,
      //loading datastates for sub components
      loadedData: { csvFiles: { data: [], loaded: false } },
      dataSource: "indexedDB",
      savedFilesDone: [],
      parsedSize: 0,
      addingDataStatus: 0,
      filesFullProcessed: {}, //{encounter: true/false, ...} shows if files was completly parsed and inserted into mongoDB/indexedDB
      savingLocation: "index", //index for indexedDb or mongo for mongoDB
      dexieDB: {}, //saves the dexie instance
      totalEntries: 0,

      //csv loading states
      chunkSizeParse: 500 * 1024, // Chunkgröße in Bytes
      maxActiveChunks: 10, // max amount of inserting chunks allowed to run at the same time
      activeChunks: 0, // current amount of open inserts
      waitingParsers: {}, // parser that are waiting in line
      csvNamesInfoNewDB: {},
      csvDataArray: [],
      newDBName: undefined,
      indexObj: {}, //safes first line of collection to create index for every key
      dataFromSaveId: "",
      openBackdrop: false,
      showPage: false,
      openPatient: {},
      patientInfoVisible: false,
      loadingProgressSimple: {
        //needs to be set true. When new datasets starts -> set to false
        processCSV: true,
        indexing: true,
        postProcessing: true,
      },
      fileProcessingProgress: {}, // safes the detailed progress of parsing and inserting each file
      fileParsing: {}, //safes progress of parsing -> is displayed as buffer
      indexingProgress: { createdIndices: 0, totalIndices: 0 },
      postProcessingProgress: {
        age: { title: "Calculate and add age to patients", done: false },
        conditions: { title: "Add conditions to patients", done: false },
      },

      //estimated simulation span
      simulationSpan: {
        start: "",
        end: "",
        loaded: false,
      },
      allDbs: { mongo: [], index: [] },

      //PatOverview component States
      patientList: [], //saves all patients for all other other components then the overview
      overviewData: [], //saves the patient list with all filtered patients that are displayed in the overview
      allPatOverviewData: [], //saves all filtered patients so you can perform searches with the filtered list
      overviewDataLoaded: false,
      numbPatients: 0,
      fileList: [],
      fileListLoaded: false,
      boringDiagnoses: [
        160968000, 224299000, 741062008, 73595000, 706893006, 160903007,
        160904001, 422650009, 224295006, 424393004, 423315002, 80583007,
        5251000175109, 266934004, 713458007, 73438004, 266948004, 361055000,
        105531004, 446654005,
      ],
      filterOverview: {},
      filteredIdsO: {},
      filterLoading: false,
      searchValue: "",
      loadingMessagePatOverview: "",
      loadingTimePatOverviewStart: null,
      loadingTimePatOverviewDuration: 0,
      overviewRequestId: 0,

      //PatientView component states
      patientViewData: {}, //contains all data of pat
      allPatDatLoaded: {}, // contains the information if all data for pat is loaded
      displayedData: {}, // contains all data of pat that is supposed to be displayed -> filtered
      displayedDataProcessed: false,
      clickedEncounter: "",
      clickedDataViewEncounter: "",
      lastOpenView: "", //important for the potion of the custom scrollbar in navigation
      patViewFilter: {}, //safes the last filter in pat view so when component gets reopened the filter loads
      collapsesSave: { nav: false, info: false }, //safes if nav and pat info are collapsed or not -> so stays the same when reopen
      emptyPat: {},
      loadingMessagePatView: "",
      loadingTimePatientStart: null,
      loadingTimePatientDuration: 0,

      //datasets component states
      detailedDbName: "",
      detailedDBLocation: "index",
      loadingProgress: 100,

      //OverviewDataset states (dashboard)
      dashboardInfo: { data: {}, loaded: false },

      //open dataset states
      openDB: "",
      openDbSavingLocation: "index",
      dataStructureVersion: 0,
      updateDataStructure: false,
      noDbSelected: false,

      //settings states
      settingsOpen: false,
      sHeaderDisplay: "standard", //"dense" or "standard"

      //snackbar states
      snackbarMessage: { variant: "", text: "" },
      showTimeSnackbar: false,

      //dialog states
      showDialogStates: {
        dashboard: { show: true, title: "Dashboard" },
        patientRecordOverview: { show: true, title: "Patient Records" },
        patientRecordPatView: {
          show: true,
          title: "Patient Records - Patient View",
        },
        moduleViewer: { show: true, title: "Module Viewer" },
        datasetManager: { show: true, title: "Dataset Manager" },
      },
      showDialogStatesKey: "",
      showPopUp: false,
      popUpTitle: "",

      //population analyses states
      popAnaDashboardData: {
        conditions: { data: [], labels: [], processed: false },
        procedures: { data: [], labels: [], processed: false },
        encounters: { data: [], labels: [], processed: false },
        observations: { data: [], labels: [], processed: false },
      },
      allReasonCodes: { data: [], loaded: false },

      //survival (pop ana) states
      startPointData: [],
      startPointDataLoaded: false,

      //mongodb states
      connectionToMongoDB: { trying: true, connected: false },
      mongoDatabases: { data: [], loaded: false },
    };

    this.containerRefStartParent = React.createRef();
    this.containerRefStart = React.createRef();

    this.wsUploadMongo = null; //gives back the progress of loading data in mongo db

    this.nextPatient = this.nextPatient.bind(this);
    this.prevPatient = this.prevPatient.bind(this);
    this.openCloseBD = this.openCloseBD.bind(this);
    this.setPatientInfoVisible = this.setPatientInfoVisible.bind(this);
    this.getFilterFormOverview = this.getFilterFormOverview.bind(this);
    this.setDetailedDbName = this.setDetailedDbName.bind(this);
    this.openNewDb = this.openNewDb.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.onClickEncounter = this.onClickEncounter.bind(this);
    this.getAllEntries = this.getAllEntries.bind(this);
    this.parseCSVData = this.parseCSVData.bind(this);
    this.streamPapa = this.streamPapa.bind(this);
  }

  startTiming = (key) => {
    this.setState({ [key]: new Date().getTime() });
  };

  stopTiming = (key, stateKey) => {
    const endTime = new Date().getTime();
    const startTime = this.state[key];
    if (startTime) {
      const duration = endTime - startTime;
      this.setState({
        [stateKey]: duration,
        [key]: null,
      });
    }
  };

  matchEncounter(data) {
    var matchedEncounters = [];
    var copyData = { ...data };

    if (data.encounters !== undefined) {
      for (let i = 0; i < data.encounters.data.length; i++) {
        var encounterPlus = { encounter: data.encounters.data[i] };
        var plus = {};

        for (const key in copyData) {
          if (key !== "encounters" && key !== "patient") {
            var eventsArray = [];
            for (
              let index = copyData[key].data.length - 1;
              index >= 0;
              index--
            ) {
              if (
                data.encounters.data[i].Id ===
                copyData[key].data[index].ENCOUNTER
              ) {
                eventsArray.push(copyData[key].data[index]); //<------------------------------------------------------------------mit Johannes angucken
                //copyData[key].data.splice(index, 1);
              }
            }
            plus[key] = eventsArray;
          }
        }
        encounterPlus["events"] = plus;
        matchedEncounters.push(encounterPlus);
      }
    }

    const sortedEncounters = matchedEncounters.sort((a, b) => {
      const dateA = new Date(a.encounter.START);
      const dateB = new Date(b.encounter.START);
      return dateA - dateB;
    });

    return sortedEncounters;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Population analyses functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  getPopAnDashboardData(dbName, savingLocation) {
    const objStorages = [
      "conditions",
      "procedures",
      "encounters",
      "observations",
    ];

    for (let i = 0; i < objStorages.length; i++) {
      if (savingLocation === "index") {
        this.getPopAnDashboardDataDexie(dbName, objStorages[i]);
      } else if (savingLocation === "mongo") {
        this.getPopAnDashboardDataMongo(dbName, objStorages[i]);
      }
    }
  }

  getPopAnDashboardDataDexie(dbName, objStorage) {
    this.getFrequenciesOfIndex(dbName, objStorage, "DESCRIPTION")
      .then((frequencies) => {
        let labels = [];
        let data = [];

        for (let i = 0; i < frequencies.length; i++) {
          labels.push(this.removeSubstrings(frequencies[i][0]));
          data.push(frequencies[i][1]);
        }

        this.setState((prevState) => ({
          popAnaDashboardData: {
            ...prevState.popAnaDashboardData,
            [objStorage]: {
              data: data,
              labels: labels,
              processed: true,
            },
          },
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  removeSubstrings(input) {
    // Definiere die Substrings, die entfernt werden sollen
    if (input !== null) {
      const substringsToRemove = ["(finding)", "(procedure)", "(situation)"];

      // Iteriere über jedes Substring und ersetze es im Input-String
      substringsToRemove.forEach((substring) => {
        input = input.replace(substring, "");
      });

      // Entferne eventuelle führende oder nachgestellte Leerzeichen
      return input.trim();
    } else {
      return input;
    }
  }

  getFrequenciesOfIndex(databaseName, storeName, indexName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(databaseName);

      request.onerror = (event) => {
        reject(`Error opening database: ${event.target.errorCode}`);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        if (db.objectStoreNames.contains(storeName)) {
          const transaction = db.transaction(storeName, "readonly");
          const store = transaction.objectStore(storeName);
          const index = store.index(indexName);

          const frequencies = {};
          const cursorRequest = index.openCursor();

          cursorRequest.onerror = (event) => {
            reject(`Error opening cursor: ${event.target.errorCode}`);
          };

          cursorRequest.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              const value = cursor.value[indexName];
              if (frequencies[value]) {
                frequencies[value]++;
              } else {
                frequencies[value] = 1;
              }
              cursor.continue();
            } else {
              // Frequencies are collected, now sort them
              const sortedFrequencies = Object.entries(frequencies).sort(
                (a, b) => b[1] - a[1]
              );
              resolve(sortedFrequencies);
            }
          };
        }
      };

      request.onupgradeneeded = (event) => {
        // Handle database upgrade if necessary
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          const objectStore = db.createObjectStore(storeName, {
            keyPath: "id",
          });
          objectStore.createIndex(indexName, indexName, { unique: false });
        }
      };
    });
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Dialog functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  dontShowAgain = () => {
    if (this.state.showDialogStates !== "") {
      this.setState(
        (prevState) => ({
          showDialogStates: {
            ...prevState.showDialogStates,
            [this.state.showDialogStatesKey]: {
              ...prevState.showDialogStates[this.state.showDialogStatesKey],
              show: false,
            },
          },
        }),
        () => {
          this.saveDialog(this.state.showDialogStates);
        }
      );
    }
  };

  saveDialog = (dialogObj) => {
    const stringObj = JSON.stringify(dialogObj);
    localStorage.setItem("dialog", stringObj);
  };

  loadDialog = () => {
    var jsonString = localStorage.getItem("dialog");
    var json;

    if (jsonString === null) {
      json = {
        dashboard: { show: true, title: "Dashboard" },
        patientRecordOverview: { show: true, title: "Patient Records" },
        patientRecordPatView: {
          show: true,
          title: "Patient Records - Patient View",
        },
        moduleViewer: { show: true, title: "Module Viewer" },
        datasetManager: { show: true, title: "Dataset Manager" },
      };
    } else {
      try {
        json = JSON.parse(jsonString);
      } catch (error) {
        console.error("Fehler beim Parsen des JSON-Strings:", error);
        json = {
          dashboard: { show: true, title: "Dashboard" },
          patientRecordOverview: { show: true, title: "Patient Records" },
          patientRecordPatView: {
            show: true,
            title: "Patient Records - Patient View",
          },
          moduleViewer: { show: true, title: "Module Viewer" },
          datasetManager: { show: true, title: "Dataset Manager" },
        };
      }
    }

    return json;
  };

  resetDialog = () => {
    const showDialogStates = {
      dashboard: { show: true, title: "Dashboard" },
      patientRecordOverview: { show: true, title: "Patient Records" },
      patientRecordPatView: {
        show: true,
        title: "Patient Records - Patient View",
      },
      moduleViewer: { show: true, title: "Module Viewer" },
      datasetManager: { show: true, title: "Dataset Manager" },
    };

    this.setState({ showDialogStates: showDialogStates });
    this.saveDialog(showDialogStates);
  };

  closeDialog = () => {
    this.setState({ showPopUp: false });
  };

  showPopUp(location) {
    var showDialogStatesKey = "";
    if (location === "/syn-viewer/overview-dataset") {
      showDialogStatesKey = "dashboard";
    } else if (location === "/syn-viewer/patient-records/overview") {
      showDialogStatesKey = "patientRecordOverview";
    } else if (
      location ===
        "/syn-viewer/patient-records/patient-view/patient-dashboard" ||
      location ===
        "/syn-viewer/patient-records/patient-view/patient-dataview" ||
      location === "/syn-viewer/patient-records/patient-view/patient-history" ||
      location ===
        "/syn-viewer/patient-records/patient-view/patient-timeline" ||
      location === "/syn-viewer/patient-records/patient-view/patient-charts"
    ) {
      showDialogStatesKey = "patientRecordPatView";
    } else if (location === "/syn-viewer/module-diagram") {
      showDialogStatesKey = "moduleViewer";
    } else if (location === "/syn-viewer/datasets/datasets-overview") {
      showDialogStatesKey = "datasetManager";
    }
    if (showDialogStatesKey !== "") {
      if (this.state.showDialogStates[showDialogStatesKey].show) {
        this.setState((prevState) => ({
          showDialogStates: {
            ...prevState.showDialogStates,
            [showDialogStatesKey]: {
              ...prevState.showDialogStates[showDialogStatesKey],
              show: false,
            },
          },
          showPopUp: true,
          popUpTitle:
            this.state.showDialogStates[showDialogStatesKey].title +
            " information",
          showDialogStatesKey: showDialogStatesKey,
        }));
      }
    }
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Snackbar functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  newSnackbar = (variant, text) => {
    this.setState({ snackbarMessage: { variant: variant, text: text } });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showTimeSnackbar: false });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Scroll functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  smoothScrollTo(element, to, duration) {
    const start = element.scrollTop;
    const difference = to - start;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);
      element.scrollTop = start + difference * progress;

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  }

  scrollToTop = () => {
    if (
      this.containerRefStart !== undefined &&
      this.containerRefStartParent !== undefined
    ) {
      const child = this.containerRefStart.current;
      const parent = this.containerRefStartParent.current;
      if (parent !== null) {
        const parentRect = parent.getBoundingClientRect();
        const parentViewableArea = {
          height: parent.clientHeight,
          width: parent.clientWidth,
        };

        const childRect = child.getBoundingClientRect();
        const isViewable =
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.top + parentViewableArea.height;

        if (!isViewable) {
          const scrollTop = childRect.top - parentRect.top;
          const scrollBot = childRect.bottom - parentRect.bottom;
          const scrollAmount =
            Math.abs(scrollTop) < Math.abs(scrollBot) ? scrollTop : scrollBot;

          this.smoothScrollTo(parent, parent.scrollTop + scrollAmount, 400); // Hier 500 ist die Dauer der Scrollbewegung in Millisekunden
        }
      }
    }
  };

  scrollToTopFast = () => {
    if (
      this.containerRefStart !== undefined &&
      this.containerRefStartParent !== undefined
    ) {
      const child = this.containerRefStart.current;
      const parent = this.containerRefStartParent.current;
      if (parent !== null) {
        // Where is the parent on page
        var parentRect = parent.getBoundingClientRect();
        // What can you see?
        var parentViewableArea = {
          height: parent.clientHeight,
          width: parent.clientWidth,
        };

        // Where is the child
        var childRect = child.getBoundingClientRect();
        // Is the child viewable?
        var isViewable =
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.top + parentViewableArea.height;

        // if you can't see the child try to scroll parent
        if (!isViewable) {
          // Should we scroll using top or bottom? Find the smaller ABS adjustment
          const scrollTop = childRect.top - parentRect.top;
          const scrollBot = childRect.bottom - parentRect.bottom;
          if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
            // we're near the top of the list
            parent.scrollTop += scrollTop;
          } else {
            // we're near the bottom of the list
            parent.scrollTop += scrollBot;
          }
        }
      }
    }
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Settings functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  openSettings = () => {
    this.setState((prevState) => ({
      settingsOpen: !prevState.settingsOpen,
    }));
  };

  changeSHeaderDisplay = (settings) => {
    this.setState({ sHeaderDisplay: settings });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------DATASETS functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  setDetailedDbName = (dbName, detailedDBLocation) => {
    this.setState({
      detailedDbName: dbName,
      detailedDBLocation: detailedDBLocation,
    });
  };

  openNewDb = (dbName, openDbSavingLocation) => {
    this.setState(
      {
        openDB: dbName,
        openDbSavingLocation: openDbSavingLocation,
      },
      () => {
        this.props.navigate("/syn-viewer/overview-dataset");
      }
    );
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------PATIENT VIEW functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  setOpenPatient = (patient) => {
    //sets open patient as state and saves the pat in local storage so when page is reloaded the patient can be opend again

    const localPatFile = patient.JSONFile;
    localPatFile["database"] = this.state.openDB;
    const stringPat = JSON.stringify(localPatFile);

    localStorage.setItem("lastOpenPatient", stringPat);

    var link = "/syn-viewer/patient-records/patient-view/patient-dashboard";

    if (this.state.lastOpenView !== "") {
      link = this.state.lastOpenView;
    }

    this.setState({ openPatient: patient.JSONFile }, this.props.navigate(link));
  };

  setPatientInfoVisible = (visible) => {
    this.setState({ patientInfoVisible: visible });
  };

  preparePatientHistory(openPatient1, fileList, dbName, savingLocation) {
    //gets all entries from all files that can be matched to the opened pat -> is needed to display patientView
    //start timing
    this.startTiming("loadingTimePatientStart");

    //set the allLoaded State to false and empty patView state
    this.setState(
      {
        allPatDatLoaded: this.emptyPatLoadingObject(fileList),
        displayedDataProcessed: false,
        patientViewData: this.state.emptyPat,
        displayedData: this.state.emptyPat,
        loadingMessagePatView:
          "Loading patient '" +
          openPatient1.LAST.replace(/\d+/g, "") +
          ", " +
          openPatient1.FIRST.replace(/\d+/g, "") +
          "'",
        loadingTimePatientDuration: 0,
      },
      () => {
        var openPatient = structuredClone(openPatient1);
        delete openPatient.CONDITIONS;

        for (const key in fileList) {
          if (key === "patient") {
            this.setState(
              (prevState) => ({
                patientViewData: {
                  ...prevState.patientViewData,
                  [key]: { typ: key, data: openPatient },
                },
              }),
              () => {
                this.setState((prevState) => ({
                  allPatDatLoaded: {
                    ...prevState.allPatDatLoaded,
                    [key]: true,
                  },
                }));
              }
            );
          } else {
            if (savingLocation === "index") {
              this.patHistoryDexie(dbName, key, openPatient.Id);
            } else if (savingLocation === "mongo") {
              this.patHistoryMongo(dbName, key, openPatient.Id);
            }
          }
        }
      }
    );
  }

  patHistoryDexie(dbName, file, patID) {
    const dbAll = new db(dbName);
    dbAll
      .getEntriesByIndex(file, "PATIENT", patID)
      .then((results) => {
        this.setState(
          (prevState) => ({
            patientViewData: {
              ...prevState.patientViewData,
              [file]: { typ: file, data: results },
            },
          }),
          () => {
            this.setState((prevState) => ({
              allPatDatLoaded: {
                ...prevState.allPatDatLoaded,
                [file]: true,
              },
            }));
          }
        );
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  }

  setDisplayDataProcessed = (trueOrFalse) => {
    this.setState((prevState) => ({
      displayedDataProcessed: trueOrFalse,
    }));
  };

  prevPatient = () => {
    this.setState({ showPage: false });
    var indexOfPatient = this.state.overviewData.findIndex(
      (o) => o.JSONFile.Id === this.state.openPatient.Id
    );

    var newIndex = 0;
    if (indexOfPatient > 0) {
      newIndex = indexOfPatient - 1;
    } else {
      return;
    }

    const newOpenPatient = this.state.overviewData[newIndex];
    this.setState({ openPatient: newOpenPatient.JSONFile }, () => {
      this.setState({ showPage: true });
    });
  };

  nextPatient = () => {
    this.setState({ showPage: false });
    var indexOfPatient = this.state.overviewData.findIndex(
      (o) => o.JSONFile.Id === this.state.openPatient.Id
    );

    var newIndex = 0;
    if (indexOfPatient < this.state.overviewData.length - 1) {
      newIndex = indexOfPatient + 1;
    } else {
      return;
    }

    const newOpenPatient = this.state.overviewData[newIndex];
    this.setState({ openPatient: newOpenPatient.JSONFile }, () => {
      this.setState({ showPage: true });
    });
  };

  getFilteredPatData = (filteredData) => {
    this.setState({ displayedData: filteredData }, () => {
      this.stopTiming("loadingTimePatientStart", "loadingTimePatientDuration");
      this.setState({
        displayedDataProcessed: true,
        loadingMessagePatView:
          "'" +
          this.state.openPatient.LAST.replace(/\d+/g, "") +
          ", " +
          this.state.openPatient.FIRST.replace(/\d+/g, "") +
          "' " +
          " loading time:",
      });
    });
  };

  onClickEncounter = (encounterId) => {
    this.setState(
      {
        lastOpenView:
          "/syn-viewer/patient-records/patient-view/patient-history",
        clickedEncounter: encounterId,
      },
      () => {
        this.props.navigate(
          "/syn-viewer/patient-records/patient-view/patient-history"
        );
      }
    );
  };

  resetClickedEncounter = () => {
    this.setState({ clickedEncounter: "" });
  };

  setDataViewEncounterNull = () => {
    this.setState({ clickedDataViewEncounter: "" });
  };

  whichLocationWhenMounted = () => {
    this.setState({ lastOpenView: this.props.location.pathname });
  };

  setPatViewFilter = (state) => {
    this.setState({ patViewFilter: state });
  };

  emptyPatLoadingObject = (fileList) => {
    var emptyObj = {};

    for (const key in fileList) {
      emptyObj[key] = false;
    }

    return emptyObj;
  };

  changePatViewCollapses = (collapses) => {
    this.setState({ collapsesSave: collapses });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Overview functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  prepareDataForOverview(displayedPatientList, conditions) {
    //adds to every patient object a key "CONDITIONS" that show all conditions of a patient, as long as they are not in boring diagnosis

    if (conditions !== undefined) {
      var copyConditions = [...conditions];

      for (let i = displayedPatientList.length - 1; i >= 0; i--) {
        var conditionsOfPatient = [];
        for (let j = copyConditions.length - 1; j >= 0; j--) {
          var isConditionBoring = this.state.boringDiagnoses.findIndex(
            (o) => o === copyConditions[j].CODE
          );
          if (isConditionBoring === -1) {
            if (displayedPatientList[i].Id === copyConditions[j].PATIENT) {
              conditionsOfPatient.push(copyConditions[j]);
              copyConditions.splice(j, 1); // Entferne das Element aus copyConditions
            }
          } else {
            copyConditions.splice(j, 1);
          }
        }

        displayedPatientList[i]["CONDITIONS"] = conditionsOfPatient;
      }
    }

    return displayedPatientList;
  }

  overviewSearchValue = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  searchObjectsByTerm(searchTerm, arrayOfObjects) {
    return arrayOfObjects.filter((obj) => {
      return Object.values(obj).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof value === "number") {
          return value.toString().includes(searchTerm);
        } else if (value instanceof Date) {
          const date = value; // Erstelle ein Date-Objekt (kann auch ein vorhandenes sein)

          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Monat von 0 bis 11, daher +1
          const day = String(date.getDate()).padStart(2, "0");

          const dateString = `${year}-${month}-${day}`;
          return dateString.includes(searchTerm);
        }
        return false;
      });
    });
  }

  returnConditionStr(conditions) {
    const noOfConditions = 4;
    let displayedConditionsArray = [];
    var displayedConditions = "";
    var noOfDisplayedConditions = 0;

    for (let i = 0; i < conditions.length; i++) {
      if (noOfDisplayedConditions <= noOfConditions) {
        var string1 = conditions[i].DESCRIPTION;
        var code = conditions[i].CODE;

        if (noOfDisplayedConditions === 0) {
          if (!this.state.boringDiagnoses.includes(code)) {
            displayedConditionsArray.push(string1.replace("(finding)", ""));
            noOfDisplayedConditions++;
          }
        } else {
          if (
            !displayedConditionsArray.includes(
              string1.replace("(finding)", "")
            ) &&
            !this.state.boringDiagnoses.includes(code)
          ) {
            displayedConditionsArray.push(string1.replace("(finding)", ""));

            noOfDisplayedConditions++;
          }
        }
      }
    }

    displayedConditions =
      displayedConditionsArray.join(", ") +
      "+ " +
      (conditions.length - displayedConditionsArray.length) +
      " more";
    if (noOfDisplayedConditions === 0) {
      if (conditions.length > 0) {
        return (
          conditions[0].DESCRIPTION.replace("(finding)", "") +
          "+ " +
          (conditions.length - 1) +
          " more"
        );
      } else {
        return "-";
      }
    } else {
      return displayedConditions;
    }
  }

  setPatOverViewState(patients) {
    //create format for the overview table, here important diagnoses are chosen
    var overviewArray = [];
    for (let i = 0; i < patients.length; i++) {
      var pat = {
        Id: patients[i].Id,
        Name: patients[i].LAST + ", " + patients[i].FIRST,
        Gender: patients[i].GENDER,
        Birthdate: patients[i].BIRTHDATE,
        Deathdate: patients[i].DEATHDATE,
        Age: patients[i].AGE,
        State: patients[i].STATE,
        ImportantDiagnoses: this.returnConditionStr(patients[i].CONDITIONS),
        JSONFile: patients[i],
      };
      overviewArray.push(pat);
    }

    //if its the first time that the data is loaded then save patient list
    if (this.state.patientList.length === 0) {
      this.setState({ patientList: overviewArray });
    }

    this.setState(
      {
        allPatOverviewData: overviewArray,
        numbPatients: patients.length,
        overviewData: overviewArray,
      },
      () => {
        this.stopTiming(
          "loadingTimePatOverviewStart",
          "loadingTimePatOverviewDuration"
        );
        this.setState({
          overviewDataLoaded: true,
          filterLoading: false,
          loadingMessagePatOverview: "database filter request time:",
        });
      }
    );
  }

  getOverviewDataFromDB(dbName, savingLocation) {
    //gathers all patient data needed to display initial overvie component and saves them in state
    this.startTiming("loadingTimePatOverviewStart");
    this.setState({
      loadingMessagePatOverview: "filtering data",
      loadingTimePatOverviewDuration: null,
    });
    if (savingLocation === "index") {
      this.getOverviewDataFromDBDexie(dbName);
    } else if (savingLocation === "mongo") {
      this.getOverviewDataFromDBMongo(dbName, "patients");
    }
  }

  getOverviewDataFromDBDexie(dbName) {
    const dbAll = new db(dbName, "patients");
    dbAll
      .getAllEntries()
      .then((patients) => {
        this.setPatOverViewState(patients);
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  }

  emptyPatientViewDataObject = (list) => {
    let emptyPatViewObj = {};

    for (let i = 0; i < list.length; i++) {
      let filename = list[i].name;
      let data = [];

      if (filename === "patients") {
        filename = "patient";
        data = {};
      }

      emptyPatViewObj[filename] = {
        typ: filename,
        data: data,
      };
    }

    return emptyPatViewObj;
  };

  getFileList(dbName, savingLocation) {
    //gets all needed data for overview component (expect the patient data) and saves them in state
    //also gets an empty patientView object with the saved files
    this.setState({ fileListLoaded: false }, () => {
      if (savingLocation === "index") {
        this.getFileListDexie(dbName);
      } else if (savingLocation === "mongo") {
        this.getFileListMongo(dbName);
      }
    });
  }

  getFileListDexie(dbName) {
    const dbAll = new db(dbName);
    dbAll
      .listTablesAndIndexes()
      .then((list) => {
        const emptyPatViewObj = this.emptyPatientViewDataObject(list);
        this.setState(
          {
            fileList: list,
            patientViewData: emptyPatViewObj,
            displayedData: emptyPatViewObj,
            emptyPat: emptyPatViewObj,
            allPatDatLoaded: this.emptyPatLoadingObject(list),
            displayedDataProcessed: false,
          },
          () => {
            //load all reasoncodes and thier frequency
            this.loadReasonCodes(this.state.openDB, list);
            this.setState({ fileListLoaded: true });
          }
        );
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  }

  loadReasonCodes(openDB, list) {
    this.setState({ allReasonCodes: { data: [], loaded: false } }, () => {
      let tablesWIthReason = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].indices.includes("REASONCODE")) {
          tablesWIthReason.push(list[i].name);
        }
      }
      const dbAll = new db(openDB);
      dbAll.countReasonCodes(tablesWIthReason).then((results) => {
        this.setState({ allReasonCodes: { data: results, loaded: true } });
      });
    });
  }

  /*----------------------------------- Overview filter functions (form locale storage)----------------------------------------------------------------------------------------------------------------------------- */

  getFilterFormOverview(newFilter) {
    // gets filter from overview and saves it in state
    if (!this.areFilterEqual(newFilter, this.state.filterOverview)) {
      this.setState({ filterOverview: newFilter, searchValue: "" });
    }
  }

  areFilterEqual(newF, oldF) {
    const newActive = this.returnActiveFilter(newF);
    const oldActive = this.returnActiveFilter(oldF);
    const keys1 = Object.keys(newActive);
    const keys2 = Object.keys(oldActive);
    const allKeys = keys1.concat(keys2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    // Überprüfe die Werte für jeden Schlüssel
    for (let key of keys1) {
      if (newActive[key].length !== oldActive[key].length) {
        return false;
      }
    }

    return true;
  }

  returnActiveFilter(filterFull) {
    var filter = {};

    for (const key in filterFull) {
      var activeCritInFile = [];
      for (let i = 0; i < filterFull[key].length; i++) {
        if (filterFull[key][i].checked) {
          var newCrit = structuredClone(filterFull[key][i]);
          delete newCrit.showDelete;
          activeCritInFile.push(newCrit);
        }
      }

      if (activeCritInFile.length !== 0) {
        filter[key] = activeCritInFile;
      }
    }

    return filter;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------load data------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  changeStateInApp = (newState) => {
    this.setState(newState);
  };

  parseCSVData = async (csvFiles, newDBName, savingLocation, password) => {
    console.log("start parsing '" + newDBName + "' database");

    //save dataStructure version locally
    localStorage.setItem(
      "dataStructureVersion",
      this.state.dataStructureVersion
    );

    //prepare object to save name and generale info of db in CSVNames (indexedDB)
    let csvNamesInfoNewDB = {};

    if (savingLocation === "index") {
      let savedFilesNames = [];
      var datasetSize = 0;

      for (let i = 0; i < csvFiles.length; i++) {
        savedFilesNames.push(csvFiles[i].name);
        datasetSize = datasetSize + csvFiles[i].size * 10 ** -6;
      }

      const date = new Date();

      csvNamesInfoNewDB = {
        name: newDBName,
        savedFiles: savedFilesNames,
        size: datasetSize,
        patients: undefined,
        uploaded: date.toLocaleString(),
      };
    }

    this.setState(
      {
        newDBName: newDBName,
        csvNamesInfoNewDB: csvNamesInfoNewDB,
        loadingProgressSimple: {
          processCSV: false,
          indexing: false,
          postProcessing: false,
        },
        filesFullProcessed: {},
        indexObj: {},
        parsedSize: 0,
        savingLocation: savingLocation,
        addingDataStatus: 0,
        activeChunks: 0, // current amount of open inserts
        waitingParsers: {}, // parser that are waiting in line
      },
      () => {
        this.papaPrep(csvFiles, newDBName, savingLocation, password);
      }
    );
  };

  papaPrep = async (csvFiles, newDBName, savingLocation, password) => {
    // Benutzerdefinierte Validierung und Parsen für jede ausgewählte CSV-Datei
    let dexieDB = "";

    // if saved in locally in indexedDB create the db
    if (savingLocation === "index") {
      dexieDB = new Dexie(newDBName);
      this.setState({ dexieDB: dexieDB });
      //figure out keys of files and create tabs and indices in indexedDB
      await this.dexieCreateTabs(csvFiles, dexieDB);
    }

    csvFiles.forEach((file) => {
      const name = file.name.replace(".csv", "");
      let fileData = file.data === undefined ? file : file.data;
      const totalChunks = Math.ceil(file.size / this.state.chunkSizeParse);

      const initProgress = {
        processedBytes: 0,
        totalBytes: totalChunks > 1 ? totalChunks : 1,
      };

      // add file to filesFullProcessed and set key to false -> if true means file is completely loaded
      this.setState(
        (prevState) => ({
          fileProcessingProgress: {
            ...prevState.fileProcessingProgress,
            [name]: initProgress,
          },
          fileParsing: { ...prevState.fileParsing, [name]: initProgress },
          filesFullProcessed: {
            ...prevState.filesFullProcessed,
            [name]: false,
          },
        }),
        () =>
          this.streamPapa(
            fileData,
            name,
            newDBName,
            savingLocation,
            password,
            dexieDB
          )
      );
    });
  };

  streamPapa = (
    file,
    collectionName,
    dbName,
    savingLocation,
    password,
    dexieDB
  ) => {
    return new Promise((resolve, reject) => {
      let getStructure = true;

      Papa.parse(file, {
        header: true, // Verwende die erste Zeile als Header
        skipEmptyLines: true, // Überspringe leere Zeilen
        dynamicTyping: true, // Automatische Typumwandlung
        chunkSize: this.state.chunkSizeParse,
        chunk: async (results, parser) => {
          if (results.data.length > 0) {
            //check if with too many active chunks if so pause stream
            if (
              this.state.activeChunks + 1 >= this.state.maxActiveChunks &&
              !parser.paused()
            ) {
              parser.pause();
              console.log(
                collectionName,
                "parser has stopt at",
                results.meta.cursor
              );
              this.setState((prevState) => ({
                waitingParsers: {
                  ...prevState.waitingParsers,
                  [collectionName]: parser,
                },
              }));
            }

            //set parsing progress and active chunks
            this.setState((prevState) => {
              return {
                fileParsing: {
                  ...prevState.fileParsing,
                  [collectionName]: {
                    ...prevState.fileParsing[collectionName],
                    processedBytes:
                      prevState.fileParsing[collectionName].processedBytes + 1,
                  },
                },
                activeChunks: prevState.activeChunks + 1,
              };
            });

            //check for dates and convert them to date objects
            let dataWithDates = checkWhereIsDate(results.data);
            //for transition to server -> safe keys that are dates -> server will create date objects again
            let dateKeys = dataWithDates.allDateKeys;
            //when first chunk is processed get the indices and save them in state
            if (getStructure && savingLocation === "mongo") {
              this.setState((prevState) => ({
                indexObj: {
                  ...prevState.indexObj,
                  [collectionName]: dataWithDates.data[0], //save first entry to later create the indices
                },
              }));

              getStructure = false;
            }

            const dataObj = {
              databaseName: dbName,
              collectionsData: { [collectionName]: dataWithDates.data },
              dateKeys: dateKeys,
              password: password,
            };

            let processedBytes = results.meta.cursor; // 'cursor' gibt die Position im Stream an

            if (savingLocation === "index") {
              await this.insertDataInIndexedDB(
                dexieDB,
                collectionName,
                dataWithDates.data
              );
            } else {
              await this.insertDataInMongoDB(
                dataObj,
                collectionName,
                processedBytes
              );
            }
            //update progress after insert is done
            this.setState(
              (prevState) => {
                const updatedProcessedBytes =
                  prevState.fileProcessingProgress[collectionName]
                    .processedBytes + 1;
                const isFullyProcessed =
                  updatedProcessedBytes ===
                  prevState.fileProcessingProgress[collectionName].totalBytes;

                return {
                  fileProcessingProgress: {
                    ...prevState.fileProcessingProgress,
                    [collectionName]: {
                      ...prevState.fileProcessingProgress[collectionName],
                      processedBytes: updatedProcessedBytes,
                    },
                  },
                  filesFullProcessed: {
                    ...prevState.filesFullProcessed,
                    [collectionName]: isFullyProcessed,
                  },
                };
              },
              () => {
                this.removeChunk();

                if (this.state.filesFullProcessed[collectionName]) {
                  console.log(collectionName, " was completely inserted.");
                  resolve();
                }
              }
            );
          } else {
            let progress = {
              processedBytes: 1,
              totalBytes: 1,
            };

            //set in state to fully loaded
            this.setState(
              (prevState) => ({
                fileParsing: {
                  ...prevState.fileParsing,
                  [collectionName]: progress,
                },
                fileProcessingProgress: {
                  ...prevState.fileProcessingProgress,
                  [collectionName]: progress,
                },
                filesFullProcessed: {
                  ...prevState.filesFullProcessed,
                  [collectionName]: true,
                },
              }),
              () => {
                resolve();
              }
            );
          }
          // Optional: Stoppe den Parser falls nötig
          // parser.abort(); // Falls du den Parser nach einem bestimmten Punkt stoppen möchtest
        },

        complete: () => {
          console.log(collectionName, ".csv successfully parsed.");
        },
        error: function (error) {
          console.error("Fehler beim Parsen der CSV-Datei:", error);
          reject(error);
        },
      });
    });
  };

  dexieCreateTabs = async (csvFiles, dexieDB) => {
    for (const file of csvFiles) {
      const name = file.name.replace(".csv", "");
      // Lese die erste Zeile, um nur die Header (Keys) zu erhalten
      const result = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          download: true,
          preview: 1, // Nur die erste Zeile parsen
          complete: (results) => resolve(results.meta.fields),
          error: (error) => reject(error),
        });
      });

      console.log("Create " + name + " table in indexedDB.");
      await this.dexieAddTable(name, result, dexieDB);
    }
  };

  dexieAddTable = async (tabName, tabStructure, dexieDB) => {
    const tabStrucStr = "++primaryKey, " + tabStructure.join(", ");
    const currentDbVersion = dexieDB.verno;
    await dexieDB
      .version(currentDbVersion + 1)
      .stores({ [tabName]: tabStrucStr });
    console.log(
      tabName + " table and following idices created in indexedDB:",
      tabStrucStr
    );
  };

  insertDataInIndexedDB = async (dexieDB, tabName, data) => {
    const table = dexieDB.table(tabName);
    await table.bulkAdd(data);
  };

  removeChunk = () => {
    this.setState((prevState) => {
      return {
        activeChunks: prevState.activeChunks - 1,
      };
    });
  };

  indexMongoDB = (structureObj, dbName) => {
    //get info how many indeces have to be created
    let totalIndex = 0;
    for (const key in structureObj) {
      totalIndex = totalIndex + Object.keys(structureObj[key]).length;
    }

    this.setState(
      { indexingProgress: { createdIndices: 0, totalIndices: totalIndex } },
      async () => {
        for (const collection in structureObj) {
          //old version of indexing is following
          //const dataObject = {databaseName: dbName, collectionName: collection, collectionsStructure: structureObj[collection], };
          //this.createIndexMongo(dataObject);
          await this.createIndicesMongoForFile(
            dbName,
            collection,
            structureObj[collection]
          );
        }
      }
    );
  };

  createIndicesMongoForFile = async (dbName, collection, structureObjCol) => {
    for (const index in structureObjCol) {
      const dataObject = {
        databaseName: dbName,
        collectionName: collection,
        index: index,
      };
      await this.createSingelIndexMongo(dataObject);
    }
  };

  createSingelIndexMongo = async (dataCreate) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/create-single-db-indices",
        dataCreate,
        {
          timeout: 900000, // Timeout auf 15 min erhöhen (Wert in Millisekunden)
        }
      );

      if (response.data.status === "success") {
        console.log(
          "Created " +
            dataCreate.index +
            " index for '" +
            dataCreate.collectionName +
            "' collection."
        );
        this.setState((prevState) => ({
          indexingProgress: {
            ...prevState.indexingProgress,
            createdIndices: prevState.indexingProgress.createdIndices + 1,
          },
        }));
      } else {
        console.log(
          "ERROR on mongoDB levle while creating index " +
            dataCreate.index +
            " for '" +
            dataCreate.collectionName +
            "' collection",
          response.data.error
        );
      }
    } catch (error) {
      console.log(
        "ERROR on proxyserver levle while creating index " +
          dataCreate.index +
          " for '" +
          dataCreate.collectionName +
          "' collection",
        error
      );
      this.props.showSnackbar(
        "error",
        "ERROR while creating indices for '" +
          dataCreate.collectionName +
          "' collection"
      );
    }
  };

  createIndexMongo = async (dataCreate) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/create-db-indices",
        dataCreate,
        {
          timeout: 10000, // Timeout auf 10 Sekunden erhöhen (Wert in Millisekunden)
        }
      );

      console.log(
        "Created all indices for '" +
          dataCreate.collectionName +
          "' collection."
      );

      if (process.env.REACT_APP_WS === "false") {
        const indicesOfFile = Object.keys(
          dataCreate.collectionsStructure
        ).length;
        this.setState((prevState) => ({
          indexingProgress: {
            ...prevState.indexingProgress,
            createdIndices:
              prevState.indexingProgress.createdIndices + indicesOfFile,
          },
        }));
      }
    } catch (error) {
      console.log(
        "ERROR while creating indices for '" +
          dataCreate.collectionName +
          "' collection",
        error
      );
      this.props.showSnackbar(
        "error",
        "ERROR while creating indices for '" +
          dataCreate.collectionName +
          "' collection"
      );
    }
  };

  async getPatientCountDexie(db) {
    try {
      const count = await db.patients.count();
      return count;
    } catch (error) {
      console.error("Fehler beim Abrufen der Anzahl der Patienten:", error);
      return 0;
    }
  }

  postProcessingDexie = async (dexieDB) => {
    //save dataset overview in table that lists all indexed dbs dbs
    let csvNames = this.state.csvNamesInfoNewDB;
    const conditionsPresent = csvNames.savedFiles.includes("conditions.csv");
    const noOfPat = await this.getPatientCountDexie(dexieDB);
    csvNames["patients"] = noOfPat;

    const CsvData = { names: [csvNames] };

    //post processing
    this.addAgeDexie(dexieDB);
    this.addConDexie(dexieDB, conditionsPresent);

    //save dataset info in CSVNames DB
    this.addToDB("CSVNames", "names", CsvData);
  };

  addAgeDexie = async (dexieDB) => {
    await addAgeToDataDexie(dexieDB);
    this.setState((prevState) => ({
      postProcessingProgress: {
        ...prevState.postProcessingProgress,
        age: { ...prevState.postProcessingProgress.age, done: true },
      },
    }));
  };

  addConDexie = async (dexieDB, conditionsPresent) => {
    await addConditionsToPatientsDexie(dexieDB, conditionsPresent);

    this.setState((prevState) => ({
      postProcessingProgress: {
        ...prevState.postProcessingProgress,
        conditions: {
          ...prevState.postProcessingProgress.conditions,
          done: true,
        },
      },
    }));
  };

  sendPostProcessingRequestMongo = async (db) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/post-process",
        {
          params: {
            dbName: db,
          },
        }
      );

      if (response.data.status === "success") {
        if (process.env.REACT_APP_WS === "false") {
          this.setState((prevState) => ({
            postProcessingProgress: {
              age: { ...prevState.postProcessingProgress.age, done: true },
              conditions: {
                ...prevState.postProcessingProgress.conditions,
                done: true,
              },
            },
          }));
        }
        console.log("Postprocessing succsefully complete.");
      } else {
        console.log("error postprocessing", response.data.message);
        this.props.showSnackbar("error", "error postprocessing");
      }
    } catch (error) {
      console.log("error", `Error postprocessing: ${error.message}`);
      this.props.showSnackbar("error", "error postprocessing");
    }
  };

  loadDemoFilters() {
    //load demo filter profiles for pat view
    const dataArray = [
      {
        name: "Demo - General exam",
        data: {
          includeOArray: [
            {
              label: "Heart rate - 8867-4",
              description: "Heart rate",
              code: "8867-4",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Diastolic Blood Pressure - 8462-4",
              description: "Diastolic Blood Pressure",
              code: "8462-4",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Systolic Blood Pressure - 8480-6",
              description: "Systolic Blood Pressure",
              code: "8480-6",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body mass index (BMI) [Ratio] - 39156-5",
              description: "Body mass index (BMI) [Ratio]",
              code: "39156-5",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body Weight - 29463-7",
              description: "Body Weight",
              code: "29463-7",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body Height - 8302-2",
              description: "Body Height",
              code: "8302-2",
              typ: "codeDesc",
              file: "observations",
            },
          ],
          includeAllDataOfEncounter: false,
          excludeArray: [],
        },
      },
      {
        name: "Demo - Laryngeal Ca",
        data: {
          includeOArray: [
            {
              label: "Encounter for symptom - 185345009",
              description: "Encounter for symptom",
              code: 185345009,
              typ: "codeDesc",
              file: "encounter",
            },
            {
              label: "Encounter for problem - 185347001_1",
              description: "Encounter for problem",
              code: "185347001_1",
              typ: "codeDesc",
              file: "encounter",
            },
            {
              label: "Encounter for problem (procedure) - 185347001",
              description: "Encounter for problem (procedure)",
              code: 185347001,
              typ: "codeDesc",
              file: "encounter",
            },
          ],
          includeAllDataOfEncounter: true,
          excludeArray: [],
        },
      },
    ];

    const dataArrayString = JSON.stringify(dataArray);

    try {
      localStorage.setItem("patientView", dataArrayString);
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.error(
          "Fehler: Der verfügbare Speicherplatz des Local Storage ist überschritten."
        );
        //show snackbar
        this.props.newSnackbar(
          "error",
          "Error: The available storage space of the Local Storage has been exceeded."
        );
      } else {
        console.error(
          "Fehler beim Schreiben in den Local Storage:",
          error.message
        );
      }
    }
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- indexedDB dexie loading functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  deleteDatabase(dbName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        resolve(`Datenbank ${dbName} wurde erfolgreich gelöscht.`);
      };
    });
  }

  deleteAllDatabases = () => {
    // Holen aller Datenbanknamen
    indexedDB
      .databases()
      .then((databases) => {
        databases.forEach((dbInfo) => {
          // Jede Datenbank einzeln löschen
          this.deleteDatabase(dbInfo.name)
            .then((message) => {
              this.props.showSnackbar(
                "info",
                "Database '" + dbInfo.name + "' was deleted."
              );
            })
            .catch((error) => {
              console.error(error);
              // Fehlerbehandlung
            });
        });
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Datenbanken:", error);
      });
  };

  sortByBirthdateDescending(data) {
    return data.sort((a, b) => {
      const dateA = new Date(a.STOP);
      const dateB = new Date(b.STOP);
      return dateB - dateA; // Aktuellstes Datum zuerst
    });
  }

  addAgeToData(csvData, encounterCsvData) {
    if (csvData !== undefined) {
      var youngestDateOfSimulation = new Date(
        this.sortByBirthdateDescending(encounterCsvData)[0].STOP
      );

      for (let index = 0; index < csvData.length; index++) {
        //calculate age
        var dob = new Date(csvData[index].BIRTHDATE);
        var dayOfDeath = youngestDateOfSimulation;

        if (csvData[index].DEATHDATE !== null) {
          dayOfDeath = new Date(csvData[index].DEATHDATE);
        }

        let jahre = dayOfDeath.getFullYear() - dob.getFullYear();
        let monate = dayOfDeath.getMonth() - dob.getMonth();
        let tage = dayOfDeath.getDate() - dob.getDate();

        if (tage < 0) {
          monate -= 1;
          tage += new Date(
            dayOfDeath.getFullYear(),
            dayOfDeath.getMonth(),
            0
          ).getDate();
        }

        if (monate < 0) {
          jahre -= 1;
          monate += 12;
        }

        csvData[index]["AGE"] = {
          years: jahre,
          months: jahre * 12 + monate,
          estimatedEndOfSimulation: youngestDateOfSimulation,
        };
        csvData[index]["AGESIMPLE"] = jahre;
      }
    }

    return csvData;
  }

  getSavedDataId = (id) => {
    this.setState({ dataFromSaveId: id });
  };

  openCloseBD() {
    this.state.openBackdrop
      ? this.setState({ openBackdrop: false })
      : this.setState({ openBackdrop: true });
  }

  loadData = () => {
    const dataStructureVersion = localStorage.getItem("dataStructureVersion");

    if (
      Number(dataStructureVersion) !== this.state.dataStructureVersion ||
      dataStructureVersion === null
    ) {
      //stops websites puts up pop-up
      this.setState({ updateDataStructure: true });
      return;
    }

    //load data to display everything
    this.getOverviewDataFromDB(
      this.state.openDB,
      this.state.openDbSavingLocation
    );

    this.getFileList(this.state.openDB, this.state.openDbSavingLocation);

    //get data for the dataset dashboard
    this.countEntriesInObjectStores(
      this.state.openDB,
      this.state.openDbSavingLocation
    );

    //get estimated simulation span to display in header
    this.getSimulationSpan(this.state.openDB, this.state.openDbSavingLocation);

    //get all data for pop ana dashboard
    this.setState(
      {
        popAnaDashboardData: {
          conditions: { data: [], labels: [], processed: false },
          procedures: { data: [], labels: [], processed: false },
          encounters: { data: [], labels: [], processed: false },
          observations: { data: [], labels: [], processed: false },
        },
      },
      () =>
        this.getPopAnDashboardData(
          this.state.openDB,
          this.state.openDbSavingLocation
        )
    );
  };

  setStateLoadedData = (stateToSafe, data) => {
    this.setState((prevState) => ({
      loadedData: {
        ...prevState.loadedData,
        [stateToSafe]: { data: data, loaded: true },
      },
      allDbs: { ...prevState.allDbs, index: data },
    }));
  };

  getAllEntries = async (dbName, objectStore, stateToSafe) => {
    const dbAll = new db(dbName, objectStore);
    dbAll
      .getAllEntries()
      .then((entries) => {
        if (stateToSafe !== undefined) {
          this.setStateLoadedData(stateToSafe, entries);
        }
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  countEntriesInObjectStores(dbName, savingLocation) {
    if (savingLocation === "index") {
      this.countEntriesInObjectStoresDexie(dbName);
    } else if (savingLocation === "mongo") {
      this.countEntriesInObjectStoresMongo(dbName);
    }
  }

  getSimulationSpan(dbName, savingLocation) {
    if (savingLocation === "index") {
      this.getSimulationSpanDexie(dbName);
    } else if (savingLocation === "mongo") {
      this.getSimulationSpanMongo(dbName);
    }
  }

  getSimulationSpanMongo = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getOldestPat",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        const oldestEntry = response.data.data;
        console.log(oldestEntry);
        const simulationSpan = {
          start: new Date(oldestEntry.BIRTHDATE),
          end: new Date(oldestEntry.AGE.estimatedEndOfSimulation),
          loaded: true,
        };
        this.setState({ simulationSpan: simulationSpan });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getSimulationSpanDexie = async (dbName) => {
    const dbDexie = new Dexie(dbName);
    await dbDexie.open();
    const tables = dbDexie.tables.map((table) => table.name);

    const oldestEntry = await dbDexie
      .table("patients")
      .orderBy("BIRTHDATE")
      .first();

    const simulationSpan = {
      start: oldestEntry.BIRTHDATE,
      end: oldestEntry.AGE.estimatedEndOfSimulation,
      loaded: true,
    };

    this.setState({ simulationSpan: simulationSpan });
  };

  countEntriesInObjectStoresDexie(dbName) {
    const dbAll = new db(dbName);
    dbAll
      .countEntriesInTables([
        "conditions",
        "observations",
        "encounters",
        "procedures",
      ])
      .then((entryCounts) => {
        this.setState({ dashboardInfo: { data: entryCounts, loaded: true } });
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  }

  addToDB = (dbName, table, data) => {
    const dbAll = new db(dbName, table);
    dbAll
      .addData(data)
      .then((entryCounts) => {
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  setFullyLoaded = (fileName) => {
    this.setState((prevState) => ({
      filesFullLoaded: prevState.filesFullLoaded.map((item) =>
        item.name === fileName ? { ...item, loaded: true } : item
      ),
    }));
  };

  /*------------------------------------ dexie overview filter funktions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  returnOperator(operator) {
    if (operator === "=") {
      return "===";
    } else if (operator === "!=") {
      return "!==";
    } else {
      return operator;
    }
  }

  returnOperatorObject(operator) {
    if (operator === "=") {
      return "equals";
    } else if (operator === "!=") {
      return "notEqual";
    } else if (operator === ">") {
      return "above";
    } else if (operator === ">=") {
      return "aboveOrEqual";
    } else if (operator === "<") {
      return "below";
    } else if (operator === "<=") {
      return "belowOrEqual";
    }
  }

  returnANDOR(operator) {
    if (operator === "and") {
      return "&&";
    } else if (operator === "or") {
      return "||";
    }
  }

  isNumericString = (str) => {
    const regex = /^\d+$/;
    return regex.test(str);
  };

  checkForString(value) {
    if (this.isNumericString(value)) {
      return parseFloat(value);
    } else if (value instanceof Date) {
      return "new Date('" + value.toISOString() + "')";
    } else {
      return "'" + value + "'";
    }
  }

  checkForAge(file, column) {
    if (file === "patients") {
      if (column === "AGE") {
        return "AGESIMPLE";
      } else {
        return column;
      }
    } else {
      return column;
    }
  }

  checkForStringObject(value) {
    if (this.isNumericString(value)) {
      return parseFloat(value);
    } else {
      return value;
    }
  }

  createConditionStr(criteriaArray, key) {
    let conditionsStr = "";

    for (let iArg = 0; iArg < criteriaArray.argumentsArray.length; iArg++) {
      let argData = criteriaArray.argumentsArray[iArg].data;

      conditionsStr += `item.${this.checkForAge(
        key,
        argData.column
      )} ${this.returnOperator(argData.operator)} ${this.checkForString(
        argData.value
      )}`;

      if (iArg < criteriaArray.argumentsArray.length - 1) {
        conditionsStr += this.returnANDOR(
          criteriaArray.operatorValue[iArg].data
        );
      }
    }

    return conditionsStr;
  }

  filterForDoublePatIds(file, result) {
    let newRes = [];
    let key = "PATIENT";

    if (file === "patients") {
      key = "Id";
    }

    for (let i = 0; i < result.length; i++) {
      if (!newRes.includes(result[i][key])) {
        newRes.push(result[i][key]);
      }
    }

    return newRes;
  }

  setStateOverviewFilterIds = (id, key, i, results, requestId) => {
    if (this.state.overviewRequestId === requestId) {
      this.setState((prevState) => ({
        filteredIdsO: {
          ...prevState.filteredIdsO,
          [key]: {
            ...prevState.filteredIdsO[key],
            [id]: {
              data: this.filterForDoublePatIds(key, results),
              loaded: true,
            },
          },
        },
      }));
    }
  };

  onlyAndOperator(filter) {
    let allAnd = true;

    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data !== "and") {
        allAnd = false;
        break;
      }
    }

    return allAnd;
  }

  includesOr(filter) {
    let includesOr = false;

    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data === "or") {
        includesOr = true;
        break;
      }
    }

    return includesOr;
  }

  includesBracket(filter) {
    let includesBracket = false;

    for (let i = 0; i < filter.argumentsArray.length; i++) {
      if (
        filter.argumentsArray[i].typ === "bracketStart" ||
        filter.argumentsArray[i].typ === "bracketEnd"
      ) {
        includesBracket = true;
        break;
      }
    }

    return includesBracket;
  }

  prepareSingleArg(filter, key, index) {
    filter.argumentsArray[index].data.column = this.checkForAge(
      key,
      filter.argumentsArray[index].data.column
    );
    filter.argumentsArray[index].data.operator = this.returnOperatorObject(
      filter.argumentsArray[index].data.operator
    );
    filter.argumentsArray[index].data.value = this.checkForStringObject(
      filter.argumentsArray[index].data.value
    );

    return filter;
  }

  prepareMultiArgs(filter, key) {
    const sigArgObj = structuredClone(this.prepareSingleArg(filter, key, 0));

    filter["argumentsArray"] = filter.argumentsArray.splice(1);
    const filterStrig = this.createConditionStr(filter, key);

    return { string: filterStrig, obj: sigArgObj };
  }

  prepareOrCrit(filter, key) {
    let indicesOfOr = [];
    let argumentWithStringArray = []; // {obj: {} , string: ""}

    //finde indices of or operators
    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data === "or") {
        indicesOfOr.push(i);
      }
    }

    for (let i = 0; i < indicesOfOr.length; i++) {
      if (i === 0) {
        let sliceStart = 1;
        //create first arg before first "or" object and following args till "or" as string
        const argsToNextOr = filter.argumentsArray.slice(
          sliceStart,
          indicesOfOr[i] + 1
        );
        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, 0).argumentsArray[0],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      }

      // prepare first arg after "or" as obj and following args till next "or" or end as string
      if (indicesOfOr[i + 1] === undefined) {
        const argsToNextOr = filter.argumentsArray.slice(indicesOfOr[i] + 2);

        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);

        let indexOfOrArg = indicesOfOr[i] + 1;
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, indexOfOrArg).argumentsArray[
            indexOfOrArg
          ],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      } else {
        const argsToNextOr = filter.argumentsArray.slice(
          indicesOfOr[i] + 2,
          indicesOfOr[i + 1] + 1
        );

        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);

        let indexOfOrArg = indicesOfOr[i] + 1;
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, indexOfOrArg).argumentsArray[
            indexOfOrArg
          ],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      }
    }

    return argumentWithStringArray;
  }

  dexieFilterPatOverview = (filter, requestId) => {
    for (const key in filter) {
      for (let i = 0; i < filter[key].length; i++) {
        const dbFilter = new db(this.state.openDB, key);
        if (filter[key][i].argumentsArray.length === 1) {
          dbFilter
            .singleArgPatOverview(this.prepareSingleArg(filter[key][i], key, 0))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        } else if (this.onlyAndOperator(filter[key][i])) {
          dbFilter
            .allANDOverview(this.prepareMultiArgs(filter[key][i], key))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        } else if (this.includesBracket(filter[key][i])) {
          this.setState({ filterLoading: false });
        } else if (this.includesOr(filter[key][i])) {
          dbFilter
            .simpleOrOverview(this.prepareOrCrit(filter[key][i], key))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        }
      }
    }
  };

  getFittingPatients = (fittinigIds) => {
    let fittingIdsAll = [];

    for (const key in fittinigIds) {
      for (const keyFile in fittinigIds[key]) {
        fittingIdsAll.push(fittinigIds[key][keyFile].data);
      }
    }

    const matchedIds = fittingIdsAll.reduce((commonIds, currentArray) => {
      return commonIds.filter((id) => currentArray.includes(id));
    });

    if (this.state.openDbSavingLocation === "index") {
      this.fittingPatDexie(matchedIds);
    } else if (this.state.openDbSavingLocation === "mongo") {
      this.getFittingIdsMongo(matchedIds);
    }
  };

  fittingPatDexie = (matchedIds) => {
    const dbAll = new db(this.state.openDB, "patients");
    dbAll
      .getEntryByArray("Id", matchedIds)
      .then((patients) => {
        this.setPatOverViewState(patients);
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  getFittingIdsMongo = async (matchedIds) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/findDocumentsByArray",
        {
          dbName: this.state.openDB,
          collectionName: "patients",
          indexName: "Id",
          array: matchedIds,
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setPatOverViewState(results);
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };
  /*------------------------------------ dexie survial functions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  getStartPointData = (file) => {
    this.setState({ startPointDataLoaded: false }, () => {
      if (this.state.openDbSavingLocation === "index") {
        this.getStartPointDataDexie(file);
      } else if (this.state.openDbSavingLocation === "mongo") {
        this.getStartPointDataMongo(file);
      }
    });
  };

  getStartPointDataDexie = (file) => {
    const dbAll = new db(this.state.openDB);
    dbAll
      .getEntriesByIndex(file.file, file.colum, file.value)
      .then((data) => {
        this.setState({ startPointData: data, startPointDataLoaded: true });
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- mongo DB loading functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  getStartPointDataMongo = async (file) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getEntriesByIndexValue",
        {
          params: {
            dbName: this.state.openDB,
            collectionName: file.file,
            indexName: file.colum,
            indexValue: file.value,
          },
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setState({ startPointData: results, startPointDataLoaded: true });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  checkConnection = async () => {
    let connectionStatus = { trying: false, connected: false };
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/check-connection"
      ); // Proxy-Server-Port
      if (response.data.status === "success") {
        this.props.showSnackbar("success", "MongoDB connection is successful");
        connectionStatus = { trying: false, connected: true };
        console.log("MongoDB connection status:", connectionStatus);
      } else {
        this.props.showMongoReSnackbar(
          "Unanble to connect to MongoDB. Please try to reconect or use website without MongoDB."
        );
        console.log("MongoDB connection status:", connectionStatus);
      }
      this.setState({ connectionToMongoDB: connectionStatus });
    } catch (error) {
      this.setState({ connectionToMongoDB: connectionStatus });
      this.props.showMongoReSnackbar(
        "Unanble to connect to proxyserver. Can not reach MongoDB. Please try to reconect or use website without MongoDB."
      );
      console.log(
        "Unable to reach proxyserver (server.js). Please check if the server is running (npm run server)"
      );
      console.log("MongoDB connection status:", connectionStatus);
    }
  };

  tryStringiyJson(jsonArray, key) {
    try {
      // Versuche, den aktuellen JSON-Teil zu stringifizieren
      const jsonString = JSON.stringify(jsonArray);
      // Bestimme die Größe in Bytes, wenn es funktioniert hat
      const chunkSizeInBytes = new Blob([jsonString]).size;
      return chunkSizeInBytes;
    } catch (error) {
      console.log(key, ".csv string to long need to split");
      return undefined;
    }
  }

  checkAndResumeParser = (collectionName, parser) => {
    if (this.state.noOfActiveBuffers < this.state.maxBufferSize) {
      this.setState(
        (prevState) => ({
          pausedParsers: {
            ...prevState.pausedParsers,
            [collectionName]: false,
          },
        }),
        () => {
          console.log(
            collectionName,
            "resume parse at: " + this.state.noOfActiveBuffers
          );
          parser.resume();
        }
      );
    } else {
      // Wiederholungsaufruf nach einer Verzögerung
      setTimeout(() => {
        this.checkAndResumeParser(collectionName, parser);
      }, 50);
    }
  };

  insertDataInMongoDB = async (data, collectionName, progress) => {
    /*const dataObk = {
              databaseName: dbName,
              collectionsData: { [collectionName]: dataWithDates },
              dateKeys: dateKeys,
            };*/
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/insert-data",
        data
      );

      if (response.data.status === "success") {
      } else {
        console.log(
          collectionName,
          " curosr at",
          progress,
          " Bytes: error on mongoDB level while inserting data",
          response.data.error,
          data
        );
      }
    } catch (error) {
      console.log(
        collectionName,
        " curosr at",
        progress,
        " Bytes: error on proxyserver level while inserting data",
        data
      );
      console.log(error);
    }
  };

  async getMongoDBDatestInfos() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/db-stats"
      );

      const excludeDbNames = ["admin", "config", "local", "passwords"];
      const allDbs = response.data.filter(
        (db) => !excludeDbNames.includes(db.Name)
      );
      this.setState((prevState) => ({
        mongoDatabases: {
          data: allDbs,
          loaded: true,
        },
        allDbs: { ...prevState.allDbs, mongo: allDbs },
      }));
    } catch (error) {
      this.props.showSnackbar("error", "Error fetching database stats");
    }
  }

  deleteDatabaseAxios = async (dbName, password) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/delete-database",
        { data: { dbName: dbName, password: password } }
      );
      if (response.data.status === "success") {
        this.props.showSnackbar("success", response.data.message);
        this.getMongoDBDatestInfos();
      } else {
        this.props.showSnackbar("error", response.data.message);
      }
    } catch (error) {
      this.props.showSnackbar(
        "error",
        `Error deleting database: ${error.message}`
      );
    }
  };

  createUploadWebSocket() {
    this.wsUploadMongo = new WebSocket(
      "ws://" +
        process.env.REACT_APP_SERVER_URL.replace("http://", "") +
        process.env.REACT_APP_SERVER_PORT
    );

    this.wsUploadMongo.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.step === "indexing") {
        this.setState((prevState) => ({
          indexingProgress: {
            ...prevState.indexingProgress,
            createdIndices: prevState.indexingProgress.createdIndices + 1,
          },
        }));
      } else if (data.step === "addAge") {
        this.setState((prevState) => ({
          postProcessingProgress: {
            ...prevState.postProcessingProgress,
            age: { ...prevState.postProcessingProgress.age, done: true },
          },
        }));
      } else if (data.step === "addConditions") {
        this.setState((prevState) => ({
          postProcessingProgress: {
            ...prevState.postProcessingProgress,
            conditions: {
              ...prevState.postProcessingProgress.conditions,
              done: true,
            },
          },
        }));
      }
    };

    this.wsUploadMongo.onopen = () => {
      console.log("WebSocket connection established");
    };

    this.wsUploadMongo.onclose = () => {
      console.log("WebSocket connection closed");
    };

    this.wsUploadMongo.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }

  getOverviewDataFromDBMongo = async (dbName, collectionName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getAllDataOfCollection",
        {
          params: {
            dbName: dbName,
            collectionName: collectionName,
          },
        }
      );
      if (response.data.status === "success") {
        const dataWithDate = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );
        this.setPatOverViewState(dataWithDate);
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  convertKeysToDate(array1, array2) {
    return array1.map((obj) => {
      // Erstelle eine Kopie des Objekts, um es nicht direkt zu mutieren
      const newObj = { ...obj };

      // Iteriere durch die Schlüssel in array2
      array2.forEach((key) => {
        if (newObj.hasOwnProperty(key) && newObj[key] !== null) {
          newObj[key] = new Date(newObj[key]);
        }
      });

      return newObj;
    });
  }

  getFileListMongo = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFileList",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        const list = response.data.data;
        const emptyPatViewObj = this.emptyPatientViewDataObject(list);
        this.setState(
          {
            fileList: list,
            patientViewData: emptyPatViewObj,
            displayedData: emptyPatViewObj,
            emptyPat: emptyPatViewObj,
            allPatDatLoaded: this.emptyPatLoadingObject(list),
            displayedDataProcessed: false,
          },
          () => {
            //load all reasoncodes and thier frequency
            this.loadReasonCodesMongo(this.state.openDB, list);
            this.setState({ fileListLoaded: true });
          }
        );
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  countEntriesInObjectStoresMongo = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getCollectionCounts",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        this.setState({
          dashboardInfo: { data: response.data.data, loaded: true },
        });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getPopAnDashboardDataMongo = async (dbName, objStorage) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFrequenciesIndices",
        {
          params: {
            dbName: dbName,
            collectionName: objStorage,
          },
        }
      );
      if (response.data.status === "success") {
        const frequencies = response.data.data;

        let labels = [];
        let data = [];

        for (let i = 0; i < frequencies.length; i++) {
          labels.push(this.removeSubstrings(frequencies[i]._id));
          data.push(frequencies[i].count);
        }

        this.setState((prevState) => ({
          popAnaDashboardData: {
            ...prevState.popAnaDashboardData,
            [objStorage]: {
              data: data,
              labels: labels,
              processed: true,
            },
          },
        }));
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  loadReasonCodesMongo = async (dbNamae, list) => {
    this.setState({ allReasonCodes: { data: [], loaded: false } }, () => {
      let tablesWIthReason = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].indices.includes("REASONCODE")) {
          tablesWIthReason.push(list[i].name);
        }
      }
      this.reasonMongoRequest(dbNamae, tablesWIthReason);
    });
  };

  reasonMongoRequest = async (dbName, array) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getReasonDescriptionCounts",
        {
          params: {
            dbName: dbName,
            collectionArray: array,
          },
        }
      );
      if (response.data.status === "success") {
        const reasoncodes = response.data.data;
        this.setState({ allReasonCodes: { data: reasoncodes, loaded: true } });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  patHistoryMongo = async (dbName, file, patID) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getEntriesByIndexValue",
        {
          params: {
            dbName: dbName,
            collectionName: file,
            indexName: "PATIENT",
            indexValue: patID,
          },
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setState(
          (prevState) => ({
            patientViewData: {
              ...prevState.patientViewData,
              [file]: { typ: file, data: results },
            },
          }),
          () => {
            this.setState((prevState) => ({
              allPatDatLoaded: {
                ...prevState.allPatDatLoaded,
                [file]: true,
              },
            }));
          }
        );
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getLastOpenPatFromLocalStorage() {
    const jsonString = localStorage.getItem("lastOpenPatient");
    var json = {};
    var db = "";

    if (jsonString !== null) {
      try {
        json = JSON.parse(jsonString);
        db = json.database;

        delete json.database;
      } catch (error) {
        console.error("Fehler beim Parsen des JSON-Strings:", error);
      }
    }

    return { pat: json, db: db };
  }

  mongoFilterPatOverview = async (filter, requestId) => {
    for (const key in filter) {
      for (let i = 0; i < filter[key].length; i++) {
        const mongoFIlter = this.buildMongoFilterOBj(filter[key][i]);

        try {
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              process.env.REACT_APP_SERVER_PORT +
              "/getFilteredEntries",
            {
              params: {
                dbName: this.state.openDB,
                collectionName: key,
                filterCrit: mongoFIlter,
              },
            }
          );
          if (response.data.status === "success") {
            this.setStateOverviewFilterIds(
              filter[key][i].id,
              key,
              i,
              response.data.data,
              requestId
            );
          } else {
            console.log("error", response.data.message);
          }
        } catch (error) {
          console.log(
            "error",
            `Error getting db collections: ${error.message}`
          );
        }
      }
    }
  };

  buildMongoFilterOBj(filter) {
    let counterForArgs = 0; //is needed to find rigth operator
    let inBracket = false;
    let brackets = [];
    let queryCrit = [];

    for (let i = 0; i < filter.argumentsArray.length; i++) {
      let argument = structuredClone(filter.argumentsArray[i]);
      if (!inBracket) {
        if (argument.typ === "argument") {
          let argWithConnector = argument.data;

          if (i < filter.argumentsArray.length - 1) {
            argWithConnector["connector"] =
              filter.operatorValue[counterForArgs].data;
          }

          queryCrit.push(argWithConnector);
          counterForArgs++;
        } else if (argument.typ === "bracketStart") {
          inBracket = true;
          let emptyBracket = { group: [] };
          brackets.push(emptyBracket);
        }
      } else {
        let currentBrackIndex = brackets.length - 1;
        if (argument.typ === "argument") {
          let argWithConnector = argument.data;

          if (
            filter.argumentsArray[i + 1] !== undefined &&
            filter.argumentsArray[i + 1].typ !== "bracketEnd"
          ) {
            argWithConnector["connector"] =
              filter.operatorValue[counterForArgs].data;

            brackets[currentBrackIndex].group.push(argWithConnector);
            counterForArgs++;
          } else {
            brackets[currentBrackIndex].group.push(argWithConnector);
            //here no count up for args beacuse other wise an operator is skipt -> increased after connector added to group
          }
        } else if (argument.typ === "bracketStart") {
          inBracket = true;
          let emptyBracket = { group: [] };
          brackets.push(emptyBracket);
        } else if (argument.typ === "bracketEnd") {
          let bracket = brackets[currentBrackIndex];
          if (
            filter.argumentsArray[i + 1] !== undefined &&
            filter.argumentsArray[i + 1].typ !== "bracketEnd"
          ) {
            bracket["connector"] = filter.operatorValue[counterForArgs].data;
            counterForArgs++;
          }

          if (currentBrackIndex === 0) {
            inBracket = false;
            queryCrit.push(bracket);
          } else {
            brackets[currentBrackIndex - 1].group.push(bracket);
          }

          brackets.splice(currentBrackIndex, 1);
        }
      }
    }
    return queryCrit;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- react functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  checkLoaded(loadedObj) {
    let allLoaded = true;

    if (Object.keys(loadedObj).length > 0) {
      for (const key in loadedObj) {
        if (loadedObj[key] === false) {
          allLoaded = false;
          break;
        }
      }
    } else {
      allLoaded = false;
    }
    return allLoaded;
  }

  restoreLastSession = async () => {
    const lastOpenDB = localStorage.getItem("lastOpenDB");

    //check if csvNamesDB is existing, if not create it
    this.dbCSV = new Dexie("CSVNames");
    try {
      await this.dbCSV.open();
      this.getAllEntries("CSVNames", "names", "csvFiles");
      this.dbCSV.close();
    } catch (error) {
      const CSVnamesStruck = {
        names: "++id, name, savedFiles, size, patients, uploaded",
      };

      this.dbCSV.version(1).stores(CSVnamesStruck);
      //save datastructure cersion locally
      localStorage.setItem(
        "dataStructureVersion",
        this.state.dataStructureVersion
      );
      await this.dbCSV.open();
      this.dbCSV.close();
      //save demo filters loacally
      this.loadDemoFilters();
      //if table dosent exstists that means website is opend first time -> choose no dataset and end functions
      this.setState({ noDbSelected: true });
      return;
    }

    if (lastOpenDB === null) {
      //if no dataset was opend before set empty db
      this.setState({ noDbSelected: true });
    } else {
      //try to restore session if not possible (for exmaple because dataset was deleted) set to no dataset
      const jsonLastOpen = JSON.parse(lastOpenDB);

      if (jsonLastOpen.location === "index") {
        this.db = new db(jsonLastOpen.dbName);
        try {
          await this.db.open();
          this.setState({
            openDB: jsonLastOpen.dbName,
            openDbSavingLocation: jsonLastOpen.location,
          });

          this.db.closeDatabase();
        } catch (error) {
          this.props.showSnackbar(
            "error",
            "The last session could not be restored. Database no longer exists in indexedDB."
          );

          //set to no dataset
          this.setState({ noDbSelected: true });
        }
      } else if (jsonLastOpen.location === "mongo") {
        try {
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              process.env.REACT_APP_SERVER_PORT +
              "/check-database"
          ); // Proxy-Server-Port

          if (response.data.status === "success") {
            this.setState({
              openDB: jsonLastOpen.dbName,
              openDbSavingLocation: jsonLastOpen.location,
            });
          } else {
            this.props.showSnackbar(
              "error",
              "The last session could not be restored. Unable to open Database in MongoDB."
            );
            //set to no dataset
            this.setState({ noDbSelected: true });
          }
        } catch (error) {
          this.props.showSnackbar(
            "error",
            "The last session could not be restored. Can not connect to backend server."
          );
          //set to no dataset
          this.setState({ noDbSelected: true });
        }
      }
    }
  };

  componentDidMount() {
    console.log("app version:", this.state.version);
    console.log(
      "Server-URL: " +
        process.env.REACT_APP_SERVER_URL +
        process.env.REACT_APP_SERVER_PORT
    );

    console.log(
      "MongoDB-URL: " +
        process.env.REACT_APP_MongoDB_URL +
        process.env.REACT_APP_MongoDB_PORT
    );

    //check for connection to MongoDB
    this.checkConnection();

    //create upload websocket -> gives back progress of adding data in mongodb
    if (process.env.REACT_APP_WS === "true") {
      this.createUploadWebSocket();
      console.log("Websocket activated.");
    } else {
      console.log("Websocket deactivated.");
    }

    //load all data to display website from indexedDB
    //load if to show dialog
    this.setState({ showDialogStates: this.loadDialog() });

    //restore last session -> get last open db and patient or set empty dataset
    this.restoreLastSession();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.activeChunks !== this.state.activeChunks &&
      this.state.activeChunks > 5
    ) {
      console.log("active chunk peak", this.state.activeChunks);
    }

    // if no conncetion to mongoDB is possible retry (is triggered by snackbar)
    if (prevProps.reconnect !== this.props.reconnect) {
      this.checkConnection();
    }

    //when mongoDB connection established load the saved datasets
    if (
      prevState.connectionToMongoDB !== this.state.connectionToMongoDB &&
      this.state.connectionToMongoDB.connected
    ) {
      this.getMongoDBDatestInfos();
    }

    //restarts paused parser when space available
    if (
      prevState.activeChunks !== this.state.activeChunks &&
      Object.keys(this.state.waitingParsers).length > 0 &&
      this.state.activeChunks < this.state.maxActiveChunks
    ) {
      this.setState((prevState) => {
        const firstKey = Object.keys(prevState.waitingParsers)[0];
        if (prevState.waitingParsers[firstKey].paused()) {
          prevState.waitingParsers[firstKey].resume();
        }
        const { [firstKey]: removed, ...newWaitingParsers } =
          prevState.waitingParsers;
        console.log("Resumed " + firstKey + " parser");
        return { waitingParsers: newWaitingParsers };
      });
    }

    //if all csv files are prased and insertet start indexing
    if (
      prevState.filesFullProcessed !== this.state.filesFullProcessed &&
      !this.state.loadingProgressSimple.processCSV
    ) {
      let allLoaded = this.checkLoaded(this.state.filesFullProcessed);
      let indexingNeeded = false; // little confusing BUT: if we are at mongoDB and need the indexing it is set to false, for indexedDB we skip the indexing progress and set it to true

      if (this.state.savingLocation === "index") {
        indexingNeeded = true;
      }

      if (allLoaded) {
        this.setState(
          {
            loadingProgressSimple: {
              //set processed true to triger next steps
              processCSV: true,
              indexing: indexingNeeded,
              postProcessing: false,
            },
            indexingProgress:
              this.state.savingLocation === "index"
                ? { createdIndices: 1, totalIndices: 1 }
                : { createdIndices: 0, totalIndices: 1 },
          },
          () => {
            if (this.state.savingLocation === "mongo") {
              this.indexMongoDB(this.state.indexObj, this.state.newDBName);
            }
          }
        );
      }
    }

    //start post processing when indexing is done
    if (
      prevState.indexingProgress !== this.state.indexingProgress &&
      this.state.indexingProgress.createdIndices ===
        this.state.indexingProgress.totalIndices
    ) {
      this.setState(
        {
          loadingProgressSimple: {
            //set processed and indexing true to triger next steps
            processCSV: true,
            indexing: true,
            postProcessing: false,
          },
        },
        () => {
          if (this.state.savingLocation === "mongo") {
            this.sendPostProcessingRequestMongo(this.state.newDBName);
          } else {
            this.postProcessingDexie(this.state.dexieDB);
          }
        }
      );
    }

    //check if post processing is done
    if (
      prevState.postProcessingProgress !== this.state.postProcessingProgress
    ) {
      let allLoaded = true;
      for (const key in this.state.postProcessingProgress) {
        if (!this.state.postProcessingProgress[key].done) {
          allLoaded = false;
          break;
        }
      }

      if (allLoaded) {
        this.setState({
          loadingProgressSimple: {
            //set processed and indexing true to triger next steps
            processCSV: true,
            indexing: true,
            postProcessing: true,
          },
        });
      }
    }

    //when new db was added to mongo db -> reload safed dbs
    if (
      prevState.loadingProgressSimple !== this.state.loadingProgressSimple &&
      this.state.loadingProgressSimple.postProcessing
    ) {
      this.getMongoDBDatestInfos();
    }

    //show time snackbar only when pat overview or pat view is open
    if (prevProps.location !== this.props.location) {
      const url = this.props.location.pathname;
      const prevUrl = prevProps.location.pathname;

      const showPatLoading = url.includes(
        "/syn-viewer/patient-records/patient-view/"
      );

      const prevShowPatLoading = prevUrl.includes(
        "/syn-viewer/patient-records/patient-view/"
      );

      const showPatOverviewLoading = url.includes(
        "/syn-viewer/patient-records/overview"
      );
      let showSnackbar = false;

      if (showPatOverviewLoading || (showPatLoading && !prevShowPatLoading)) {
        showSnackbar = true;
      }

      this.setState({ showTimeSnackbar: showSnackbar });
    }

    //filter the overview data if the filter conditions change
    if (prevState.filterOverview !== this.state.filterOverview) {
      //create empty id array for state
      var emptyIdObj = {};
      let filter = this.returnActiveFilter(this.state.filterOverview);

      this.setState({ showTimeSnackbar: true });

      if (Object.keys(filter).length === 0) {
        //if filter object is empty return all patients
        this.setState({
          overviewData: this.state.patientList,
          numbPatients: this.state.patientList.length,
        });
      } else {
        this.startTiming("loadingTimePatOverviewStart");
        this.setState({
          loadingMessagePatOverview: "filtering data",
          loadingTimePatOverviewDuration: null,
        });

        for (const key in filter) {
          emptyIdObj[key] = {};
          for (let index = 0; index < filter[key].length; index++) {
            emptyIdObj[key] = {};
            for (let i = 0; i < filter[key].length; i++) {
              emptyIdObj[key][filter[key][i].id] = {
                loaded: false,
                data: [],
              };
            }
          }
        }

        const newOverviewRequestId = this.state.overviewRequestId + 1;

        this.setState(
          {
            filteredIdsO: emptyIdObj,
            filterLoading: true,
            overviewRequestId: newOverviewRequestId,
          },
          () => {
            if (this.state.openDbSavingLocation === "index") {
              this.dexieFilterPatOverview(filter, newOverviewRequestId);
            } else if (this.state.openDbSavingLocation === "mongo") {
              this.mongoFilterPatOverview(filter, newOverviewRequestId);
            }
          }
        );
      }
    }

    //shoots when ever the overview id array is changed
    if (prevState.filteredIdsO !== this.state.filteredIdsO) {
      //check if all ids are loaded
      let allIdLoaded = true;
      const ids = this.state.filteredIdsO;
      for (const key in ids) {
        for (const fileKey in ids[key]) {
          if (!ids[key][fileKey].loaded) {
            allIdLoaded = false;
          }
        }
      }

      //when all data loaded the array of all fitting patients is created
      if (allIdLoaded) {
        this.getFittingPatients(ids);
      }
    }

    //loads open patient data when filList was loaded (because it creates an empty patientViewData object -> is needed to load all patient data) -> is needed when website is reloaded and patientview is open
    if (
      prevState.fileList !== this.state.fileList &&
      Object.keys(this.state.openPatient).length !== 0
    ) {
      this.preparePatientHistory(
        this.state.openPatient,
        this.state.patientViewData,
        this.state.openDB,
        this.state.openDbSavingLocation
      );
    }

    //loads open patient data when open patient is changed
    if (
      prevState.openPatient !== this.state.openPatient &&
      this.state.fileListLoaded &&
      Object.keys(this.state.openPatient).length !== 0
    ) {
      const url = this.props.location.pathname;
      const showPatLoading = url.includes(
        "/syn-viewer/patient-records/patient-view/"
      );
      if (showPatLoading) {
        this.setState({ showTimeSnackbar: true });
      }
      this.preparePatientHistory(
        this.state.openPatient,
        this.state.patientViewData,
        this.state.openDB,
        this.state.openDbSavingLocation
      );
    }

    //reloads patientList in Overview when new Database is opened
    if (prevState.openDB !== this.state.openDB) {
      this.newSnackbar(
        "success",
        "Loading " + "'" + this.state.openDB + "' dataset"
      );

      const openDBSav = {
        dbName: this.state.openDB,
        location: this.state.openDbSavingLocation,
      };

      const stringObj = JSON.stringify(openDBSav);

      //save last open dataset
      localStorage.setItem("lastOpenDB", stringObj);

      //load last open patient
      const lastPat = this.getLastOpenPatFromLocalStorage();
      this.setState(
        {
          openPatient: lastPat.db === this.state.openDB ? lastPat.pat : {},
          noDbSelected: false,
          patientList: [], // set pat list to zero so after loading overview data we now if its init load or a filter request
        },
        () => this.loadData()
      );
    }

    //performs search in Overview List when search term was alterd
    if (prevState.searchValue !== this.state.searchValue) {
      var newPatList = [];
      if (this.state.searchValue !== "") {
        newPatList = this.searchObjectsByTerm(
          this.state.searchValue,
          this.state.allPatOverviewData
        );
      } else {
        newPatList = this.state.allPatOverviewData;
      }
      this.setState({ overviewData: newPatList });
    }

    //adds a new snackbar when tthe message saved in the state is cahnged
    if (prevState.snackbarMessage !== this.state.snackbarMessage) {
      this.props.showSnackbar(
        this.state.snackbarMessage.variant,
        this.state.snackbarMessage.text
      );
    }

    //checks if dialog pop-up needs to be shown when location is changed
    if (prevProps.location !== this.props.location) {
      this.showPopUp(this.props.location.pathname);
    }
  }

  returnViewWithNoDatasetSign(htmlClass) {
    if (this.state.noDbSelected) {
      return <NoDBselectedText />;
    } else {
      return htmlClass;
    }
  }

  snackbarMessage = (showPatLoading, showPatOverviewLoading) => {
    if (showPatLoading) {
      return (
        <div
          className="flex gap-1"
          style={{
            fontSize: "13px",
            fontWeight: 300,
            color: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
          }}
        >
          {this.state.loadingTimePatientDuration === 0 && (
            <CircularProgress
              size={13}
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                marginTop: "0.4vh",
                marginRight: "5px",
              }}
            />
          )}
          <p>{this.state.loadingMessagePatView}</p>
          {this.state.loadingTimePatientDuration !== 0 && (
            <p>{(this.state.loadingTimePatientDuration / 1000).toFixed(1)} s</p>
          )}
        </div>
      );
    } else if (showPatOverviewLoading) {
      return (
        <div
          className="flex gap-1"
          style={{
            fontSize: "13px",
            fontWeight: 300,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {this.state.loadingTimePatOverviewDuration === 0 && (
            <CircularProgress
              size={13}
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                marginTop: "0.4vh",
                marginRight: "5px",
              }}
            />
          )}
          <p>{this.state.loadingMessagePatOverview}</p>
          {this.state.loadingTimePatOverviewDuration !== 0 && (
            <p>
              {(this.state.loadingTimePatOverviewDuration / 1000).toFixed(1)} s
            </p>
          )}
        </div>
      );
    }
  };

  render() {
    //time snackbar functions
    const url = this.props.location.pathname;
    const showPatLoading = url.includes(
      "/syn-viewer/patient-records/patient-view/"
    );
    const showPatOverviewLoading = url.includes(
      "/syn-viewer/patient-records/overview"
    );

    const actionSnackBar = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleCloseSnackbar}
          sx={{ width: "20px", height: "20px", marginRight: "5px" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    if (!this.state.updateDataStructure) {
      return (
        <React.StrictMode>
          <div className="App">
            {/** shows loading time of patient or database at the bottom of the screen */}
            <Snackbar
              open={this.state.showTimeSnackbar}
              onClose={this.handleCloseSnackbar}
              TransitionComponent={SlideTransition}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <CustomSnackbarContent
                message={this.snackbarMessage(
                  showPatLoading,
                  showPatOverviewLoading
                )}
                action={actionSnackBar}
                // Anwenden der benutzerdefinierten Klasse
              />
            </Snackbar>
            <Overlay
              isOpen={this.state.settingsOpen}
              onClose={() => this.openSettings()}
              title="Settings"
            >
              <Settings
                sHeaderDisplay={this.state.sHeaderDisplay}
                changeSHeaderDisplay={this.changeSHeaderDisplay}
                boringDiagnoses={this.state.boringDiagnoses}
                resetDialog={this.resetDialog}
                newSnackbar={this.newSnackbar}
                showDeleteSnackbar={this.props.showDeleteSnackbar}
              />
            </Overlay>
            <Overlay
              isOpen={this.state.showPopUp}
              onClose={() => this.closeDialog()}
              title={this.state.popUpTitle}
            >
              <DialogPopUp
                showDialogStatesKey={this.state.showDialogStatesKey}
                dontShowAgain={this.dontShowAgain}
                closeDialog={this.closeDialog}
              />
            </Overlay>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <StartPage />
                    </>
                  }
                ></Route>
                <Route
                  path="troubleshooting"
                  element={
                    <>
                      <Troubleshooting />
                    </>
                  }
                ></Route>
                <Route
                  path="set-passwort"
                  element={
                    <>
                      <SetPasswort />
                    </>
                  }
                ></Route>
                <Route
                  path="syn-viewer"
                  element={
                    <>
                      <SyntheaView
                        savingLocation={this.state.savingLocation}
                        openNewDb={this.openNewDb}
                        allDbs={this.state.allDbs}
                        simulationSpan={this.state.simulationSpan}
                        getOffOnStartPage={this.changeView}
                        location={this.props.location}
                        openDB={this.state.openDB}
                        size={this.state.overviewData.length}
                        collapsesSave={this.state.collapsesSave}
                        navigate={this.props.navigate}
                        openSettings={this.openSettings}
                        sHeaderDisplay={this.state.sHeaderDisplay}
                      />
                    </>
                  }
                >
                  <Route
                    path="overview-dataset"
                    element={
                      <>
                        {this.returnViewWithNoDatasetSign(
                          <OverviewDatset
                            openDB={this.state.openDB}
                            dashboardInfo={this.state.dashboardInfo}
                            boringDiagnoses={this.state.boringDiagnoses}
                            patientList={this.state.patientList}
                            getAllEntries={this.getAllEntries}
                            csvFiles={this.state.loadedData.csvFiles}
                          />
                        )}
                      </>
                    }
                  ></Route>
                  <Route
                    path="patient-records"
                    element={
                      <div className="body-view-main p-4">
                        {this.returnViewWithNoDatasetSign(<Outlet />)}
                      </div>
                    }
                  >
                    <Route
                      path="Overview"
                      element={
                        <>
                          {this.state.overviewDataLoaded &&
                          this.state.fileListLoaded ? (
                            <Overview
                              changeStateInApp={this.changeStateInApp}
                              savingLocation={this.state.savingLocation}
                              patientList={this.state.overviewData}
                              loadedFileList={this.state.fileList}
                              changeView={this.setOpenPatient}
                              getFilterFormOverview={this.getFilterFormOverview}
                              numbPatients={this.state.numbPatients}
                              filterLoading={this.state.filterLoading}
                              dbName={this.state.openDB}
                              oldFilterArray={this.state.filterOverview}
                              overviewSearchValue={this.overviewSearchValue}
                              searchValueOverview={this.state.searchValue}
                              newSnackbar={this.newSnackbar}
                              showDeleteSnackbar={this.props.showDeleteSnackbar}
                              detailedDBLocation={this.state.detailedDBLocation}
                              location={this.state.openDbSavingLocation}
                            />
                          ) : (
                            <CircularProgress
                              color="inherit"
                              style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#4169e1",
                              }}
                            />
                          )}
                        </>
                      }
                    ></Route>
                    <Route
                      path="patient-view"
                      element={
                        <>
                          {Object.keys(this.state.patientViewData).length !==
                          0 ? (
                            <>
                              <PatientView
                                numbPatients={this.state.numbPatients}
                                openPatientIndex={
                                  this.state.overviewData.findIndex(
                                    (o) => o.Id === this.state.openPatient.Id
                                  ) + 1
                                }
                                openPatientData={this.state.patientViewData}
                                allPatDatLoaded={this.state.allPatDatLoaded}
                                displayedDataProcessed={
                                  this.state.displayedDataProcessed
                                }
                                displayedData={this.state.displayedData}
                                setDisplayDataProcessed={
                                  this.setDisplayDataProcessed
                                }
                                setPatientInfoVisible={
                                  this.setPatientInfoVisible
                                }
                                getFilteredPatData={this.getFilteredPatData}
                                lastOpenView={this.state.lastOpenView}
                                location={this.props.location.pathname}
                                containerRefStartParent={
                                  this.containerRefStartParent
                                }
                                containerRefStart={this.containerRefStart}
                                setPatViewFilter={this.setPatViewFilter}
                                patViewFilter={this.state.patViewFilter}
                                changePatViewCollapses={
                                  this.changePatViewCollapses
                                }
                                collapsesSave={this.state.collapsesSave}
                                nextPatient={this.nextPatient}
                                prevPatient={this.prevPatient}
                                newSnackbar={this.newSnackbar}
                                showDeleteSnackbar={
                                  this.props.showDeleteSnackbar
                                }
                                allReasonCodes={this.state.allReasonCodes}
                              />
                            </>
                          ) : (
                            <div className="body-view-main">
                              <CircularProgress
                                color="inherit"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#4169e1",
                                }}
                              />
                            </div>
                          )}
                        </>
                      }
                    >
                      <Route
                        path="patient-dashboard"
                        element={
                          <Dashboard
                            data={this.state.displayedData}
                            displayedDataProcessed={
                              this.state.displayedDataProcessed
                            }
                            matchEncounter={this.matchEncounter}
                            openEncounter={this.onClickEncounter}
                            whichLocationWhenMounted={
                              this.whichLocationWhenMounted
                            }
                            allPatDatLoaded={this.state.allPatDatLoaded}
                          />
                        }
                      ></Route>
                      <Route
                        path="patient-dataview"
                        element={
                          <>
                            <PatientDataview
                              displayedData={this.state.displayedData}
                              openEncounter={this.onClickEncounter}
                              scrollToTop={this.scrollToTop}
                              scrollToTopFast={this.scrollToTopFast}
                              clickedDataViewEncounter={
                                this.state.clickedDataViewEncounter
                              }
                              setDataViewEncounterNull={
                                this.setDataViewEncounterNull
                              }
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-history"
                        element={
                          <>
                            <PatientHistory
                              clickedEncounter={this.state.clickedEncounter}
                              resetClickedEncounter={this.resetClickedEncounter}
                              openPatientData={this.state.displayedData}
                              matchEncounter={this.matchEncounter}
                              view={this.state.view}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-timeline"
                        element={
                          <>
                            <PatientTimeline
                              data={this.state.displayedData}
                              matchEncounter={this.matchEncounter}
                              openEncounter={this.onClickEncounter}
                              view={this.state.view}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-charts"
                        element={
                          <>
                            <Charts
                              data={this.state.displayedData}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                    </Route>
                  </Route>
                  <Route
                    path="population-analysis"
                    element={
                      <>
                        <div className="body-view-main p-4">
                          {this.returnViewWithNoDatasetSign(
                            <PopulationAnalysis />
                          )}
                        </div>
                      </>
                    }
                  >
                    <Route
                      path="pop-ana-dashboard"
                      element={
                        <>
                          <PopAnaDashboard
                            patientList={this.state.patientList}
                            popAnaDashboardData={this.state.popAnaDashboardData}
                            openDB={this.state.openDB}
                            allReasonCodes={this.state.allReasonCodes}
                            location={this.state.openDbSavingLocation}
                          />
                        </>
                      }
                    ></Route>
                    <Route
                      path="pop-ana-survival"
                      element={
                        <>
                          {this.state.fileListLoaded ? (
                            <SurvivalContainer
                              patientList={this.state.patientList}
                              openDB={this.state.openDB}
                              loadedFileList={this.state.fileList}
                              getStartPointData={this.getStartPointData}
                              startPointData={this.state.startPointData}
                              startPointDataLoaded={
                                this.state.startPointDataLoaded
                              }
                              location={this.state.openDbSavingLocation}
                            />
                          ) : (
                            <div className="body-view-main">
                              <CircularProgress
                                color="inherit"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#4169e1",
                                }}
                              />
                            </div>
                          )}
                        </>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="module-diagram"
                    element={
                      <>
                        <div className="body-view-main">
                          {this.returnViewWithNoDatasetSign(
                            <ModuleDataHandler
                              newSnackbar={this.newSnackbar}
                              patientList={this.state.patientList}
                              fileList={this.state.patientViewData}
                              openDB={this.state.openDB}
                            />
                          )}
                        </div>
                      </>
                    }
                  ></Route>
                  <Route
                    path="datasets"
                    element={
                      <>
                        <div className="body-view-main">
                          <Outlet />
                        </div>
                      </>
                    }
                  >
                    <Route
                      path="datasets-overview"
                      element={
                        <>
                          <Datasets
                            allDbs={this.state.allDbs}
                            setDetailedDbName={this.setDetailedDbName}
                            chunkSize={this.state.chunkSizeParse}
                            maxActiveChunks={this.state.maxActiveChunks}
                            openNewDb={this.openNewDb}
                            changeStateInApp={this.changeStateInApp}
                            loadingProgressSimple={
                              this.state.loadingProgressSimple
                            }
                            fileProcessingProgress={
                              this.state.fileProcessingProgress
                            }
                            fileParsing={this.state.fileParsing}
                            indexingProgress={this.state.indexingProgress}
                            postProcessingProgress={
                              this.state.postProcessingProgress
                            }
                            openDB={this.state.openDB}
                            getAllEntries={this.getAllEntries}
                            csvFiles={this.state.loadedData.csvFiles}
                            parseCSVData={this.parseCSVData}
                            addingDataStatus={this.state.addingDataStatus}
                            filesFullProcessed={this.state.filesFullProcessed}
                            connectionToMongoDB={this.state.connectionToMongoDB}
                            mongoDatabases={this.state.mongoDatabases}
                            deleteDatabaseAxios={this.deleteDatabaseAxios}
                          />
                        </>
                      }
                    ></Route>
                    <Route
                      path="dataset-details"
                      element={
                        <div className="body-view-main">
                          <DatasetDetails
                            detailedDbName={this.state.detailedDbName}
                            detailedDBLocation={this.state.detailedDBLocation}
                          />
                        </div>
                      }
                    ></Route>
                  </Route>
                </Route>
              </Routes>
            </ThemeProvider>
          </div>
        </React.StrictMode>
      );
    } else {
      return (
        <div>
          <Overlay
            isOpen={this.state.updateDataStructure}
            title="ATTENTION: Old data structure detected"
          >
            <div style={{ width: "40vw" }}>
              <p>
                We dedected that the datesets, saved in your local indexedDB use
                an older data structure then the current version of the website.
                Unfortunally you need to delete all of your saved datasets for
                the website to work.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "2vh",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 300,
                    marginRight: "2vw",
                  }}
                >
                  Delete all databases (filter profiles and filter criterions
                  will be kept):
                </p>
                <Button
                  variant="contained"
                  color="error"
                  onClick={this.deleteAllDatabases}
                >
                  Delete Databases
                </Button>
              </div>
            </div>
          </Overlay>
        </div>
      );
    }
  }
}

export default withRouter(App);
