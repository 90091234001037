// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.sticky-header {
    position: sticky;
    top: 0;
    z-index: 10; /* Höherer z-index Wert */
    background-color: white;
  }
  
  tr.sticky-header::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--divider-color);
  }`, "",{"version":3,"sources":["webpack://./src/PopulationAnalysis/Survival/SurvivalContainer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,WAAW,EAAE,yBAAyB;IACtC,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,WAAW;IACX,WAAW;IACX,sCAAsC;EACxC","sourcesContent":["tr.sticky-header {\n    position: sticky;\n    top: 0;\n    z-index: 10; /* Höherer z-index Wert */\n    background-color: white;\n  }\n  \n  tr.sticky-header::after {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 1px;\n    background-color: var(--divider-color);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
