import React, { Component } from "react";
import ReactFlowApp from "./ReactFlowApp";

import Overlay from "../Overlay/Overlay";
import hypertensionModule from "../data/synthea-modules/hypertension.json";
import laryngealModule from "../data/synthea-modules/laryngeal_cancer.json";

import db from "../utils/DexieDatabase/db";
import dagre from "dagre";
import ELK from "elkjs/lib/elk.bundled.js";

import { MarkerType } from "reactflow";

import { TextField, FormControl, Button } from "@mui/material";

const elk = new ELK();
const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": "100",
  "elk.spacing.nodeNode": "80",
  "elk.direction": "DOWN",
};

export default class ModuleDataHandler extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      edges: [],
      nodes: [],
      showReactFlow: false,
      openModule: "Laryngeal_Cancer",
      modules: [],
      isCollapsedInput: true,
      selectOpen: false,
      addNewMOpen: false,

      //drag and drop states
      isDragActive: false,
      newModule: {},
      newName: "",
      showDrag: true,
      layoutTyp: "elk",

      //match with patient states
      selectPatValue: "",
      openPat: {},
      patData: {},

      reasons: [],
    };
  }

  openOverlay = () => {
    this.setState((prevState) => ({
      addNewMOpen: !prevState.addNewMOpen,
    }));
  };

  setNewModuleName = (event) => {
    this.setState({ newName: event.target.value });
  };

  emptyPatLoadingObject = (fileList) => {
    var emptyObj = {};

    for (const key in fileList) {
      emptyObj[key] = false;
    }

    return emptyObj;
  };

  preparePatientHistory(openPatient1, fileList, dbName) {
    //gets all entries from all files that can be matched to the opened pat -> is needed to display patientView

    //set the allLoaded State to false and empty patView state
    this.setState(
      {
        allPatDatLoaded: this.emptyPatLoadingObject(fileList),
      },
      () => {
        const dbAll = new db(dbName);

        var openPatient = structuredClone(openPatient1);
        delete openPatient.CONDITIONS;

        for (const key in fileList) {
          if (key === "patient") {
            this.setState(
              (prevState) => ({
                patData: {
                  ...prevState.patData,
                  [key]: { typ: key, data: openPatient },
                },
              }),
              () => {
                this.setState((prevState) => ({
                  allPatDatLoaded: {
                    ...prevState.allPatDatLoaded,
                    [key]: true,
                  },
                }));
              }
            );
          } else {
            dbAll
              .getEntriesByIndex(key, "PATIENT", openPatient.Id)
              .then((results) => {
                this.setState(
                  (prevState) => ({
                    patData: {
                      ...prevState.patData,
                      [key]: { typ: key, data: results },
                    },
                  }),
                  () => {
                    this.setState((prevState) => ({
                      allPatDatLoaded: {
                        ...prevState.allPatDatLoaded,
                        [key]: true,
                      },
                    }));
                  }
                );
              })
              .catch((error) => {
                console.error("Fehler:", error);
              });
          }
        }
      }
    );
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- match pat to diagram functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  setNewOpenPat = (event) => {
    const selectedPatient = this.props.patientList.find(
      (item) => item.Name === event.target.value
    );

    this.setState({
      selectPatValue: event.target.value,
      openPat: selectedPatient,
    });
  };

  getAllEncountersByReason(enArray, reasonCodes) {
    let matchingEncounters = [];

    for (let i = 0; i < enArray.data.length; i++) {
      if (enArray.data[i].REASONCODE !== null) {
        for (let index = 0; index < reasonCodes.length; index++) {
          if (reasonCodes[index].code === enArray.data[i].REASONCODE) {
            matchingEncounters.push(enArray.data[i]);
            break;
          }
        }
      }
    }

    return matchingEncounters;
  }

  matchEventsToEncounter(encounterArray, data) {
    var matchedEncounters = [];
    var copyData = structuredClone(data);

    if (encounterArray.length > 0) {
      for (let i = 0; i < encounterArray.length; i++) {
        var encounterPlus = { encounter: encounterArray[i] };
        var plus = [];
        plus.push(encounterArray[i]);

        for (const key in copyData) {
          if (key !== "encounters" && key !== "patient") {
            for (
              let index = copyData[key].data.length - 1;
              index >= 0;
              index--
            ) {
              if (
                encounterArray[i].Id === copyData[key].data[index].ENCOUNTER
              ) {
                plus.push(copyData[key].data[index]); //<------------------------------------------------------------------mit Johannes angucken
                //copyData[key].data.splice(index, 1);
              }
            }
          }
        }
        encounterPlus["eventCodes"] = plus;
        matchedEncounters.push(encounterPlus);
      }
    }
    return matchedEncounters;
  }

  getReasonCodes(module) {
    //gets reason codes and names
    // in synthea a valid reason is an attribute (that stores an condition) or a State name of a CondtionOnset state
    // so this functions safes all reasons, attributes and ConditionOnsets to then match the reason with Reasondescription and ReasonCode

    let reasonArray = []; //stores reasons
    let attributes = [];
    let conditions = [];
    let reasonCodeDescription = []; //stores the reasonCodes and descriptions

    //collect reasons
    for (const key in module.data.states) {
      if (
        module.data.states[key].reason !== undefined &&
        !reasonArray.includes(module.data.states[key].reason)
      ) {
        reasonArray.push(module.data.states[key].reason);
      }

      if (module.data.states[key].type === "SetAttribute") {
        attributes.push({ name: key, data: module.data.states[key] });
      } else if (module.data.states[key].type === "ConditionOnset") {
        conditions.push({ name: key, data: module.data.states[key] });
      }
    }

    //check if reasons is attribute
    for (let i = 0; i < attributes.length; i++) {
      if (reasonArray.includes(attributes[i].data.attribute)) {
        reasonCodeDescription.push(attributes[i].data.value_code);
      }
    }

    //checks if reason is condtions
    for (let i = 0; i < conditions.length; i++) {
      if (
        reasonArray.includes(conditions[i].name) ||
        reasonArray.includes(conditions[i].data.assign_to_attribute)
      ) {
        for (let index = 0; index < conditions[i].data.codes.length; index++) {
          reasonCodeDescription.push(conditions[i].data.codes[index]);
        }
      }
    }

    this.setState({ reasons: reasonCodeDescription });
  }

  changeColorOfNode(encounters, nodes) {
    const newColor = {
      border: "1px solid #e05c97",
      backgroundColor: "#fdc4de",
    };

    let newNodes = [];
    let oldNodes = [...nodes];

    for (let i = 0; i < encounters.length; i++) {
      for (let i2 = 0; i2 < encounters[i].eventCodes.length; i2++) {
        for (let index = oldNodes.length - 1; index >= 0; index--) {
          let splice = false;

          //-------------------------------------------------------------- problem nr 1: one code can occure more then once in the module. which one is the one that the patient has?
          if (oldNodes[index].codes !== undefined) {
            for (
              let index2 = 0;
              index2 < oldNodes[index].codes.length;
              index2++
            ) {
              if (
                oldNodes[index].codes[index2].code ===
                encounters[i].eventCodes[i2].CODE
              ) {
                let newNode = oldNodes[index];
                newNode["style"] = newColor;
                newNodes.push(newNode);
                splice = true;
              }
            }
          }

          if (splice) {
            oldNodes.splice(index, 1);
          }
        }
      }
    }

    return newNodes.concat(oldNodes);
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- drag and drop functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  onDragEnter = () => {
    this.setState({ isDragActive: true });
  };

  onDragLeave = () => {
    this.setState({ isDragActive: false });
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    this.readFile(file[0]);
  };

  handleFileClick = () => {
    this.fileInput.click();
  };

  handleFileClickBtn = () => {
    this.fileInput.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files;
    this.readFile(file[0]);
  };

  readFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          this.setState({ newModule: { file: file, jsonData: json } }, () =>
            this.setState({ showDrag: false })
          );
        } catch (err) {
          alert("no json");
        }
      };
      reader.readAsText(file);
    }
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- functions to choose module ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  handleModuleSelect = (event) => {
    this.setState({ openModule: event.target.value });
  };

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        isCollapsedInput: !prevState.isCollapsedInput,
      }),
      () => {
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ selectOpen: true });
          }, 260);
      }
    );
  };

  onBlur = () => {
    this.setState({ selectOpen: false, isCollapsedInput: true });
  };

  handleOpen = () => {
    this.setState({ selectOpen: true });
  };

  handleClose = () => {
    this.setState({ selectOpen: false, isCollapsedInput: true });
    document.getElementById("select-module").blur();
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- handling local modules----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  checkForDuplicateName(name, modules) {
    // return true if a duplicate is found
    let duplicateFound = false;

    for (let i = 0; i < modules.length; i++) {
      if (modules[i] === name) {
        duplicateFound = true;
        break;
      }
    }

    return duplicateFound;
  }

  loadAndSave = () => {
    // checks if file was uploaded
    if (this.state.newModule.file.name !== undefined) {
      if (this.state.newName !== "") {
        if (
          !this.checkForDuplicateName(this.state.newName, this.state.modules)
        ) {
          try {
            this.getNodesAndEdges(this.state.newModule.jsonData.states);
          } catch (error) {
            alert(
              "Error while processing your module. Pleas check if your file might be damaged Error: " +
                error
            );
          }
          this.saveModuleInLocalS(
            this.state.newName,
            this.state.newModule.jsonData
          );

          this.setState({ openModule: this.state.newName, addNewMOpen: false });
        } else {
          alert(
            "there is already a module with the same name. please change the name"
          );
        }
      } else {
        alert("please typ in a name for the module");
      }
    } else {
      alert("please choose a file to upload.");
    }
  };

  getSavedModules() {
    const savedValues = localStorage.getItem("synteaModules");
    let dataArray = JSON.parse(savedValues);

    return dataArray;
  }

  saveModuleInLocalS(name, data) {
    const savedValues = localStorage.getItem("synteaModules");
    let dataArray = [];
    var nameAlreadyUsed = false;

    if (savedValues !== null) {
      dataArray = JSON.parse(savedValues);
      const doubleName = dataArray.find((o) => o.name === name);
      if (doubleName !== undefined) {
        nameAlreadyUsed = true;
      }
    }

    if (!nameAlreadyUsed) {
      var filterProfile = { name: name, data: data };
      dataArray.push(filterProfile);
      const dataArrayString = JSON.stringify(dataArray);

      try {
        localStorage.setItem("synteaModules", dataArrayString);
      } catch (error) {
        if (error.name === "QuotaExceededError") {
          console.error(
            "Fehler: Der verfügbare Speicherplatz des Local Storage ist überschritten."
          );
          //show snackbar
          this.props.newSnackbar(
            "error",
            "Error: The available storage space of the Local Storage has been exceeded."
          );
        } else {
          console.error(
            "Fehler beim Schreiben in den Local Storage:",
            error.message
          );
        }
      }

      //show snackbar
      this.props.newSnackbar(
        "success",
        "'" + name + "' Module has been saved."
      );

      //get all names of modules to
      const savedModules = this.getSavedModules();
      this.setState({ modules: this.returnNamesOfModules(savedModules) });
      this.setState({ value: "" });
    } else {
      //show snackbar
      this.props.newSnackbar(
        "error",
        "Error: " +
          "'" +
          name +
          "' is already used for another profile. Please choose a other name for your filter profile."
      );
    }
  }

  initializeModule(modules, name) {
    if (modules !== null && this.state.layoutTyp === "dagre") {
      let firstModule = modules.find((o) => o.name === name);
      this.getReasonCodes(firstModule);
      let nodesAndEdges = this.getNodesAndEdges(firstModule.data.states);

      this.setState(
        { edges: nodesAndEdges.edges, nodes: nodesAndEdges.nodes },
        () => {
          this.setState({ showReactFlow: true });
        }
      );
    } else if (modules !== null && this.state.layoutTyp === "elk") {
      let firstModule = modules.find((o) => o.name === name);
      this.getReasonCodes(firstModule);
      this.getNodesAndEdges(firstModule.data.states);
    } else {
      this.getReasonCodes({ data: hypertensionModule });
      let nodesAndEdges = this.getNodesAndEdges(hypertensionModule.states);
      this.setState(
        { edges: nodesAndEdges.edges, nodes: nodesAndEdges.nodes },
        () => {
          this.setState({ showReactFlow: true });
        }
      );
      this.saveModuleInLocalS("Hypertension", hypertensionModule);
      this.saveModuleInLocalS("Laryngeal_Cancer", laryngealModule);
    }
  }

  returnNamesOfModules(modules) {
    if (modules !== null) {
      let names = [];

      for (let i = 0; i < modules.length; i++) {
        names.push(modules[i].name);
      }

      return names;
    }
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- transforming module in ReactFlow syntax ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  getPositionsDagre(nodes, edges, direction = "TB") {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 300;
    const nodeHeight = 36;

    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  }

  getPositionsElkjs(nodes, edges, options = {}) {
    const isHorizontal = options?.["elk.direction"] === "RIGHT";
    const graph = {
      id: "root",
      layoutOptions: options,
      children: nodes.map((node) => ({
        ...node,
        // Adjust the target and source handle positions based on the layout
        // direction.
        targetPosition: isHorizontal ? "left" : "top",
        sourcePosition: isHorizontal ? "right" : "bottom",

        // Hardcode a width and height for elk to use when layouting.
        width: 150,
        height: 50,
      })),
      edges: edges,
    };

    return elk
      .layout(graph)
      .then((layoutedGraph) =>
        this.setState(
          {
            nodes: layoutedGraph.children.map((node) => ({
              ...node,
              // React Flow expects a position property on the node instead of `x`
              // and `y` fields.
              position: { x: node.x, y: node.y },
            })),
            edges: layoutedGraph.edges,
          },
          () => {
            this.setState({ showReactFlow: true });
          }
        )
      )
      .catch(console.error);
  }

  getPositions = (nodes, edges, directionOptions) => {
    if (this.state.layoutTyp === "dagre") {
      return this.getPositionsDagre(nodes, edges, directionOptions);
    } else if (this.state.layoutTyp === "elk") {
      let options;
      if (directionOptions === undefined) {
        options = elkOptions;
      } else {
        options = directionOptions;
      }

      return this.getPositionsElkjs(nodes, edges, options);
    }
  };

  getNodesAndEdges(states) {
    const position = { x: 0, y: 0 };
    const edgeType = "default";

    let nodes = [];
    let edges = [];
    for (const key in states) {
      //create node
      let node = {
        id: key,
        data: { label: key },
        typ: states[key].type,
        position,
      };

      //get codes of item
      let code = [];
      if (states[key].type !== "MultiObservation") {
        code = states[key].codes;
      } else {
        for (let i = 0; i < states[key].observations.length; i++) {
          for (
            let index = 0;
            index < states[key].observations[i].codes.length;
            index++
          ) {
            code.push(states[key].observations[i].codes[index]);
          }
        }
      }

      //only adds code if its not empty
      if (states[key].codes !== undefined) {
        node["codes"] = code;
      }

      nodes.push(node);

      //create edges
      const keysOfState = Object.keys(states[key]);
      let transitionKey = "";

      if (keysOfState.includes("conditional_transition")) {
        transitionKey = "conditional_transition";

        for (
          let index = 0;
          index < states[key][transitionKey].length;
          index++
        ) {
          let label = "";
          if (states[key][transitionKey][index].condition === undefined) {
            label = "else";
          } else {
            const valCon = Object.values(
              states[key][transitionKey][index].condition
            );
            label = valCon.join(" ");
          }
          let edge = {
            id: key + states[key][transitionKey][index].transition + index,
            source: key,
            target: states[key][transitionKey][index].transition,
            type: edgeType,
            label: label,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
          };
          edges.push(edge);
        }
      } else if (keysOfState.includes("direct_transition")) {
        transitionKey = "direct_transition";
        let edge = {
          id: key + states[key][transitionKey],
          source: key,
          target: states[key][transitionKey],
          type: edgeType,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        };
        edges.push(edge);
      } else if (keysOfState.includes("complex_transition")) {
        transitionKey = "complex_transition";
        for (
          let index = 0;
          index < states[key][transitionKey].length;
          index++
        ) {
          if (states[key][transitionKey].transition === undefined) {
            for (
              let i = 0;
              i < states[key][transitionKey][index].distributions.length;
              i++
            ) {
              let edge = {
                id:
                  key +
                  states[key][transitionKey][index].distributions[i]
                    .transition +
                  index +
                  i,
                source: key,
                target:
                  states[key][transitionKey][index].distributions[i].transition,
                type: edgeType,
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
              };
              edges.push(edge);
            }
          } else {
            let edge = {
              id: key + states[key][transitionKey][index].transition + index,
              source: key,
              target: states[key][transitionKey][index].transition,
              type: edgeType,
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            };
            edges.push(edge);
          }
        }
      } else if (keysOfState.includes("distributed_transition")) {
        transitionKey = "distributed_transition";
        for (
          let index = 0;
          index < states[key][transitionKey].length;
          index++
        ) {
          let edge = {
            id: key + states[key][transitionKey][index].transition + index,
            source: key,
            target: states[key][transitionKey][index].transition,
            type: edgeType,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
          };
          edges.push(edge);
        }
      } else if (keysOfState.includes("lookup_table_transition")) {
        // ----------------------------------------------------------------------------------------------------
        transitionKey = "lookup_table_transition";
      } else if (keysOfState.includes("type_of_care_transition")) {
        // ----------------------------------------------------------------------------------------------------
        transitionKey = "type_of_care_transition";
      } else {
        transitionKey = "no_transition";
      }
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } = this.getPositions(
      nodes,
      edges
    );

    return { edges: layoutedEdges, nodes: layoutedNodes };
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- react functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  componentDidMount() {
    const savedModules = this.getSavedModules();
    this.setState({ modules: this.returnNamesOfModules(savedModules) });
    this.initializeModule(savedModules, this.state.openModule);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.isCollapsedInput !== this.state.isCollapsedInput &&
      !this.state.isCollapsedInput
    ) {
      document.getElementById("select-module").focus();
    }

    if (prevState.openModule !== this.state.openModule) {
      this.setState({ showReactFlow: false }, () => {
        const savedModules = this.getSavedModules();
        let module = savedModules.find((o) => o.name === this.state.openModule);
        this.getReasonCodes(module);
        if (this.state.layoutTyp === "dagre") {
          let nodesAndEdges = this.getNodesAndEdges(module.data.states);
          this.setState(
            { edges: nodesAndEdges.edges, nodes: nodesAndEdges.nodes },
            () => {
              this.setState({ showReactFlow: true });
            }
          );
        } else if (this.state.layoutTyp === "elk") {
          this.getNodesAndEdges(module.data.states);
        }
      });
    }

    if (prevState.openPat !== this.state.openPat) {
      this.preparePatientHistory(
        this.state.openPat,
        this.props.fileList,
        this.props.openDB
      );
    }

    if (prevState.allPatDatLoaded !== this.state.allPatDatLoaded) {
      var allLoaded = true;

      for (const key in this.state.allPatDatLoaded) {
        if (!this.state.allPatDatLoaded[key]) {
          allLoaded = false;
          break;
        }
      }

      if (allLoaded) {
        this.setState({ showReactFlow: false });
        //get all encounter with the reasons used in the module
        let encounterArray = this.getAllEncountersByReason(
          this.state.patData.encounters,
          this.state.reasons
        );

        //get all events that happend in the encounter
        let encounterWithEvents = this.matchEventsToEncounter(
          encounterArray,
          this.state.patData
        );

        //change colors in diagram
        var newNodes = this.changeColorOfNode(
          encounterWithEvents,
          this.state.nodes
        );
        this.setState({ nodes: newNodes }, () => {
          this.setState({ showReactFlow: true });
        });
      }
    }
  }

  render() {
    if (this.state.showReactFlow) {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <Overlay
            isOpen={this.state.addNewMOpen}
            onClose={() => this.openOverlay()}
            title="Add Synthea Module"
          >
            <div style={{ width: "20vw" }}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <p style={{ whiteSpace: "nowrap", marginRight: "10px" }}>
                  Module name:
                </p>
                <FormControl fullWidth>
                  <TextField
                    id="textField-module-name"
                    variant="standard"
                    placeholder="Module-Name"
                    onChange={this.setNewModuleName}
                    sx={{
                      maxWidth: "8vw",
                      marginRight: "5px",
                      ".MuiInputBase-input": {
                        fontSize: "15px",
                      },
                    }}
                    size="small"
                  />
                </FormControl>
              </div>
              {this.state.showDrag ? (
                <div
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onDragOver={this.onDragOver}
                  onClick={this.handleFileClick}
                  style={dropzoneStyles}
                >
                  <p className="spacer-csv-file">
                    Drag-and-Drop or click to upload your module json file
                  </p>
                  <input
                    type="file"
                    accept=".json"
                    ref={(input) => (this.fileInput = input)}
                    onChange={this.handleFileChange}
                    style={{
                      display: "none",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 25 25"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-file-code"
                      style={{ marginRight: "10px" }}
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 13l-1 2l1 2" />
                      <path d="M14 13l1 2l-1 2" />
                    </svg>
                    {this.state.newModule.file.name}
                  </div>
                  <Button onClick={() => this.setState({ showDrag: true })}>
                    Change file
                  </Button>
                </div>
              )}
              <div>
                Please make sure all encounters in your module have a reason
                code assigned to them. Otherwise matching the patients will not
                work.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={this.loadAndSave}
                >
                  Load
                </Button>
              </div>
            </div>
          </Overlay>
          <ReactFlowApp
            edges={this.state.edges}
            nodes={this.state.nodes}
            getLayoutedElements={this.getPositions}
            moduleName={this.state.openModule}
            modules={this.state.modules}
            open={this.state.selectOpen}
            isCollapsedInput={this.state.isCollapsedInput}
            handleToggleCollapseInput={this.handleToggleCollapseInput}
            onBlur={this.onBlur}
            handleClose={this.handleClose}
            handleOpen={this.handleOpen}
            add={this.openOverlay}
            handleModuleSelect={this.handleModuleSelect}
            layoutTyp={this.state.layoutTyp}
            elkOptions={elkOptions}
            patientList={this.props.patientList}
            selectPatValue={this.state.selectPatValue}
            setNewOpenPat={this.setNewOpenPat}
          />
        </div>
      );
    }
  }
}

const dropzoneStyles = {
  border: "2px solid #cccccc",
  borderRadius: "5px",
  padding: "10px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "rgba(230, 222, 222, 0.49)",
  width: "20vw",
};
