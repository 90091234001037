import React, { Component } from "react";
import Plotly from "plotly.js-dist-min";

class PlotyJsSurvivalChart extends Component {
  renderPolt() {
    const survData = this.props.survivalData.survivalCurve;
    const censData = this.props.survivalData.censoredPoints;
    // Beispiel-Daten für Kaplan-Meier-Kurve
    var time = [0];
    var survival = [100];
    var censorX = [];
    var censorY = [];

    if (survData.length > 0) {
      for (let i = 0; i < survData.length; i++) {
        time.push(survData[i].time / 365);
        survival.push(survData[i].survival * 100);
      }

      if (censData.length > 0) {
        time.push(censData[censData.length - 1].x / 365);
        survival.push(censData[censData.length - 1].y * 100);
      }

      for (let i = 0; i < censData.length; i++) {
        censorX.push(censData[i].x / 365);
        censorY.push(censData[i].y * 100);
      }
    }

    // Erstellen der Plotly-Spuren (Traces)
    const kmCurve = {
      x: time,
      y: survival,
      mode: "lines+markers",
      type: "scatter",
      name: "Survival",
      line: { shape: "hv" }, // Treppenlinien für Kaplan-Meier
    };

    const censorPoints = {
      x: censorX,
      y: censorY,
      mode: "markers",
      type: "scatter",
      name: "Censored Daten",
      marker: { symbol: "cross", size: 8, color: "red" },
    };

    const layout = {
      title: "Kaplan-Meier survival curve",
      xaxis: { title: "Time (years)", range: [0, Math.max(...time)] },
      yaxis: { title: "Survival (%)", range: [0, 100] },
    };

    const config = {
      displayModeBar: true, // Modebar anzeigen
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "select2d",
        "autoscale2d",
        "zoomIn2d",
        "zoomOut2d",
        "zoom2d",
      ],
      displaylogo: false,
      scrollZoom: true,
    };

    Plotly.newPlot("kaplanMeierPlot", [kmCurve, censorPoints], layout, config);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.survivalData !== this.props.survivalData) {
      this.renderPolt();
    }
  }

  componentDidMount() {
    this.renderPolt();
  }

  render() {
    return (
      <div id="kaplanMeierPlot" style={{ width: "100%", height: "100%" }} />
    );
  }
}

export default PlotyJsSurvivalChart;
