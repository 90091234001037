import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import ReactFlow, {
  addEdge,
  Controls,
  Background,
  MiniMap,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from "reactflow";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import "reactflow/dist/style.css";

import AddCircleIcon from "@mui/icons-material/AddCircle";

// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html

const ReactFlowApp = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);

  const onLayout = useCallback(
    ({ direction, useInitialNodes = false }) => {
      if (props.layouType === "dagre") {
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          props.getLayoutedElements(nodes, edges, direction);
        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
      } else if (props.layouType === "elk") {
        let elkDirect = "RIGHT";
        if (direction === "TB") {
          elkDirect = "DOWN";
        }

        const opts = { "elk.direction": elkDirect, ...props.elkOptions };
        const ns = useInitialNodes ? props.nodes : nodes;
        const es = useInitialNodes ? props.edges : edges;

        props
          .getLayoutedElements(ns, es, opts)
          .then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
          });
      }
    },
    [nodes, edges]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
    >
      <Background />
      <Controls />
      <MiniMap />
      <Panel position="top-right">
        <button onClick={() => onLayout("TB")}>vertical layout</button>
        <button onClick={() => onLayout("LR")}>horizontal layout</button>
      </Panel>
      <Panel position="top-left">
        <Paper
          style={{
            fontSize: "14px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginRight: "10px" }}>Module: </p>
            <Collapse orientation="horizontal" in={props.isCollapsedInput}>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <p style={{ whiteSpace: "nowrap" }}>{props.moduleName}</p>
                <IconButton
                  aria-label="openInput"
                  onClick={props.handleToggleCollapseInput}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </Collapse>
            <Collapse orientation="horizontal" in={!props.isCollapsedInput}>
              <FormControl fullWidth>
                <Select
                  id="select-module"
                  value={props.moduleName}
                  open={props.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                  onClose={props.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                  onOpen={props.handleOpen}
                  onChange={props.handleModuleSelect}
                  onBlur={props.onBlur}
                  autoFocus
                  sx={{
                    marginTop: "4px",
                    height: "35px", // Ändere die Mindesthöhe nach Bedarf
                    fontSize: "13px",
                    width: "6.8vw",
                  }}
                >
                  {props.modules.map((item) => (
                    <MenuItem
                      value={item}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
            <IconButton
              aria-label="add"
              onClick={props.add}
              style={{ height: "25px", width: "25px" }}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
          <div style={{ textAlign: "left" }}>
            <p>Match with Patient:</p>
            <FormControl fullWidth>
              <Select
                id="select-patient"
                value={props.selectPatValue}
                onChange={props.setNewOpenPat}
                sx={{
                  marginTop: "4px",
                  height: "35px", // Ändere die Mindesthöhe nach Bedarf
                  fontSize: "13px",
                }}
              >
                {props.patientList.map((item) => (
                  <MenuItem
                    value={item.Name}
                    data-item={item.Name}
                    key={item.Id}
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Paper>
      </Panel>
    </ReactFlow>
  );
};

export default ReactFlowApp;
