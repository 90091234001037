import React, { Component } from "react";
import Overlay from "../../Overlay/Overlay";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Table,
} from "@mui/material";

import FileColumValueSearchNavigator from "../../utils/FileColumValueSearchNavigator";
import PlotyJsSurvivalChart from "./PlotyJsSurvivalChart";

import KaplanMeier from "../../utils/PopAnalyser/KaplanMeier";

import EditIcon from "@mui/icons-material/Edit";

import "./SurvivalContainer.css";

const controlItem = { display: "flex", justifyContent: "space-between" };

export default class SurvivalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //edit teh start point states
      editStart: false,
      startPointFileNavigatorState: {
        file: "conditions",
        colum: "DESCRIPTION",
        value: "laryngeal cancer",
      },

      //survival data
      survivalData: {},
      survDataLoaded: false,
    };
  }

  openEditStart = () => {
    this.setState((prevState) => ({ editStart: !prevState.editStart }));
  };

  callBack = (state) => {
    this.setState({ startPointFileNavigatorState: state });
  };

  dateDifferenceInDays(date1, date2) {
    // Berechne den Zeitunterschied in Millisekunden
    const differenceInTime = date2.getTime() - date1.getTime();

    // Berechne den Zeitunterschied in Tagen
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  }

  matchStartWPatData = (patientList, startData) => {
    var copyStartData = [...startData];
    var survivalData = [];

    for (let i = 0; i < patientList.length; i++) {
      let fitting = false;
      let startDate;

      for (let index = 0; index < copyStartData.length; index++) {
        if (copyStartData[index].PATIENT === patientList[i].Id) {
          fitting = true;
          if (copyStartData[index].START !== undefined) {
            startDate = copyStartData[index].START;
          } else {
            startDate = copyStartData[index].DATE;
          }
          copyStartData.splice(index, 1);
          break;
        }
      }

      if (fitting) {
        let death = 0;
        let lastDay = new Date(patientList[i].Age.estimatedEndOfSimulation);
        if (patientList[i].Deathdate !== null) {
          death = 1;
          lastDay = patientList[i].Deathdate;
        }

        let timeEvent = {
          time: this.dateDifferenceInDays(startDate, lastDay),
          event: death,
        };

        survivalData.push(timeEvent);
      }
    }

    const km = new KaplanMeier();

    const survivalOverall = km.kaplanMeierAnalysis(
      survivalData,
      "time",
      "event"
    );

    return survivalOverall;
  };

  map1510Survival(survData) {
    var displayDat = [];
    var displayName = {
      oneYearSurvival: "1-year",
      fiveYearSurvival: "5-year",
      tenYearSurvival: "10-year",
    };

    for (const key in survData) {
      if (key !== "survivalCurve") {
        var dat = { name: displayName[key], data: survData[key] };
        displayDat.push(dat);
      }
    }

    return displayDat;
  }

  componentDidMount() {
    if (this.state.startPointFileNavigatorState.file !== "") {
      this.props.getStartPointData(this.state.startPointFileNavigatorState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editStart !== this.state.editStart && !this.state.editStart) {
      this.props.getStartPointData(this.state.startPointFileNavigatorState);
    }

    if (
      prevProps.startPointDataLoaded !== this.props.startPointDataLoaded &&
      this.props.startPointDataLoaded
    ) {
      const kmData = this.matchStartWPatData(
        this.props.patientList,
        this.props.startPointData
      );

      this.setState({ survivalData: kmData }, () => {
        this.setState({ survDataLoaded: true });
      });
    }
  }

  render() {
    return (
      <div
        style={{
          paddingLeft: "15px",
          width: "100%",
          height: "100%",
        }}
      >
        <Overlay
          isOpen={this.state.editStart}
          onClose={() => this.openEditStart()}
          title="Choose a start point for the survival analysis"
        >
          <div style={{ padding: "15px", fontWeight: 300, width: "50vw" }}>
            <p>
              Please select the event that should represent the starting point
              of your survival time analyses. For example, if you are interested
              in how long a patient survives after being diagnosed with
              laryngeal carcinoma, select laryngeal carcinoma under conditions.
            </p>
            <p style={{ marginTop: "10px" }}>
              Make sure to choose an unique event that only occurs only once in
              a patients lifetime. Otherwise he survival analysis will give
              wrong results.
            </p>
            <p style={{ marginTop: "10px" }}>
              All patients who have not completed this event are automatically
              excluded from the survival analysis.
            </p>
          </div>
          <FileColumValueSearchNavigator
            patientList={this.props.patientList}
            openDB={this.props.openDB}
            loadedFileList={this.props.loadedFileList}
            callBack={this.callBack}
            prevChoice={this.state.startPointFileNavigatorState}
            location={this.props.location}
            disablePat={true}
          />
        </Overlay>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100% - 25px)",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "30%",
              height: "100%",
              textAlign: "left",
              fontWeight: 300,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                textAlign: "left",
                height: "25px",
              }}
            >
              Survival analyses
            </p>
            <div
              style={{
                width: "100%",
              }}
            >
              <div style={controlItem}>
                <p>Method:</p> <p>Kaplan-Meier-Analysis</p>
              </div>

              <div>Excluding Patients by:</div>
              <div style={controlItem}>
                <p>Analysis start point:</p>{" "}
                <div style={{ display: "flex" }}>
                  <p>
                    {this.state.startPointFileNavigatorState.file !== "" ? (
                      <span>
                        {this.state.startPointFileNavigatorState.file},{" "}
                        {this.state.startPointFileNavigatorState.colum} ={" "}
                        {this.state.startPointFileNavigatorState.value}
                      </span>
                    ) : (
                      "non chosen"
                    )}
                  </p>
                  <IconButton
                    sx={{ height: "25px", width: "25px", marginLeft: "10px" }}
                    onClick={this.openEditStart}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              <div>Stratify by:</div>
            </div>
            <Divider sx={{ margin: "10px" }} />
            <div>
              <p
                style={{
                  marginBottom: "5px",
                  fontSize: "17px",
                  fontWeight: 400,
                }}
              >
                1-, 5- and 10-year survival
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {this.state.survDataLoaded ? (
                  this.map1510Survival(this.state.survivalData.yearSurv).map(
                    (su) => (
                      <p>
                        {su.name}: {su.data}%
                      </p>
                    )
                  )
                ) : (
                  <CircularProgress size={20} />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <p
                style={{
                  marginBottom: "5px",
                  fontSize: "17px",
                  fontWeight: 400,
                }}
              >
                Detailed data
              </p>
              <p>
                N (total) ={" "}
                {this.state.survDataLoaded ? (
                  this.state.survivalData.n
                ) : (
                  <CircularProgress size={10} />
                )}
              </p>
            </div>
            <div
              style={{ flexGrow: 1, overflowY: "scroll", marginTop: "10px" }}
            >
              {this.state.survDataLoaded ? (
                <table
                  style={{
                    width: "100%",
                  }}
                >
                  <tr className="sticky-header">
                    <td>Time (Days)</td>
                    <td>Survival (%)</td>
                    <td>N</td>
                    <td>Events</td>
                  </tr>
                  {this.state.survivalData.survivalCurve.map((item) => (
                    <tr>
                      <td>{item.time.toFixed(1)}</td>
                      <td>{(item.survival * 100).toFixed(1)}</td>
                      <td>{item.nAtRisk}</td>
                      <td>{item.events}</td>
                    </tr>
                  ))}
                </table>
              ) : (
                <CircularProgress size={20} />
              )}
            </div>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginLeft: "25px", marginRigth: "15px" }}
          />
          <div style={{ flexGrow: 1, height: "100%" }}>
            {this.state.survDataLoaded ? (
              <PlotyJsSurvivalChart survivalData={this.state.survivalData} />
            ) : (
              <CircularProgress size={50} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
