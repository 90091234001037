import React, { Component } from "react";

export default class PaitentRecordsSettings extends Component {
  render() {
    return (
      <div style={{ fontFamily: "Spline Sans, sans-serif" }}>
        <p
          style={{
            color: "var(--primary-color)",
            fontSize: "25px",
            marginBottom: "10px",
          }}
        >
          Overview
        </p>
        <div>
          <p style={{ color: "var(--secondary-color)", fontSize: "20px" }}>
            Important Diagnosis
          </p>
          <div style={{ marginLeft: "10px" }}>
            <p style={{ fontWeight: 300 }}>
              Choose the code of diagnoses you are not interested in. They will
              be excluded and not longer shown in the "Important Diagnosis"
              colum.
            </p>
            <div style={{ fontSize: "13px", fontWeight: 300 }}>
              {this.props.boringDiagnoses.join(", ")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
