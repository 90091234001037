// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Geist[wght].woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles/fonts.css */  
  @font-face {
    font-family: 'Geist Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2-variations');
    font-weight: 100 900; /* Ermöglicht flexibles Gewicht von 100 bis 900 */
    font-style: normal;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,qBAAqB;EACnB;IACE,yBAAyB;IACzB,uEAA2E;IAC3E,oBAAoB,EAAE,iDAAiD;IACvE,kBAAkB;EACpB","sourcesContent":["/* styles/fonts.css */  \n  @font-face {\n    font-family: 'Geist Sans';\n    src: url('../../public/fonts/Geist[wght].woff2') format('woff2-variations');\n    font-weight: 100 900; /* Ermöglicht flexibles Gewicht von 100 bis 900 */\n    font-style: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
