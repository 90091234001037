import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemButton,
  Skeleton,
  Collapse,
  CircularProgress,
} from "@mui/material";

import DonutChartChartJs from "../Charts/DonutChartChartJs/DountChartChartJs";

import "./AgeDisStyles.css";

import React, { Component } from "react";

export default class PopAnaDashboardMostPopular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popAnaDashboardData: {
        conditions: { data: [], labels: [], topFivePer: [], processed: false },
        procedures: { data: [], labels: [], topFivePer: [], processed: false },
        encounters: { data: [], labels: [], topFivePer: [], processed: false },
        observations: {
          data: [],
          labels: [],
          topFivePer: [],
          processed: false,
        },
      },
      openFrequency: {
        Conditions: true,
        Procedures: false,
        Encounters: false,
        Observations: false,
      },
      chartFrequency: "Conditions",
      frequencies: ["Conditions", "Procedures", "Encounters", "Observations"],
    };
  }

  truncateString(str) {
    if (str.length > 45) {
      return str.substring(0, 42) + "...";
    }
    return str;
  }

  openList = (frequency) => {
    var oldState = structuredClone(this.state.openFrequency);
    for (const key in oldState) {
      if (key !== frequency) {
        oldState[key] = false;
      } else {
        oldState[key] = true;
      }
    }
    this.setState({ openFrequency: oldState, chartFrequency: frequency });
  };

  calculatePer(data) {
    for (const key in data) {
      let totalNo = 0;
      let NoOther = 0;
      for (let i = 0; i < data[key].data.length; i++) {
        totalNo += data[key].data[i];
        if (i >= 4) {
          NoOther += data[key].data[i];
        }
      }

      let topFivePer = [];
      for (let i = 0; i < 5; i++) {
        if (i < 4) {
          var per = data[key].data[i] / totalNo;
          topFivePer.push(per);
        } else {
          var per = NoOther / totalNo;
          topFivePer.push(per);
        }
      }
      data[key]["topFivePer"] = topFivePer;
    }

    return data;
  }

  componentDidMount() {
    this.setState({
      popAnaDashboardData: this.calculatePer(this.props.popAnaDashboardData),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.popAnaDashboardData !== this.props.popAnaDashboardData) {
      this.setState({
        popAnaDashboardData: this.calculatePer(this.props.popAnaDashboardData),
      });
    }
  }

  render() {
    const styleLeft = {};

    const styleRight = {
      textAlign: "right",
      marginTop: "0",
      marginBottom: "0",
    };

    const styleChart = {
      width: "200px",
      marginLeft: "10px",
      marginTop: "10px",
    };

    const backgroundColors = [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "rgb(158, 180, 197)",
    ];

    return (
      <div style={{ width: "100%", height: "100%", marginBottom: "15px" }}>
        <p
          style={{
            textAlign: "left",
            fontFamily: "Spline Sans",
            lineHeight: "2rem",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "14px",
            marginLeft: "15px",
          }}
        >
          Most frequent events
        </p>
        <Divider variant="middle" />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={styleChart}>
            {this.state.popAnaDashboardData[
              this.state.chartFrequency.toLowerCase()
            ].processed ? (
              <DonutChartChartJs
                data={
                  this.state.popAnaDashboardData[
                    this.state.chartFrequency.toLowerCase()
                  ].data
                }
                labels={
                  this.state.popAnaDashboardData[
                    this.state.chartFrequency.toLowerCase()
                  ].labels
                }
              />
            ) : (
              <Skeleton variant="circular" width={200} height={200} />
            )}
          </div>
          <List dense sx={{ flexGrow: 1 }}>
            {this.state.frequencies.map((frequency, index) => (
              <div>
                {this.state.openFrequency[frequency] && index > 0 && (
                  <Divider variant="middle" />
                )}
                <ListItemButton onClick={() => this.openList(frequency)}>
                  <ListItemText sx={styleLeft}>
                    <span
                      style={{
                        color:
                          this.state.chartFrequency === frequency
                            ? "var(--primary-color)"
                            : "black",
                        fontWeight:
                          this.state.chartFrequency === frequency
                            ? "400"
                            : "300",
                      }}
                    >
                      {frequency}:
                    </span>
                  </ListItemText>
                  <ListItemText sx={styleRight}>
                    {this.state.popAnaDashboardData[frequency.toLowerCase()]
                      .processed ? (
                      this.state.popAnaDashboardData[frequency.toLowerCase()]
                        .data.length > 0 ? (
                        <span
                          style={{
                            color:
                              this.state.chartFrequency === frequency
                                ? "var(--primary-color)"
                                : "black",
                            fontWeight:
                              this.state.chartFrequency === frequency
                                ? "400"
                                : "300",
                          }}
                        >
                          {this.truncateString(
                            this.state.popAnaDashboardData[
                              frequency.toLowerCase()
                            ].labels[0]
                          ) +
                            " (" +
                            (
                              this.state.popAnaDashboardData[
                                frequency.toLowerCase()
                              ].topFivePer[0] * 100
                            ).toFixed(1) +
                            "%)"}
                        </span>
                      ) : (
                        <span>no data</span>
                      )
                    ) : (
                      <CircularProgress color="inherit" size={15} />
                    )}
                  </ListItemText>
                </ListItemButton>
                <Collapse
                  in={this.state.openFrequency[frequency]}
                  timeout="auto"
                  unmountOnExit
                  sx={{ paddingLeft: "7px" }}
                >
                  <div
                    style={{
                      minHeight: "60px",
                      maxHeight: "80px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                      overflowY: "auto",
                      paddingRight: "17px",
                      paddingLeft: "17px",
                    }}
                  >
                    {this.state.popAnaDashboardData[frequency.toLowerCase()]
                      .processed ? (
                      this.state.popAnaDashboardData[frequency.toLowerCase()]
                        .data.length > 0 ? (
                        this.state.popAnaDashboardData[
                          frequency.toLowerCase()
                        ].labels
                          .slice(0, 4)
                          .map((label, index) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                lineHeight: 1,
                                fontSize: "13px",
                                fontWeight: "300",
                              }}
                            >
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  backgroundColor: backgroundColors[index],
                                  marginRight: "8px",
                                }}
                              ></div>
                              <div style={{ textAlign: "left" }}>
                                {label +
                                  " (" +
                                  (
                                    this.state.popAnaDashboardData[
                                      frequency.toLowerCase()
                                    ].topFivePer[index] * 100
                                  ).toFixed(1) +
                                  "%)"}
                              </div>
                            </div>
                          ))
                      ) : (
                        <span>no data</span>
                      )
                    ) : (
                      <Skeleton
                        variant="rounded"
                        sx={{ width: "100%", height: "55px", marginTop: "5px" }}
                      />
                    )}
                    {this.state.popAnaDashboardData[frequency.toLowerCase()]
                      .processed &&
                      this.state.popAnaDashboardData[frequency.toLowerCase()]
                        .labels.length > 4 &&
                      (this.state.popAnaDashboardData[frequency.toLowerCase()]
                        .data.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            lineHeight: 1,
                            fontSize: "13px",
                            fontWeight: "300",
                          }}
                        >
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: backgroundColors[4],
                              marginRight: "8px",
                            }}
                          ></div>
                          <div style={{ textAlign: "left" }}>
                            Other
                            {" (" +
                              (
                                this.state.popAnaDashboardData[
                                  frequency.toLowerCase()
                                ].topFivePer[4] * 100
                              ).toFixed(1) +
                              "%)"}
                          </div>
                        </div>
                      ) : (
                        <span>no data</span>
                      ))}
                  </div>
                  <Divider
                    sx={{ width: "95%", marginLeft: "1.5%", marginTop: "5px" }}
                  />
                </Collapse>
              </div>
            ))}
          </List>
        </div>
      </div>
    );
  }
}

/**
 *  <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "10px",
                    height: "40px",
                    overflowY: "auto",
                  }}
                >
                  {this.state.popAnaDashboardData.conditions.labels
                    .slice(0, 4)
                    .map((label, index) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          lineHeight: 1,
                          fontSize: "13px",
                          fontWeight: "300",
                        }}
                      >
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            backgroundColor: backgroundColors[index],
                            marginRight: "8px",
                          }}
                        ></div>
                        <div>{label}</div>
                      </div>
                    ))}
                </div>
 */
