import React, { Component } from "react";

import {
  Chart as ChartJs,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

import "chartjs-adapter-date-fns";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BarCharts from "./BarCharts";
import LineCharts from "./LineCharts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ViewStreamRoundedIcon from "@mui/icons-material/ViewStreamRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";

import Grid from "@mui/material/Grid2"; // Grid version 2

import {
  IconButton,
  Chip,
  Collapse,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

import "./Charts.css";

ChartJs.register(
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ flexGrow: 1, overflow: "hidden" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height: "100%", overflowY: "auto" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default class Charts extends Component {
  constructor(props) {
    super(props);

    const observationsNum = this.filterNumObservations(
      this.props.data.observations
    );

    this.state = {
      chartTyp: "line",
      dataTyp: ["encounters"],
      label: [],
      datasets: [],
      observationsNum: observationsNum,
      openObservation: [[observationsNum[0]]],
      dataProcessed: false,
      tabValue: 0,
      isCollapsed: false,
      noLine: 1,
      noBar: 1,
      layout: "list", //can be list or grid
    };

    this.handleChangeBar = this.handleChangeBar.bind(this);
    this.handleChangeLine = this.handleChangeLine.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleChangeLayout = (layout) => {
    this.setState({ layout: layout }, this.forceUpdate());
  };

  handleChangeSelect = (event) => {
    if (this.state.tabValue === 0) {
      let newData = [...this.state.openObservation];
      var difference = newData.length - event.target.value;
      if (difference < 0) {
        for (let index = 0; index < difference * -1; index++) {
          newData.push([this.state.observationsNum[0]]);
        }
      } else if (difference > 0) {
        newData = [...this.state.openObservation].slice(0, difference * -1);
      }

      this.setState({ noLine: event.target.value, openObservation: newData });
    } else {
      let newData = [...this.state.dataTyp];
      var difference = newData.length - event.target.value;
      if (difference < 0) {
        for (let index = 0; index < difference * -1; index++) {
          newData.push("encounters");
        }
      } else if (difference > 0) {
        newData = [...this.state.dataTyp].slice(0, difference * -1);
      }

      this.setState({
        noBar: event.target.value,
        dataTyp: newData,
      });
    }
  };

  handleToggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  filterNumObservations(observations) {
    var numObservations = [];
    if (observations !== undefined) {
      for (let i = 0; i < observations.data.length; i++) {
        if (observations.data[i].TYPE === "numeric") {
          numObservations.push(observations.data[i]);
        }
      }

      return numObservations.sort(function (a, b) {
        if (a.DESCRIPTION > b.DESCRIPTION) {
          return +1;
        }
        if (a.DESCRIPTION < b.DESCRIPTION) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      return numObservations;
    }
  }

  getAllKindsOfObservations(observations) {
    let observationCoded = [];
    let observationArray = [];

    for (let i = 0; i < observations.length; i++) {
      if (!observationCoded.includes(observations[i].CODE)) {
        observationCoded.push(observations[i].CODE);
        observationArray.push(observations[i]);
      }
    }

    return observationArray.sort(function (a, b) {
      if (a.DESCRIPTION > b.DESCRIPTION) {
        return +1;
      }
      if (a.DESCRIPTION < b.DESCRIPTION) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  handleChangeBar = (e) => {
    this.setState((prevState) => ({
      dataTyp: prevState.dataTyp.map((element, i) =>
        i === e.target.name ? e.target.value : element
      ),
    }));
  };

  handleChangeLine = (e) => {
    var openObservation = this.state.observationsNum.find(
      (o) => o.DESCRIPTION === e.target.value
    );

    var newOpenObservationArray = [
      ...this.state.openObservation[e.target.name],
    ];

    newOpenObservationArray.push(openObservation);

    this.setState(
      (prevState) => ({
        openObservation: prevState.openObservation.map((element, i) =>
          i === e.target.name ? newOpenObservationArray : element
        ),
      }),
      () => this.handleToggleCollapse()
    );
  };

  removeObservation = (openObservation, index, indexChart) => {
    var newOpenObservations = [...openObservation[indexChart]];
    newOpenObservations.splice(index, 1);
    this.setState((prevState) => ({
      openObservation: prevState.openObservation.map((element, i) =>
        i === indexChart ? newOpenObservations : element
      ),
    }));
  };

  prepareDataTypSelect(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "patient") {
        displayArray.push(key);
      }
    }

    return displayArray;
  }

  displayEmptySelect(openObservation, index) {
    if (openObservation[index].DESCRIPTION === undefined) {
      return (
        <option value="none" selected disabled hidden>
          Select an Observation
        </option>
      );
    }
  }

  componentDidMount() {
    this.props.scrollToTopFast();
    this.props.whichLocationWhenMounted();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const observationsNum = this.filterNumObservations(
        this.props.data.observations
      );

      this.setState({ observationsNum: observationsNum });
    }
  }

  render() {
    const colorsBar = [
      "rgb(10,169,222)",
      "rgb(249,227,12)",
      "rgb(217,70,140)",
      "rgb(246,220,228)",
      "rgb(0,114,184)",
      "rgb(210,210,235)",
      "rgb(232,92,34)",
      "rgb(119,66,73)",
    ];

    const { isCollapsed } = this.state;

    return (
      <Box className="overflow-y-hidden h-full w-full flex flex-col">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab
              label="LINE-CHART"
              {...a11yProps(0)}
              style={{
                fontSize: "14px",
                fontFamily: "Spline Sans",
                paddingTop: "2px",
                paddingBottom: "2px",
                lineHeight: "1rem",
              }}
            />
            <Tab
              label="FREQUENCIES"
              {...a11yProps(1)}
              style={{
                fontSize: "14px",
                fontFamily: "Spline Sans",
                paddingTop: "2px",
                paddingBottom: "2px",
                lineHeight: "1rem",
              }}
            />
          </Tabs>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
              fontSize: "14px",
              lineHeight: "1rem",
            }}
          >
            <p className="font-light mr-4">No. of charts</p>
            <FormControl variant="standard" sx={{ width: 50 }}>
              {/* Verwende Box als flexibles Container-Element */}

              {/* Select ohne Label-Prop */}
              <Select
                id="demo-simple-select-filled"
                value={
                  this.state.tabValue === 0
                    ? this.state.noLine
                    : this.state.noBar
                }
                onChange={this.handleChangeSelect}
                sx={{ flexGrow: 1 }} // Optional, um sicherzustellen, dass das Select den restlichen Platz einnimmt
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
            <p className="font-light ml-7 mr-3">Layout</p>
            <IconButton
              onClick={() => this.handleChangeLayout("list")}
              sx={{
                width: "25px",
                height: "25px",
                color:
                  this.state.layout === "list" ? "var(--primary-color)" : "",
              }}
            >
              <ViewStreamRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => this.handleChangeLayout("grid")}
              sx={{
                width: "25px",
                height: "25px",
                marginLeft: "5px",
                color:
                  this.state.layout === "grid" ? "var(--primary-color)" : "",
              }}
            >
              <GridViewRoundedIcon />
            </IconButton>
          </Box>
        </Box>
        <CustomTabPanel value={this.state.tabValue} index={0}>
          <Grid container spacing={2}>
            {this.state.openObservation.map((openObservation, i) => (
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "57vh",
                  minHeight: "400px",
                }}
                size={{
                  xs: this.state.layout === "list" ? 12 : 6,
                }}
              >
                <div className="flex-grow flex-shrink h-full">
                  <LineCharts
                    data={this.props.data}
                    openObservation={openObservation}
                    observationsNum={this.state.observationsNum}
                    showLegend={true}
                    title={"Chart " + (i + 1)}
                  />
                </div>
                <div className="w-full ml-2">
                  <div style={{ height: "44px" }}></div>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 300,
                      marginBottom: "5px",
                    }}
                  >
                    Options for Chart {i + 1}:
                  </p>
                  <div className="charts-options-div">
                    {this.state.openObservation[i].map((options, index) => (
                      <Chip
                        label={options.DESCRIPTION}
                        onDelete={() =>
                          this.removeObservation(
                            this.state.openObservation,
                            index,
                            i
                          )
                        }
                      />
                    ))}
                    {isCollapsed ? (
                      <></>
                    ) : (
                      <IconButton
                        aria-label="add"
                        onClick={this.handleToggleCollapse}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    )}

                    <Collapse orientation="horizontal" in={isCollapsed}>
                      <Select
                        id="select"
                        onChange={this.handleChangeLine}
                        name={i}
                        sx={{
                          marginLeft: "10px",
                          height: "35px",
                          minWidth: "120px",
                        }}
                      >
                        {this.getAllKindsOfObservations(
                          this.state.observationsNum
                        ).map((item) => (
                          <MenuItem value={item.DESCRIPTION}>
                            {item.DESCRIPTION}
                          </MenuItem>
                        ))}
                      </Select>
                    </Collapse>

                    {isCollapsed ? (
                      <IconButton
                        aria-label="add"
                        onClick={this.handleToggleCollapse}
                      >
                        <CancelIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={this.state.tabValue} index={1}>
          <Grid container spacing={2}>
            {this.state.dataTyp.map((dataTyp, index) => (
              <Grid
                style={{
                  display: "flex",
                  height: "100%",
                }}
                size={this.state.layout === "list" ? 12 : 6}
              >
                <div className="flex-grow">
                  <BarCharts
                    data={this.props.data}
                    dataTyp={dataTyp}
                    color={colorsBar[index]}
                  />
                </div>
                <div className="w-[300px] ml-2">
                  <div
                    style={{
                      height: "44px",
                    }}
                  ></div>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 300,
                      marginBottom: "5px",
                    }}
                  >
                    Options for Chart {index + 1}:
                  </p>

                  <Select
                    name={index}
                    id={index}
                    value={dataTyp.toString()}
                    onChange={this.handleChangeBar}
                    sx={{
                      marginLeft: "10px",
                      height: "35px",
                      minWidth: "120px",
                    }}
                  >
                    {this.prepareDataTypSelect(this.props.data).map(
                      (select) => (
                        <MenuItem value={select.toString()}>
                          {select.toString()}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
      </Box>
    );
  }
}
