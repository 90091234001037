import React, { Component } from "react";
import SmsFailedRoundedIcon from "@mui/icons-material/SmsFailedRounded";
import { Link } from "react-router-dom";

export default class NoDBselectedText extends Component {
  render() {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex items-center gap-2">
          <SmsFailedRoundedIcon
            sx={{
              color: "var(--primary-color)",
              height: "100px",
              width: "100px",
            }}
          />
          <p style={{ fontSize: "28px", minWidth: "500px", width: "50vw" }}>
            No Dataset selected. Please go to the dataset section and loaded one
            of the available datasets or press{" "}
            {
              <Link
                to="/syn-viewer/datasets/datasets-overview"
                style={{ color: "var(--primary-color)" }}
              >
                here
              </Link>
            }
            .
          </p>
        </div>
      </div>
    );
  }
}
