import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DragItem = ({ data, setActiveItem, activeItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: data.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const changeBackgroundColor = (id) => {
    if (id === activeItem) {
      return { backgroundColor: "#4361ee" };
    } else {
      return {};
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, daher +1 und führende Nullen hinzufügen
    const day = String(date.getDate()).padStart(2, "0"); // Führende Nullen hinzufügen, wenn der Tag einstellig ist
    return `${year}-${month}-${day}`;
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {data.data === undefined ? (
        <div>{data.display}</div>
      ) : (
        <div
          className="argument-filter"
          onMouseDown={() => setActiveItem(data.id)}
          style={changeBackgroundColor(data.id)}
        >
          {data.data.column} {data.data.operator}{" "}
          {data.data.inputTyp === "date"
            ? formatDate(data.data.value)
            : data.data.value}
        </div>
      )}
    </div>
  );
};

export default DragItem;
