import React, { Component } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  IconButton,
  Switch,
  Chip,
  Collapse,
  Autocomplete,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Button,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import FilterSaver from "./FilterSaver";

import "./Filter.css";
import Overlay from "../Overlay/Overlay";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeOArray: [],
      includeOArrayNew: false,
      includeAllDataOfEncounter: false,
      excludeArray: [],
      excludeArrayNew: false,
      isCollapsedI: true,
      isCollapsedE: true,
      changedFromFilterProfile: false,
      includeReasons: [],
      collapseInReasons: true,
      open: false, // Zustand für das Dropdown-Menü
      valueSelect: "",
      filterSaverName: undefined,
      reloadedFilterSaver: undefined,
      filterOnSliderChange: false,
      showIncludeOverlay: false,
      includeSelectMode: "codeDescription",
      includeAutocompleteValue: null,
      showExcludeOverlay: false,
      excludeSelectMode: "codeDescription",
      excludeAutocompleteValue: null,
    };
    this.changeToChangedProfile = this.changeToChangedProfile.bind(this);
    this.changeFromFilterProfile = this.changeFromFilterProfile.bind(this);
  }

  /*-------------------------------------------- handel Collapse ------------------------------------------------------------------------------------------------------------ */

  handleToggleCollapseI = () => {
    this.setState((prevState) => ({
      isCollapsedI: !prevState.isCollapsedI,
    }));
  };

  handleToggleCollapseE = () => {
    this.setState((prevState) => ({
      isCollapsedE: !prevState.isCollapsedE,
    }));
  };

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        collapseInReasons: !prevState.collapseInReasons,
      }),
      () => {
        document.getElementById("include-by-reason").focus();
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  onBlur = () => {
    this.setState({ open: false, collapseInReasons: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, collapseInReasons: true });
    document.getElementById("include-by-reason").blur();
  };

  /*-------------------------------------------- add/remove to filter arrays------------------------------------------------------------------------------------------------------------ */

  removeFromFilterArray(filterArray, filterName, item) {
    let oldArray = [...filterArray];
    var index = oldArray.indexOf(item);
    oldArray.splice(index, 1);
    this.setState({ displayDataUploaded: false }, () => {
      this.setState({ [filterName]: oldArray });
    });
  }

  addToFilterArray(value, filterName) {
    if (value === null) {
      return;
    }

    let oldArray = [...this.state[filterName]];
    var isItemInArray = oldArray.find((o) => o.code === value.code);
    if (isItemInArray === undefined) {
      oldArray.push(value);
      this.setState({
        [filterName]: oldArray,
        isCollapsedI: true,
        isCollapsedE: true,
      });
      if (filterName === "includeOArray") {
        this.setState({ showIncludeOverlay: false });
      } else if (filterName === "excludeArray") {
        this.setState({ showExcludeOverlay: false });
      }
    } else {
      alert("Item is already in filter list! Please try again.");
      return;
    }
  }

  /*-------------------------------------------- include all ------------------------------------------------------------------------------------------------------------ */
  changeCheckbox = () => {
    if (this.state.includeAllDataOfEncounter) {
      this.setState({ includeAllDataOfEncounter: false });
    } else {
      this.setState({ includeAllDataOfEncounter: true });
    }
  };
  /*-------------------------------------------- filter functions ------------------------------------------------------------------------------------------------------------ */

  excludeFilter(data, filterArray) {
    let filterData = {};
    let encountersArray = [];

    //figure out if any filterItem is an encounter
    for (let i = 0; i < data["encounters"].data.length; i++) {
      let encounter = data["encounters"].data[i];

      filterArray.forEach((filter) => {
        if (
          (filter.typ === "codeDesc" &&
            encounter.CODE === filter.code &&
            !encountersArray.some((item) => item.Id === encounter.Id)) ||
          (filter.typ === "reasoncode" &&
            encounter.REASONCODE === filter.code &&
            !encountersArray.some((item) => item.Id === encounter.Id))
        ) {
          encountersArray.push(encounter);
        }
      });
    }

    //start filtering
    for (const key in data) {
      if (key === "patient") {
        filterData[key] = data[key];
      } else {
        var filteredItems = [];
        for (let i = 0; i < data[key].data.length; i++) {
          var isOk = true;
          //check if data is in encounter that should be filtered out
          var isFromFilteredOutEncounter = encountersArray.find(
            (o) => o.Id === data[key].data[i].ENCOUNTER
          );

          if (isFromFilteredOutEncounter !== undefined) {
            isOk = false;
          } else {
            //check if data shall be filtered out by code
            for (let index = 0; index < filterArray.length; index++) {
              if (
                (filterArray[index].typ === "codeDesc" &&
                  data[key].data[i].CODE === filterArray[index].code) ||
                (filterArray[index].typ === "reasoncode" &&
                  data[key].data[i].REASONCODE === filterArray[index].code)
              ) {
                isOk = false;
                break;
              }
            }
          }

          if (isOk) {
            filteredItems.push(data[key].data[i]);
          }
        }

        filterData[key] = { typ: key, data: filteredItems };
      }
    }

    return filterData;
  }

  includeOArray(data, filterArray) {
    let filterData = {};
    let encountersArray = []; //holds all fitting encounters -> they are checked in case include all of encounter is active
    let additionalEncounter = []; //holds encounters that are not fitting but needs to be included because it holds a fitting event (observation, ....) -> needed so that not all events of encounter is added

    if (filterArray.length > 0) {
      //first check for fitting encounter
      for (let i = 0; i < data["encounters"].data.length; i++) {
        let encounter = data["encounters"].data[i];

        filterArray.forEach((filter) => {
          if (
            (filter.typ === "codeDesc" &&
              encounter.CODE === filter.code &&
              !encountersArray.some((item) => item.Id === encounter.Id)) ||
            (filter.typ === "reasoncode" &&
              encounter.REASONCODE === filter.code &&
              !encountersArray.some((item) => item.Id === encounter.Id))
          ) {
            encountersArray.push(encounter);
            additionalEncounter.push(encounter);
          }
        });
      }

      //start filtering
      for (const key in data) {
        if (key === "patient") {
          filterData[key] = data[key]; // data out of he patient file cant be filtered
        } else if (key === "encounters") {
          filterData[key] = {}; // already did this in first
        } else {
          //filter function
          let filteredItems = [];

          for (let i = 0; i < data[key].data.length; i++) {
            let isOk = false; //
            let isFromFilteredOutEncounter = undefined;
            let event = data[key].data[i];

            //first check if data is in encounter that should be included
            if (this.state.includeAllDataOfEncounter) {
              isFromFilteredOutEncounter = encountersArray.find(
                (o) => o.Id === event.ENCOUNTER
              );
            }

            if (isFromFilteredOutEncounter !== undefined) {
              isOk = true;
            } else {
              //if event is not in encounter that should be added, we go through all filter and check if it fits && check if we need to add an additional encounter
              for (let index = 0; index < filterArray.length; index++) {
                if (
                  (filterArray[index].typ === "codeDesc" &&
                    event.CODE === filterArray[index].code) ||
                  (filterArray[index].typ === "reasoncode" &&
                    event.REASONCODE === filterArray[index].code)
                ) {
                  isOk = true;
                  //add encounter of data to encounterArray
                  var encounterOfData = data["encounters"].data.find(
                    (o) => o.Id === event.ENCOUNTER
                  );

                  if (
                    !additionalEncounter.some(
                      (item) => item.Id === encounterOfData.Id
                    )
                  ) {
                    additionalEncounter.push(encounterOfData);
                  }
                  break;
                }
              }
            }

            if (isOk) {
              filteredItems.push(event);
            }
          }
          filterData[key] = { typ: key, data: filteredItems };
        }
      }

      filterData.encounters = { typ: "encounters", data: additionalEncounter };
    } else {
      filterData = data;
    }

    return filterData;
  }

  filterForDate(data, filterArray) {
    let filterData = {};
    for (const key in data) {
      if (key === "patient") {
        filterData[key] = data[key];
      } else {
        var filteredItems = [];

        for (let i = 0; i < data[key].data.length; i++) {
          if (data[key].data[i] !== undefined) {
            var dateOfData;
            if (
              key === "observations" ||
              key === "imaging_studies" ||
              key === "immunizations"
            ) {
              dateOfData = new Date(data[key].data[i].DATE);
            } else {
              dateOfData = new Date(data[key].data[i].START);
            }

            if (
              dateOfData.getFullYear() >= filterArray[0] &&
              dateOfData.getFullYear() <= filterArray[1]
            ) {
              filteredItems.push(data[key].data[i]);
            }
          }
        }

        filterData[key] = { typ: key, data: filteredItems };
      }
    }

    return filterData;
  }

  allLoaded(allLoadedObj) {
    //checks if all entrys of obj are true
    var allLoaded = true;
    for (const key in allLoadedObj) {
      if (!allLoadedObj[key]) {
        allLoaded = false;
      }
    }

    return allLoaded;
  }
  /*-------------------------------------------- filtersaver functions ------------------------------------------------------------------------------------------------------------ */

  prepareFilterSave() {
    //prepare relevant filter info for the FilterSaver Component
    var filterData = {
      includeOArray: this.state.includeOArray,
      includeAllDataOfEncounter: this.state.includeAllDataOfEncounter,
      excludeArray: this.state.excludeArray,
    };

    return filterData;
  }

  changeToChangedProfile(filterData) {
    this.setState(
      { filterProfileLoaded: false, watchForFilterChanges: false },
      () =>
        this.setState(
          {
            includeOArray: filterData.data.includeOArray,
            includeAllDataOfEncounter:
              filterData.data.includeAllDataOfEncounter,
            excludeArray: filterData.data.excludeArray,
            sortBy: filterData.data.sortBy,
            filterSaverName: filterData.name,
          },
          () =>
            this.setState({
              filterProfileLoaded: true,
              watchForFilterChanges: true,
            })
        )
    );
  }

  changeFromFilterProfile() {
    this.setState({ changedFromFilterProfile: false });
  }

  filterFunction = () => {
    if (this.allLoaded(this.props.allPatDatLoaded)) {
      this.props.setDisplayDataProcessed(false);
      this.props.setPatViewFilter(this.state); //saves state in App component
      const displayedData = this.createDisplayData();
      this.props.updateDisplayedData(
        this.filterForDate(displayedData, this.props.sliderValue)
      );
    }
  };

  initialFilter = () => {
    //filter without silder -> slider reset vor very pat
    //silder value before filter starts -> wrong display data because dates from prev pat used
    if (this.allLoaded(this.props.allPatDatLoaded)) {
      this.props.setDisplayDataProcessed(false);
      this.props.setPatViewFilter(this.state); //saves state in App component
      const displayedData = this.createDisplayData();
      this.props.updateDisplayedData(displayedData);
    }
  };

  createDisplayData = () => {
    return this.excludeFilter(
      this.includeOArray(this.props.openPatientData, this.state.includeOArray),
      this.state.excludeArray
    );
  };

  sortAlphabetically(arr) {
    return arr.sort((a, b) => a.label.localeCompare(b.label));
  }

  /*-------------------------------------------- React functions ------------------------------------------------------------------------------------------------------------ */

  componentDidUpdate(prevProps, prevState, snapshot) {
    // update displayedData after excludeArray was changed
    if (prevState.excludeArray !== this.state.excludeArray) {
      this.filterFunction();
    }

    // update displayedData after includeArray was changed
    if (prevState.includeOArray !== this.state.includeOArray) {
      this.filterFunction();
    }

    //triggers new set include if include all data in encounter is activated
    if (
      prevState.includeAllDataOfEncounter !==
      this.state.includeAllDataOfEncounter
    ) {
      this.filterFunction();
    }

    //filters data considering the time
    if (prevProps.sliderValue !== this.props.sliderValue) {
      if (this.state.filterOnSliderChange) {
        this.filterFunction();
      } else {
        this.setState({ filterOnSliderChange: true });
      }
    }

    //tells FilterSaver if Filter settings are changed from the set profile
    if (this.state.watchForFilterChanges) {
      if (
        prevState.excludeArray !== this.state.excludeArray ||
        prevState.includeOArray !== this.state.includeOArray ||
        prevState.includeAllDataOfEncounter !==
          this.state.includeAllDataOfEncounter ||
        prevState.sliderValue !== this.state.sliderValue
      ) {
        this.setState({
          watchForFilterChanges: false,
          changedFromFilterProfile: true,
        });
      }
    }

    //triggers if the open patient is changed and all data is loaded
    if (prevProps.allPatDatLoaded !== this.props.allPatDatLoaded) {
      if (this.state.filterOnSliderChange) {
        this.setState({ filterOnSliderChange: false });
      }
      this.initialFilter();
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.patViewFilter).length !== 0) {
      this.setState(this.props.patViewFilter, () => {
        this.setState({
          reloadedFilterSaver: this.props.patViewFilter.filterSaverName,
        });
      }); //if patview is closed and reopend sets filter to pervious filter
    } else {
      if (
        this.allLoaded(this.props.allPatDatLoaded) &&
        Object.keys(this.props.allPatDatLoaded).length !== 0
      ) {
        this.initialFilter();
      }
    }
  }

  render() {
    const { isCollapsedE } = this.state;
    return (
      <div className="filter-div">
        <Overlay
          isOpen={this.state.showIncludeOverlay}
          onClose={() => this.setState({ showIncludeOverlay: false })}
          title={"Include events"}
        >
          <div className="p-2 w-[800px]">
            <ul className="list-disc list-outside mb-8 font-light ml-5 mr-5">
              <li>
                If you include an events only those event will be displayed, all
                other events are excluded (Events refer to the individual
                encounters, observations and conditions)
              </li>
              <li>
                If you include an observation, condition etc. then the encounter
                in which it occurred will also be included
              </li>
              <li>
                Chose either if you want to include events by "description -
                code" or "reason code"
              </li>
              <li>
                For the selection of "description - code", only the events that
                also occur in the patient are suggested in the autocomplete
                field
              </li>
            </ul>
            <div className="flex items-center justify-around">
              <div className="flex items-center gap-2">
                <p className="text-lg font-light">Typ:</p>
                <FormControl>
                  <Select
                    id="include-select-mode"
                    value={this.state.includeSelectMode}
                    onChange={(event) =>
                      this.setState({ includeSelectMode: event.target.value })
                    }
                    sx={{
                      padding: 0, // Ändere die Mindesthöhe nach Bedarf
                      width: "10vw",
                      height: "37px",
                    }}
                  >
                    <MenuItem value="codeDescription">
                      Description - Code
                    </MenuItem>
                    <MenuItem value="reasonCode">Reason code</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="flex items-center gap-2">
                <p className="text-lg font-light">Event:</p>
                <Tooltip
                  title={
                    this.state.includeSelectMode === "reasonCode" &&
                    !this.props.allReasonCodes.loaded
                      ? "disabled because reason codes are still loading"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={this.state.includeAutocompleteValue}
                      options={
                        this.state.includeSelectMode === "codeDescription"
                          ? this.sortAlphabetically(
                              this.props.autocompleteArray
                            )
                          : this.sortAlphabetically(
                              this.props.allReasonCodes.data
                            )
                      }
                      sx={{
                        width: "400px",
                        height: "37px",
                        ".MuiAutocomplete-input": { padding: 0 },
                      }}
                      onChange={(event, newValue) => {
                        this.setState({ includeAutocompleteValue: newValue });
                      }}
                      name="include"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          label="Include"
                          size="small"
                          sx={{
                            ".MuiInputBase-input": {
                              padding: 0,
                              fontSize: "14px",
                            },
                          }}
                        ></TextField>
                      )}
                      disabled={
                        this.state.includeSelectMode === "reasonCode" &&
                        !this.props.allReasonCodes.loaded
                          ? true
                          : false
                      }
                    />
                  </span>
                </Tooltip>
                {this.state.includeSelectMode === "reasonCode" &&
                  !this.props.allReasonCodes.loaded && (
                    <CircularProgress size={30} />
                  )}
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <Button
                color="success"
                variant="contained"
                onClick={() =>
                  this.addToFilterArray(
                    this.state.includeAutocompleteValue,
                    "includeOArray"
                  )
                }
              >
                Add
              </Button>
            </div>
          </div>
        </Overlay>
        <Overlay
          isOpen={this.state.showExcludeOverlay}
          onClose={() => this.setState({ showExcludeOverlay: false })}
          title={"Exclude events"}
        >
          <div className="p-2 w-[800px]">
            <ul className="list-disc list-outside mb-8 font-light ml-5 mr-5">
              <li>
                If you include an events only those event will be displayed, all
                other events are excluded (Events refer to the individual
                encounters, observations and conditions)
              </li>
              <li>
                If you include an observation, condition etc. then the encounter
                in which it occurred will also be included
              </li>
              <li>
                Chose either if you want to include events by "description -
                code" or "reason code"
              </li>
              <li>
                For the selection of "description - code", only the events that
                also occur in the patient are suggested in the autocomplete
                field
              </li>
            </ul>
            <div className="flex items-center justify-around">
              <div className="flex items-center gap-2">
                <p className="text-lg font-light">Typ:</p>
                <FormControl>
                  <Select
                    id="include-select-mode"
                    value={this.state.excludeSelectMode}
                    onChange={(event) =>
                      this.setState({ excludeSelectMode: event.target.value })
                    }
                    sx={{
                      padding: 0, // Ändere die Mindesthöhe nach Bedarf
                      width: "10vw",
                      height: "37px",
                    }}
                  >
                    <MenuItem value="codeDescription">
                      Description - Code
                    </MenuItem>
                    <MenuItem value="reasonCode">Reason code</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="flex items-center gap-2">
                <p className="text-lg font-light">Event:</p>
                <Tooltip
                  title={
                    this.state.excludeSelectMode === "reasonCode" &&
                    !this.props.allReasonCodes.loaded
                      ? "disabled because reason codes are still loading"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={this.state.excludeAutocompleteValue}
                      options={
                        this.state.excludeSelectMode === "codeDescription"
                          ? this.sortAlphabetically(
                              this.props.autocompleteArray
                            )
                          : this.sortAlphabetically(
                              this.props.allReasonCodes.data
                            )
                      }
                      sx={{
                        width: "400px",
                        height: "37px",
                        ".MuiAutocomplete-input": { padding: 0 },
                      }}
                      onChange={(event, newValue) => {
                        this.setState({ excludeAutocompleteValue: newValue });
                      }}
                      name="exclude"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          label="Exclude"
                          size="small"
                          sx={{
                            ".MuiInputBase-input": {
                              padding: 0,
                              fontSize: "14px",
                            },
                          }}
                        ></TextField>
                      )}
                      disabled={
                        this.state.includeSelectMode === "reasonCode" &&
                        !this.props.allReasonCodes.loaded
                          ? true
                          : false
                      }
                    />
                  </span>
                </Tooltip>
                {this.state.includeSelectMode === "reasonCode" &&
                  !this.props.allReasonCodes.loaded && (
                    <CircularProgress size={30} />
                  )}
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <Button
                color="success"
                variant="contained"
                onClick={() =>
                  this.addToFilterArray(
                    this.state.excludeAutocompleteValue,
                    "excludeArray"
                  )
                }
              >
                Add
              </Button>
            </div>
          </div>
        </Overlay>
        <div className="filter-head">
          <h3 className="h3-filter">Filter:</h3>
          <FilterSaver
            filterData={this.prepareFilterSave()}
            typ="patientView"
            changeFilterFunction={this.changeToChangedProfile}
            changedFromFilterProfile={this.state.changedFromFilterProfile}
            changeFromFilterProfile={this.changeFromFilterProfile}
            newSnackbar={this.props.newSnackbar}
            showDeleteSnackbar={this.props.showDeleteSnackbar}
            prevProfileName={this.state.reloadedFilterSaver}
          />
        </div>
        <div className="filter-body">
          <div className="include-div">
            <h6
              style={{
                paddingTop: "8px",
                fontSize: "15px",
                fontWeight: "400",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              Include
            </h6>
            <div className="include-div-body">
              {this.state.includeOArray.map((array, i) => (
                <Tooltip
                  title={
                    array.typ === "codeDesc"
                      ? array.file + ".csv: " + array.description
                      : "Reason code: " + array.description
                  }
                  arrow
                  key={"tooltipIn" + i + array.code}
                >
                  <Chip
                    label={array.code}
                    onDelete={() =>
                      this.removeFromFilterArray(
                        this.state.includeOArray,
                        "includeOArray",
                        array
                      )
                    }
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      marginRight: "5px",
                      marginBottom: "5px",
                      ".MuiChip-label": { fontSize: "12px" },
                      ".MuiChip-deleteIcon": {
                        color: "rgba(255, 255, 255, 0.829)",
                      },
                    }}
                    key={"chipIn" + i + array.code}
                  />
                </Tooltip>
              ))}
              {!this.state.isCollapsedI ? (
                <></>
              ) : (
                <IconButton
                  aria-label="add"
                  onClick={() => this.setState({ showIncludeOverlay: true })}
                >
                  <AddCircleIcon />
                </IconButton>
              )}
            </div>
            <div className="switch-div mt-2">
              <div className="label-switch">include all data of encounter:</div>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                onChange={this.changeCheckbox}
                checked={this.state.includeAllDataOfEncounter}
              />
            </div>
          </div>
          <div className="exclude-div">
            <div className="include-div-head">
              <h6>Exclude</h6>
            </div>
            <div className="include-div-body">
              {this.state.excludeArray.map((array, i) => (
                <Tooltip
                  title={
                    array.typ === "codeDesc"
                      ? array.file + ".csv: " + array.description
                      : "Reason code: " + array.description
                  }
                  arrow
                  key={"tooltipEx" + i + array.code}
                >
                  <Chip
                    label={array.code}
                    onDelete={() =>
                      this.removeFromFilterArray(
                        this.state.excludeArray,
                        "excludeArray",
                        array
                      )
                    }
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      marginRight: "5px",
                      marginBottom: "5px",
                      ".MuiChip-label": { fontSize: "12px" },
                      ".MuiChip-deleteIcon": {
                        color: "rgba(255, 255, 255, 0.829)",
                      },
                    }}
                    key={"chipEx" + i + array.code}
                  />
                </Tooltip>
              ))}

              <IconButton
                aria-label="add"
                onClick={() => this.setState({ showExcludeOverlay: true })}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
