import React, { Component } from "react";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import axios from "axios";
import "./DataUploadPage.css";
import {
  Collapse,
  TextField,
  LinearProgress,
  CircularProgress,
  Chip,
  IconButton,
  Button,
  ButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
} from "@mui/material";

import InfoBtnToolTip from "../utils/InfoBtnToolTip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

const dropzoneStyles = {
  border: "2px solid var(--divider-color)",
  borderRadius: "5px",
  padding: "10px",
  textAlign: "center",
  margin: "5px",
  cursor: "pointer",
  backgroundColor: "var(--drop-color)",
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" size={30} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="primary"
          sx={{ fontSize: "8px", fontWeight: 500 }}
        >
          {props.number + "."}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ minWidth: "200px", mr: 1, flexGrow: 1 }}>
        <LinearProgress variant="buffer" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default class DataUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvFiles: [],
      error: null,
      saveLocally: true,
      savedDatasets: [],
      updateSavedDataSets: false,
      nameValue: "",
      sizes: {},
      showProgress: false,
      showPrasePro: false,
      showPraseIndexing: false,
      showPrasePost: false,
      startTimePra: null,
      startTimeCrea: null,
      startTimeWrit: null,
      times: {
        parseCsv: 0,
        createDB: 0,
        writeInDB: 0,
      },
      showImportBtn: true,
      showCloseBtn: false,
      savingLocation: "index", //index or mongo
      infoOpen: false,
    };
  }

  /*-------------------------------drag and drop functions---------------------------------------------------------------------------------------------------------------------------------------*/
  onDragEnter = () => {
    this.setState({ isDragActive: true });
  };

  onDragLeave = () => {
    this.setState({ isDragActive: false });
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    let duplicateCheck = [...Array.from(files)];
    for (let i = 0; i < this.state.csvFiles.length; i++) {
      duplicateCheck.push(this.state.csvFiles[i]);
    }
    var duplicates = this.findDuplicates(duplicateCheck);
    if (duplicates.length === 0) {
      this.setState((prevState) => ({
        isDragActive: false,
        csvFiles: [...prevState.csvFiles, ...Array.from(files)],
        error: null,
      }));
    } else {
      this.setState({
        error:
          "You tried to import a file that already existed or tried to import 2 files with the same name. File/Files: " +
          duplicates.join(", ") +
          " Please try again.",
      });
    }
  };

  handleFileClick = () => {
    if (this.state.csvFiles.length === 0) {
      this.fileInput.click();
    }
  };

  handleFileClickBtn = () => {
    this.fileInput.click();
  };

  /*------------------------------file management-----------------------------------------------------------------------------------------------------------------------------------------*/
  findDuplicates(arr) {
    let hashTable = {};
    let duplicates = [];
    for (let i = 0; i < arr.length; i++) {
      if (hashTable[arr[i].name] === undefined) {
        hashTable[arr[i].name] = true;
      } else {
        duplicates.push(arr[i].name);
      }
    }

    return duplicates;
  }

  handleFileChange = (event) => {
    const files = event.target.files;
    let duplicateCheck = [...Array.from(files)];
    for (let i = 0; i < this.state.csvFiles.length; i++) {
      duplicateCheck.push(this.state.csvFiles[i]);
    }
    var duplicates = this.findDuplicates(duplicateCheck);
    if (duplicates.length === 0) {
      this.setState((prevState) => ({
        csvFiles: [...prevState.csvFiles, ...Array.from(files)],
        error: null,
      }));
    } else {
      this.setState({
        error:
          "You tried to import a file that already existed or tried to import 2 files with the same name. File/Files: " +
          duplicates.join(", ") +
          " Please try again.",
      });
    }
  };

  removeFile = (file) => {
    const index = this.state.csvFiles.indexOf(file);
    var fileArray = [...this.state.csvFiles];
    fileArray.splice(index, 1);
    this.setState({ csvFiles: fileArray });
  };

  async checkPassword(password) {
    try {
      // Objekt mit dem zu sendenden Passwort
      const data = {
        password,
      };

      // POST-Anfrage mit axios
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/check-password",
        data
      );

      // Antwort behandeln: Gibt true oder false zurück
      if (response.data.success) {
        console.log("Passwort korrekt: true");
        return true;
      } else {
        console.log("Passwort korrekt: false");
        return false;
      }
    } catch (error) {
      // Fehler behandeln
      if (error.response) {
        // Der Server hat geantwortet, aber mit einem Fehlerstatuscode
        console.error("Fehler:", error.response.data);
      } else {
        // Ein anderer Fehler, z.B. Verbindungsfehler
        console.error("Fehler:", error.message);
      }
    }
  }

  /*-------------------------Prase Csv to JSON--------------------------------------------------------------------------------------------------------------------------------------------- */
  handleParseButtonClick = async () => {
    const { csvFiles } = this.state;
    // error handling
    if (csvFiles.length === 0) {
      this.setState({
        error: "Please Import at least one file.",
      });
      return;
    }

    if (this.state.nameValue.length === 0 && this.state.saveLocally) {
      this.setState({
        error: "Please enter a Name.",
      });
      return;
    }

    for (const key in this.props.allDbs) {
      const allDbs = this.props.allDbs[key];
      for (let i = 0; i < allDbs.length; i++) {
        if (key === "index") {
          if (allDbs[i].name === this.state.nameValue) {
            this.setState({
              error:
                "Name already used for a different Dataset. Please choose a new name.",
            });
            return;
          }
        } else if (key === "mongo") {
          if (allDbs[i].Name === this.state.nameValue) {
            this.setState({
              error:
                "Name already used for a different Dataset. Please choose a new name.",
            });
            return;
          }
        }
      }
    }

    var patientCSVPresent = false;
    var encounterCSVPresent = false;

    for (let i = 0; i < csvFiles.length; i++) {
      if (csvFiles[i].name === "patients.csv") {
        patientCSVPresent = true;
      }

      if (csvFiles[i].name === "encounters.csv") {
        encounterCSVPresent = true;
      }
    }

    if (!encounterCSVPresent || !patientCSVPresent) {
      this.setState({
        error:
          "At least the encounter.csv and patient.csv file needs to be uploaded. Make sure that the files are named accordingly.",
      });
      return;
    }

    let password = "";
    let correctPassword = false;

    if (
      process.env.REACT_APP_WS !== "true" &&
      this.state.savingLocation === "mongo"
    ) {
      let errorMessage = "";

      while (!correctPassword) {
        password = prompt(errorMessage + "Please enter password:");
        if (password === null) {
          // Wenn der Benutzer "Abbrechen" drückt, brechen wir die Funktion ab, aber setzen keine Authentifizierung
          console.log("Der Benutzer hat den Passwortvorgang abgebrochen.");
          return; // Beendet die gesamte Funktion
        }

        correctPassword = await this.checkPassword(password);
        if (!correctPassword) {
          errorMessage = "Wrong password. Please try again.";
        }
      }
    }

    this.setState({ error: null, showImportBtn: false });
    const sizes = {};
    var totalSize = 0;
    //exarate the the single sizes
    for (let i = 0; i < csvFiles.length; i++) {
      sizes[csvFiles[i].name.replace(".csv", "")] = this.state.csvFiles[i].size;
      totalSize = totalSize + this.state.csvFiles[i].size;
    }
    sizes["total"] = totalSize;
    this.setState({ sizes: sizes });

    this.props.parseCSVData(
      csvFiles,
      this.state.nameValue,
      this.state.savingLocation,
      password
    );
  };

  handleTextChange = (e) => {
    this.setState({ nameValue: e.target.value });
  };

  changeLocation = (location) => {
    this.setState({ savingLocation: location });
  };

  /*-------------------------Load Dataset--------------------------------------------------------------------------------------------------------------------------------------------- */

  startTiming = (key) => {
    this.setState({ [key]: new Date().getTime() });
  };

  stopTiming = (key, stateKey) => {
    const endTime = new Date().getTime();
    const startTime = this.state[key];
    if (startTime) {
      const duration = endTime - startTime;
      this.setState((prevState) => ({
        times: {
          ...prevState.times,
          [stateKey]: duration,
        },
        [key]: null,
      }));
    }
  };

  handleChangeChunkSize = (event) => {
    const value = event.target.value;

    // Nur Zahlen erlauben und sicherstellen, dass der Wert größer oder gleich 1 ist
    if (
      value === "" ||
      (Number.isInteger(Number(value)) && Number(value) >= 1)
    ) {
      this.props.changeStateInApp({ chunkSizeParse: value * 1024 });
    }
  };

  handleChangeMax = (event) => {
    const value = event.target.value;

    // Nur Zahlen erlauben und sicherstellen, dass der Wert größer oder gleich 1 ist
    if (
      value === "" ||
      (Number.isInteger(Number(value)) && Number(value) >= 1)
    ) {
      this.props.changeStateInApp({ maxActiveChunks: value });
    }
  };

  /*-------------------------Display--------------------------------------------------------------------------------------------------------------------------------------------- */

  setName = (allData) => {
    var indexLastObj = allData.length - 1;
    var highestId = 0;
    if (indexLastObj > -1) {
      highestId = allData[indexLastObj].id;
    }
    this.setState({
      savedDatasets: allData,
      highestId: highestId,
    });
  };

  componentDidMount() {
    //gets all dataset names -> is needed for new id and to check if the name of db already exists
    this.props.getAllEntries("CSVNames", "names", "csvFiles");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.csvFiles !== this.props.csvFiles) {
      this.setName(this.props.csvFiles.data);
    }

    if (
      prevProps.loadingProgressSimple.processCSV !==
      this.props.loadingProgressSimple.processCSV
    ) {
      if (!this.props.loadingProgressSimple.processCSV) {
        this.setState({ showPrasePro: true, showProgress: true });
        this.startTiming("startTimePra");
      } else {
        this.setState({
          showPraseIndexing:
            this.state.savingLocation === "index" ? false : true,
        }); //only show indexing progress for mongoDB -> not needed for indexDB
        this.stopTiming("startTimePra", "parseCsv");
        this.startTiming("startTimeCrea");
      }
    }

    if (
      prevProps.loadingProgressSimple.indexing !==
      this.props.loadingProgressSimple.indexing
    ) {
      if (this.props.loadingProgressSimple.indexing) {
        this.setState({ showPrasePost: true });
        this.stopTiming("startTimeCrea", "createDB");
        this.startTiming("startTimeWrit");
      }
    }

    if (
      prevProps.loadingProgressSimple.postProcessing !==
      this.props.loadingProgressSimple.postProcessing
    ) {
      if (this.props.loadingProgressSimple.postProcessing) {
        this.stopTiming("startTimeWrit", "writeInDB");
        this.setState({ showCloseBtn: true, showProgress: false });
      }
    }

    if (
      prevState.savingLocation !== this.state.savingLocation &&
      this.state.savingLocation === "mongo" &&
      process.env.REACT_APP_WS !== "true"
    ) {
      this.setState({ infoOpen: true });
    }
  }

  render() {
    const styleTime = {
      marginLeft: "10px",
      fontWeight: 300,
      fontSize: "14px",
      color: "var(--text-color-start)",
    };
    const styleCollapseHori = {
      display: "flex",
      alignItems: "center",
    };
    const stylePCol = {
      marginLeft: "10px",
      fontWeight: 300,
      color: "var(--primary-color)",
    };

    const lineBox = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      paddingTop: "5px",
      paddingBottom: "5px",
    };

    const line = {
      backgroundColor: "var(--primary-color)",
      height: "100%",
      width: "1px",
      flexShrink: 0,
    };
    const btnStyle = {
      borderColor: "var(--primary-color)",
      color: "var(--primary-color)",
    };

    const btnStyleActive = {
      color: "white",
      backgroundColor: "var(--primary-color)",
    };

    return (
      <div className="pl-4 pr-4 font-light w-[60vw] min-w-[700px] relative">
        <Backdrop
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          }}
          open={this.state.infoOpen}
        >
          <div
            style={{
              textAlign: "left",
              fontWeight: 300,
              backgroundColor: "#f0f5f5",
              padding: "15px",
              borderRadius: "15px",
              border: "1px solid black",
            }}
          >
            <p>
              Unfortunately, in the online version we cannot offer everyone to
              write their dataset to our MongoDB because we do not have enough
              storage space.
            </p>
            <p>
              If you want to use a MongoDB yourself, you have to clone our
              git-project and set up your own MongoDB (instructions can be found
              on our homepage).
            </p>
            <p className="mt-2">Advantages of MongoDB over indexedDB:</p>
            <ul className="list-disc pl-5">
              <li>Larger datasets possible</li>
              <li>Faster loading and processing</li>
              <li>Better performance of website</li>
            </ul>
            <p className="mt-2">
              Loading datasets into our mongoDB is only possible with a
              password.
            </p>

            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: "15px" }}
              onClick={() => this.setState({ infoOpen: false })}
            >
              Understood
            </Button>
          </div>
        </Backdrop>
        <Collapse orientation="vertical" in={!this.state.showPrasePro}>
          <div className="flex gap-9 items-center mt-4 mb-6">
            <h5 style={{ fontWeight: 400, marginBottom: "5px" }}>
              Choose saving location:
            </h5>
            <ButtonGroup variant="outlined">
              <Button
                sx={
                  this.state.savingLocation === "index"
                    ? btnStyleActive
                    : btnStyle
                }
                onClick={() => this.changeLocation("index")}
              >
                IndexedDB (Dexie)
              </Button>
              <Button
                sx={
                  this.state.savingLocation === "mongo"
                    ? btnStyleActive
                    : btnStyle
                }
                onClick={() => this.changeLocation("mongo")}
                disabled={!this.props.connectionToMongoDB.connected}
              >
                MongoDB
              </Button>
            </ButtonGroup>
            {this.props.connectionToMongoDB.trying && (
              <div className="flex gap-2 items-center">
                <CircularProgress
                  size={20}
                  sx={{ color: "var(--divider-color)" }}
                />
                <p style={{ color: "var(--divider-color)" }}>
                  connecting to MongoDB
                </p>
              </div>
            )}
            {!this.props.connectionToMongoDB.connected &&
              !this.props.connectionToMongoDB.trying && (
                <p style={{ color: "red" }}>connecting with MongoDB failed</p>
              )}
          </div>
          <div className="data-info-div">
            <h5 style={{ fontWeight: 400, marginBottom: "5px" }}>
              Important information considering the data import{" "}
              {this.state.savingLocation === "index"
                ? "with Dexie into the indexedDB"
                : "into the MongoDB database"}
              :
            </h5>
            <ul className="list-disc pl-5">
              <li>Only CSV files are accepted</li>
              <li>
                Please use the Synthea formatting for the CSV files, otherwise
                the correct functioning of the website cannot be guaranteed
              </li>
              <li>
                {this.state.savingLocation === "index"
                  ? "Your data is stored locally in the browser in the so-called indexedDB"
                  : "Your data will only be stored into your local MongoDB database"}
              </li>
              <li>
                How fast your datasets can be processed depends heavily on your
                hardware (we recommend at least 32GB RAM)
              </li>
              {this.state.savingLocation === "index" ? (
                <li>
                  The size of the local storage space offered by the browser
                  varies from browser to browser - in case of problems it may be
                  useful to use a different browser
                </li>
              ) : (
                <li>
                  We currently recommend dataset sizes of{" "}
                  <span className="font-bold">no more than 1GB</span>.
                </li>
              )}
              {this.state.savingLocation === "index" && (
                <li>
                  <span className="font-bold">IndexedDB</span> is the slower
                  option and is <span className="font-bold">only</span>{" "}
                  recommended for{" "}
                  <span className="font-bold">
                    small datasets (up to ca. 200-300mb)
                  </span>
                  . For <span className="font-bold">larger datasets</span>{" "}
                  please use a <span className="font-bold">MongoDB</span>{" "}
                  database. For more information on how to use a MongoDB click
                  here.
                </li>
              )}
            </ul>
          </div>

          <Accordion className="mt-3">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Parsing options
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex gap-3 justify-around">
                <div className="flex items-center gap-2 ">
                  <p>Chunk size (in KB):</p>
                  <TextField
                    id="chunk-size"
                    label=""
                    variant="outlined"
                    value={this.props.chunkSize / 1024}
                    sx={{ width: "100px" }}
                    type="number"
                    onChange={this.handleChangeChunkSize}
                    inputProps={{
                      min: 1, // Stellt sicher, dass der Wert nicht kleiner als 1 sein kann
                      step: 1, // Schritte von 1 für nur ganze Zahlen
                    }}
                  />
                  <InfoBtnToolTip
                    title={
                      <React.Fragment>
                        <p>
                          The CSV files are processed in chunks. Here you can
                          alter the size in KB accordingly to your hardware.
                        </p>
                        <p className="mt-2">
                          If you have 30GB of RAM you can easily set the chuck
                          size to 2-4 MB.
                        </p>
                        <p className="mt-2">
                          If the website freezes while loading the date it can
                          be useful to lower the chunk size.
                        </p>
                      </React.Fragment>
                    }
                    placement="right"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <p>Max. amount of open threats:</p>
                  <TextField
                    id="chunk-size"
                    label=""
                    variant="outlined"
                    value={this.props.maxActiveChunks}
                    sx={{ width: "100px" }}
                    type="number"
                    onChange={this.handleChangeMax}
                    inputProps={{
                      min: 1, // Stellt sicher, dass der Wert nicht kleiner als 1 sein kann
                      step: 1, // Schritte von 1 für nur ganze Zahlen
                    }}
                  />
                  <InfoBtnToolTip
                    title={
                      <React.Fragment>
                        <p>
                          The CSV files are processed asynchronous in chunks.
                          Here you can set the maximal mount of chunks thaT are
                          processed at the same time.
                        </p>
                        <p className="mt-2">
                          Be aware that the RAM storage that is needed to
                          process the csv files is proportional to the max
                          amount of chunks and the chunk size.
                        </p>
                        <p className="mt-2">
                          If the website freezes while loading the date it can
                          be useful to lower the max amount of chunks.
                        </p>
                      </React.Fragment>
                    }
                    placement="right"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="font-light mt-3">
            <h5 style={{ fontWeight: 400 }}>Import your CSV files:</h5>
            <div
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
              onDragOver={this.onDragOver}
              onClick={this.handleFileClick}
              style={dropzoneStyles}
            >
              {this.state.csvFiles.length !== 0 ? (
                <div className="imported-files-div gap-1">
                  {this.state.csvFiles.map((data) => (
                    <Chip
                      label={data.name}
                      variant="outlined"
                      onDelete={() => this.removeFile(data)}
                      color="primary"
                      key={data.name}
                    />
                  ))}
                  <IconButton onClick={this.handleFileClickBtn} color="primary">
                    <AddIcon />
                  </IconButton>
                </div>
              ) : (
                <p style={{ color: "var(--text-color-start)" }} className="p-8">
                  Drag-and-Drop or click to upload your CSV files
                </p>
              )}
              <input
                type="file"
                accept=".csv"
                ref={(input) => (this.fileInput = input)}
                onChange={this.handleFileChange}
                style={{ display: "none" }}
                multiple
              />
            </div>
          </div>
        </Collapse>
        <Collapse
          orientation="vertical"
          in={this.state.showPrasePro}
          className="mt-1"
        >
          <p
            style={{
              marginBottom: "10px",
              fontWeight: 300,
              fontSize: "18px",
              color: "var(--text-color-start)",
            }}
          >
            Loading Progress of {this.state.nameValue} dataset
          </p>

          <div
            style={{
              minHeight: "200px",
              marginBottom: "15px",
              paddingLeft: "20px",
            }}
          >
            <Collapse orientation="vertical" in={this.state.showPrasePro}>
              <div style={styleCollapseHori}>
                <Collapse in={this.state.showPrasePro} timeout={{ enter: 500 }}>
                  <div className="flex gap-2">
                    <CircularProgressWithLabel value={100} number={1} />
                    <div style={styleCollapseHori}>
                      <p style={stylePCol}>
                        Processing and inserting CSV-Data in{" "}
                        {this.state.savingLocation === "index"
                          ? "indexedDB"
                          : "MongoDB"}
                      </p>{" "}
                      {this.state.times.parseCsv !== 0 && (
                        <p style={styleTime}>
                          {(this.state.times.parseCsv / 1000).toFixed(3)}s
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-[100%] items-stretch">
                    <div
                      style={{ ...lineBox, flexBasis: "30px", flexShrink: 0 }}
                    >
                      <Collapse
                        orientation="horizontal"
                        in={this.state.showPraseIndexing}
                        sx={{ height: "100%" }}
                      >
                        <div style={line}></div>
                      </Collapse>
                    </div>
                    <div className="flex flex-wrap flex-grow flex-shrink pl-3 pb-4">
                      {Object.keys(this.props.filesFullProcessed).map(
                        (file) => (
                          <div className="flex pl-2 pr-2 w-[50%] gap-1">
                            <p className="w-[225px]">{file}:</p>
                            <LinearProgressWithLabel
                              value={
                                (this.props.fileProcessingProgress[file]
                                  .processedBytes /
                                  this.props.fileProcessingProgress[file]
                                    .totalBytes) *
                                100
                              }
                              valueBuffer={
                                (this.props.fileParsing[file].processedBytes /
                                  this.props.fileParsing[file].totalBytes) *
                                100
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>
            </Collapse>

            <Collapse
              orientation="vertical"
              timeout={{ enter: 1000 }}
              in={this.state.showPraseIndexing}
            >
              <div style={styleCollapseHori}>
                <CircularProgressWithLabel value={100} number={2} />
                <Collapse
                  in={this.state.showPraseIndexing}
                  timeout={{ enter: 500 }}
                >
                  <div style={styleCollapseHori}>
                    <p style={stylePCol}>Indexing Database</p>{" "}
                    {this.state.times.createDB !== 0 && (
                      <p style={styleTime}>
                        {(this.state.times.createDB / 1000).toFixed(3)} s
                      </p>
                    )}
                  </div>
                </Collapse>
              </div>
              <div className="flex w-[100%] items-stretch">
                <div style={{ ...lineBox, flexBasis: "30px", flexShrink: 0 }}>
                  <Collapse
                    orientation="horizontal"
                    in={this.state.showPrasePost}
                    sx={{ height: "100%" }}
                  >
                    <div style={line}></div>
                  </Collapse>
                </div>
                <div className="flex-grow flex-shrink pl-3 pb-4 pt-4">
                  <LinearProgressWithLabel
                    value={
                      (this.props.indexingProgress.createdIndices /
                        this.props.indexingProgress.totalIndices) *
                      100
                    }
                    valueBuffer={100}
                  />
                </div>
              </div>
            </Collapse>

            <Collapse orientation="vertical" in={this.state.showPrasePost}>
              <div style={styleCollapseHori}>
                <Collapse
                  in={this.state.showPrasePost}
                  timeout={{ enter: 500 }}
                >
                  <div style={styleCollapseHori}>
                    <CircularProgressWithLabel
                      value={100}
                      number={this.state.savingLocation === "mongo" ? 3 : 2}
                    />
                    <p style={stylePCol}>Postprocessing data</p>{" "}
                    {this.state.times.writeInDB !== 0 && (
                      <p style={styleTime}>
                        {(this.state.times.writeInDB / 1000).toFixed(3)}s
                      </p>
                    )}
                  </div>
                  <div className="flex gap-2 justify-start flex-wrap pb-4 pl-3 pt-4">
                    {Object.keys(this.props.postProcessingProgress).map(
                      (step) => (
                        <div className="flex pl-2 gap-1">
                          {this.props.postProcessingProgress[step].title}{" "}
                          {this.props.postProcessingProgress[step].done ? (
                            <DoneIcon sx={{ height: "25px" }} color="success" />
                          ) : (
                            <CircularProgress size={10} />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </Collapse>
              </div>
            </Collapse>
          </div>
        </Collapse>
        <div>
          {this.state.showImportBtn && (
            <div className="data-upload-control">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <p>Dataset name:</p>
                <TextField
                  name="value"
                  variant="standard"
                  value={this.state.nameValue}
                  onChange={this.handleTextChange}
                  sx={{ m: 1, width: "15vw", marginLeft: "15px" }}
                  size="small"
                />
              </div>

              <button
                className="general-btn"
                onClick={this.handleParseButtonClick}
              >
                {this.state.saveLocally ? "Import" : "Import & Load"}
              </button>
            </div>
          )}

          {this.state.error && (
            <div style={{ color: "red" }}>{this.state.error}</div>
          )}
        </div>
        <Collapse in={!this.state.showImportBtn}>
          <div className="mt-2 flex  justify-between items-center gap-4">
            <div className="flex-grow">
              {this.state.showProgress ? (
                <LinearProgress />
              ) : (
                <p style={{ color: "var(--primary-color)" }}>
                  Loading the dataset into indexedDB completed. Dataset can now
                  be opened in SynViewer.
                </p>
              )}
            </div>
            <button
              className="general-btn"
              onClick={this.props.onClose}
              disabled={!this.props.loadingProgressSimple.postProcessing}
            >
              Close
            </button>
          </div>
        </Collapse>
      </div>
    );
  }
}
