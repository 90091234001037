import React, { Component, Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import "./Overlay.css";

export class Overlay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        {this.props.isOpen && (
          <div className="overlay">
            <div
              className="overlay__background"
              onClick={
                this.props.loadingDone === undefined
                  ? this.props.onClose
                  : !this.props.loadingDone
                  ? () => {}
                  : this.props.onClose
              }
            />
            <div className="overlay__container">
              <div className="overlay__controls">
                <h4 className="overlay-title">{this.props.title}</h4>
                <IconButton
                  onClick={this.props.onClose}
                  sx={{ color: "black" }}
                  disabled={
                    this.props.loadingDone === undefined
                      ? false
                      : !this.props.loadingDone
                  }
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider style={{ marginBottom: "10px" }} />
              {this.props.children}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Overlay;
