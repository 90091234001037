import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import ModeIcon from "@mui/icons-material/Mode";

import React, { useState, useEffect } from "react";

import DragItem from "./DragItem";

import "./Overview.css";

const DndBox = ({
  argumentArray,
  operatorArray,
  handleDragEnd,
  handleOperatorMouseOver,
  openSelect,
  handleOperatorSelect,
  setActiveItem,
  activeItem,
}) => {
  //collects ids for DndContext and indicies of brackets
  var arrayOfIds = [];
  var indiciesBrackets = [];
  for (let i = 0; i < argumentArray.length; i++) {
    arrayOfIds.push(argumentArray[i].id);
    if (
      argumentArray[i].typ === "bracketStart" ||
      argumentArray[i].typ === "bracketEnd"
    ) {
      indiciesBrackets.push(i);
    }
  }

  const displayOperator = (argument, index) => {
    //determins at which index a operator needs to be shown
    var operator = {};
    var isNextEndBracket = false;

    //checks if next argument is end of bracket
    if (index + 1 < argumentArray.length) {
      if (argumentArray[index + 1].typ === "bracketEnd") {
        isNextEndBracket = true;
      }
    }

    if (
      argument.typ === "bracketStart" ||
      isNextEndBracket ||
      index >= argumentArray.length - 1
    ) {
      operator = undefined;
    } else {
      var indexDecrease = 0;
      for (let i = 0; i < indiciesBrackets.length; i++) {
        if (index >= indiciesBrackets[i]) {
          indexDecrease++;
        }
      }

      operator = operatorArray[index - indexDecrease];
    }

    if (operator !== undefined) {
      if (operator.showSelect) {
        return (
          <FormControl sx={{ m: 1, minWidth: 74 }}>
            <InputLabel id={"operatorSelectIn" + index}>Operator</InputLabel>
            <Select
              labelId={"operatorSelectIn" + index}
              id={"operatorSelectIn" + index}
              name={operator.position}
              value={operator.data}
              label="Operator"
              onChange={handleOperatorSelect}
              autoWidth
              size="small"
            >
              <MenuItem value="and">AND</MenuItem>
              <MenuItem value="or">OR</MenuItem>
              <MenuItem value="not" disabled>
                NOT
              </MenuItem>
              <MenuItem value="xor" disabled>
                XOR
              </MenuItem>
              <MenuItem value="nand" disabled>
                NAND
              </MenuItem>
              <MenuItem value="nor" disabled>
                NOR
              </MenuItem>
            </Select>
          </FormControl>
        );
      } else {
        return (
          <div
            className="operator-text-div"
            onMouseOver={() => handleOperatorMouseOver(operator.position, true)}
            onMouseOut={() => handleOperatorMouseOver(operator.position, false)}
            onClick={() => openSelect(operator.position)}
          >
            {operator.data.toUpperCase()}
            {operator.showIconButton && (
              <IconButton size="small" style={{ color: "#4361ee" }}>
                <ModeIcon style={{ fontSize: "medium" }} />
              </IconButton>
            )}
          </div>
        );
      }
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className="overlay-filter-div-overview">
        <SortableContext
          items={arrayOfIds}
          strategy={horizontalListSortingStrategy}
        >
          {argumentArray.map((argument, index) => (
            <>
              <DragItem
                key={argument.id}
                data={argument}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
              />
              <React.Fragment key={index}>
                {displayOperator(argument, index)}
              </React.Fragment>
            </>
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default DndBox;
