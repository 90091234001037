import React, { Component } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import ChildCareIcon from "@mui/icons-material/ChildCare";

import VisibilityIcon from "@mui/icons-material/Visibility";
import MasksIcon from "@mui/icons-material/Masks";
import VaccinesIcon from "@mui/icons-material/Vaccines";

import "./Timeline.css";
import { Skeleton } from "@mui/material";

export default class PatientTimeline extends Component {
  onClick = (encounterId) => {
    this.props.openEncounter(encounterId);
  };

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  mapHistory(data) {
    var displayArray = [];
    for (const key in data) {
      if (data[key].length !== 0) {
        var obj = {};
        obj["typ"] = key;
        obj["data"] = data[key];
        displayArray.push(obj);
      }
    }

    return displayArray;
  }

  mapIcons(data) {
    //returns array if encounter includes events of a data typ

    let array = [];
    for (const key in data) {
      if (data[key].length > 0) {
        array.push(key);
      }
    }

    return array;
  }

  returnIcon(typ) {
    var icon;
    if (typ === "observations") {
      icon = (
        <VisibilityIcon style={{ marginTop: "0.4vh", color: "#00b8e6" }} />
      );
    } else if (typ === "procedures") {
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#b300b3"
            stroke="none"
          >
            <path
              d="M3025 5015 l-25 -24 0 -246 0 -245 -72 0 c-87 0 -147 -25 -178 -74 -20 -33 -20 -47 -20 -672 0 -620 1 -640 20 -672 11 -18 34 -41 52 -52 32 -19 53 -20 919 -20 l886 0 33 23 c18 12 43 35 54 50 21 28 21 35 21 665 l0 637 -24 39 c-33 53 -86 76 -178 76 l-73 0 0 244 c0 231 -1 245 -21 270 -26 33 -79 36 -109 6 -19 -19 -20 -33 -20 -270 l0 -250 -565 0 -565 0 0 229 c0 235 -6 283 -40 301 -32 17 -70 11 -95 -15z m1432 -777 l23 -21 0 -464 0 -464 -25 -24 -24 -25 -711 0 -711 0 -24 25 -25 24 0 462 c0 461 0 463 22 486 l21 23 716 0 715 0 23 -22z"
              fill="#b300b3"
            />
            <path
              d="M3110 3970 l0 -140 99 0 98 0 63 96 c69 103 90 124 130 124 46 0 57 -17 167 -279 8 -18 16 -31 19 -28 3 2 23 51 44 108 22 57 48 110 57 117 27 20 77 15 100 -10 12 -13 42 -56 67 -95 26 -40 51 -73 55 -73 5 0 14 7 21 15 9 11 44 15 154 17 l141 3 3 143 3 142 -611 0 -610 0 0 -140z"
              fill="#b300b3"
            />
            <path
              d="M3436 3747 c-20 -30 -48 -59 -62 -65 -14 -7 -76 -12 -145 -12 l-120 0 3 -137 3 -138 603 -3 602 -2 0 140 0 140 -103 0 -103 0 -44 -45 c-34 -34 -52 -45 -75 -45 -35 0 -54 17 -108 99 l-36 54 -11 -29 c-47 -127 -84 -221 -93 -233 -19 -24 -58 -33 -88 -21 -30 13 -35 22 -120 223 -29 70 -56 127 -60 127 -3 0 -22 -24 -43 -53z"
              fill="#b300b3"
            />
            <path
              d="M839 3406 c-155 -43 -305 -197 -410 -420 -117 -251 -179 -563 -179 -906 0 -488 127 -916 348 -1169 38 -44 39 -44 73 -31 21 8 101 15 211 18 l177 4 -28 42 c-131 195 -226 474 -273 806 -17 122 -17 534 0 655 58 417 189 742 382 948 l62 67 -158 -1 c-95 0 -178 -6 -205 -13z"
              fill="#b300b3"
            />
            <path
              d="M1496 3409 c-106 -25 -249 -139 -330 -262 -118 -181 -213 -455 -250 -727 -24 -170 -24 -523 0 -667 l7 -43 153 0 c169 0 160 -4 144 65 -5 22 -15 102 -21 177 -22 280 26 568 131 778 82 164 193 240 302 209 67 -20 156 -114 206 -219 22 -47 48 -110 57 -141 l18 -55 50 13 c61 15 155 17 216 2 24 -5 45 -8 46 -7 2 2 -11 62 -30 133 -67 269 -165 464 -300 600 -130 130 -259 177 -399 144z"
              fill="#b300b3"
            />
            <path
              d="M2019 2390 c-68 -12 -141 -53 -192 -110 -147 -164 -91 -424 112 -518 82 -39 193 -38 273 1 74 36 151 120 174 189 80 239 -120 478 -367 438z"
              fill="#b300b3"
            />
            <path
              d="M3067 2305 c-54 -8 -192 -37 -307 -65 l-209 -52 6 -86 c8 -129 -25 -240 -99 -334 l-30 -38 1103 0 1102 0 -6 56 c-8 73 -55 172 -111 232 -73 77 -137 106 -331 146 -94 19 -244 53 -335 75 -91 22 -205 48 -255 57 -124 22 -403 27 -528 9z"
              fill="#b300b3"
            />
            <path
              d="M1687 1566 c-49 -18 -64 -32 -89 -79 -30 -59 -23 -161 15 -210 55 -73 -66 -68 1673 -65 l1561 3 37 29 c52 39 69 84 64 168 -4 76 -23 110 -79 143 -34 20 -54 20 -1589 22 -1283 2 -1562 0 -1593 -11z"
              fill="#b300b3"
            />
            <path
              d="M950 1552 c0 -5 13 -55 30 -113 56 -200 127 -351 227 -481 l43 -56 228 -4 228 -3 64 -32 65 -32 60 62 c33 34 75 85 93 112 l33 50 -168 5 -168 5 -79 38 c-130 62 -220 171 -296 360 l-37 92 -162 3 c-88 1 -161 -1 -161 -6z"
              fill="#b300b3"
            />
            <path
              d="M3165 734 c-121 -179 -222 -330 -223 -335 -2 -6 138 -8 370 -7 l373 3 213 315 c117 173 218 323 223 333 9 16 -11 17 -363 17 l-373 -1 -220 -325z"
              fill="#b300b3"
            />
            <path
              d="M700 721 c-19 -10 -45 -33 -57 -51 -21 -30 -23 -45 -23 -156 l0 -124 585 0 585 0 0 118 c0 137 -13 173 -76 209 -39 23 -41 23 -509 23 -439 0 -473 -1 -505 -19z"
              fill="#b300b3"
            />
            <path
              d="M191 204 c-28 -35 -26 -69 4 -99 l24 -25 2270 0 c1248 0 2277 3 2286 6 24 10 46 60 39 89 -15 59 157 55 -2325 55 l-2278 0 -20 -26z"
              fill="#b300b3"
            />
          </g>
        </svg>
      );
    } else if (typ === "conditions") {
      icon = (
        <MasksIcon
          style={{
            marginTop: "0.4vh",
            width: "28px",
            height: "28px",
            color: "#6600cc",
          }}
        />
      );
    } else if (typ === "medications") {
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#00cc99"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-pill"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />
          <path d="M8.5 8.5l7 7" />
        </svg>
      );
    } else if (typ === "allergies") {
      icon = (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#cc0052"
            stroke="none"
          >
            <path
              d="M2386 5109 c-506 -70 -930 -421 -1091 -902 -40 -121 -61 -232 -70
     -383 l-7 -111 -78 -11 c-223 -32 -386 -164 -456 -372 -28 -81 -25 -253 5 -335
     74 -206 260 -344 481 -359 l64 -4 26 -108 c59 -247 166 -443 345 -633 65 -68
     67 -72 52 -93 -9 -13 -100 -129 -204 -258 l-188 -235 -381 -71 c-209 -39 -410
     -81 -446 -93 -182 -61 -341 -224 -405 -414 -26 -80 -27 -89 -31 -360 -4 -304
     -1 -327 49 -352 42 -22 4976 -22 5018 0 50 25 53 48 49 352 -4 270 -5 280 -31
     359 -74 222 -251 383 -482 438 -59 13 -925 176 -940 176 -3 0 -5 17 -5 39 0
     57 -43 132 -158 273 l-103 127 98 98 c186 186 297 385 362 647 l27 108 65 5
     c220 14 406 153 480 358 30 82 33 254 5 335 -70 208 -233 340 -456 372 l-78
     11 -7 111 c-21 331 -117 581 -319 825 -180 218 -470 388 -760 447 -90 18 -340
     26 -430 13z m350 -204 c49 -8 120 -24 157 -35 l67 -21 0 -347 0 -348 -137 -71
     c-394 -204 -848 -365 -1286 -458 -95 -20 -109 -21 -114 -8 -10 26 6 294 22
     381 29 151 94 304 187 439 60 87 210 233 298 291 241 159 529 222 806 177z
     m498 -209 c75 -54 209 -191 263 -271 126 -186 185 -372 199 -628 6 -106 5
     -127 -7 -127 -18 0 -134 68 -218 127 -83 60 -205 181 -264 265 l-47 66 0 306
     c0 168 3 306 6 306 4 0 34 -20 68 -44z m-149 -803 c117 -152 326 -316 518
     -403 l99 -45 -5 -380 c-6 -405 -10 -445 -61 -599 -57 -170 -148 -314 -279
     -444 l-84 -84 -129 159 c-186 230 -189 234 -208 284 -19 54 -88 130 -143 157
     -66 34 -150 45 -310 40 -188 -6 -225 -19 -309 -102 -39 -40 -66 -76 -75 -104
     -9 -27 -66 -107 -154 -216 l-139 -174 -26 21 c-37 31 -126 135 -165 193 -46
     69 -108 195 -134 274 -47 145 -53 198 -58 571 l-5 355 138 28 c278 57 598 153
     889 268 157 62 407 177 505 231 36 20 70 37 75 36 6 0 33 -30 60 -66z m-1865
     -723 l0 -341 -52 7 c-120 15 -232 95 -281 202 -32 68 -31 197 2 269 26 60 112
     146 167 169 39 16 103 31 142 33 l22 1 0 -340z m2823 314 c65 -21 157 -108
     188 -176 33 -73 34 -202 2 -270 -49 -107 -161 -187 -280 -202 l-53 -7 0 342 0
     341 48 -6 c26 -4 69 -14 95 -22z m-1354 -1122 c68 -34 65 -21 177 -703 58
     -349 103 -645 102 -659 -6 -48 -23 -50 -448 -50 -417 0 -442 3 -448 46 -2 11
     45 308 103 660 78 477 110 645 123 664 36 50 75 60 222 60 109 0 142 -3 169
     -18z m-737 -834 c-32 -191 -61 -350 -64 -354 -4 -3 -64 13 -135 36 -145 46
     -173 67 -173 128 0 47 -3 42 230 331 127 157 196 236 197 224 2 -10 -23 -174
     -55 -365z m1284 135 c110 -136 204 -256 211 -267 19 -33 16 -90 -7 -119 -16
     -20 -52 -36 -151 -67 -72 -23 -133 -39 -136 -37 -4 5 -123 701 -123 725 0 6 2
     12 4 12 2 0 93 -111 202 -247z m-1716 -575 c24 -16 110 -49 190 -74 154 -49
     165 -54 173 -84 3 -11 -61 -160 -158 -372 l-163 -353 -446 -3 c-245 -1 -446 1
     -446 5 0 5 169 225 376 490 l376 482 26 -31 c15 -17 47 -44 72 -60z m2584 -32
     c559 -106 583 -113 679 -210 112 -112 137 -196 137 -458 l0 -188 -1571 0
     c-863 0 -1568 3 -1566 7 2 5 61 132 131 284 l127 276 57 -9 c31 -5 242 -8 467
     -6 452 3 444 2 519 69 21 18 50 58 65 88 l26 55 145 46 c161 52 213 76 249
     114 14 14 27 26 30 26 3 0 230 -43 505 -94z m-3036 -4 c-7 -10 -159 -206 -338
     -435 l-325 -417 -102 0 -103 0 0 188 c0 260 25 347 135 458 86 87 144 108 461
     169 153 29 280 54 282 54 2 1 -3 -7 -10 -17z"
            />
            <path
              d="M1873 3260 c-36 -15 -56 -55 -51 -103 4 -33 0 -49 -13 -66 -11 -13
     -19 -34 -19 -48 0 -32 25 -80 49 -93 11 -6 88 -10 176 -10 l157 0 29 29 c39
     40 40 89 2 130 -41 43 -253 171 -283 170 -14 0 -35 -4 -47 -9z"
            />
            <path
              d="M3060 3200 c-63 -39 -127 -85 -142 -101 -39 -41 -38 -90 1 -130 l29
     -29 157 0 c88 0 165 4 176 10 24 13 49 61 49 93 0 14 -8 35 -18 47 -14 18 -18
     35 -14 70 6 61 -24 99 -83 106 -36 5 -51 -2 -155 -66z"
            />
            <path
              d="M4738 2393 c-56 -35 -65 -108 -19 -154 69 -68 187 -5 166 89 -14 64
     -94 99 -147 65z"
            />
            <path
              d="M4255 2296 c-145 -32 -165 -47 -165 -121 0 -47 45 -95 88 -95 16 0
     80 11 143 25 135 30 164 50 164 116 0 34 -6 46 -33 70 -39 35 -60 36 -197 5z"
            />
            <path
              d="M4144 1870 c-55 -22 -72 -108 -31 -156 21 -24 50 -32 343 -95 175
     -38 334 -69 352 -69 24 0 42 9 63 29 39 39 40 89 2 131 -27 29 -42 34 -355
     100 -180 39 -333 70 -340 69 -7 0 -23 -4 -34 -9z"
            />
          </g>
        </svg>
      );
    } else if (typ === "careplans") {
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#008000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-mood-heart"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M21 12a9 9 0 1 0 -8.012 8.946" />
          <path d="M9 10h.01" />
          <path d="M15 10h.01" />
          <path d="M9.5 15a3.59 3.59 0 0 0 2.774 .99" />
          <path d="M18.994 21.5l2.518 -2.58a1.74 1.74 0 0 0 .004 -2.413a1.627 1.627 0 0 0 -2.346 -.005l-.168 .172l-.168 -.172a1.627 1.627 0 0 0 -2.346 -.004a1.74 1.74 0 0 0 -.004 2.412l2.51 2.59z" />
        </svg>
      );
    } else if (typ === "immunizations") {
      icon = <VaccinesIcon style={{ color: "#666633" }} />;
    }

    return <div className="icon-timeline"> {icon} </div>;
  }

  prepareCard(data, i) {
    var style;
    var btnLeftRight;

    if (i === 0 || i % 2 === 0) {
      style = { justifyContent: "flex-end" };
      btnLeftRight = true;
    } else {
      style = { justifyContent: "flex-start" };
      btnLeftRight = false;
    }

    var card = (
      <div className="patient-card-body" style={style}>
        {!btnLeftRight && (
          <button
            className="detail-btn"
            onClick={() => this.onClick(data.encounter.Id)}
            style={{ marginRight: "5px" }}
          >
            Details &#xbb;
          </button>
        )}
        {this.mapIcons(data.events).map((typ) => this.returnIcon(typ))}
        {btnLeftRight && (
          <button
            className="detail-btn"
            onClick={() => this.onClick(data.encounter.Id)}
            style={{ marginLeft: "5px" }}
          >
            Details &#xbb;
          </button>
        )}
      </div>
    );

    return card;
  }

  prepareDummyCard(i) {
    var style;
    var btnLeftRight;

    if (i === 0 || i % 2 === 0) {
      style = { justifyContent: "flex-end" };
      btnLeftRight = true;
    } else {
      style = { justifyContent: "flex-start" };
      btnLeftRight = false;
    }

    var card = (
      <div className="patient-card-body" style={style}>
        {!btnLeftRight && (
          <button
            className="detail-btn"
            style={{ marginRight: "5px", pointerEvents: "none" }}
            disabled
          >
            Details &#xbb;
          </button>
        )}
        {btnLeftRight && (
          <button
            className="detail-btn"
            style={{ marginLeft: "5px", pointerEvents: "none" }}
          >
            Details &#xbb;
          </button>
        )}
      </div>
    );

    return card;
  }
  render() {
    const matchedEncounters = this.props.matchEncounter(this.props.data);
    const loadingTimeline = [1, 2, 3, 4, 5, 6, 7];
    return (
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            color="var(--primary-color)"
            sx={{ marginTop: "0.7vh", fontSize: "15px" }}
          >
            {this.props.data.patient.data.BIRTHDATE !== undefined &&
              this.formatDate(this.props.data.patient.data.BIRTHDATE)}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                padding: 0,
              }}
            >
              <ChildCareIcon sx={{ color: "var(--primary-color)" }} />
            </TimelineDot>
            <TimelineConnector
              sx={{ backgroundColor: "var(--secondary-color)" }}
            />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
        {this.props.displayedDataProcessed
          ? matchedEncounters.map((data, index) => (
              <TimelineItem>
                <TimelineOppositeContent
                  color="var(--primary-color)"
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  {this.formatDate(data.encounter.START)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "var(--primary-color)" }}
                  />
                  {index < matchedEncounters.length - 1 && (
                    <TimelineConnector
                      sx={{ backgroundColor: "var(--secondary-color)" }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <div className="patient-card">
                    <div className="patient-card-head">
                      <p style={{ fontSize: "15px" }}>
                        {data.encounter.DESCRIPTION}
                      </p>
                      <p style={{ fontSize: "13px" }}>
                        Reason:{" "}
                        {data.encounter.REASONDESCRIPTION !== null
                          ? data.encounter.REASONDESCRIPTION
                          : "-"}
                      </p>
                    </div>
                    {this.prepareCard(data, index)}
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))
          : loadingTimeline.map((data, index) => (
              <TimelineItem>
                <TimelineOppositeContent
                  color="var(--primary-color)"
                  sx={{
                    fontSize: "15px",
                  }}
                >
                  <div
                    style={
                      index === 0 || index % 2 === 0
                        ? {}
                        : { display: "flex", justifyContent: "flex-end" }
                    }
                  >
                    <Skeleton width={80} />
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{ backgroundColor: "var(--primary-color)" }}
                  />
                  {index < loadingTimeline.length - 1 && (
                    <TimelineConnector
                      sx={{ backgroundColor: "var(--secondary-color)" }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <div className="patient-card">
                    <div className="patient-card-head">
                      <p style={{ fontSize: "15px" }}>
                        <Skeleton />
                      </p>
                      <p style={{ fontSize: "13px" }}>
                        <Skeleton />
                      </p>
                    </div>
                    {this.prepareDummyCard(index)}
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}

        {this.props.data.patient.data.DEATHDATE !== undefined &&
          this.props.data.patient.data.DEATHDATE !== null && (
            <TimelineItem>
              <TimelineOppositeContent
                color="var(--primary-color)"
                sx={{ marginTop: "2.6vh", fontSize: "15px" }}
              >
                {this.formatDate(this.props.data.patient.data.DEATHDATE)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector
                  sx={{ backgroundColor: "var(--secondary-color)" }}
                />
                <TimelineDot
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    padding: 0,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="var(--primary-color)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-grave-2"
                    style={{ marginLeft: "0.2vw" }}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 16.17v-9.17a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3v9.171" />
                    <path d="M12 7v5" />
                    <path d="M10 9h4" />
                    <path d="M5 21v-2a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v2h-14z" />
                  </svg>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          )}
      </Timeline>
    );
  }
}
