import React, { Component } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import GeneralSettings from "./GeneralSettings";
import PaitentRecordsSettings from "./PaitentRecordsSettings";

import PropTypes from "prop-types";

export default class OverviewSavedCriterions extends Component {
  // Component that displayes all saved filter criterions of the overview component -> allows to manage hidden and show criterions
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      settingsList: ["General Settings", "Patient Records"],
    };
  }

  handleChange = (event, newValue) => {
    //handles the chnage of tabs
    this.setState({ tabValue: newValue });
  };

  render() {
    return (
      <div>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "60vh",
            width: "70vw",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.tabValue}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              fontFamily: "Spline Sans",
            }}
          >
            {this.state.settingsList.map((file, index) => (
              <Tab label={file} {...a11yProps(index)} />
            ))}
          </Tabs>
          {this.state.settingsList.map((file, index) => (
            <TabPanel value={this.state.tabValue} index={index}>
              {file === "General Settings" && (
                <GeneralSettings
                  sHeaderDisplay={this.props.sHeaderDisplay}
                  changeSHeaderDisplay={this.props.changeSHeaderDisplay}
                  resetDialog={this.props.resetDialog}
                  newSnackbar={this.props.newSnackbar}
                  showDeleteSnackbar={this.props.showDeleteSnackbar}
                />
              )}
              {file === "Patient Records" && (
                <PaitentRecordsSettings
                  boringDiagnoses={this.props.boringDiagnoses}
                />
              )}
            </TabPanel>
          ))}
        </Box>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------ tabs functions from MUI------------------------------------------------------------------------------------------------------------- */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
