// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-main-box {
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  color: var(--text-color-app);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  font-family: "Geist Sans", sans-serif;
}

.synspector-logo {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.header-navigation-item {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}

.header-navigation-item:hover {
  color: var(--secondary-color);
}

.header-navigation-item-active {
  color: var(--primary-color);
  font-size: 13px;
  font-weight: 400;
}

.logo-nfdi-head {
  width: 115px;
}

.logo-iccas-head {
  width: 63px;
}

.icon-head {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}

.icon-head:hover {
  color: var(--secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/Head/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;EAC5B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".header-main-box {\n  height: 60px;\n  padding-left: 15px;\n  padding-right: 15px;\n  background-color: transparent;\n  color: var(--text-color-app);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100vw;\n  font-family: \"Geist Sans\", sans-serif;\n}\n\n.synspector-logo {\n  color: var(--primary-color);\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 20px;\n}\n\n.header-navigation-item {\n  font-size: 13px;\n  font-weight: 400;\n  cursor: pointer;\n}\n\n.header-navigation-item:hover {\n  color: var(--secondary-color);\n}\n\n.header-navigation-item-active {\n  color: var(--primary-color);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.logo-nfdi-head {\n  width: 115px;\n}\n\n.logo-iccas-head {\n  width: 63px;\n}\n\n.icon-head {\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  align-items: center;\n}\n\n.icon-head:hover {\n  color: var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
