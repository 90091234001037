// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 4vh;
}

.switch-div {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 7px;
}

.label-switch {
  font-size: 12px;
}

.filter-div {
  padding-top: 10px;
  margin-left: 20px;
  height: 53vh;
}

.include-div-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.include-div-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  align-items: center;
  min-height: 50px;
}

.exclude-div {
  margin-top: 7px;
}

.filter-body {
  width: 100%;
  height: 48vh;
  overflow-y: auto;
}

.h3-filter {
  font-family: Spline Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Filter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,WAAW;EACX,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".filter-head {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  align-items: center;\n  height: 4vh;\n}\n\n.switch-div {\n  width: 97%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 8px;\n  margin-bottom: 7px;\n}\n\n.label-switch {\n  font-size: 12px;\n}\n\n.filter-div {\n  padding-top: 10px;\n  margin-left: 20px;\n  height: 53vh;\n}\n\n.include-div-head {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 5px;\n}\n\n.include-div-body {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  padding: 6px;\n  width: 100%;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  border-radius: 16px;\n  align-items: center;\n  min-height: 50px;\n}\n\n.exclude-div {\n  margin-top: 7px;\n}\n\n.filter-body {\n  width: 100%;\n  height: 48vh;\n  overflow-y: auto;\n}\n\n.h3-filter {\n  font-family: Spline Sans;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
