import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import "./DountChartChartJs.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        padding: 10,
        boxWidth: 10,
        boxHeight: 10,
        font: {
          size: 11, // Anpassung der Schriftgröße der Legende
        },
      },
    },
    title: {
      display: false,
    },
  },
};

// Funktion zum Abrufen von CSS-Variablenwerten
const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
};

// Farben aus CSS-Variablen abrufen
const chartColors = [
  getCSSVariableValue("--chart-1"),
  getCSSVariableValue("--chart-2"),
  getCSSVariableValue("--chart-3"),
  getCSSVariableValue("--chart-4"),
  "rgb(158, 180, 197)",
];

const DonutChart = ({ data, labels, colors }) => {
  var alteredLabels = [];
  var alteredData = [];

  if (labels.length > 5) {
    alteredLabels = labels.slice(0, 4);
    alteredData = data.slice(0, 4);

    alteredLabels.push("Other");

    let frequencyOther = 0;

    for (let i = 4; i < data.length; i++) {
      frequencyOther += data[i];
    }

    alteredData.push(frequencyOther);
  } else {
    alteredData = data;
    alteredLabels = labels;
  }

  const dataChart = {
    labels: alteredLabels,
    datasets: [
      {
        label: "# of Votes",
        data: alteredData,
        backgroundColor: colors === undefined ? chartColors : colors,
        borderColor: colors === undefined ? chartColors : colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Doughnut data={dataChart} options={options} />
    </div>
  );
};

export default DonutChart;
