import React, { Component } from "react";

import {
  Chart as ChartJs,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

ChartJs.register(
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale
);

export default class BarCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: [],
      datasets: [],
      dataProcessed: false,
      isFullscreen: false,
      tabValue: 0,
    };
  }

  prepareBar(data, dataTyp) {
    var neededData = data[dataTyp].data;
    var labelObj = {};
    var codeArray = [];
    for (let i = 0; i < neededData.length; i++) {
      if (!codeArray.includes(neededData[i].CODE)) {
        codeArray.push(neededData[i].CODE);
        var label = { name: neededData[i].DESCRIPTION, frequency: 1 };
        labelObj[neededData[i].CODE] = label;
      } else {
        labelObj[neededData[i].CODE].frequency =
          labelObj[neededData[i].CODE].frequency + 1;
      }
    }

    return labelObj;
  }

  prepareChart(data, dataTyp, chartTyp, openObservation) {
    var labelData = {
      datasets: [],
      observationName: "",
      observationUnit: "",
    };

    labelData.datasets.push({
      label: dataTyp,
      data: [],
      backgroundColor: this.props.color,
      borderColor: this.props.color,
      borderWidth: 1,
    });

    var barData = this.prepareBar(data, dataTyp);
    let labels = [];
    for (const key in barData) {
      labels.push(barData[key].name);
      labelData.datasets[0].data.push(barData[key].frequency);
    }
    labelData["labels"] = labels;

    return labelData;
  }

  componentDidMount() {
    var data = this.prepareChart(
      this.props.data,
      this.props.dataTyp,
      this.state.chartTyp,
      this.state.openObservation
    );

    this.setState({ label: data.labels, datasets: data.datasets }, () =>
      this.setState({ dataProcessed: true })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataTyp !== this.props.dataTyp) {
      var data = this.prepareChart(
        this.props.data,
        this.props.dataTyp,
        this.state.chartTyp,
        this.state.openObservation
      );

      this.setState({ label: data.labels, datasets: data.datasets }, () =>
        this.setState({ dataProcessed: true })
      );
    }
  }

  render() {
    if (this.state.dataProcessed) {
      const data = {
        datasets: this.state.datasets,
      };

      const options = {
        scales: {
          y: {
            title: {
              display: true,
              text: "frequency in patient",
            },
          },
        },
      };
      data["labels"] = this.state.label;

      return (
        <div>
          <Bar data={data} options={options} />
        </div>
      );
    }
  }
}
