// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay__background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 90;
  pointer-events: auto;
}

.overlay__container {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 100;
  padding: 13px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  text-align: left;
  pointer-events: auto;
}

.overlay__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.overlay__close:after {
  display: inline-block;
  content: "\\00d7";
}

.overlay__close {
  border: none;
  background-color: transparent;
  font-size: 50px;
  cursor: pointer;
  width: 2vw;
}

.overlay-title {
  margin-left: 20px;
  font-family: Spline Sans;
  font-weight: 300;
  font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Overlay/Overlay.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,eAAe;EACf,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".overlay__background {\n  background-color: rgba(0, 0, 0, 0.5);\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  cursor: pointer;\n  z-index: 90;\n  pointer-events: auto;\n}\n\n.overlay__container {\n  background-color: white;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  z-index: 100;\n  padding: 13px;\n  width: fit-content;\n  height: fit-content;\n  border-radius: 10px;\n  text-align: left;\n  pointer-events: auto;\n}\n\n.overlay__controls {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 5px;\n}\n\n.overlay__close:after {\n  display: inline-block;\n  content: \"\\00d7\";\n}\n\n.overlay__close {\n  border: none;\n  background-color: transparent;\n  font-size: 50px;\n  cursor: pointer;\n  width: 2vw;\n}\n\n.overlay-title {\n  margin-left: 20px;\n  font-family: Spline Sans;\n  font-weight: 300;\n  font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
