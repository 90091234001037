const dayjs = require("dayjs");
const { default: Dexie } = require("dexie");
//const Dexie = require("dexie");

// all functions that are needed to post process the json files in order for the website to run properly

function checkWhereIsDate(data) {
  //checks which colums are dates and then alters all entreies into dates -> only shoots if papa didnt automatically alter it
  let dateKeys = [];
  let allDateKeys = [];
  let emptyColums = [];
  //allowed date formats
  const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}Z)?$/;

  //check for columns that are dates
  for (let i = 0; i < data.length; i++) {
    if (i === 0) {
      //check for dates
      for (const key in data[i]) {
        //if colum is empty push in array
        if (data[i][key] === null) {
          emptyColums.push(key);
        } else {
          if (regex.test(data[i][key])) {
            dateKeys.push(key);
            allDateKeys.push(key);
          }

          if (data[i][key] instanceof Date) {
            allDateKeys.push(key);
          }
        }
      }
    } else {
      //only shoots if in first row there is an empty column
      for (let index = emptyColums.length - 1; index >= 0; index--) {
        if (data[i][emptyColums[index]] !== null) {
          // Eintrag löschen, wenn die Bedingung zutrifft
          if (regex.test(data[i][emptyColums[index]])) {
            dateKeys.push(emptyColums[index]);
            allDateKeys.push(emptyColums[index]);
          }

          if (data[i][emptyColums[index]] instanceof Date) {
            allDateKeys.push(emptyColums[index]);
          }

          emptyColums.splice(index, 1);
        }
      }
    }

    //stops if all colums have been looked at
    if (emptyColums.length === 0) {
      break;
    }
  }

  //alters colums dates into date objs
  for (let i = 0; i < data.length; i++) {
    var dateObject = {};
    //create date objects
    for (let index = 0; index < dateKeys.length; index++) {
      if (data[i][dateKeys[index]] !== null) {
        dateObject[dateKeys[index]] = new Date(data[i][dateKeys[index]]);
      }
    }

    //exchange date string with date object
    for (let index = 0; index < dateKeys.length; index++) {
      if (dateObject[dateKeys[index]] !== undefined) {
        data[i][dateKeys[index]] = dateObject[dateKeys[index]];
      }
    }
  }

  return { data: data, allDateKeys: allDateKeys };
}

async function addConditionsToPatientsDexie(db, conditionsPresent) {
  try {
    // 1. Patienteninformationen abrufen (nur die IDs)
    const patients = await db.patients.toArray();

    for (const patient of patients) {
      if (conditionsPresent) {
        const patientId = patient.Id;

        // 2. Bedingungen für den aktuellen Patienten abrufen
        const conditions = await db.conditions
          .where("PATIENT")
          .equals(patientId)
          .toArray();

        // 3. Bedingungen als Array im Patienten speichern
        await db.patients.update(patient.primaryKey, {
          CONDITIONS: conditions,
        });
      } else {
        // Wenn keine Bedingungen vorhanden sind, CONDITIONS auf ein leeres Array setzen
        await db.patients.update(patient.primaryKey, { CONDITIONS: [] });
      }
    }
    return "success";
  } catch (error) {
    console.error("Fehler beim Abrufen der Bedingungen:", error);
  }
}

async function addConditionsToPatients(db, conditionsPresent) {
  try {
    const patientCollection = db.collection("patients");

    const conditionCollection = db.collection("conditions");

    // 1. Patienteninformationen holen
    const patients = await patientCollection
      .find({}, { projection: { _id: 1, Id: 1 } })
      .toArray();

    for (const patient of patients) {
      if (conditionsPresent) {
        const patientId = patient.Id;

        // 2. Bedingungen für den Patienten abrufen
        const conditions = await conditionCollection
          .find({ PATIENT: patientId })
          .toArray();

        // 3. Bedingungen als Array im Patienten speichern
        await patientCollection.updateOne(
          { _id: patient._id },
          { $set: { CONDITIONS: conditions } }
        );
      } else {
        await patientCollection.updateOne(
          { _id: patient._id },
          { $set: { CONDITIONS: [] } }
        );
      }
    }
  } catch (error) {
    console.error("Fehler beim Abrufen der Bedingungen:", error);
  } finally {
    return "succuss";
  }
}

async function addAgeToDataDexie(db) {
  try {
    console.log("started AddAge");
    // 1. Jüngstes STOP-Datum aus der encounters-Tabelle ermitteln
    const youngestEncounter = await db
      .table("encounters")
      .orderBy("STOP")
      .last();
    const youngestDateOfSimulation = youngestEncounter
      ? youngestEncounter.STOP
      : null;
    console.log("youngestDateOfSimulation", youngestDateOfSimulation);

    if (!youngestDateOfSimulation) {
      throw new Error(
        "Kein gültiges STOP-Datum in der Encounters-Tabelle gefunden."
      );
    }

    // 2. Alle Patienten-Daten abrufen (nur _id, BIRTHDATE, und DEATHDATE)
    const patients = await db.patients.toArray();

    for (const patient of patients) {
      const birthday = patient.BIRTHDATE;
      const deathDate = patient.DEATHDATE;

      // Berechnung des Enddatums basierend auf Todesdatum oder jüngstem Encounter-Datum
      let endDate = deathDate || youngestDateOfSimulation;
      const ageObject = calculateAge(
        birthday,
        endDate,
        youngestDateOfSimulation
      );

      // 3. Patient mit dem berechneten Alter aktualisieren, ohne andere Felder zu verändern
      await db.patients.update(patient.primaryKey, {
        AGE: ageObject,
        AGESIMPLE: ageObject.years,
      });
    }
    return "success";
  } catch (error) {
    console.error("Fehler bei der Altersberechnung:", error);
    return "error";
  }
}

function calculateAge(birthday, endDate, estimatedEndOfSimulation) {
  const start = dayjs(birthday);
  const end = dayjs(endDate);

  const years = end.diff(start, "year");
  const months = end.diff(start, "month");

  return {
    years: years,
    months: months,
    estimatedEndOfSimulation: estimatedEndOfSimulation,
  };
}

async function addAgeToData(db) {
  try {
    const youngestEncounterStop = await db
      .collection("encounters")
      .aggregate([
        { $sort: { STOP: -1 } },
        { $limit: 1 },
        { $project: { _id: 0, STOP: 1 } },
      ])
      .limit(1)
      .toArray();
    const youngestDateOfSimulation = youngestEncounterStop[0].STOP;

    const patients = await db
      .collection("patients")
      .find({}, { projection: { _id: 1, BIRTHDATE: 1, DEATHDATE: 1 } })
      .toArray();

    for (const patient of patients) {
      const birthday = patient.BIRTHDATE;
      const deathDate = patient.DEATHDATE;

      // Berechnung des Alters basierend auf dem Geburtstag und Todesdatum oder estimatedEndOfSimulation
      let endDate = deathDate ? deathDate : youngestDateOfSimulation;
      const ageObject = calculateAge(
        birthday,
        endDate,
        youngestDateOfSimulation
      );

      // Aktualisiere den Patienten mit dem berechneten Alter, ohne andere Felder zu verändern
      await db
        .collection("patients")
        .updateOne(
          { _id: patient._id },
          { $set: { AGE: ageObject, AGESIMPLE: ageObject.years } }
        );
    }
  } catch (error) {
    console.error("Fehler bei der Altersberechnung:", error);
    return "error";
  } finally {
    return "success";
  }
}

module.exports = {
  checkWhereIsDate,
  addAgeToData,
  addConditionsToPatients,
  addConditionsToPatientsDexie,
  addAgeToDataDexie,
};
