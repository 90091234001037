// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-card {
  background-color: white;
  border: 1px solid rgba(100, 100, 100, 0.301);
  border-radius: 5px;
  font-family: Spline Sans;
  padding: 10px;
}

.patient-card-body {
  width: 100%;
}

.detail-btn {
  border: none;
  background-color: transparent;
  color: #aabcf2;
  cursor: pointer;
  margin-top: 5px;
}

.detail-btn:hover {
  color: #fdc4de;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Timeline.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4CAA4C;EAC5C,kBAAkB;EAClB,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,cAAc;EACd,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".patient-card {\n  background-color: white;\n  border: 1px solid rgba(100, 100, 100, 0.301);\n  border-radius: 5px;\n  font-family: Spline Sans;\n  padding: 10px;\n}\n\n.patient-card-body {\n  width: 100%;\n}\n\n.detail-btn {\n  border: none;\n  background-color: transparent;\n  color: #aabcf2;\n  cursor: pointer;\n  margin-top: 5px;\n}\n\n.detail-btn:hover {\n  color: #fdc4de;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
