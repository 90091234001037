// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synthea-view-main {
  height: 100vh;
  width: 100vw;
  min-width: 1200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.syn-view-content-box {
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  min-height: 0;
}

.background-main-view {
  background: var(--background-main);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.body-view-main {
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}

.content-box {
  width: 85%;
  padding-top: 25px;
  padding-left: 25px;
  overflow: hidden;
}

.general-btn {
  background-color: #4361ee;
  border: none;
  height: 45px;
  margin: 5px;
  min-width: 100px;
  border-radius: 25px;
  color: #f8f4f0;
  cursor: pointer;
}

.general-btn:hover {
  border: 2px solid #fdc4de;
  color: #fdc4de;
}
`, "",{"version":3,"sources":["webpack://./src/SyntheaView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".synthea-view-main {\n  height: 100vh;\n  width: 100vw;\n  min-width: 1200px;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.syn-view-content-box {\n  flex-grow: 1;\n  flex-shrink: 1;\n  padding-left: 8px;\n  padding-right: 8px;\n  padding-bottom: 8px;\n  width: 100%;\n  box-sizing: border-box;\n  min-height: 0;\n}\n\n.background-main-view {\n  background: var(--background-main);\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n}\n\n.body-view-main {\n  overflow-y: hidden;\n  height: 100%;\n  width: 100%;\n}\n\n.content-box {\n  width: 85%;\n  padding-top: 25px;\n  padding-left: 25px;\n  overflow: hidden;\n}\n\n.general-btn {\n  background-color: #4361ee;\n  border: none;\n  height: 45px;\n  margin: 5px;\n  min-width: 100px;\n  border-radius: 25px;\n  color: #f8f4f0;\n  cursor: pointer;\n}\n\n.general-btn:hover {\n  border: 2px solid #fdc4de;\n  color: #fdc4de;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
