import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

/** 
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 14 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 31 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 31 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 11 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];
*/

export default class OverviewSavedCriterions extends Component {
  // Component that displayes the raw csv table

  constructor(props) {
    super(props);
    this.state = {
      columns: [{ field: "id", headerName: "ID", width: 90 }],
      rows: [],
      loadingDone: false,
    };
  }

  getColums(dbName, storeName) {
    // gets all colums from indexedDB
    return new Promise((resolve, reject) => {
      var request = indexedDB.open(dbName);

      request.onerror = function (event) {
        reject("Fehler beim Öffnen der Datenbank");
      };

      request.onsuccess = function (event) {
        var db = event.target.result;

        // Überprüfen, ob der Objektspeicher existiert
        if (db.objectStoreNames.contains(storeName)) {
          var transaction = db.transaction(storeName, "readonly");
          var store = transaction.objectStore(storeName);

          var columns = [];
          Array.from(store.indexNames).forEach((column) =>
            columns.push({
              field: column,
              headerName: column.toUpperCase(),
              width: 150,
              editable: false,
            })
          );
          // Rückgabe der Indizes
          resolve(columns);
        } else {
          reject("Objektspeicher existiert nicht: " + storeName);
        }
      };

      request.onupgradeneeded = function (event) {
        reject("Datenbankversion wurde aktualisiert");
      };
    });
  }

  getAllRows(dbName, storeName) {
    //returns all enties of DB
    return new Promise((resolve, reject) => {
      var request = indexedDB.open(dbName);

      request.onerror = function (event) {
        reject("Fehler beim Öffnen der Datenbank");
      };

      request.onsuccess = function (event) {
        var db = event.target.result;

        // Überprüfen, ob der Objektspeicher existiert
        if (db.objectStoreNames.contains(storeName)) {
          var transaction = db.transaction(storeName, "readonly");
          var store = transaction.objectStore(storeName);

          // Öffnen des Cursors und Abrufen aller Einträge
          var requestCursor = store.openCursor();
          var results = [];

          requestCursor.onsuccess = function (event) {
            var cursor = event.target.result;
            if (cursor) {
              var result = cursor.value;
              //result["id"] = cursor.primaryKey;
              results.push(result);
              cursor.continue();
            } else {
              resolve(results);
            }
          };

          requestCursor.onerror = function (event) {
            reject("Fehler beim Öffnen des Cursors");
          };
        } else {
          reject("Objektspeicher existiert nicht: " + storeName);
        }
      };

      request.onupgradeneeded = function (event) {
        reject("Datenbankversion wurde aktualisiert");
      };
    });
  }

  getMongoColumns = async (dbName, collectionName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getIndicesOfCollection",
        {
          params: {
            dbName: dbName,
            collectionName: collectionName,
          },
        }
      );
      if (response.data.status === "success") {
        const columns = [];

        response.data.data.forEach((column) =>
          columns.push({
            field:
              column === "_id_"
                ? column.replace("d_", "d")
                : column.replace("_1", ""),
            headerName:
              column === "_id_"
                ? column.replace("d_", "d")
                : column.replace("_1", "").toUpperCase(),
            width: 150,
            editable: false,
          })
        );

        this.setState({ columns: columns });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getMongoAllRows = async (dbName, collectionName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getAllDataOfCollection",
        {
          params: {
            dbName: dbName,
            collectionName: collectionName,
          },
        }
      );
      if (response.data.status === "success") {
        this.setState({ rows: response.data.data }, () =>
          this.setState({ loadingDone: true })
        );
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  componentDidMount() {
    if (this.props.location === "index") {
      this.getColums(this.props.dbName, this.props.openFile)
        .then((indexes) => {
          this.setState({ columns: indexes });
        })
        .catch((error) => {
          console.error("Fehler:", error);
        });

      this.getAllRows(this.props.dbName, this.props.openFile)
        .then((rows) => {
          this.setState({ rows: rows }, () =>
            this.setState({ loadingDone: true })
          );
        })
        .catch((error) => {
          console.error("Fehler:", error);
        });
    } else if (this.props.location === "mongo") {
      this.getMongoColumns(this.props.dbName, this.props.openFile);
      this.getMongoAllRows(this.props.dbName, this.props.openFile);
    }
  }

  render() {
    return (
      <div style={this.props.style}>
        <Backdrop
          sx={{
            color: "#4169e1",
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.623)",
          }}
          open={!this.state.loadingDone}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          rows={this.state.rows}
          getRowId={(row) =>
            this.props.location === "mongo" ? row._id : row.primaryKey
          }
          columns={this.state.columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          disableRowSelectionOnClick
          sx={{
            ".MuiTablePagination-displayedRows": {
              marginTop: "1em",
              marginBottom: "1em",
            },
            ".MuiTablePagination-selectLabel": {
              marginTop: "1em",
              marginBottom: "1em",
            },
          }}
        />
      </div>
    );
  }
}
